import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, HostBinding } from "@angular/core";

@Component({
    selector: "af-panel",
    styleUrls: ["./panel.component.scss"],
    exportAs: "panel",
    template: `
    <div class="panel-box">
        <div class="panel-box-title" (click)="toggle()">
            <ng-content select="[title]"></ng-content>
            <div class="panel-box-buttons" (click)="$event.stopPropagation()"><ng-content select="[buttons]"></ng-content></div>
            <span class="fa" [class.fa-angle-down]="!expanded" [class.fa-angle-up]="expanded"></span>
        </div>
        <div class="panel-box-body" *ngIf="expanded">
            <ng-content></ng-content>
        </div>
    </div>
    `
})
export class PanelComponent {
    @Input() expanded = false;

    toggle() {
        this.expanded = !this.expanded;
    }
}
