import { Component, Input, ViewChild, OnInit, OnChanges, Output, EventEmitter, ElementRef } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

import { CompanyService } from "./company.service";
import { CompanyFileService, SharePointFolderAndFiles } from "./company-file.service";
import { ListQueryParams } from "../../models/list-query-params.model";
import { FileUploadOptions } from "../../shared/components/file-upload/file-upload.service";
import { Immutable } from "../../core/immutable";
import { AppService } from "../../app.service";
import { finalize } from "rxjs/operators";
import { AccountCenterService } from '../../organization/account-center/account-center.service';
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";

class QueryParams extends ListQueryParams {
    companyId: number;
    path: string;
    createFolder: boolean;
    documentType: string;
    invoiceNumber: string;
    archiveLocation: string;
    showToast: boolean;
    isMetriqFiles: boolean = false;
}

class MetriqFolder {
    originalFolderName: string;
    newFolderName: string;
    isRenaming: boolean;
}
@Component({
    selector: "crm-company-file-list",
    templateUrl: "./company-file-list.component.html"
})
export class CompanyFileListComponent implements OnInit, OnChanges {
    @Input() archiveLocation: string;
    @Input() path: string;
    @Input() createFolder: boolean;
    @Input() isFilter: boolean = true;
    @Input() isUpload: boolean = true;
    //@Input() refreshFiles: boolean = false;
    @Input() content: SharePointFolderAndFiles;
    @Output() contentChange = new EventEmitter();
    @Output() countChange = new EventEmitter<number>();

    @Input() documentType: string;
    @Input() companyId: string;
    @Input() invoiceNumber: string;

    @Input() changeCounter: number = 0;
    @Output() filesChange = new EventEmitter<PagedList<app.sharepoint.SharePointFileViewModel>>();
    @ViewChild("newFolder", { static: true }) newFolderRef: ElementRef;
    modalRef: NgbModalRef;
    languageId: string;
    company: app.crm.CompanyInsertUpdateViewModel;
    queryParams = new QueryParams();
    objectKey: string;
    uploadOptions: FileUploadOptions;
    accountCenterList: app.organization.AccountCenterSelectListViewModel[] = [];
    selectedAccountCenters: string[] = [];
    selectedNotificationInterval: number = 0;
    _model: PagedList<app.sharepoint.SharePointFileViewModel>;
    notificationList: app.crm.CompanyFileNotificationSimpleViewModel[];
    emptyFileList: PagedList<app.sharepoint.SharePointFileViewModel> = {
        data: [],
        page: 0,
        pageSize: 0,
        totalCount: 0
    };
    isMetriqFileMode = false;
    metriqFolders: MetriqFolder[];
    newFolderName: string;
    get model() { return this._model };

    set model(list: PagedList<app.sharepoint.SharePointFileViewModel>) {
        this._model = list;
        this.filesChange.emit(list);
    }

    isLoading: boolean;
    sortBy: string;
    isSharepointEnabled = false;
    isLoadingSharePoint = false;

    constructor(protected router: Router, protected route: ActivatedRoute,
        private companyService: CompanyService,
        private companyFileService: CompanyFileService,
        private accountCenterService: AccountCenterService,
        private sanitizer: DomSanitizer,
        private modalService: NgbModal,
        private appService: AppService) {
        this.queryParams.sortBy = this.sortBy = "lastUpdatedUtc desc";
        this.objectKey = companyFileService.objectKey;
        this.languageId = appService.homeViewModel.user.language;
        this.isSharepointEnabled = this.appService.isSharepointEnabled;
    }

    ngOnInit() {
        this.onReset();
        this.setUploadOptions();
        this.companyFileService.getNotificationList(this.queryParams.companyId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.notificationList = res;
            });

        this.companyService.get(this.queryParams.companyId, false).subscribe((result) => {
            this.company = result.model;
        });
        if (this.content == null) {
            //this.loadFiles();
            this.loadFolderAndFiles();
        } else {
            this.model = this.content.files;
        }

        this.accountCenterService.getSelectList()
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.accountCenterList = res;
            });
    }

    ngOnChanges() {
        this.queryParams.archiveLocation = this.archiveLocation;
        this.queryParams.createFolder = this.createFolder;

        if (this.queryParams.companyId) {
            this.setUploadOptions();
            //this.loadFiles();
            this.loadFolderAndFiles();
        }
    }

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    target(item: app.sharepoint.SharePointFileViewModel) {
        if (item.fileUrl.startsWith("http") || item.fileUrl.startsWith("/")) return "_blank";
        else return "";
    }

    onSuccessUpload(data: any): void {
        this.loadFiles();
    }

    loadFiles() {
        this.isLoading = true;

        this.companyFileService.query(this.queryParams)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.model = res;
                this.afterFilesLoaded();
            }, err => {
                this.model = this.emptyFileList;
                this.afterFilesLoaded();
            });
    }

    afterFilesLoaded() {
        // Fill NotificationInterval and AccountCenter in observable
        let newData: app.sharepoint.SharePointFileViewModel[] = [];
        if (this.model && this.model.data) {
            this.model.data.forEach((item) => {
                let notifInterval = this.notificationList.find(x => x.uniqueId == item.uniqueId);
                let acList = this.notificationList.find(x => x.uniqueId == item.uniqueId);
                let itemCopy = { ...item };

                itemCopy.notificationInterval = notifInterval == null ? null : notifInterval.notificationInterval;
                itemCopy.accountCenterIds = acList == null ? null : acList.accountCenterList.split(';');
                newData.push(itemCopy);
            });
        }
        else {
            console.log("this.model.data missing");
        }

        this.model = { ...this.model, data: newData };

        // emit updates
        this.countChange.emit(this.model.totalCount);
        this.content = { ...this.content, files: this.model };
        this.contentChange.emit(this.content);

    }

    loadFolderAndFiles() {
        if (this.isSharepointEnabled) {
            this.isLoading = true;
            this.isLoadingSharePoint = true;
            this.queryParams.isMetriqFiles = this.isMetriqFileMode;
            this.companyFileService.getSharePointFolderAndFiles(this.queryParams)
                .pipe(finalize(() => {
                    this.isLoading = false;
                    this.isLoadingSharePoint = false;
                }))
                .subscribe(folderAndFiles => {
                    this.content = {
                        ...this.content,
                        folder: folderAndFiles.folder
                    };

                    this.metriqFolders = this.content.folder.subFolders.map(m => <MetriqFolder>{
                        isRenaming: false,
                        newFolderName: m,
                        originalFolderName: m
                    });

                    if (this.content && this.content.folder && this.content.folder.archiveLocation != this.archiveLocation) {
                        this.queryParams.archiveLocation = this.archiveLocation = this.content.folder.archiveLocation;
                        this.setUploadOptions();
                    }

                    this.model = folderAndFiles.files;
                    this.afterFilesLoaded();
                }, () => {
                    // if archive does not contain folder
                    if (!this.isTopFolder) {
                        // go to upper folder (when switching to different archive location and missing year folder)
                        this.goToUpperFolder();
                    }

                    // clean folder data (because folder does not exists)
                    this.content = { ...this.content, folder: null };

                    // clean file list
                    this.model = this.emptyFileList;
                    this.afterFilesLoaded();
                });
        }
    }

    delete(item: app.sharepoint.SharePointFileViewModel) {
        this.companyFileService.delete(item,
            item.uniqueId.toString(),
            false,
            { archiveLocation: this.queryParams.archiveLocation }).subscribe(() => this.loadFiles());
    }

    editItem(item: app.sharepoint.SharePointFileViewModel, propName, oldValue: any, newValue: any) {
        let propObj = {};
        if (!item['originalValues'] || (item['originalValues'] && !item['originalValues'][propName])) {
            propObj['originalValues'] = item['originalValues'] || {};
            propObj['originalValues'][propName] = oldValue;
        }
        propObj[propName] = newValue;

        let index = this.model.data.indexOf(item);
        this.model = Immutable.setInPagedList(this.model, index, propObj);
    }

    onEdit(item: app.sharepoint.SharePointFileViewModel) {
        let index = this.model.data.indexOf(item);
        let indexForNullEdit = 0;
        //disable different edited rows
        this.model.data.forEach((a) => {
            this.model = Immutable.setInPagedList(this.model, indexForNullEdit, { isEditing: false });
            indexForNullEdit++;
        });
        this.model = Immutable.setInPagedList(this.model, index, { isEditing: true });
        this.selectedAccountCenters = item.accountCenterIds != null ? item.accountCenterIds : [];
        this.selectedNotificationInterval = item.notificationInterval >= 1 ? item.notificationInterval : 0;
    }

    onCancel(item: app.sharepoint.SharePointFileViewModel) {
        let index = this.model.data.indexOf(item);
        // revert changes
        let originalValues = item['originalValues'] || {};
        this.model = Immutable.setInPagedList(this.model, index, { ...originalValues, isEditing: false, originalValues: null });
    }

    onSave(item: app.sharepoint.SharePointFileViewModel) {
        item.notificationInterval = this.selectedNotificationInterval;
        item.accountCenterIds = this.selectedAccountCenters;
        let index = this.model.data.indexOf(item);
        this.model = Immutable.setInPagedList(this.model, index, { isLoading: true });
        this.companyFileService.update(item, item.uniqueId.toString())
            .pipe(finalize(() => this.model = Immutable.setInPagedList(this.model, index, { isLoading: false })))
            .subscribe(res => {
                this.model = Immutable.setInPagedList(this.model, index, { isEditing: false, originalValues: null });
            });
    }

    onReset() {
        this.onClear();
    }

    onClear() {
        this.queryParams.page = 1;
        this.queryParams.pageSize = 20;
        this.queryParams.searchTerm = null;
        this.queryParams.companyId = +(this.appService.getParam("companyId") || this.companyId);
        this.queryParams.path = this.path;
        this.queryParams.documentType = this.documentType;
        this.queryParams.invoiceNumber = this.invoiceNumber;
        this.queryParams.archiveLocation = this.archiveLocation;
        this.queryParams.showToast = false;
    }

    goToPath(index: number) {
        this.path = this.content.folder.path.slice(1, index + 1).join("/");
        this.onReset();

        this.setUploadOptions();
        this.loadFolderAndFiles();
    }

    goToFolder(folderName: string) {
        this.isMetriqFileMode = false;

        let currentPath = this.content.folder.path.slice(1).join("/");
        folderName = folderName ? folderName : "";
        this.path = (currentPath ? currentPath + "/" : "") + folderName;
        this.loadFolderMethods();
    }

    goToMetriqFolder(folderName: string, showInfoToast: true) {
        this.isMetriqFileMode = true;
        this.newFolderName = null;
        let currentPath = this.content.folder.path.slice(1).join("/");
        folderName = folderName ? folderName : "";
        this.path = (currentPath ? currentPath + "/" : "") + folderName;
        this.loadFolderMethods();

        if (showInfoToast) {
            toastr.info(this.languageId == "sk" ? "Boli ste navigovaný do priečinku: " + folderName : "You was navigated into folder: " + folderName);
        }
        //close modal if opened
        this.onClose();
    }

    loadFolderMethods() {
        this.onReset();
        this.setUploadOptions();
        this.loadFolderAndFiles();
    }

    setUploadOptions() {
        this.uploadOptions = this.companyFileService.getUploadOptions(this.queryParams.companyId,
            this.path,
            this.documentType,
            this.invoiceNumber,
            this.archiveLocation,
            this.isMetriqFileMode);
    }

    isEconomyFolder() {
        return this.path && this.path.indexOf("Economy") == 0;
    }

    isMetriQFilesFolder() {
        return this.path && this.path.indexOf("me/fi/co") == 0;
    }

    get isTopFolder() {
        return !this.content || !this.content.folder || !this.content.folder.path || this.content.folder.path.length == 1
    };

    goToUpperFolder() {
        if (!this.content || !this.content.folder || !this.content.folder.path || this.content.folder.path.length < 2) {
            return;
        }

        this.goToPath(this.content.folder.path.length - 2);
    }

    accountCenterChange(code: string) {
        let index = this.selectedAccountCenters.indexOf(code);
        if (index > -1) {
            this.selectedAccountCenters.splice(index, 1);
        } else {
            this.selectedAccountCenters.push(code);
        }
    }

    sort(column: string) {
        if (this.queryParams.sortField() === column) {
            this.queryParams.setSortOrder(
                this.queryParams.sortOrder() === "desc" ? null : "desc"
            );
        } else {
            this.queryParams.sortBy = column;
        }
        this.loadFiles();
    }

    isSorted(column: string, order: string) {
        return (
            this.queryParams.sortField() === column &&
            this.queryParams.sortOrder() === order
        );
    }

    paginate(event) {
        this.queryParams.page = event.page;
        this.queryParams.pageSize = event.pageSize;
        this.loadFiles();
    }

    onRenameFolder(folder: MetriqFolder) {
        folder.isRenaming = true;
    }

    onCancelRename(folder: MetriqFolder) {
        folder.isRenaming = false;
    }

    onSaveRename(folder: MetriqFolder) {

        let request = <app.crm.RenameFolderRequest>{
            companyId: this.company.companyId,
            newName: folder.newFolderName,
            oldName: folder.originalFolderName,
            path: this.path
        };

        this.companyFileService.renameFolder(request)
            .pipe(finalize(() => {
                folder.isRenaming = false;
                this.loadFolderAndFiles();
            }
            ))
            .subscribe(res => { 
            });
    }

    createFolderModal() {
        if (this.modalRef) {
            this.modalRef.close();
        }

        if (this.newFolderRef) {
            this.modalRef = this.modalService.open(this.newFolderRef);
        }
    }

    onClose() {
        if (this.modalRef) {
            this.modalRef.close();
        }
    }

    deleteFolder(folder: MetriqFolder) {  
        let currentPath = this.content.folder.path.slice(1).join("/");
        this.companyFileService.deleteFolder(this.company.companyId, currentPath, folder.originalFolderName)
            .pipe(finalize(() => {
                this.loadFolderAndFiles();
            }))
            .subscribe(res => {
            });
    }
}
