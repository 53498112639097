import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class EmployeeTripService
    extends HttpService<app.operation.EmployeeTripListViewModel, app.operation.EmployeeTripFormViewModel, app.operation.EmployeeTripInsertUpdateViewModel>
{
    objectKey = "op.EmployeeTrip";

    constructor(http: HttpClient) {
        super(http, "/api/operation/employeetrip/");
    }

    getFilter(): Observable<app.operation.EmployeeTripFilterViewModel> {
        return this.http.get<app.operation.EmployeeTripFilterViewModel>(this.url + "filter");
    }

}
