import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class RequestLogService {
  objectKey = "sy.requestlog";
  url: string;

  constructor(private http: HttpClient) {
    this.url = "/api/sys/requestlog/";
  }

  query(params?: HttpParams): Observable<any> {
    return this.http.get(this.url, { params });
  }

  getById(id: number): Observable<any> {
    return this.http.get(this.url + id.toString());
  }
}
