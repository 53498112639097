import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpClient, HttpParams } from "@angular/common/http";
import { getParameters } from "../../core/utils";

@Injectable()
export class WagesService
{
    url = "/api/b2b/wages/";

    constructor(private http: HttpClient) {}

    getEmployeeWagesAsync(deliveryNoteId: number) {
        let params = new HttpParams();
        params = params.set("deliveryNoteId", deliveryNoteId.toString());
        return this.http.get<app.b2b.WagesEmployeeViewModel>(this.url + "employee/", { params });
    }

    getProviderWagesAsync(majorProviderDeliveryNoteId: number) {
        let params = new HttpParams();
        params = params.set("majorProviderDeliveryNoteId", majorProviderDeliveryNoteId.toString());
        return this.http.get<app.b2b.WagesProviderViewModel>(this.url + "provider/", { params });
    }

    getWagesAsync(query) {
        let params = getParameters(query);
        return this.http.get<app.b2b.WagesAllEmployeeViewModel>(this.url + "report/", { params });
    }

    getReportUrl(params: any) {
        let parameters = getParameters(params);
        return this.url + `report/file?${parameters.toString()}`;
    }

    getFilter(): Observable<app.b2b.WagesFilterViewModel> {
        return this.http.get<app.b2b.WagesFilterViewModel>(this.url + "filter");
    }
}
