
import { switchMap, distinctUntilChanged, take, mergeMap } from "rxjs/operators";
import { Injectable, LOCALE_ID, Inject } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { MissingTranslationHandler, TranslateLoader, MissingTranslationHandlerParams, TranslateParser, TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { Store } from '@ngrx/store';
import { State } from "../../store/reducers";
import { AppService } from '../../app.service';

export function createTranslateLoader(http: HttpClient) {
    return new TextLoaderTranslateService(http);
}

export class TextLoaderTranslateService implements TranslateLoader {
    constructor(private http: HttpClient) { }

    getTranslation(lang: string): Observable<any> {
        if (!lang) return of();
        let urlParams = new HttpParams();
        urlParams = urlParams.set("allowAnonymous", "true");
        return this.http.get("/text/" + lang, { params: urlParams });
    }
}

@Injectable()
export class TextMissingTranslationHandler implements MissingTranslationHandler {
    constructor(private http: HttpClient, private store: Store<State>) { }

    handle(params: MissingTranslationHandlerParams) {
        return this.store.pipe(take(1), mergeMap(state => {
            let languageId = state.app.language;
            let urlParams = new HttpParams();
            urlParams = urlParams.set("id", params.key);
            urlParams = urlParams.set("allowAnonymous", "true");
            return this.http
                .get("/text/" + languageId, { params: urlParams, responseType: "text" }).pipe(
                    distinctUntilChanged(),
                    switchMap(res => {
                        let result = res;
                        if (!result) {
                            result = " ";
                        }
                        params.translateService["translations"][languageId][params.key] = result;
                        return params.translateService.get(params.key, params.interpolateParams);
                    }));
        }));
    }
}
