import { Component, Input, forwardRef, ViewChild, ElementRef } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { makeRandomId } from "src/app/core/utils";

export const RADIO_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RadioComponent),
    multi: true
};

@Component({
    selector: "af-radio",
    template: `
        <div class="form-check" [class.form-check-inline]="inline">
            <input type="radio"
                #input
                [id]="id"
                [name]="name"
                [checked]="checked"
                [value]="value"                
                (focus)="onFocus($event)"
                (blur)="onBlur($event)"
                (change)="onChange($event)"
                class="form-check-input" />
            <label class="form-check-label" [for]="id"><ng-content></ng-content> {{label}}</label>
        </div>
    `,
    providers: [RADIO_VALUE_ACCESSOR]
})
export class RadioComponent implements ControlValueAccessor {
    @ViewChild("input", { static: true }) input: ElementRef;

    @Input() value: any;

    @Input() name: string;

    @Input() disabled: boolean;

    @Input() inline: boolean;

    @Input() label: string;

    @Input() compareWith: (o1: any, o2: any) => boolean;

    checked: boolean;

    protected model: any;

    protected hover: boolean;

    protected focused: boolean;

    private _id: string;

    constructor() {
        this._id = makeRandomId();
    }

    get id() {
        return (this.name ? this.name.replace(".", "_") : this.name) + this._id;
    }

    writeValue(model: any): void {
        this.model = model;
        let checked: boolean;

        if (this.compareWith && this.model && this.value) {
            checked = this.compareWith(this.model, this.value);
        }

        this.checked = (this.model === this.value) || checked;
    }

    registerOnChange(fn: Function): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onModelTouched = fn;
    }

    setDisabledState(val: boolean): void {
        this.disabled = val;
        this.input.nativeElement.disabled = val;
    }

    onFocus(event) {
        this.focused = true;
    }

    onBlur(event) {
        this.focused = false;
        this.onModelTouched();
    }

    onChange(event) {
        this.checked = true;
        this.onModelChange(this.value);
    }

    isDisabled() {
        return this.disabled;
    }

    protected onModelChange: Function = () => { };

    protected onModelTouched: Function = () => { };
}
