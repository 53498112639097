export class Immutable {
    static setInPagedList<T>(model: PagedList<T>, index: number, updatedProperties: any) {
        let data = this.set(<any>model.data, index, updatedProperties);
        let result = { ...model, ...{ data } };
        return result;
    }

    static set(array: any[], index: number, updatedProperties: any) {
        return array.map((item, ind) => {
            if (ind !== index) {
                // This isn't the item we care about - keep it as-is
                return item;
            }

            // Otherwise, this is the one we want - return an updated value
            return {
                ...item,
                ...updatedProperties
            };
        });
    }

    static updateAllInPagedList<T>(model: PagedList<T>, updatedProperties: any) {
        let data = this.updateAll(<any>model.data, updatedProperties);
        let result = { ...model, ...{ data } };
        return result;
    }

    static updateAll(array: any[], updatedProperties: any) {
        return array.map((item, ind) => {
            // Otherwise, this is the one we want - return an updated value
            return {
                ...item,
                ...updatedProperties
            };
        });
    }

    static addToPagedList<T>(model: PagedList<T>, index: number, items: any[]) {
        let data = this.add(<any>model.data, index, items);
        let result = { ...model, ...{ data } };
        return result;
    }

    static add(array: any[], index: number, items: any[]) {
        return array.slice(0, index).concat(items).concat(array.slice(index, array.length))
    }

    static removeFromPagedList<T>(model: PagedList<T>, index: number, count: number) {
        let data = this.remove(<any>model.data, index, count);
        let result = { ...model, ...{ data } };
        return result;
    }

    static remove(array: any[], index: number, count: number) {
        return array.slice(0, index).concat(array.slice(index + count, array.length))
    }
}