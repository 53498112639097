import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { Observable, of } from "rxjs";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AppService } from "../../app.service";

import { EmployeeAdvanceService } from "./employee-advance.service";
import { EmployeeService } from "../../operation/employee/employee.service";
import { ProjectTaskService } from "../../operation/project-task/project-task.service";
import { FormComponent } from "../../core";
import { finalize, switchMap } from "rxjs/operators";
import { filterAndDebounce } from "../../core/utils";
import { KeyValue } from "@angular/common";

@Component({
    selector: "op-employee-advance-create",
    templateUrl: "./employee-advance-create.component.html"
})
export class EmployeeAdvanceCreateComponent extends FormComponent {
    @Input() employeeId: number;
    @Output() inserted = new EventEmitter<app.operation.EmployeeAdvanceInsertUpdateViewModel>();
    isLoading: boolean;
    @ViewChild(NgForm) createEmployeeAdvanceForm: NgForm;
    employeeAdvance = <app.operation.EmployeeAdvanceInsertUpdateViewModel>{};
    selectedEmployee: app.operation.EmployeeSelectListViewModel;
    selectedProjectTask: app.operation.ProjectTaskSelectListViewModel;
    objectKey: string;
    currentUser: app.UserViewModel;
    paramId: number;
    advanceTypes: dotnet.KeyValuePair[];
    paymentTypes: dotnet.KeyValuePair[];
    validityMonth: number = 1;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private employeeAdvanceService: EmployeeAdvanceService,
        private employeeService: EmployeeService,
        private projectTaskService: ProjectTaskService,
        private appService: AppService,

    ) {
        super(router, "employeeAdvance");

        this.objectKey = employeeAdvanceService.objectKey;
    }

    ngOnInit() {
        super.ngOnInit();

        this.isLoading = true;
        this.paramId = +this.appService.getParam("employeeId");

        this.employeeAdvanceService.getNew().subscribe((result) => {

            this.advanceTypes = result.advanceTypes;
            this.paymentTypes = result.paymentTypes;

            if (this.hasPreviousState) {
                if (this.employeeAdvance.employeeId && this.employeeAdvance.employeeName) {
                    this.selectedEmployee = <app.operation.EmployeeSelectListViewModel>{
                        employeeId: this.employeeAdvance.employeeId,
                        employeeName: this.employeeAdvance.employeeName,
                    }
                    if (this.employeeAdvance.projectTaskId != null) {
                        this.selectedProjectTask = <app.operation.ProjectTaskSelectListViewModel>{
                            firstProjectTaskId: this.employeeAdvance.projectTaskId,
                            projectTaskNumber: this.employeeAdvance.projectTaskNumber,
                            title: this.employeeAdvance.projectTaskNumber,
                        }
                    }
                }
            }
            else {
                this.employeeAdvance = result.model;
                this.employeeAdvance.advanceType = 1;
            }

            this.updateValidityMonth();

            this.isLoading = false;
            if (this.paramId != null && this.paramId != 0) {

                this.selectedEmployee = <app.operation.EmployeeSelectListViewModel>{
                    employeeId: this.paramId,
                    employeeName: this.paramId.toString(),
                }
            }
        });

    }

    onSave() {
        if (this.selectedEmployee != null && this.employeeAdvance != null) {
            this.employeeAdvance.employeeId = this.selectedEmployee.employeeId;
            this.employeeAdvance.employeeName = this.selectedEmployee.employeeName;
        }

        if (this.selectedProjectTask == null) {
            this.employeeAdvance.projectTaskId = null;
            this.employeeAdvance.projectTaskNumber = null;
        }
        else {
            this.employeeAdvance.projectTaskId = this.selectedProjectTask.firstProjectTaskId;
            this.employeeAdvance.projectTaskNumber = this.selectedProjectTask.projectTaskNumber;
        }
    }

    updateValidityMonth() {
        switch (this.validityMonth) {
            case 0: {
                break;
            }
            case 1: {
                this.employeeAdvance.validityMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
                break;
            }
            case 2: {
                this.employeeAdvance.validityMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                break;
            }
            case 3: {
                this.employeeAdvance.validityMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
                break;
            }
            default: {
                break;
            }
        }
    }

    insert(): void {
        if (this.createEmployeeAdvanceForm.invalid || this.isLoading) {
            return;
        }

        this.updateValidityMonth();
        this.isLoading = true;

        this.onSave();

        this.employeeAdvanceService.insert(this.employeeAdvance)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((result) => {
                this.inserted.emit(result);
                this.selectedProjectTask = null;
                this.employeeAdvance = <app.operation.EmployeeAdvanceInsertUpdateViewModel>{
                    advanceType: this.employeeAdvance.advanceType
                };
                this.createEmployeeAdvanceForm.control.markAsPristine();
            });

     
    }

    employeeAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(searchTerm => searchTerm.length < 2
                    ? of([])
                    : this.employeeService.getListByRequest(<app.operation.EmployeeSelectListRequest>{
                        page: 1,
                        pageSize: 20,
                        searchTerm,
                        onlyB2b: false,
                        isAdvanceContractTypes: true,
                        isActive:true
                    })));
    }

    employeeFormatter = (employee: app.operation.EmployeeSelectListViewModel) => employee.employeeName;

    projectTaskAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(term => term.length < 2 ? of([]) : this.projectTaskService.getList(1, 20, term, null, null, null, this.selectedEmployee.countryCode)));
    }

    projectTaskFormatter = (projectTask: app.operation.ProjectTaskSelectListViewModel) => (projectTask.projectTaskNumber + " - " + projectTask.title);

}
