import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class PersonService
    extends HttpService<app.crm.PersonListViewModel, app.crm.PersonFormViewModel, app.crm.PersonInsertUpdateViewModel>
{
    objectKey = "crm.person";
    objectKeyMetriqView = "crm.personMQV";
    objectKeyB2BPortal = "crm.personB2BPortal";

    constructor(http: HttpClient) {
        super(http, "/api/crm/person/");
    }

    getSelectList(request: app.crm.PersonSelectListRequest): Observable<app.crm.PersonSelectListViewModel[]> {
        let params = new HttpParams();

        if (request.searchTerm) {
            params = params.set("searchTerm", request.searchTerm);
        }
        if (request.page) {
            params = params.set("page", request.page.toString());
        }
        if (request.pageSize) {
            params = params.set("pageSize", request.pageSize.toString());
        }
        if (request.companyId) {
            params = params.set("companyId", request.companyId.toString());
        }
        if (request.personType) {
            params = params.set("personType", request.personType.toString());
        }       

        return this.http.get<app.crm.PersonSelectListViewModel[]>(this.url + "list", { params });
    }

    copyPerson(personId: number, item: app.crm.PersonCopyViewModel): Observable<app.crm.PersonInsertUpdateViewModel> {
        return this.http.post<app.crm.PersonInsertUpdateViewModel>(this.url + personId + "/copy", item);
    }

    getDuplicateList(firstName: string, lastName: string, companyId: number): Observable<app.crm.PersonSelectListViewModel[]> {
        let params = new HttpParams();
        params = params.set("firstName", firstName);
        params = params.set("lastName", lastName);
        params = params.set("companyId", companyId.toString());

        return this.http.get<app.crm.PersonSelectListViewModel[]>(this.url + "listduplicates", { params });
    }

    getEmailDuplicateList(personId: number, email: string, companyId: number): Observable<app.crm.PersonSelectListViewModel[]> {
        let params = new HttpParams();
        params = params.set("personId", personId.toString());
        params = params.set("email", email);
        params = params.set("companyId", companyId.toString());

        return this.http.get<app.crm.PersonSelectListViewModel[]>(this.url + "list-email-duplicates", { params });
    }

    b2cCreateUser(item: app.crm.PersonInsertUpdateViewModel) {
        return this.http.put<app.b2c.B2cServiceResponse>(this.url + item.personId + "/b2ccreateuser", {
            personId: item.personId,
            email: item.email,
            firstName: item.firstName,
            lastName: item.lastName
        });
    }

    mqvCreateUser(item: app.crm.PersonInsertUpdateViewModel) {
        return this.http.put<app.metriqsync.MqvServiceResponse>(this.url + item.personId + "/mqvcreateuser", {
            personId: item.personId,
            email: item.email,
            firstName: item.firstName,
            lastName: item.lastName,
            mqvAccessLevel: item.mqvAccessLevel
        });
    }

    mqvResetPassword(item: app.crm.PersonInsertUpdateViewModel) {
        return this.http.put<app.metriqsync.MqvServiceResponse>(this.url + item.personId + "/mqvresetpassword", {
            personId: item.personId,
            email: item.email,
            firstName: item.firstName,
            lastName: item.lastName,
            mqvAccessLevel: item.mqvAccessLevel
        });
    }

    b2bPortalCreateUser(item: app.crm.PersonInsertUpdateViewModel) {
        return this.http.put<app.hollenb2b.HollenB2bServiceResponse>(this.url + item.personId + "/hollenb2bcreateuser", {
            personId: item.personId,
            email: item.email,
            firstName: item.firstName,
            lastName: item.lastName,     
        });
    }

    b2bPortalResetPassword(item: app.crm.PersonInsertUpdateViewModel) {
        return this.http.put<app.hollenb2b.HollenB2bServiceResponse>(this.url + item.personId + "/hollenb2bresetpassword", {
            personId: item.personId,
            email: item.email,
            firstName: item.firstName,
            lastName: item.lastName       
        });
    }

    mqvSyncPeople(companyId: number) {
        return this.http.put<app.metriqsync.MqvServiceResponse>(this.url + companyId + "/mqvSyncPeople", {});
    }

    mqvUpdatePeopleWithPassword(companyId: number) {
        return this.http.get<number>(this.url + companyId + "/mqvPeople", {});
    }

    b2cResetPassword(item: app.crm.PersonInsertUpdateViewModel) {
        return this.http.put<app.b2c.B2cServiceResponse>(this.url + item.personId + "/b2cresetpassword", {});
    }

    personWithCompanyFormatter = (item: app.crm.PersonSelectListViewModel) => {
        return item && item.fullName ? item.fullName + ` (${item.companyTitleWithAddressCity})` : "";
    }

    getFilter(): Observable<app.crm.PersonListFilterViewModel> {
        return this.http.get<app.crm.PersonListFilterViewModel>(this.url + "filter");
    }

    getPerson(personId: number): Observable<app.crm.PersonFormViewModel> {
        return this.http.get<app.crm.PersonFormViewModel>(this.url + personId);
    }

    getHistory(page: number, pageSize: number, id: number): Observable<af.sys.EntityHistoryViewModel[]> {
        let params = new HttpParams();
        params = params.set("page", page.toString());
        params = params.set("pageSize", pageSize.toString());
        return this.http.get<af.sys.EntityHistoryViewModel[]>(this.url + id + "/history", { params });
    }
}
