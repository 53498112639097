
<af-page-bar title="{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.summary for invoices' | translate }}" helpid="invoice.project.task.summary">
    <a [href]="getReportUrl()"
    target="_blank"
    class="btn btn-default-alt"
    title="{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.generate marked xslx' | translate }}"
    [class.disabled-all]="isLoading"
    [class.disabled]="!selectedItemsIds || selectedItemsIds.length === 0">
    <i class="fa fa-file-excel"></i>
        {{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.generate marked xslx' | translate }}
    </a>
    <a [href]="getNotInvoicedReportUrl()"
    target="_blank"
    class="btn btn-default-alt"
    title="{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.generate not invoiced filtered projecttask xslx' | translate }}"
    [class.disabled-all]="isLoading"><i class="fa fa-file-excel"></i>
        {{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.generate not invoiced filtered projecttask xslx' | translate }} 
    </a>
</af-page-bar>

<af-page-filter *ngIf="filter" [loading]="isLoading" [params]="queryParams" (filter)="loadData()" (reset)="onReset()" (clear)="onClear()">
    <div class="form-group">
        <input type="text" class="form-control" [(ngModel)]="queryParams.searchTerm" placeholder="{{ 'filter.keyword' | translate }}" />
        <af-help helpid="invoice.project.task.summary.searchTerm"></af-help>
    </div>

    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.project task' | translate }}</label>
        <div class="input-group">
                <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
            <input type="text" name="ProjectTaskId" class="form-control"
                   (ngModelChange)="onProjectTaskChange($event)"
                   [ngModel]="selectedProjectTask"
                   [editable]="false"
                   [ngbTypeahead]="projectTaskAutocomplete"
                   [resultFormatter]="projectTaskFormatter"
                   [inputFormatter]="projectTaskFormatter" />
        </div>
        <af-help helpid="invoice.project.task.summary.projectTaskId"></af-help>
    </div>

    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.account center' | translate }}</label>
        <select class="form-control form-select" [ngModel]="queryParams.accountCenterCode" (ngModelChange)="queryParams.accountCenterCode = $event; onCountryCodeChange()">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.all' | translate }}</option>
            <option *ngFor="let accountCenter of accountCenters" [ngValue]="accountCenter.code">{{accountCenter.title}}</option>
        </select>
    </div>
    <div class="form-group" *ngIf="filter && queryParams.accountCenterCode == null">
        <label>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.country' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryParams.countryCode">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.all' | translate }}</option>
            <option *ngFor="let countrycode of filter.countries" [ngValue]="countrycode.code">{{countrycode.title}}</option>
        </select>
    </div>
    <div class="form-group ac-group">
        <label>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.technician' | translate }}</label>
        <div class="input-group">
                <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
            <input type="text" class="form-control" [(ngModel)]="selectedTechnician"
                   [editable]="false"
                   [ngbTypeahead]="employeeAutocomplete"
                   [resultFormatter]="employeeFormatter"
                   [inputFormatter]="employeeFormatter" />
        </div>
    </div>
    <div class="form-group ac-group">
        <label>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.company' | translate }}</label>
        <div class="input-group">
                <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
            <input type="text" class="form-control" [(ngModel)]="selectedCompany"
                   [editable]="false"
                   [ngbTypeahead]="companyAutocomplete"
                   [resultFormatter]="companyFormatter"
                   [inputFormatter]="companyFormatter" />
        </div>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.stav faktur_cie' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryParams.isNotInvoiced">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.all' | translate }}</option>
            <option [ngValue]="true">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.not invoiced' | translate }}</option>
            <option [ngValue]="false">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.invoiced' | translate }}</option>
        </select>
        <af-help helpid="project-task-product-rate-invoice.list.filter.isnotinvoiced"></af-help>
    </div>
    <div class="filter-group">
        <div class="form-group">
            <af-checkbox [(ngModel)]="queryParams.isReadyToInvoice" label="{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.ready to invoice' | translate }}"></af-checkbox>
        </div>
        <div class="form-group">
            <af-checkbox [(ngModel)]="queryParams.isReadyToPartiallyInvoice" label="{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.ready to partially invoice' | translate }}"></af-checkbox>
        </div>
        <div class="form-group">
            <af-checkbox [(ngModel)]="queryParams.hasNotApprovedRates" label="{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.rates not approved' | translate }}"></af-checkbox>
        </div>
    </div>

    <div class="filter-group" style="width: 100%;">
        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.year' | translate }}</label>
            <select class="form-control form-select" [ngModel]="queryParams.year" (ngModelChange)="onYearChange($event)">
                <option [ngValue]="null"></option>
                <option *ngFor="let year of filter.years" [ngValue]="year.key">{{year.value}}</option>
            </select>
        </div>
        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.month' | translate }}</label>
            <select class="form-control form-select" [disabled]="!queryParams.year" [ngModel]="queryParams.month" (ngModelChange)="onMonthChange($event)">
                <option [ngValue]="null"></option>
                <option *ngFor="let month of filter.months" [ngValue]="month.key">{{month.value}}</option>
            </select>
        </div>
        <div class="filter-group">
            <div class="form-group">
                <label>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.from' | translate }}</label>
                <af-datepicker [ngModel]="queryParams.from" (ngModelChange)="onFromChange($event)" name="From"></af-datepicker>
            </div>
            <div class="form-group">
                <label>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.to' | translate }}</label>
                <af-datepicker [ngModel]="queryParams.to" (ngModelChange)="onToChange($event)" name="To"></af-datepicker>
            </div>
        </div>
    </div>
</af-page-filter>

<div class="mt-3 mb-1">
    <div class="form-group" *ngIf="queryParams.countryCode == 'S' && queryParams.accountCenterCode == null || queryParams.accountCenterCode != null && queryParams.accountCenterCode.toLowerCase().startsWith('s')">
        <button type="button" class="btn btn-success" *afHasPerm="{obj:objectKeyEstimate,perm:'Read'}" (click)="onCalcEstimation($event)">
            <af-spinner [isInline]="true" [loading]="isLoadingCalc" [timeout]="0" type="circle"></af-spinner>
            {{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.calculate estimation' | translate }}
        </button>
    </div>
    <div *ngIf="sumNonPayers || sumPayers">
        <span style="white-space: nowrap">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.sum of estimation, non vat payers_' | translate }} <b style="display:inline-block; margin-right: 10px">{{ sumNonPayers | number:'.2-9'}}</b></span>
        <span style="white-space: nowrap">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.sum of estimation, vat payers_' | translate }} <b style="display:inline-block; margin-right: 10px">{{ sumPayers | number:'.2-9'}}</b></span>
        <span style="white-space: nowrap">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.number of proccessed records_' | translate }} <b style="display:inline-block; margin-right: 10px">{{ numberOfRecords}}</b></span>
    </div>
</div>

<af-table #afTable [value]="model" [loading]="isLoading" [params]="queryParams" [sortBy]="sortBy" (load)="loadData()" [hover]="false"
[pageSizes]="pageSizess"
tableClasses="table-striped-dark">
    <af-column [dataColClass]="getPlusMinusColClass">
        <ng-template let-item="rowData" afTemplate>
            <a (click)="toggle(item)"><i class="fa" [ngClass]="item.isExpanded ? 'fa-minus-square' : 'fa-plus-square'"></i></a>
        </ng-template>
        <ng-template afTemplate>
            <a (click)="toggleAllItems()">
                <i class="fa" [ngClass]="isAllExpanded ? 'fa-minus-square' : 'fa-plus-square'"></i>
            </a>
        </ng-template>
    </af-column>

    <af-column field="fullProjectTaskNumber" sortable="fullProjectTaskNumber" header="{{ 'hollen.operation.projecttaskproductrateinvoiceviewmodel.task' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a [routerLink]="['/operation/projectTask', item.projectTaskId, 'ending']">{{item.fullProjectTaskNumber}} - {{item.title}}</a>
        </ng-template>
    </af-column>

    <af-column field="companyTitle" sortable="companyTitle" header="{{ 'hollen.operation.projecttaskproductrateinvoiceviewmodel.company' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a [routerLink]="['/crm/company', item.companyId]">{{ item.companyTitleWithAddressCity }}</a>
        </ng-template>
    </af-column>

    <af-column field="technicianName" sortable="technicianName" header="{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.technician' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a [routerLink]="['/operation/employee', item.technicianId]">{{ item.technicianName }}</a>
        </ng-template>
    </af-column>

    <af-column field="papersCompletitionUtc" sortable="papersCompletitionUtc" header="{{ 'hollen.operation.projecttaskproductrateinvoiceviewmodel.papers completition date' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.papersCompletitionUtc | afDate : 'L' : true }}
            <af-help *ngIf="item.papersCompletitionUtc" class="help-static" helpid="Hollen.Operation.ProjectTaskProductRateInvoiceViewModel.PapersCompletitionUtc"></af-help>
        </ng-template>
    </af-column>

    <af-column field="isReadyToInvoice" sortable="isReadyToInvoice" header="{{ 'hollen.operation.projecttaskproductrateinvoiceviewmodel.ready to invoice' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <div class="form-check">
                <input type="checkbox" #checkboxIsReadyToInvoice
                       name="isReadyToInvoice{{item.projectTaskId}}"
                       [disabled]="(!item.isReadyToInvoice && !item.canSetIsReadyToInvoice) || (!item.isReadyToInvoice && !item.hasForInvFiles) || !canChangeIsReadyToInvoice || item['changingIsReadyToInvoice']"
                       class="form-check-input"
                       [value]="true"
                       [ngModel]="item.isReadyToInvoice"
                       (ngModelChange)="changeIsReadyToInvoice($event, item, checkboxIsReadyToInvoice)">
                <label class="form-check-label"></label>
            </div>
        </ng-template>
    </af-column>
    <af-column field="isReadyToPartiallyInvoice" sortable="isReadyToPartiallyInvoice" header="{{ 'hollen.operation.projecttaskproductrateinvoiceviewmodel.ready to partially invoice' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <div class="form-check">
                <input type="checkbox" #checkboxIsReadyToPartiallyInvoice
                       name="isReadyToPartiallyInvoice{{item.projectTaskId}}"
                       [disabled]="(!item.isReadyToPartiallyInvoice && !item.canSetIsReadyToPartiallyInvoice) || (!item.isReadyToPartiallyInvoice && !item.hasForInvFiles) || !canChangeIsReadyToInvoice || item['changingIsReadyToPartiallyInvoice']"
                       class="form-check-input"
                       [value]="true"
                       [ngModel]="item.isReadyToPartiallyInvoice"
                       (ngModelChange)="changeIsReadyToPartiallyInvoice($event, item, checkboxIsReadyToPartiallyInvoice)">
                <label class="form-check-label"></label>
            </div>
        </ng-template>
    </af-column>
    <af-column field="isMarkedToInvoice" sortable="isMarkedToInvoice" header="{{ 'hollen.operation.projecttaskproductrateinvoiceviewmodel.marked to invoice' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <div class="form-check">
                <input type="checkbox" #isMarkedToInvoiceCheckBox
                       name="isMarkedToInvoice{{item.projectTaskId}}"
                       [disabled]="!canChangeIsMarkedToInvoice || item['changingIsMarkedToInvoice']"
                       class="form-check-input"
                       [ngModel]="item.isMarkedToInvoice"
                       (ngModelChange)="changeIsMarkedToInvoice($event, item, isMarkedToInvoiceCheckBox)">
                <label class="form-check-label"></label>
            </div>
        </ng-template>
    </af-column>
    <af-column>
        <ng-template let-item="rowData" afTemplate>
            <i class="fa fa-button fa-sticky-note" [class.text-primary]="item.invoiceNote" (click)="openInvoiceNoteModal(invoiceNoteModal, item)" [class.fa-regular]="!item.invoiceNote" [ngbTooltip]="item.invoiceNote" container="body" aria-hidden="true"></i>
            <i class="fa fa-exclamation-circle text-danger fa-button" *ngIf="item.invoiceRejectNote" [ngbTooltip]="item.invoiceRejectNote" container="body" aria-hidden="true"></i>
        </ng-template>
    </af-column>
    <af-column field="endDate" sortable="endDate" header="{{ 'hollen.operation.projecttaskproductrateinvoiceviewmodel.end date' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.endDate | afDate : 'L' }}
        </ng-template>
    </af-column>
    <af-column field="hasNextMonth" sortable="hasNextMonth" header="{{ 'hollen.operation.projecttaskproductrateinvoiceviewmodel.next month' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <div class="form-check">
                <input type="checkbox" name="hasNextMonth{{item.projectTaskId}}" disabled class="form-check-input" [ngModel]="item.hasNextMonth">
                <label class="form-check-label"></label>
            </div>
        </ng-template>
    </af-column>
    <af-column field="check">
        <ng-template let-item="rowData" afTemplate>
            <div class="form-check">
                <input type="checkbox" class="form-check-input"
                       (click)="toggleCheck(item)"
                       [checked]=isSelected(item)>
                <label class="form-check-label"></label>
            </div>
        </ng-template>
        <ng-template afTemplate>
            <div class="form-check">
                <input type="checkbox" class="form-check-input" [checked]="isAllChecked" (change)="onAllCheckedChange()">
                <label class="form-check-label"></label>
            </div>
        </ng-template>
    </af-column>

    <af-table-expanded-row>
        <ng-template let-item="rowData" afTemplate>
            <div class="form form-horizontal" style="background-color: white;">
                <div class="form-body">
                    <op-project-task-product-rate-summary [productRates]="item?.productRates"></op-project-task-product-rate-summary>

                    <h3 class="form-section">
                        <a style="font-size: 0.85rem;" (click)="toggleFiles(item)"><i class="fa" [ngClass]="item['showFiles'] ? 'fa-minus-square' : 'fa-plus-square'"></i></a>
                        {{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.files' | translate }}
                    </h3>

                    <af-spinner [loading]="item['filesLoading']" [timeout]="0"></af-spinner>

                    <op-project-task-file *ngIf="item['showFiles'] && item['filesContent'] != null"
                                          [projectTaskId]="item.projectTaskId"
                                          [forInv]="true"
                                          [isFilter]="false"
                                          [isUpload]="false"
                                          [content]="item['filesContent']"
                                          [paginator]="false"
                                          (countChange)="onFilesCountChange(item, $event)"></op-project-task-file>
                </div>
            </div>
        </ng-template>
    </af-table-expanded-row>
</af-table>

<ng-template #invoiceNoteModal let-close="close" let-dismiss="dismiss">
    <div class="modal-header">
        <h3 class="modal-title">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.note' | translate }}</h3>
    </div>
    <div class="modal-body">
        <textarea rows="5" class="form-control" [(ngModel)]="invoiceNote"></textarea>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-update btn-primary" (click)="updateInvoiceNote()">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.save' | translate }}</button>
        <button type="button" class="btn-back btn-warning btn" (click)="close()">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.cancel' | translate }}</button>
    </div>
</ng-template>


<div class="form">
    <div class="form-horizontal">
        <div class="form-actions">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <div class="offset-md-1 col-md-11">
                            <a *afHasPerm="{obj:'fin.Invoice',perm:'Insert'}" class="btn btn-insert btn-primary btn-float" target="_blank" [href]="invoiceUrl" (click)="onInvoice($event)">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-invoice.component.invoice' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
