<div class="card">
    <div class="card-header">{{ 'hollen.clientapp.src.app.operation.report.month-report.component.month report' | translate }}</div>
    <div class="card-block card-report form-horizontal">
        <div>
            <div>
                <div class="row form-group">
                    <div class="col-md-7 control-label">
                        {{ 'hollen.clientapp.src.app.operation.report.month-report.component.workplace' | translate }}
                    </div>
                    <div class="col-md-5">
                        <select class="form-control form-select" [(ngModel)]="request.workPlaceId">
                            <option *ngFor="let workplace of workplaces" [ngValue]="workplace.workPlaceId">{{workplace.title}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div>
                <div class="row form-group">
                    <div class="col-md-7 control-label">
                        {{ 'hollen.clientapp.src.app.operation.report.month-report.component.hours' | translate }}
                    </div>
                    <div class="col-md-5">
                        <af-checkbox [(ngModel)]="request.hours" name="hours"></af-checkbox>
                    </div>
                </div>
            </div>
            <div>
                <div class="row form-group">
                    <div class="col-md-7 control-label">
                        {{ 'hollen.clientapp.src.app.operation.report.month-report.component.only active' | translate }}
                    </div>
                    <div class="col-md-5">
                        <af-checkbox [(ngModel)]="request.onlyActiveProjectTasks" name="onlyActiveProjectTasks"></af-checkbox>
                    </div>
                </div>
            </div>
            <div>
                <div class="row form-group">
                    <div class="col-md-7 control-label">
                        {{ 'hollen.clientapp.src.app.operation.report.month-report.component.summary' | translate }}
                    </div>
                    <div class="col-md-5">
                        <af-checkbox [(ngModel)]="request.summary" name="summary"></af-checkbox>
                    </div>
                </div>
            </div>
            <div>
                <div class="row form-group">
                    <div class="col-md-7 control-label">
                        {{ 'hollen.clientapp.src.app.operation.report.month-report.component.bank hours' | translate }}
                    </div>
                    <div class="col-md-5 col-fixed-md">
                        <div class="input-group input-group-inline">
                            <input class="form-control" [(ngModel)]="request.hoursBank" />
                            <span class="input-group-text">%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-12">
                    <a [href]="getUrl()" [class.disabled]="!params.projectTaskId" class="btn btn-default-alt btn-generate btn-large-icon" title="{{ 'btn.generate' | translate }}"><i class="fa fa-download"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
