import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { WorkTimeUnsyncedListQueryParams } from "./work-time-unsynced-list.component";

@Injectable()
export class WorkTimeUnsyncedService
    extends HttpService<any, any, any>
{  
    objectKey = "mq.WorkTimeUnsync";

    constructor(http: HttpClient) {
        super(http, "/api/metriqsync/metriqsync/");
    }

    getWorkTimeList(request: WorkTimeUnsyncedListQueryParams): Observable<PagedList<any>> {
        return this.http.get<PagedList<any>>(this.url + "work-times-unsync", { params: this.getParameters(request) });
    }
}
