import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class CustomPropertyService
    extends HttpService<app.codebook.CodebookListViewModel, app.codebook.CodebookFormViewModel, dotnet.KeyValuePair[]>
{
    objectKey: string;
    baseUrl = "/api/codebook/customProperty/";

    constructor(http: HttpClient) {
        super(http, null);
    }
    
    archive(id: number) {
        return this.http.put(this.baseUrl + id + "/archive", null);
    }

    getPagedList(parameters?: any): Observable<PagedList<app.codebook.CodebookListViewModel>> {

        return this.http.get<PagedList<app.codebook.CodebookListViewModel>>(this.baseUrl  + "custom", {
            params: this.getParameters(parameters)
        });
    }

    getNew(parameters?: any) {
        return this.http.get<app.codebook.CustomPropertyFormViewModel>(this.baseUrl + "new", {
            params: this.getParameters(parameters)
        });
    }

    getFilter(): Observable< app.codebook.CustomPropertyListFilterViewModel> {
        return this.http.get<app.codebook.CustomPropertyListFilterViewModel>(this.baseUrl + "customfilter");
    }

    get(id: any, appendLists: boolean, useQueryString?: boolean): Observable<app.codebook.CustomPropertyFormViewModel> {
        let params = new HttpParams();
        if (appendLists) {
            params = params.set("appendLists", appendLists.toString());
        }

        this.url = this.baseUrl + "";

        if (useQueryString) {
            params = params.set("id", id);
        } else {
            this.url += id;
        }

        return this.http.get<app.codebook.CustomPropertyFormViewModel>(this.url, {
            params
        });
    }

}
