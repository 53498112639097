import { Injectable } from "@angular/core";

@Injectable()
export class B2bService {
    objectKey = "op.EmployeeB2b";
    url: string;

    constructor() {
        this.url = "/api/b2b/";
    }

    orderXlsUrl() {
        return this.url + "report/orderxls/";
    }

    deliveryNoteXlsUrl() {
        return this.url + "report/deliverynotexls/";
    }

    invoiceXlsUrl() {
        return this.url + "report/invoicexls/";
    }

    invoiceXmlUrl() {
        return this.url + "invoice/file/";
    }

    orderPdfUrl() {
        return "/api/report/report/code/hollenb2b_order?format=PDF&OrderId=";
    }

    deliveryNotePdfUrl() {
        return "/api/report/report/code/hollenb2b_deliverynote?format=PDF&DeliveryNoteId=";
    }

    companyDeliveryNotePdfUrl() {
        return "/api/report/report/code/hollenb2b_majorproviderdeliverynote?format=PDF&MajorProviderDeliveryNoteId=";
    }

    invoicePdfUrl() {
        return "/api/report/report/code/hollenb2b_invoice?format=pdf&InvoiceId=";
    }

    invoiceWithAttachPdfUrl() {
        return "/api/report/report/code/hollenb2b_invoice_w_attachment?format=pdf&InvoiceId=";
    }
}
