import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class TechnicianTableService
    extends HttpService<app.metriqsync.SyncQueueListViewModel, app.metriqsync.SyncQueueListViewModel, app.metriqsync.SyncQueueListViewModel>
{  
    objectKey = "mq.TechnicianTable";

    constructor(http: HttpClient) {
        super(http, "/api/metriqsync/metriqsync/");
    }

    getFilter(): Observable<app.metriqsync.SyncQueueFilterViewModel> {
        return this.http.get<app.metriqsync.SyncQueueFilterViewModel>(this.url + "filter");
    }

    syncById(syncQueueId: number) {
        let params = new HttpParams();
        params = params.set("showToast", "true");
        return this.http.put(this.url + syncQueueId + "/syncById","", { params });
    }

    syncByIds(syncQueueIds: number[]) {
        let params = new HttpParams();
        //params = params.set("showToast", "true");
        for (let sq of syncQueueIds) {
            params = params.append("syncQueueIds", sq.toString());
        }

        return this.http.put(this.url + "syncByIds", "", { params });
    }

    getRows(syncQueueIds: number[]): Observable<app.metriqsync.SyncQueueListViewModel[]> {
        let params = new HttpParams();

        for (let sq of syncQueueIds) {
            params = params.append("syncQueueIds", sq.toString());
        }

        return this.http.get<app.metriqsync.SyncQueueListViewModel[]>(this.url + "rows", { params });
    }
}
