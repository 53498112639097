import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { finalize, switchMap } from "rxjs/operators";
import { FormComponent } from "../../core";
import { filterAndDebounce } from "../../core/utils";
import { EmployeeService } from "../../operation/employee/employee.service";
import { ProjectTaskService } from "../../operation/project-task/project-task.service";
import { EmployeeAdvanceService } from "./employee-advance.service";

@Component({
    selector: "op-employee-advance-edit",
    templateUrl: "./employee-advance-edit.component.html"
})
export class EmployeeAdvanceEditComponent extends FormComponent implements OnChanges {
    @Input() employeeAdvanceId: number;
    @Output() updated = new EventEmitter;
    isLoading: boolean;
   
    @ViewChild(NgForm, { static: true }) editEmployeeAdvanceForm: NgForm;
    employeeAdvance = <app.operation.EmployeeAdvanceInsertUpdateViewModel>{};

    selectedEmployee: app.operation.EmployeeSelectListViewModel;    
    selectedProjectTask: app.operation.ProjectTaskSelectListViewModel;    
    advanceTypes: dotnet.KeyValuePair[];
    paymentTypes: dotnet.KeyValuePair[];

    objectKey: string;
    validityMonth: number = 0;
    currentUser: app.UserViewModel;
    
    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private employeeAdvanceService: EmployeeAdvanceService,
        private employeeService: EmployeeService,
        private projectTaskService: ProjectTaskService)
    {
        super(router, "employeeAdvance");
        
        this.objectKey = employeeAdvanceService.objectKey;        
    }
    
    ngOnInit() {
        super.ngOnInit();
        this.loadData();
    }

    ngOnChanges(changes: SimpleChanges) {        
        this.loadData();
    }

    loadData() {
        let employeeAdvanceId = 0;

        if (this.employeeAdvanceId) {
            employeeAdvanceId = this.employeeAdvanceId;     
        }
        else {
            employeeAdvanceId = +(this.route.snapshot.params["employeeAdvanceId"] || this.route.parent.snapshot.params["employeeAdvanceId"]);
        }

        this.isLoading = true;

        this.employeeAdvanceService.get(employeeAdvanceId, true).subscribe((result) => {
            this.employeeAdvance = result.model;
            this.advanceTypes = result.advanceTypes;
            this.paymentTypes = result.paymentTypes;

            this.selectedProjectTask = <app.operation.ProjectTaskSelectListViewModel>{
                projectTaskId: this.employeeAdvance.projectTaskId,
                projectTaskName: this.employeeAdvance.projectTaskNumber,            
                accountCenterCode: null,
                firstProjectTaskId: null,
                isDetailedResults: null,
                isProjectTaskMetriq: null,
                workPlaceTitle: null,
                isGeneralExclusionOfBreaksEnabled: null,
                projectTaskNumber: this.employeeAdvance.projectTaskNumber,
                title: null,
                metriqOrderGuid:null
            };
            this.validityMonth = 0;

            this.selectedEmployee = <app.operation.EmployeeSelectListViewModel>{
                employeeId: this.employeeAdvance.employeeId,
                employeeName: this.employeeAdvance.employeeName,  
            };

            this.isLoading = false;
        });
    }   

    updateValidityMonth() {
        switch (this.validityMonth) {
            case 0: {      
                break;
            }
            case 1: {       
                this.employeeAdvance.validityMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
                break;
            }
            case 2: {       
                this.employeeAdvance.validityMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                break;
            }
            case 3: {      
                this.employeeAdvance.validityMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
                break;
            }
            default: {
                break;
            }
        } 
    }

    update() {
        if (this.editEmployeeAdvanceForm.invalid) {
            return;
        }

        this.updateValidityMonth();

        this.employeeAdvance.employeeId = this.selectedEmployee.employeeId;
        this.employeeAdvance.employeeName = this.selectedEmployee.employeeName;        
        if (this.selectedProjectTask == null) {
            this.employeeAdvance.projectTaskId = null;
        }
        else {
            this.employeeAdvance.projectTaskId = this.selectedProjectTask.firstProjectTaskId;
        }
        
        this.isLoading = true;        
        this.employeeAdvanceService.update(this.employeeAdvance, this.employeeAdvance.employeeAdvanceId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(() => {                           
                this.editEmployeeAdvanceForm.control.markAsPristine();
                this.updated.emit();
                this.router.navigate(["/operation/employeeAdvance"]); 
            });
              
    }

    delete() {
        this.isLoading = true;
        this.employeeAdvanceService.delete(this.employeeAdvance, this.employeeAdvance.employeeAdvanceId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((result) => {
                this.editEmployeeAdvanceForm.reset();
                this.router.navigate(["/operation/employeeAdvance"]); 
            });
    }

    employeeAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(searchTerm => searchTerm.length < 2
                    ? of([])
                    : this.employeeService.getListByRequest(<app.operation.EmployeeSelectListRequest>{
                        page: 1,
                        pageSize: 20,
                        searchTerm,
                        onlyB2b: false,
                        isAdvanceContractTypes: true,
                        isActive:true
                    })));
    }

    employeeFormatter = (employee: app.operation.EmployeeSelectListViewModel) => employee.employeeName;

    projectTaskAutocomplete = (text$: Observable<string>) => {            
       
        return text$
            .pipe(filterAndDebounce,
                switchMap(term => term.length < 2 ? of([]) : this.projectTaskService.getList(1, 20, term, null, null, null, this.selectedEmployee.countryCode)));
    }
    
    projectTaskFormatter = (projectTask: app.operation.ProjectTaskSelectListViewModel) => (projectTask.projectTaskNumber);

}
