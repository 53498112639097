import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs/operators";

@Injectable()
export class ShiftTypeService {
    objectKey = "op.ShiftType";
    url: string;
    cachedList: app.operation.ShiftTypeSelectListViewModel[];

    constructor(private http: HttpClient) {
        this.url = "/api/operation/shifttype/";
    }

    getSelectList(): Observable<app.operation.ShiftTypeSelectListViewModel[]> {
        if (this.cachedList) {
            return of(this.cachedList);
        }
        return this.http.get<app.operation.ShiftTypeSelectListViewModel[]>(this.url + "list").pipe(tap(res => {
            this.cachedList = res;
        }));
    }
}
