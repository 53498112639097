import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class UserService
    extends HttpService<
    af.usersystem.UserListViewModel,
    app.usersystem.UserFormViewModel,
    app.usersystem.UserInsertUpdateViewModel>
{
    objectKey = "us.user";
    userGenericUrl: string;

    constructor(http: HttpClient) {
        super(http, "/api/usersystem/user/");
        this.userGenericUrl = "/api/usersystem/user/";
    }

    generatePassword() {
        return this.http.get<af.usersystem.GeneratePasswordViewModel>(this.url + "generatepassword");
    }

    unblock(id: number) {
        return this.http.put(this.url + id + "/unblock", "");
    }

    getRoles(id: number) {
        return this.http.get<af.usersystem.UserRoleFormViewModel>(this.userGenericUrl + id + "/role");
    }

    updateRoles(id: number, roles: af.usersystem.UserRoleInsertUpdateViewModel) {
        return this.http.put(this.userGenericUrl + id + "/role", roles);
    }

    getPermissionsByUser(id: number) {
        let params = new HttpParams();
        return this.http.get<af.usersystem.PermissionFormViewModel>(this.url + id + "/permission", { params });
    }

    getList(request?: app.usersystem.UserSelectListRequest): Observable<af.usersystem.UserSelectListViewModel[]> {
        return this.http.get<af.usersystem.UserSelectListViewModel[]>(this.url + "list", {
            params: this.getParameters(request)
        });
    }

    copyRoles(destUserId: number, sourceUserId: number) {
        let model: af.usersystem.UserRoleCopyViewModel = {
            sourceUserId
        };
        return this.http.put(this.url + destUserId + "/copyroles", model);
    }

    addRole(userId: number, roleId: number) {
        return this.http.post(this.url + userId + "/role/" + roleId, null);
    }

    deleteRole(userId: number, roleId: number) {
        return this.http.delete(this.url + userId + "/role/" + roleId);
    }

    getClaims(id: number) {
        return this.http.get<app.usersystem.UserClaimFormViewModel>(this.userGenericUrl + id + "/claim");
    }

    updateClaims(id: number, claims: app.usersystem.UserClaimInsertUpdateViewModel) {
        return this.http.put(this.userGenericUrl + id + "/claim", claims);
    }
}
