import { NgModule, Optional, SkipSelf, ErrorHandler } from "@angular/core";

import {
    HomeResolver,
    LogService,
    LoggingErrorHandler,
    MenuService,
    UnsavedChangesGuard,
    DialogService,
    PermissionService,
    ToastService,
    TextMissingTranslationHandler,
    RequestLogService,
    TimeService,
    ScreenService,
    VersionService
} from "./services";

import { providers as b2bProviders } from "../b2b/core.providers";
import { providers as cbProviders } from "../codebook/core.providers";
import { providers as crmProviders } from "../crm/core.providers";
import { providers as finProviders } from "../finance/core.providers";
import { providers as opProviders } from "../operation/core.providers";
import { providers as orgProviders } from "../organization/core.providers";
import { providers as sysProviders } from "../sys/core.providers";
import { providers as usProviders } from "../usersystem/core.providers";
import { ApplicationInsightsService } from './services/application-insights.service';
import { providers as mqProviders } from "../metriq-sync/core.providers";
import { ClipboardService } from "./services/clipboard.service";

@NgModule({
    providers: [
        ...b2bProviders,
        ...cbProviders,
        ...crmProviders,
        ...finProviders,
        ...opProviders,
        ...orgProviders,
        ...sysProviders,
        ...usProviders,
        ...mqProviders,

        HomeResolver,
        LogService,
        MenuService,
        UnsavedChangesGuard,
        DialogService,
        PermissionService,
        ToastService,
        RequestLogService,
        TimeService,
        ScreenService,
        VersionService,
        ApplicationInsightsService,
        ClipboardService,
        { provide: ErrorHandler, useClass: LoggingErrorHandler }
    ]
})
export class CoreModule {

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error("CoreModule is already loaded. Import it in the AppModule only");
        }
    }
}
