import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { FileUploadOptions } from "../../../shared/components/file-upload/file-upload.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpService } from "../../../core/services/http.service";

@Injectable()
export class B2bRateService
    extends HttpService<app.crm.B2bRateListViewModel, app.crm.B2bRateFormViewModel, app.crm.B2bRateInsertUpdateViewModel> {
    objectKey = "crm.B2bRates";

    constructor(http: HttpClient) {
        super(http, "/api/crm/b2brate/");
    }

    approve(b2bRateId: number): Observable<any> {
        return this.http.put(this.url + b2bRateId + "/approve", "");
    }

    unapprove(b2bRateId: number): Observable<any> {
        return this.http.put(this.url + b2bRateId + "/unapprove", "");
    }

    getFilter(): Observable<app.crm.B2bRateListFilterViewModel> {
        return this.http.get<app.crm.B2bRateListFilterViewModel>(this.url + "filter");
    }

    getNewByCompany(companyId: number): Observable<app.crm.B2bRateFormViewModel> {
        let params = new HttpParams();
        params = params.set("companyId", companyId.toString());
        return this.http.get<app.crm.B2bRateFormViewModel>(this.url + "new", { params });
    }

    //Cooperant Fee
    getCooperantFee(b2bRateCooperantFeeId: number): Observable<app.crm.B2bRateCooperantFeeInsertUpdateViewModel>{
        let params = new HttpParams();
        params = params.set("id", b2bRateCooperantFeeId.toString());
        return this.http.get<app.crm.B2bRateCooperantFeeInsertUpdateViewModel>(this.url + "cooperantFee/" + b2bRateCooperantFeeId, { params });
    }
    
    updateCooperantFee(model: app.crm.B2bRateCooperantFeeInsertUpdateViewModel): Observable<any> {
        return this.http.put(this.url + "cooperantFee/" + model.b2bRateCooperantFeeId, model);
    }

    insertCooperantFee(model: app.crm.B2bRateCooperantFeeInsertUpdateViewModel): Observable<any>  {
        return this.http.post(this.url + "cooperantFee", model);
    }

    approveCooperantFee(b2bRateCooperantFeeId: number): Observable<any> {
        return this.http.put(this.url + b2bRateCooperantFeeId + "/approvecooperantfee", "");
    }

    unapproveCooperantFee(b2bRateCooperantFeeId: number): Observable<any> {
        return this.http.put(this.url + b2bRateCooperantFeeId + "/unapprovecooperantfee", "");
    }

    //BMS Value
    getBmsValue(b2bRateBmsValueId: number): Observable<app.crm.B2bRateBmsValueInsertUpdateViewModel> {
        let params = new HttpParams();
        params = params.set("id", b2bRateBmsValueId.toString());
        return this.http.get<app.crm.B2bRateBmsValueInsertUpdateViewModel>(this.url + "bmsValue/" + b2bRateBmsValueId, { params });
    }

    updateBmsValue(model: app.crm.B2bRateBmsValueInsertUpdateViewModel): Observable<any> {
        return this.http.put(this.url + "bmsValue/" + model.b2bRateBmsValueId, model);
    }

    insertBmsValue(model: app.crm.B2bRateBmsValueInsertUpdateViewModel): Observable<any> {
        return this.http.post(this.url + "bmsValue", model);
    }

    approveBmsValue(b2bRateBmsValueId: number): Observable<any> {
        return this.http.put(this.url + b2bRateBmsValueId + "/approvebmsValue", "");
    }


    unapproveBmsValue(b2bRateBmsValueId: number): Observable<any> {
        return this.http.put(this.url + b2bRateBmsValueId + "/unapprovebmsValue", "");
    }

    deleteBmsValue(b2bRateBmsValueId: number): Observable<any> {
        return this.http.delete(this.url + b2bRateBmsValueId + "/bmsValue" );
    }

    getBmsValues(companyId: number): Observable<PagedList<app.crm.B2bRateBmsValueListViewModel>> {

        let params = new HttpParams();

        if (companyId) {
            params = params.set("companyId", companyId.toString());
        }
        return this.http.get<PagedList<app.crm.B2bRateBmsValueListViewModel>>(this.url + "bmsvaluelist", { params });
    }

    getCooperantFeeValues(companyId: number): Observable<PagedList<app.crm.B2bRateCooperantFeeListViewModel>> {

        let params = new HttpParams();

        if (companyId) {
            params = params.set("companyId", companyId.toString());
        }
        return this.http.get<PagedList<app.crm.B2bRateCooperantFeeListViewModel>>(this.url + "cooperantfeelist", { params });
    }
}
