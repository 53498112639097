import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class CommonAnalyticsService
    extends HttpService<app.codebook.CodebookListViewModel, app.codebook.CodebookFormViewModel, dotnet.KeyValuePair[]>
{
    objectKey: string;
    baseUrl = "/api/codebook/";

    constructor(http: HttpClient) {
        super(http, null);
    }

    getCodeById(id: number) {
        return this.http.get<app.codebook.CommonAnalyticsFormViewModel>(this.baseUrl + "commonAnalytics/getcodebyid/" + id);
    }


    getSelectList(request: app.codebook.CommonAnalyticsSelectListRequest): Observable<app.codebook.CommonAnalyticsSelectListViewModel[]> {
        let params = new HttpParams();

        if (request.searchTerm) {
            params = params.set("searchTerm", request.searchTerm);
        }
        if (request.page) {
            params = params.set("page", request.page.toString());
        }
        if (request.pageSize) {
            params = params.set("pageSize", request.pageSize.toString());
        }
        if (request.commonAnalyticsId) {
            params = params.set("commonAnalyticsId", request.commonAnalyticsId.toString());
        }
        if (request.rowNumber) {
            params = params.set("rowNumber", request.rowNumber.toString());
        }

        return this.http.get<app.codebook.CommonAnalyticsSelectListViewModel[]>(this.baseUrl + "commonAnalytics/customlist", { params });
    }

}
