import { ElementRef, HostListener, Directive } from "@angular/core";

@Directive({
    selector: "textarea:not([no-autosize])"
})

export class TextareaAutosizeDirective {
    private initStyleHeight: string;

    constructor(public element: ElementRef) {
    }

    ngOnInit() {
        this.initStyleHeight = this.element.nativeElement.style.height;
    }

    @HostListener("input", ["$event.target"])
    onInput(textArea: HTMLTextAreaElement): void {
        this.adjust();
    }

    ngAfterContentChecked(): void {
        this.adjust();
    }

    adjust(): void {
        if (this.element.nativeElement.style.height !== "" + (2 + this.element.nativeElement.scrollHeight) + "px") {
            this.element.nativeElement.style.height = this.initStyleHeight;
            this.element.nativeElement.style.height = "" + (2 + this.element.nativeElement.scrollHeight) + "px";
        }
    }
}