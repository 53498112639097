import { Injectable } from "@angular/core";
import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { strictEqual } from 'assert';
import { FileUploadOptions } from "../../shared/components/file-upload/file-upload.service";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class CourseService
    extends HttpService<app.operation.CourseListViewModel, app.operation.CourseFormViewModel, app.operation.CourseInsertUpdateViewModel>
{
    objectKey = "op.Course";

    constructor(http: HttpClient) {
        super(http, "/api/operation/course/");
    }

    getFilter(): Observable<app.operation.CourseListFilterViewModel> {
        return this.http.get<app.operation.CourseListFilterViewModel>(this.url + "filter");
    }

    getCoursePlaningList(id: number): Observable<app.operation.CourseOverviewListViewModel[]> {
        return this.http.get<app.operation.CourseOverviewListViewModel[]>(this.url + id + "/coursePlanning");    
    }

    getCourseOverviewList(parameters?: any): Observable<app.operation.CourseOverviewListViewModel[]> {
        return this.http.get<app.operation.CourseOverviewListViewModel[]>(this.url + parameters.courseId + "/overviewFilter", {
            params: this.getParameters(parameters)
        });    
    }

    getCourseDateList(parameters?: any): Observable<app.operation.CourseTermListViewModel[]> {
    return this.http.get<app.operation.CourseTermListViewModel[]>(this.url + "courseTermFilter", {
        params: this.getParameters(parameters)
        });
    }

    getCourseParticipants(parameters?: any): Observable<app.operation.CoursePriceParticipantViewModel> {        
        return this.http.get<app.operation.CoursePriceParticipantViewModel>(this.url + "courseTermParticipants", {
            params: this.getParameters(parameters)
        });
    }

    getCourseInfo(parameters?: any): Observable<app.operation.CoursePriceParticipantViewModel> {
        return this.http.get<app.operation.CoursePriceParticipantViewModel>(this.url + "courseInfo", {
            params: this.getParameters(parameters)
        });
    }

    saveCourseParticipants(courseId: number, participants: app.operation.CoursePriceParticipantViewModel) {
        return this.http.put(this.url + courseId + "/participants", participants);
    }

    saveCourseDetails(courseId: number, courseDetail: app.operation.CourseInsertUpdateViewModel) {
        return this.http.put<app.operation.CourseInsertUpdateViewModel>(this.url + "courseDetail", courseDetail);
    }

    setActivateCourse(termId: number) {
        return this.http.put(this.url + termId + "/activateCourse",null);
    }

    getCourseId(parameters?: any): Observable<app.operation.CourseOverviewListViewModel> {
        return this.http.get<app.operation.CourseOverviewListViewModel>(this.url + "courseId", {
            params: this.getParameters(parameters)
        });   
    }

    getUploadOptions(date: string = null): FileUploadOptions {
        let options = new FileUploadOptions();
        options.url = this.url + "import";

        return options;
    }

    getFileByGuid(guid: string): Observable<{ filename: string, blob: Blob }> {
        const url = `${this.url}attendance/${guid}`;

        return this.http.get(url, { responseType: 'blob', observe: 'response' }).pipe(
            map((response: HttpResponse<Blob>) => {
                const contentDispositionHeader = response.headers.get('content-disposition');
                const filenameMatch = contentDispositionHeader.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                const filename = filenameMatch ? filenameMatch[1] : 'file';

                return { filename, blob: response.body };
            }),
            catchError(error => {
                console.error('Error occurred:', error);
                return throwError(error);
            })
        );
    }

    getSharepointUploadOptions(courseTermId: number): FileUploadOptions {
        let options = new FileUploadOptions();
        options.url = this.url + "importTherm/" + courseTermId;       
        return options;
    }


    getSupplierList(courseId: number): Observable<app.operation.CourseSupplierListViewModel[]> {
        return this.http.get<app.operation.CourseSupplierListViewModel[]>(this.url + "supplierList/" + courseId);
    }
    
}
