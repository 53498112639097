import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../../core/services/http.service";
import { FileUploadOptions } from "../../../shared/components/file-upload/file-upload.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class CompanyB2bService
    extends HttpService<app.operation.EmployeeListViewModel, app.operation.EmployeeFormViewModel, app.operation.EmployeeInsertUpdateViewModel>
{
    objectKey = "crm.CompanyB2b";
    url: string;

    constructor(http: HttpClient) {
        super(http, "/api/crm/companyb2b/");
    }

    orderListQuery(id: number, parameters?: any): Observable<PagedList<app.b2b.OrderListViewModel>> {
        return this.http.get<PagedList<app.b2b.OrderListViewModel>>(this.url + id + "/orderlist", {
            params: this.getParameters(parameters)
        });
    }

    deliveryNoteListQuery(id: number, parameters?: any): Observable<PagedList<app.b2b.MajorProviderDeliveryNoteListViewModel>> {
        return this.http.get<PagedList<app.b2b.MajorProviderDeliveryNoteListViewModel>>(this.url + id + "/delivery-note-list", {
            params: this.getParameters(parameters)
        });
    }

    getDeliveryNote(companyId: number, deliveryNoteId: number) {
        return this.http.get<app.b2b.MajorProviderDeliveryNoteViewModel>(this.url + companyId + "/delivery-note/" + deliveryNoteId);
    }

    getRecalculatedDeliveryNote(companyId: number, deliveryNoteId: number) {
        return this.http.get<app.b2b.MajorProviderDeliveryNoteViewModel>(this.url + companyId + "/delivery-note/" + deliveryNoteId + "/recalc");
    }

    updateDeliveryNote(companyId: number, deliveryNoteId: number, item: app.b2b.MajorProviderDeliveryNoteViewModel) {
        return this.http.put<app.b2b.MajorProviderDeliveryNoteViewModel>(this.url + companyId + "/delivery-note/" + deliveryNoteId, item);
    }

    dismissDeliveryNote(companyId: number, deliveryNoteId: number) {
        return this.http.put<app.b2b.MajorProviderDeliveryNoteViewModel>(this.url + companyId + "/delivery-note/" + deliveryNoteId + "/dismiss", null);
    }

    invoiceListQuery(companyId: number, parameters?: any): Observable<PagedList<app.b2b.SimpleInvoiceListViewModel>> {
        return this.http.get<PagedList<app.b2b.SimpleInvoiceListViewModel>>(this.url + companyId + "/invoice-list", {
            params: this.getParameters(parameters)
        });
    }

    getInvoice(companyId: number, invoiceId: number) {
        return this.http.get<app.b2b.InvoiceViewModel>(this.url + companyId + "/invoice/" + invoiceId);
    }

    createInvoice(companyId: number, request: any) {
        return this.http.post(this.url + companyId + "/invoice/", request);
    }

    approveInvoice(companyId: number, invoiceId: number) {
        let item = {
            companyId,
            invoiceId
        };
        return this.http.post(this.url + "approve-invoice", item, { responseType: "text" });
    }

    rejectInvoice(companyId: number, invoiceId: number, msgFromHollen: string) {
        let item = {
            companyId,
            invoiceId,
            msgFromHollen
        };
        return this.http.post(this.url + companyId + "/invoice/" + invoiceId + "/reject", item, { responseType: "text" });
    }

    getBasicSetting(id: number): Observable<app.b2b.B2bCompanyBasicSettingViewModel> {
        return this.http.get<app.b2b.B2bCompanyBasicSettingViewModel>(this.url + id + "/b2b-basic-setting");
    }

    updateBasicSetting(item: app.b2b.B2bCompanyBasicSettingViewModel) {
        return this.http.put(this.url + item.companyId + "/b2b-basic-setting", item);
    }

    getB2bDates(id: number): Observable<app.b2b.B2bCompanyEditDatesViewModel> {
        return this.http.get<app.b2b.B2bCompanyEditDatesViewModel>(this.url + id + "/b2b-dates");
    }

    updateB2bDates(item: app.b2b.B2bCompanyEditDatesViewModel) {
        return this.http.put(this.url + item.companyId + "/b2b-dates", item);
    }
}
