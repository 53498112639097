import { Injectable } from "@angular/core";

@Injectable()
export class TimeService {
    getYears(from?: number, to?: number) {
        if (!from) {
            from = 2000;
        }

        if (!to) {
            to = 2025;
        }

        let years = [];

        for (let i = from; i <= to; i++) {
            years.push(i);
        }

        return years;
    }

    getTimes(minHours = 0, maxHours = 24) {
        let times = [];
        let stepCount = 15;
        let numberOfItems = (maxHours - minHours) * (60 / stepCount) + 1;

        for (let i = 0; i < numberOfItems; i++) {
            let hours = Math.floor(i * stepCount / 60);
            let minutes = i * stepCount - hours * 60;

            if (hours >= 24) { hours = hours - 24; }
            let title = hours.toString() + ":";
            if (hours < 10) { title = "0" + title; }
            if (minutes < 10) { title = title + "0"; }
            title = title + minutes.toString();
            if (i >= 96) { title = title + "+"; }

            times.push({
                value: i * stepCount,
                title
            });
        }

        return times;
    }
}
