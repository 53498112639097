import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { Observable, of } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";

import { WorkPlaceService } from "./work-place.service";
import { CompanyService } from "../../crm/company/company.service";
import { IslandService } from "../island/island.service";
import { AccountCenterService } from "../account-center/account-center.service";
import { ListQueryParams } from "../../models/list-query-params.model";
import { filterAndDebounce } from "../../core/utils";
import { TableBaseComponent } from "../../core";
import { finalize, switchMap } from "rxjs/operators";

class QueryParams extends ListQueryParams {
    accountCenterCode: string = null;
    islandCode: string = null;
    companyId: number;
    companyTitleWithAddressCity: string;
    isArchived: boolean = null;
}

@Component({
    selector: "org-work-place-list",
    templateUrl: "./work-place-list.component.html"
})
export class WorkPlaceListComponent extends TableBaseComponent {
    @Input() islandCode: string = null;
    queryParams = new QueryParams();
    objectKey: string;
    model: PagedList<app.organization.WorkPlaceListViewModel>;
    accountCenters: app.organization.AccountCenterSelectListViewModel[];
    islands: app.organization.IslandSelectListViewModel[];
    islandsFullList: app.organization.IslandSelectListViewModel[];
    selectedCompany: app.crm.CompanySelectListViewModel;
    routeParameter: boolean;

    constructor(router: Router, private workPlaceService: WorkPlaceService,
        private companyService: CompanyService,
        private islandService: IslandService,
        private accountCenterService: AccountCenterService,
        private route: ActivatedRoute) {
        super(router);
        this.queryParams.sortBy = this.sortBy = "title";
        this.objectKey = workPlaceService.objectKey;
    }

    ngOnInit() {
        super.ngOnInit();
        this.routeParameter = this.route.parent.snapshot.data["supplyChainRoute"];
        if (this.islandCode) {
            this.queryParams.islandCode = this.islandCode;
        }

        this.accountCenterService.getSelectList().subscribe((result) => {
            this.accountCenters = result;
        });

        this.islandService.getSelectList().subscribe((result) => {
            this.islandsFullList = result;
            this.islands = result;
        });

        if (this.hasPreviousState) {
            if (this.queryParams.companyId) {
                this.selectedCompany = <app.crm.CompanySelectListViewModel>{
                    companyId: this.queryParams.companyId,
                    titleWithAddressCity: this.queryParams.companyTitleWithAddressCity
                };
            }
            this.loadData();
        }
        else {
            this.onReset();
            this.loadData();
        }
    }

    loadData() {
        if (this.selectedCompany) {
            this.queryParams.companyId = this.selectedCompany.companyId;
            this.queryParams.companyTitleWithAddressCity = this.selectedCompany.titleWithAddressCity;
        }
        else {
            this.queryParams.companyId = null;
            this.queryParams.companyTitleWithAddressCity = null;
        }

        this.isLoading = true;

        this.workPlaceService.query(this.queryParams)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.model = res;
                this.saveState(this.queryParams);
            });
    }

    companyAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(term => term.length < 2 ? of([]) : this.companyService.getList(1, 20, term)));
    }

    companyFormatter = (company: app.crm.CompanySelectListViewModel) => company.titleWithAddressCity;

    onReset() {
        this.onClear();
    }

    onClear() {
        this.selectedCompany = null;
    }

    onChangeAccountCenter($event) {
        this.queryParams.accountCenterCode = $event;
        if (this.queryParams.accountCenterCode != null) {
            this.islands = this.islandsFullList.filter(b => b.accountCenterCode == this.queryParams.accountCenterCode);
        } else {
            this.islands = this.islandsFullList;
        }      
    }
}
