import { Component, Input, Output, EventEmitter, NgZone, HostBinding } from "@angular/core";
import { FileUploadOptions, UploadedFile, ProgressInfo } from "./file-upload.service";

@Component({
    selector: "af-file-upload",
    styleUrls: ["./file-upload.component.scss"],
    templateUrl: "./file-upload.component.html"
})
export class FileUploadComponent {

    @Input() id: string;
    @Input() name: string;
    @Input() disabled: boolean;
    @Input() multiple: boolean;
    @Input() accept: string;
    @Input() showProgress: boolean;
    @Input() options: FileUploadOptions;
    @HostBinding("class.af-simple-file-upload") @Input() simple: boolean;
    @HostBinding("class.af-file-upload-flex") @Input() flex: boolean;
    @Input() styleClass: string;
    @Input() title = "";
    @Input() buttonText: string;

    @Output() success = new EventEmitter<UploadedFile>();
    @Output() error = new EventEmitter<UploadedFile>();
    @Output() upload = new EventEmitter<UploadedFile>();

    isUploading = false;
    isSuccessful = false;
    progress = "0%";

    constructor(private ngZone: NgZone) { }

    ngOnInit() {
        // always remove after upload for this case
        this.options.fieldReset = true;
        this.options.calculateSpeed = true;
    }

    onUpload(file: UploadedFile) {
        this.upload.emit(file);

        if (file.done) {
            this.isUploading = false;
            if (file.success) {
                this.success.emit(file);
                this.isSuccessful = true;

                // after success, reset back to empty
                setTimeout(() => {
                    this.isSuccessful = false;
                }, 0);
            }
            else if (file.error) {
                this.error.emit(file);
            }
        } else {
            this.isUploading = true;
            this.ngZone.run(() => {
                this.progress = file.progress.percent + "%";
            });
        }
    }

}
