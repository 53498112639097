
<af-table #afTable [value]="model" [loading]="isLoading" [params]="queryParams" [sortBy]="sortBy" (load)="loadData()" [class.disabled-all]="isLoading">
    <af-column field="description" header="{{ 'hollen.b2b.advancerequestlistviewmodel.description' | translate }}"></af-column>
    <af-column field="amount" header="{{ 'hollen.b2b.advancerequestlistviewmodel.amount' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <div style="white-space: nowrap; text-align: right;">
                {{item.amount | number:'1.2-2'}}
            </div>
        </ng-template>
    </af-column>
    <af-column field="projectTaskNumber" header="{{ 'hollen.b2b.advancerequestlistviewmodel.project task number' | translate }}"></af-column>
    <af-column field="validity" header="{{ 'hollen.clientapp.src.app.crm.company.b2b-advance.b2b-advance-list.component.month of validity' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.month | number: '2.0-0'}}.{{item.year}}
        </ng-template>
    </af-column>
    <af-column field="note" header="{{ 'hollen.b2b.advancerequestlistviewmodel.note' | translate }}"></af-column>
    <af-column field="approveDate" header="{{ 'hollen.b2b.advancerequestlistviewmodel.approved' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.approveDate | afDate:'L'}}
        </ng-template>
    </af-column>
    <af-column field="issue" header="{{ 'hollen.clientapp.src.app.crm.company.b2b-advance.b2b-advance-list.component.issued' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <ng-container *ngIf="item.advanceInvoiceId">
                <a [routerLink]="['/crm/company', companyId, 'b2b', 'invoice', item.advanceInvoiceId]">{{item.advanceInvoiceNumber}}</a>
            </ng-container>
        </ng-template>
    </af-column>
    <af-column field="issue" header="{{ 'hollen.clientapp.src.app.crm.company.b2b-advance.b2b-advance-list.component.cleared in' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <ng-container *ngIf="item.b2bInvoiceId">
                <a [routerLink]="['/crm/company', companyId, 'b2b', 'invoice', item.b2bInvoiceId]">{{item.b2bInvoiceNumber}}</a>
            </ng-container>
        </ng-template>
    </af-column>
    <af-column>
        <ng-template let-item="rowData" afTemplate>
            <button *afHasPerm="{obj:objectKey, perm: 'Update'}" type="button" class="btn btn-default btn-sm" (click)="clickEdit(item)" [disabled]="item.isApproved">
                {{ 'btn.edit' | translate }}
            </button>
            <button *afHasPerm="{obj:objectKey, perm: 'Insert'}" type="button" class="btn btn-default btn-sm" (click)="clickCopy(item)">
                {{ 'btn.copy' | translate }}
            </button>
            <button *afHasPerm="{obj:objectKey, perm: 'Approve', if: !item.isApproved }" type="button" class="btn btn-default btn-sm" (click)="approve(item)">
                {{ 'hollen.clientapp.src.app.crm.company.b2b-advance.b2b-advance-list.component.approve' | translate }}
            </button>
            <button *afHasPerm="{obj:objectKey, perm: 'Approve', if: item.isApproved }" type="button" class="btn btn-default btn-sm"
                    [disabled]="item.advanceInvoiceId != null"
                    (click)="unapprove(item)">
                {{ 'hollen.clientapp.src.app.crm.company.b2b-advance.b2b-advance-list.component.unapprove' | translate }}
            </button>
        </ng-template>
    </af-column>
</af-table>
