
import { filter } from "rxjs/operators";
import { Component, OnInit, OnDestroy, HostBinding, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";

import { MenuService } from "../../../core/services/menu.service";
import { VersionService } from "../../../core/services/version.service";
import { AppService } from "../../../app.service";
import { State } from "../../../store/reducers";
import * as layout from "../../../store/actions/layout";
import { MenuItem } from "../../../models/menu-item";

@Component({
    selector: "af-sidebar",
    styleUrls: ["./sidebar.component.scss"],
    templateUrl: "./sidebar.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit, OnDestroy {
    menu: MenuItem[];

    menuSubscription: Subscription;
    isCollapsedSubscription: Subscription;
    isClosedSubscription: Subscription;

    isClosed: boolean;
    isCollapsed: boolean;
    versionDate: Date;

    constructor(private menuService: MenuService,
        private versionService: VersionService,
        private store: Store<State>,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.menuSubscription = this.store.select(x => x.menu.menu).pipe(
            filter(loaded => !!loaded))
            .subscribe(res => {
                this.updateMenu();
            });

        this.isCollapsedSubscription = this.store.select(x => x.layout.mobileMenuIsCollapsed).subscribe(isCollapsed => {
            this.isCollapsed = isCollapsed;
            this.changeDetectorRef.markForCheck();
            this.triggerWindowResize();
        });

        this.isClosedSubscription = this.store.select(x => x.layout.sidebarIsClosed).subscribe(isClosed => {
            this.isClosed = isClosed;
            this.changeDetectorRef.markForCheck();
            this.triggerWindowResize();
        });
    }

    triggerWindowResize() {
        setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
        });
    }

    ngOnDestroy() {
        if (this.menuSubscription) {
            this.menuSubscription.unsubscribe();
        }
        if (this.isCollapsedSubscription) {
            this.isCollapsedSubscription.unsubscribe();
        }
        if (this.isClosedSubscription) {
            this.isClosedSubscription.unsubscribe();
        }
    }

    @HostBinding("class.page-sidebar-closed") get c1() { return this.isClosed; }

    updateMenu() {
        this.versionDate = this.versionService.versionDate;
        this.menu = this.menuService.getMenu();
        this.changeDetectorRef.markForCheck();
    }

    getUrl(item) {
        return this.menuService.getUrl(item);
    }

    toggleSidebar() {
        if (this.isClosed) {
            this.store.dispatch(new layout.OpenSidenavAction());
        }
        else {
            this.store.dispatch(new layout.CloseSidenavAction());
        }
    }

    toggleMenu(item) {
        this.menuService.toggleOpen(item);
    }

    onSubMenuChange() {
        if (!this.isCollapsed) {
            this.store.dispatch(new layout.CloseMobileMenuAction());
        }
    }
}
