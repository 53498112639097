import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { FileUploadOptions } from "../../../shared/components/file-upload/file-upload.service";

@Injectable()
export class InventoryTransferService
    extends HttpService<app.operation.InventoryTransferListViewModel, app.operation.InventoryTransferFormViewModel, app.operation.InventoryTransferInsertUpdateViewModel>
{
    objectKey = "op.InventoryTransfer";

    constructor(http: HttpClient) {
        super(http, "/api/operation/inventorytransfer/");
    }

    getFilter(): Observable<app.operation.InventoryTransferListFilterViewModel> {
        return this.http.get<app.operation.InventoryTransferListFilterViewModel>(this.url + "filter");
    }

    //getHistory(page: number, pageSize: number, id: number): Observable<af.sys.EntityHistoryViewModel[]> {
    //    let params = new HttpParams();
    //    params = params.set("page", page.toString());
    //    params = params.set("pageSize", pageSize.toString());
    //    return this.http.get<af.sys.EntityHistoryViewModel[]>(this.url + id + "/history", { params });
    //}

    getNewInventoryTransferCopy(inventoryTransferId: number, transferType: number): Observable<app.operation.InventoryTransferFormViewModel> {
        const url = this.url + "new/" + inventoryTransferId + "/" + transferType;
        return this.http.post<app.operation.InventoryTransferFormViewModel>(url, null);
    }

    getNewInventoryTransfer(inventoryIds: number[], transferType: number): Observable<app.operation.InventoryTransferFormViewModel> {
        const url = this.url + "new/" + transferType;
        return this.http.post<app.operation.InventoryTransferFormViewModel>(url, inventoryIds);
    }


    getByInventoryNumber(inventoryNumber: string, hollenCompanyCode: string): Observable<app.operation.InventoryTransferInsertItemViewModel> {
        return this.http.get<app.operation.InventoryTransferInsertItemViewModel>(this.url + "inventoryNumber/" + inventoryNumber + "/" + hollenCompanyCode);
    }

    action(request: app.operation.InventoryTransferActionRequest) {
        let url = this.url + request.inventoryTransferId + "/action";
        return this.http.put(url, request);
    }

    getUploadOptions(inventoryTransferId: number, inventoryIds: number[]): FileUploadOptions {
        const params = new HttpParams().set('inventoryIds', inventoryIds.join(','));
        let options = new FileUploadOptions();
        options.url = this.url + inventoryTransferId + "/file/?" + params.toString();
        return options;
    }

    deleteFile(inventoryTransferId: number) {
        let params = new HttpParams();
        params = params.set("inventoryTransferId", inventoryTransferId.toString());
        let url = this.url + inventoryTransferId.toString() + "/file";
        return this.http.delete(url, { params });
    }

    getReportUrl(params: any) {
        let parameters = this.getParameters(params);
        parameters.set("format", "xlsx");
        return this.url + `report?${parameters.toString()}`;
    }
}
