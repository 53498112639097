import { Component, Input, SimpleChanges, OnChanges, ChangeDetectionStrategy } from "@angular/core";

import { ReportProjectTaskService, ReportProjectTaskRequest } from "./report-project-task.service";
import { WorkPlaceService } from "../../organization/work-place/work-place.service";

@Component({
    selector: "op-month-report",
    templateUrl: "./month-report.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonthReportComponent implements OnChanges {
    @Input() params: ReportProjectTaskRequest;
    workplaces: app.organization.WorkPlaceSelectListViewModel[];
    request = <app.operation.MonthReportRequest>{};

    constructor(private reportService: ReportProjectTaskService, private workPlaceService: WorkPlaceService) { }

    getUrl() {
        let requestParams = Object.assign({}, this.params, this.request);
        return this.reportService.getMonth(requestParams);
    }

    ngOnChanges(changes: SimpleChanges) {
        let newParams = changes["params"];
        if (newParams && newParams.currentValue.branchId) {
            this.workPlaceService.getSelectList(null, null, null, null, newParams.currentValue.branchId).subscribe(res => {
                this.workplaces = res;
            });
        }
    }
}
