import { Directive, ElementRef, HostListener, HostBinding, OnInit } from "@angular/core";
import { ControlContainer, FormGroupDirective, NgForm } from "@angular/forms";

import { ToastService } from "../../core";

@Directive({
    selector: "form:not([afNoFormValidationSummary]):not([ngNoForm]),afFormValidationSummary,[afFormValidationSummary]"
})
export class FormValidationSummaryDirective implements OnInit {

    _form: NgForm | FormGroupDirective;

    constructor(private elementRef: ElementRef,
        private form: ControlContainer,
        private toastService: ToastService) { }

    ngOnInit(): void {
        if (this.form instanceof NgForm || this.form instanceof FormGroupDirective) {
            this._form = this.form;
        }       
    }

    @HostBinding("class.ng-submitted")
    get formSubmitted() {
        return this._form.submitted;
    }

    @HostListener("submit", ["$event"])
    onSubmit($event) {
        if (this._form.valid) {
            return;
        }

        setTimeout(() => this.showValidationMessages(), 0);
    }

    showValidationMessages() {
        let el = $(this.elementRef.nativeElement);

        let focusEl = el.find(".ng-invalid:first");

        if (focusEl && focusEl.length > 0) {
            $("html, body").animate({
                scrollTop: focusEl.offset().top - 80
            },
                {
                    duration: 500,
                    complete: () => {
                        focusEl.focus();
                    }
                });
        }

        let messages = el.find(".field-validation-error");

        let errorMessage = "";

        for (let i = 0; i < messages.length; i++) {
            errorMessage += messages[i].textContent + "<br />";
        }

        if (!errorMessage || errorMessage.length === 0) {
            errorMessage = "Invalid form";
        }

        this.toastService.error(errorMessage);
    }
}
