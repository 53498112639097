import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";

import { ReportEmployeeService, ReportEmployeeRequest } from "./report-employee.service";

@Component({
    selector: "op-report-employee-detail",
    templateUrl: "./report-employee-detail.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportEmployeeDetailComponent {
    @Input() params: ReportEmployeeRequest;
    @Input() item: app.sys.ReportViewModel;

    selectedReport: app.sys.ReportDetailViewModel;

    constructor(private reportService: ReportEmployeeService, private changeDetectorRef: ChangeDetectorRef) { }

    getUrl() {
        if (!this.selectedReport) {
            return;
        }
        let requestParams = Object.assign({}, this.params);
        return this.reportService.getReportUrl(this.selectedReport, requestParams, this.selectedReport["parameters"]);
    }

    onReportSelected(report: app.sys.ReportDetailViewModel) {
        this.selectedReport = report;
        this.reportService.getReportParameters(this.selectedReport.reportId).subscribe(result => {
            this.selectedReport["parameters"] = result;
            this.changeDetectorRef.markForCheck();
        });
    }
}
