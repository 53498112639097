import { Injectable } from "@angular/core";
import { HttpService } from "../core/services/http.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class OrderNoteService
    extends HttpService<app.b2b.OrderNoteListViewModel, app.b2b.OrderNoteFormViewModel, app.b2b.OrderNoteInsertUpdateViewModel> {
    objectKey = "b2b.OrderNote";

    constructor(http: HttpClient) {
        super(http, "/api/b2b/ordernote/");
    }
}
