<af-spinner #afSpinner [loading]="loading" [timeout]="timeout"></af-spinner>
<div *ngIf="!afSpinner.loading">
	<div *ngIf="value?.totalCount > 0">
		<div class="table-header" *ngIf="header">
			<ng-content select="header"></ng-content>
		</div>
        <div class="table-count" *ngIf="showCount">
            {{ 'table.record count_' | translate }} {{value?.totalCount}}
        </div>
        <af-paginator name="top" [pageSizes]="pageSizes" [pageSize]="value?.pageSize" [page]="value?.page" [totalCount]="value?.totalCount" [pageLinkSize]="pageLinks"
                      styleClass="table-paginator-bottom" (pageChange)="paginate($event)" *ngIf="paginator"></af-paginator>
		<div class="table-wrapper">
			<table class="af-table table table-striped table-bordered {{tableClass}} {{tableClasses}}" [class.table-hover]="hover" afFixedTableHeaders>
				<thead>
					<tr *ngIf="!headerRows">
                        <th #headerCell *ngFor="let col of columns" [ngStyle]="col.style" [class]="col.styleClass" (click)="sort($event,col)"
                            [ngClass]="{'sortable': col.sortable,'sort-asc': isSorted(col) && !getSortOrder(col), 'sort-desc': isSorted(col) && (getSortOrder(col) == 'desc')}">
                            <div class="column-title" [class.sort-indicator]="col.sortable">
                                <ng-container *ngIf="!col.headerTemplate">{{col.header}}</ng-container>
                                <ng-container *ngIf="col.headerTemplate"><af-column-header-template-loader [column]="col"></af-column-header-template-loader></ng-container>
                            </div>
                        </th>
					</tr>
					<tr *ngFor="let headerRow of headerRows">
						<th #headerCell *ngFor="let col of headerRow.columns" [ngStyle]="col.style" [class]="col.styleClass" [attr.colspan]="col.colspan"
							[attr.rowspan]="col.rowspan" (click)="sort($event,col)"
							[ngClass]="{'sortable': col.sortable,'sorted-column': isSorted(col)}">
                            <div class="column-title" [class.sort-indicator]="col.sortable">
                                <ng-container *ngIf="!col.headerTemplate">{{col.header}}</ng-container>
                                <ng-container *ngIf="col.headerTemplate"><af-column-header-template-loader [column]="col"></af-column-header-template-loader></ng-container>
                            </div>
						</th>
					</tr>
				</thead>
				<tfoot *ngIf="hasFooter()">
					<tr *ngIf="!footerRows">
						<th *ngFor="let col of columns" [ngStyle]="col.style" [class]="col.styleClass">{{col.footer}}</th>
					</tr>
					<tr *ngFor="let footerRow of footerRows">
						<th *ngFor="let col of footerRow.columns" [ngStyle]="col.style" [class]="col.styleClass" [attr.colspan]="col.colspan" [attr.rowspan]="col.rowspan">{{col.footer}}</th>
					</tr>
				</tfoot>
				<tbody>
                    <ng-container *ngFor="let rowData of dataToRender; let even = even; let odd = odd;">
                        <tr #rowElement class="ui-widget-content"
                            (click)="onRowClick($event, rowData)" (dblclick)="onRowDblclick($event, rowData)" [ngClass]="setRowClasses(rowData, even, odd, rowElement)">
                            <td *ngFor="let col of columns" [ngStyle]="col.style" [class]="col.styleClass" [ngClass]="setColClasses(rowData, col)"
                                [attr.colspan]="getColSpan(rowData, col)" [attr.rowspan]="getRowSpan(rowData, col)"
                                (click)="switchCellToEditMode($event.target,col,rowData)">
                                <span class="table-cell-data" *ngIf="!col.template">{{resolveFieldData(rowData,col.field)}}</span>
                                <span class="table-cell-data" *ngIf="col.template">
                                    <af-column-template-loader [column]="col" [rowData]="rowData"></af-column-template-loader>
                                </span>
                                <input type="text" class="table-cell-editor table-row-highlight" *ngIf="col.editable" [(ngModel)]="rowData[col.field]" (blur)="switchCellToViewMode($event.target,col,rowData,true)"
                                       (keydown)="onCellEditorKeydown($event, col, rowData)" />
                            </td>
                        </tr>
                        <tr *ngIf="rowData.isExpanded" [ngClass]="setRowClasses(rowData, even, odd, rowElement)">
                            <td [attr.colspan]="columns.length">
                                <span class="table-cell-data" *ngIf="expanded.template">
                                    <af-table-expanded-row-template-loader [expanded]="expanded" [rowData]="rowData"></af-table-expanded-row-template-loader>
                                </span>
                            </td>
                        </tr>
                    </ng-container>
				</tbody>
			</table>
		</div>
        <af-paginator name="bottom" [pageSizes]="pageSizes" [pageSize]="value?.pageSize" [page]="value?.page" [totalCount]="value?.totalCount" [pageLinkSize]="pageLinks"
			styleClass="table-paginator-bottom" (pageChange)="paginate($event)" *ngIf="paginator"></af-paginator>
		<div class="table-footer" *ngIf="footer">
			<ng-content select="footer"></ng-content>
		</div>
	</div>
	<af-note *ngIf="value && value.totalCount === 0" type="info">
        <span *ngIf="noRecordsFoundText">{{ noRecordsFoundText }}</span>
        <span *ngIf="!noRecordsFoundText">{{ 'table.no records found' | translate }}</span>
	</af-note>
</div>
