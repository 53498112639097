import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable, of, Subject, Subscription } from "rxjs";
import { finalize, map, switchMap, tap } from "rxjs/operators";
import { FormComponent } from "../../../core";
import { B2bAdvanceService } from "./b2b-advance.service";

@Component({
    selector: "crm-company-b2b-advance-create",
    templateUrl: "./b2b-advance-create.component.html"
})
export class B2bAdvanceCreateComponent extends FormComponent {

    @Input() companyId: number;
    @Input() input = new Observable<{ advanceId: number }>();
    @Output() inserted = new EventEmitter<app.b2b.AdvanceRequestInsertUpdateViewModel>();
    @Output() canceled = new EventEmitter<any>();
    @ViewChild("createForm", { static: true }) createForm: NgForm;
    subscription: Subscription;
    model = <app.b2b.AdvanceRequestInsertUpdateViewModel>{};
    objectKey: string;
    allowedMonths: app.b2b.MonthSelectViewModel[];
    allowedProjectTasks: app.b2b.ProjectTaskB2bSelectListViewModel[];
    advanceTypes: dotnet.KeyValuePair[];
    maxAmount: number;
    isCopy: boolean;

    constructor(protected router: Router,
        private b2bAdvanceService: B2bAdvanceService) {
        super(router);
        this.objectKey = b2bAdvanceService.objectKey;
    }

    ngOnInit() {
        super.ngOnInit();

        this.subscription = this.input
            .pipe(tap(() => this.isLoading = true),
                switchMap(x => {
                    // copy
                    if (x && x.advanceId) {
                        this.isCopy = true;
                        return this.b2bAdvanceService.getNew({ companyId: this.companyId, requestId: x.advanceId })
                            .pipe(finalize(() => this.isLoading = false));
                    }
                    // new
                    else {
                        this.isCopy = false;
                        return this.b2bAdvanceService.getNew({ companyId: this.companyId })
                            .pipe(finalize(() => this.isLoading = false));
                    }
                }))
            .subscribe(x => {
                this.model = x.model;
                this.allowedMonths = x.allowedMonths;
                this.allowedProjectTasks = x.allowedProjectTasks;
                this.advanceTypes = x.advanceTypes;
                this.maxAmount = x.maxAmount;
            });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    insert() {
        if (this.createForm.invalid) {
            return;
        }

        this.isLoading = true;

        this.b2bAdvanceService.create(this.model, this.isCopy)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.inserted.emit(res);
            });
    }

    cancel() {
        this.canceled.emit();
    }

    onAdvanceTypeChange(event: any) {
        this.model.advanceType = event;
        this.model.projectTask = null;
    }

    onMonthOfValidityChange(event: any, index: number) {
        this.model.monthOfValidity = event;

        if (!this.isCopy) {
            this.model.projectTask = null;
        }
        else {
            this.model.projectTask = this.allowedProjectTasks
                .find(x => x.year == this.model.monthOfValidity.year && x.month == this.model.monthOfValidity.month);
        }
    }

    projectTaskAutocomplete = (text$: Observable<string>) =>
        text$.pipe(
            map(term => term.length < 2 ? [] : this.allowedProjectTasks
                .filter(x => x.year == this.model.monthOfValidity.year && x.month == this.model.monthOfValidity.month
                    && x.fullProjectTaskNumber.toLowerCase().includes(term.trim().toLowerCase())))
        );

    projectTaskFormatter = (projectTask: app.b2b.ProjectTaskB2bSelectListViewModel) => (projectTask.fullProjectTaskNumber + " - " + projectTask.title);

    compareWithFn = (o1: app.b2b.MonthSelectViewModel, o2: app.b2b.MonthSelectViewModel) => o1.month == o2.month && o1.year == o2.year;
}
