import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { FileUploadOptions } from "../../../shared/components/file-upload/file-upload.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpService } from "../../../core/services/http.service";

@Injectable()
export class OtherAgreementsService
    extends HttpService<app.crm.OtherAgreementsListViewModel, app.crm.OtherAgreementsFormViewModel, app.crm.OtherAgreementsInsertUpdateViewModel> {
    objectKey = "crm.OtherAgreements";

    constructor(http: HttpClient) {
        super(http, "/api/crm/otheragreements/");
    }

    approve(otherAgreementsId: number): Observable<any> {
        return this.http.put(this.url + otherAgreementsId + "/approve", "");
    }

    unapprove(otherAgreementsId: number): Observable<any> {
        return this.http.put(this.url + otherAgreementsId + "/unapprove", "");
    }

    getFilter(): Observable<app.crm.OtherAgreementsListFilterViewModel> {
        return this.http.get<app.crm.OtherAgreementsListFilterViewModel>(this.url + "filter");
    }

    getNewByCompany(companyId: number): Observable<app.crm.OtherAgreementsFormViewModel> {
        let params = new HttpParams();
        params = params.set("companyId", companyId.toString());
        return this.http.get<app.crm.OtherAgreementsFormViewModel>(this.url + "new", { params });
    }
}
