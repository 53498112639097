import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'afPure',
    pure: true, // thats important
})
export class PurePipe implements PipeTransform {
    public transform(templateValue: any, fnReference: Function, ...fnArguments: any[]): any {
        // join the inputs from both sides of the function ref
        fnArguments.unshift(templateValue);

        return fnReference.apply(null, fnArguments);
    }
}
