import { SyncQueueService } from './sync-queue/sync-queue.service';
import { MetriqSyncService } from './metriq-sync.service';
import { TechnicianTableService } from './technician-table/technician-table.service';
import { WorkTimeUnsyncedService } from './work-time-unsynced/work-time-unsynced.service';

export const providers = [
    MetriqSyncService,
    SyncQueueService,
    WorkTimeUnsyncedService,
    TechnicianTableService
]
