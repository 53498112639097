import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { WorkClothesService } from "../work-clothes/work-clothes.service";
import { ListQueryParams } from "../../models/list-query-params.model";
import { AppService } from "../../app.service";
import { PermissionService } from "../../core";
import { finalize } from "rxjs/operators";

class QueryParams extends ListQueryParams {
    accountCenterCode: string;
    employeeId: number;
    employeeName: string;
    searchTerm: string;
}

@Component({
    selector: "op-employee-work-clothes",
    templateUrl: "./employee-work-clothes.component.html"
})
export class EmployeeWorkClothesComponent implements OnInit {
    queryParams = new QueryParams();
    employeeId: number;
    objectKey: string;
    model: PagedList<app.operation.WorkClothesListViewModel>;
    isLoading: boolean;
    sortBy: string;
    currentUser: app.UserViewModel;
    hasUpdatePermission = false;

    constructor(private route: ActivatedRoute, private workClothesService: WorkClothesService, private appService: AppService, private permissionService: PermissionService) {
        this.queryParams.sortBy = this.sortBy = "title";
        this.objectKey = workClothesService.objectKey;
        this.currentUser = appService.homeViewModel.user;
        this.hasUpdatePermission = this.permissionService.hasPermission(this.objectKey, "Update");
    }

    ngOnInit() {
        if (this.route  .snapshot.data && this.route.snapshot.data.currentUser) {
            this.employeeId = this.currentUser.employeeId;
        }
        else {
            this.employeeId = +this.route.parent.snapshot.params["employeeId"];
        }
        this.loadData();
    }

    loadData() {
        this.queryParams.employeeId = this.employeeId;
        this.isLoading = true;

        this.workClothesService.query(this.queryParams)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.model = res;
            });
    }
}
