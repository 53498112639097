import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { finalize } from "rxjs/operators";
import { FormComponent } from "../../core";
import { AccommodationService } from "../accommodation/accommodation.service";
import { AccommodationTermService } from "./accommodation-term.service";

@Component({
    selector: "op-accommodation-term-create",
    templateUrl: "./accommodation-term-create.component.html"
})
export class AccommodationTermCreateComponent extends FormComponent {

    @Input() employeeId: number;
    @Output() inserted = new EventEmitter<app.operation.AccommodationTermInsertUpdateViewModel>();
    @ViewChild("createForm", { static: true }) createForm: NgForm;

    model = <app.operation.AccommodationTermInsertUpdateViewModel>{};
    objectKey: string;
    accommodations: app.operation.AccommodationSelectListViewModel[];
    accommodationTypes: app.operation.AccommodationTypeSelectListViewModel[];

    constructor(protected router: Router,
        private accommodationTermService: AccommodationTermService,
        private accommodationService: AccommodationService) {
        super(router, "accommodationTerm");
        this.objectKey = accommodationTermService.objectKey;
    }

    ngOnInit() {
        super.ngOnInit();

        this.isLoading = true;
        this.accommodationTermService.getNew({ employeeId: this.employeeId })
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(x => {
                this.model = x.model;
                this.accommodations = x.accommodations;
            });
    }

    insert() {
        if (this.createForm.invalid) {
            return;
        }

        if (this.model.invoicedRate != null) this.model.invoicedRate = Math.floor(this.model.invoicedRate);

        this.isLoading = true;
        this.accommodationTermService.insert(this.model)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.inserted.emit(res);
            });
    }

    onChange(event: number) {
        if (!event) return;

        this.model.accommodationTypeId = null;

        this.accommodationService.getTypeSelectList(event)
            .subscribe(res => this.accommodationTypes = res)
    }

    reset() {
        this.model.note = null;
    }
}
