import { Component, Input, Output, EventEmitter, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { makeRandomId } from "src/app/core/utils";

export const CHECKBOX_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true
};

@Component({
    selector: "af-checkbox",
    template: `
        <div class="form-check" [class.form-check-inline]="inline">
            <input type="checkbox"
                [id]="id"
                [name]="name"
                [value]="value"
                [checked]="checked"
                [disabled]="disabled"
                (focus)="onFocus($event)" (blur)="onBlur($event)"
                [ngClass]="{'ui-state-focus':focused}"
                (change)="handleChange($event)"
                class="form-check-input" />
            <label class="form-check-label" [for]="id"><ng-content></ng-content> {{label}}</label>
        </div>
    `,
    providers: [CHECKBOX_VALUE_ACCESSOR]
})
export class CheckboxComponent implements ControlValueAccessor {

    @Input() value: any;

    @Input() name: string;

    @Input() disabled: boolean;

    @Input() inline: boolean;

    @Input() binary = true;

    @Input() label: string;

    @Output() change: EventEmitter<any> = new EventEmitter();

    model: any;

    hover: boolean;

    focused = false;

    checked = false;

    private _id: string;  

    constructor() {
        this._id = makeRandomId();
    }

    get id() {
        return (this.name ? this.name.replace(".", "_") : this.name) + this._id;
    }

    onClick(event, checkbox, focus: boolean) {
        event.preventDefault();

        if (this.disabled) {
            return;
        }

        this.checked = !this.checked;
        this.updateModel();

        if (focus) {
            checkbox.focus();
        }
    }

    updateModel() {
        if (!this.binary) {
            if (this.checked) {
                this.addValue(this.value);
            } else {
                this.removeValue(this.value);
            }

            this.onModelChange(this.model);
        } else {
            this.onModelChange(this.checked);
        }

        this.change.emit(this.checked);
    }

    handleChange(event) {
        this.checked = event.target.checked;
        this.updateModel();
    }

    isChecked(): boolean {
        if (!this.binary) {
            return this.findValueIndex(this.value) !== -1;
        } else {
            return this.model;
        }
    }

    removeValue(value) {
        let index = this.findValueIndex(value);
        if (index >= 0) {
            this.model.splice(index, 1);
        }
    }

    addValue(value) {
        this.model.push(value);
    }

    onFocus(event) {
        this.focused = true;
    }

    onBlur(event) {
        this.focused = false;
        this.onModelTouched();
    }

    findValueIndex(value) {
        let index: number = -1;
        if (this.model) {
            for (let i = 0; i < this.model.length; i++) {
                if (this.model[i] === value) {
                    index = i;
                    break;
                }
            }
        }

        return index;
    }

    writeValue(model: any): void {
        this.model = model;
        this.checked = this.isChecked();
    }

    registerOnChange(fn: Function): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onModelTouched = fn;
    }

    setDisabledState(val: boolean): void {
        this.disabled = val;
    }

    onModelChange: Function = () => { };

    onModelTouched: Function = () => { };
}
