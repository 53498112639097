import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from "../../../app.service";
import { SharePointFileService } from "./share-point-file.service";
import { ListQueryParams } from "../../../models/list-query-params.model";
import { FileUploadOptions } from "../file-upload/file-upload.service";
import { Immutable } from "../../../core/immutable";
import { finalize } from "rxjs/operators";

class QueryParams extends ListQueryParams {
    employeeId: number;
    invoiceNumber: string;
    islandCode: string;
    workPlaceId: number;
    amadeusId: number;
    courseTermId: number;
    path: string;
}

class SharePointFolder {
    files: PagedList<app.sharepoint.SharePointFileViewModel>;
    folder: app.sharepoint.SharePointResponse;
}

@Component({
    selector: "af-share-point-file",
    templateUrl: "./share-point-file.component.html"
})
export class SharePointFileComponent implements OnInit {
    @Input() path: string;
    @Input() isFilter: boolean = true;
    @Input() isUpload: boolean = true;
    @Input() content: SharePointFolder;
    @Input() employeeId: number = null;
    @Input() invoiceNumber: string = null;
    @Output() contentChange = new EventEmitter();
    @Output() countChange = new EventEmitter<number>();
    isUploadPermission: boolean = false;
    isDeletePermission: boolean = false;
    isSharepointLinkDisabled: boolean = true;

    objectKey: string;
    queryParams = new QueryParams();
    uploadOptions: FileUploadOptions;
    model: PagedList<app.sharepoint.SharePointFileViewModel>;
    isLoading: boolean;
    sortBy: string;
    isSharepointEnabled = false;
    isLoadingSharePoint = false;
    islandCode: string;

    pathList: string[] = [];

    constructor(protected router: Router,
        protected route: ActivatedRoute,
        private appService: AppService,
        private sharePointFileService: SharePointFileService,
        private sanitizer: DomSanitizer) {
        this.queryParams.sortBy = this.sortBy = "lastUpdatedUtc desc";
        this.objectKey = sharePointFileService.objectKey;
        this.isSharepointEnabled = this.appService.isSharepointEnabled;
    }

    ngOnInit() {
        this.onReset();
        this.setUploadOptions();
        if (this.content == null) {
            this.loadFiles();
            this.loadFolder();
        } else {
            this.model = this.content.files;
        }
    }

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    target(item: app.sharepoint.SharePointFileViewModel) {
        if (item.fileUrl.startsWith("http") || item.fileUrl.startsWith("/")) return "_blank";
        else return "";
    }

    loadFiles() {
        this.isLoading = true;

        this.sharePointFileService.query(this.queryParams)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.model = res;
                this.countChange.emit(res.totalCount);

                this.content = { ...this.content, files: res };
                this.contentChange.emit(this.content);
            });
    }

    loadFolder() {
        if (this.isSharepointEnabled) {
            this.isLoadingSharePoint = true;
            this.sharePointFileService.getSharePointFolder(this.folderInfoRequest)
                .pipe(finalize(() => {
                    this.isLoadingSharePoint = false;
                }))
                .subscribe(folder => {
                    if (folder.title) {
                        this.islandCode = folder.title;
                    }
                    this.content = { ...this.content, folder: folder };
                    this.isUploadPermission = this.isUpload && folder.isEditPermission;
                    this.isDeletePermission = folder.isDeletePermission;
                    this.isSharepointLinkDisabled = folder.isSharepointLinkDisabled;
                    this.contentChange.emit(this.content);
                });
        }
    }

    onSuccessUpload($event) {
        this.loadFiles();
    }

    delete(item: app.sharepoint.SharePointFileViewModel) {
        this.sharePointFileService.delete(item).subscribe(() => this.loadFiles());
    }

    updateItem(item: app.sharepoint.SharePointFileViewModel, propObj: any) {
        let index = this.model.data.indexOf(item);
        this.model = Immutable.setInPagedList(this.model, index, propObj);
        this.model = Immutable.setInPagedList(this.model, index, { updating: true });
        this.sharePointFileService.update(this.model.data[index], item.islandCode)
            .pipe(finalize(() => {
                this.model = Immutable.setInPagedList(this.model, index, { updating: false });
            })).subscribe(() => { });
    }

    onReset() {
        this.onClear();
    }

    onClear() {
        this.queryParams.page = 1;
        this.queryParams.searchTerm = null;
        this.queryParams.employeeId = this.employeeId || +this.appService.getParam("employeeId") || undefined;
        this.queryParams.invoiceNumber = this.invoiceNumber;
        this.queryParams.islandCode = this.appService.getParam("code") || this.islandCode;
        this.queryParams.workPlaceId = +this.appService.getParam("workPlaceId") || undefined;
        this.queryParams.amadeusId = +this.appService.getParam("amadeusId") || undefined;
        this.queryParams.path = this.path;
    }

    goToPath(index: number) {
        this.path = this.content.folder.path.slice(1, index + 1).join("/");
        this.onReset();

        this.setUploadOptions();
        this.loadFiles();
        this.loadFolder();
    }

    goToFolder(folderName: string) {
        let currentPath = this.content.folder.path.slice(1).join("/");
        this.path = (currentPath ? currentPath + "/" : "") + folderName;
        this.onReset();

        this.setUploadOptions();
        this.loadFiles();
        this.loadFolder();
    }

    setUploadOptions() {
        this.uploadOptions = this.sharePointFileService.getUploadOptions(this.folderInfoRequest);
    }

    get folderInfoRequest(): app.sharepoint.SharePointFolderInfoRequest {
        return <app.sharepoint.SharePointFolderInfoRequest>{
            amadeusId: this.queryParams.amadeusId,
            employeeId: this.queryParams.employeeId,
            invoiceNumber: this.queryParams.invoiceNumber,
            //inventoryId: number;
            islandCode: this.queryParams.islandCode,
            path: this.queryParams.path,
            workPlaceId: this.queryParams.workPlaceId,
            courseTermId: this.queryParams.courseTermId
        };
    }

    get isTopFolder() { return !this.content || !this.content.folder.path || this.content.folder.path.length == 1 };

    goToUpperFolder() {
        if (!this.content || !this.content.folder.path || this.content.folder.path.length < 2) {
            return;
        }

        this.goToPath(this.content.folder.path.length - 2);
    }
}
