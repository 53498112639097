
<af-table #afTable [value]="model" [loading]="isLoading" [params]="queryParams" [sortBy]="sortBy" (load)="loadData()">
    <af-column field="title" sortable="title" header="{{ 'hollen.operation.workclotheslistviewmodel.workclothes' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a *ngIf="hasUpdatePermission" [routerLink]="['/operation/workClothes', item.workClothesId, 'transfer']">{{item.title}}</a>
            <span *ngIf="!hasUpdatePermission">{{item.title}}</span>
        </ng-template>
    </af-column>
    <af-column field="size" sortable="size" header="{{ 'hollen.operation.workclotheslistviewmodel.size' | translate }}"></af-column>
    <af-column field="type" sortable="type" header="{{ 'hollen.operation.workclotheslistviewmodel.type' | translate }}"></af-column>
    <af-column field="actualStock" sortable="actualStock" header="{{ 'hollen.operation.workclotheslistviewmodel.actual stock' | translate }}"></af-column>
    <af-column field="amount" sortable="amount" header="{{ 'hollen.operation.workclotheslistviewmodel.amount' | translate }}"></af-column>
    <af-column field="accountCenterCode" sortable="accountCenterCode" header="{{ 'hollen.operation.workclotheslistviewmodel.accountcentercode' | translate }}"></af-column>
    <af-column field="date" header="{{ 'hollen.operation.workclotheslistviewmodel.last issue' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.date|afDate:'L' }}
        </ng-template>
    </af-column>
</af-table>
