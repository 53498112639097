import { Component, Input, ViewContainerRef, OnInit, OnDestroy, ChangeDetectionStrategy, EmbeddedViewRef } from "@angular/core";

@Component({
    selector: "af-table-expanded-row-template-loader",
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: ``
})
export class TableExpandedRowTemplateLoaderComponent implements OnInit, OnDestroy {

    @Input() expanded: any;

    @Input() rowData: any;

    view: EmbeddedViewRef<any>;

    constructor(private viewContainer: ViewContainerRef) { }

    ngOnInit() {
        this.view = this.viewContainer.createEmbeddedView(this.expanded.template, {
            $implicit: this.expanded,
            rowData: this.rowData
        });
    }

    ngOnDestroy() {
        if (this.view) {
            this.view.destroy();
        }
    }
}
