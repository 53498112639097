import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "afStripDiacritics"
})
export class StripDiacriticsPipe implements PipeTransform {

    transform(s) {
        if (!s) {
            return;
        }

        s = s.replace(/[ÀÁÂÃÄÅ]/g, "A");
        s = s.replace(/[àáâãäå]/g, "a");

        s = s.replace(/[ČÇ]/g, "C");
        s = s.replace(/[čç]/g, "c");

        s = s.replace(/[Ď]/g, "D");
        s = s.replace(/[ď]/g, "d");

        s = s.replace(/[ÈÉËĚÊ]/g, "E");
        s = s.replace(/[èéëěê]/g, "e");

        s = s.replace(/[ÌÍ]/g, "I");
        s = s.replace(/[ìí]/g, "i");

        s = s.replace(/[ĹĽ]/g, "L");
        s = s.replace(/[ĺľ]/g, "l");

        s = s.replace(/[ŇÑ]/g, "N");
        s = s.replace(/[ňñ]/g, "n");

        s = s.replace(/[ÖŐÓÔÕÒ]/g, "O");
        s = s.replace(/[öőóôõò]/g, "o");

        s = s.replace(/[ŘŔ]/g, "R");
        s = s.replace(/[řŕ]/g, "r");

        s = s.replace(/[Š]/g, "S");
        s = s.replace(/[š]/g, "s");

        s = s.replace(/[Ť]/g, "T");
        s = s.replace(/[ť]/g, "t");

        s = s.replace(/[ÙÚŮÜŰ]/g, "U");
        s = s.replace(/[ùúůüű]/g, "u");

        s = s.replace(/[Ý]/g, "Y");
        s = s.replace(/[ý]/g, "y");

        s = s.replace(/[Ž]/g, "Z");
        s = s.replace(/[ž]/g, "z");
        
        return s;
    }
}
