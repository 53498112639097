import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, HostBinding } from "@angular/core";

@Component({
    selector: "af-spinner",
    template: `
        <div class="spinner" *ngIf="loading && !type" [class.inline]="isInline">
            <div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div>
        </div>
        <div class="spinner-circle" *ngIf="loading && type == 'circle'"></div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {
    _loading: boolean;
    _timeoutId: any;

    @Input() type: string;

    @Input() timeout = 1000;

    @Input() isInline = false;

    @Input() get loading(): boolean {
        return this._loading;
    }

    set loading(val: boolean) {
        if (val) {
            this._timeoutId = setTimeout(() => {
                this._loading = true;
                this.changeDetectorRef.markForCheck();
            }, this.timeout);
        } else {
            clearTimeout(this._timeoutId);
            setTimeout(() => {
                this._loading = val;
                this.changeDetectorRef.markForCheck();
            }, 0);
        }
    }

    @HostBinding("class") get display() {
        return this.type;
    }

    constructor(private changeDetectorRef: ChangeDetectorRef) {

    }
}
