import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class AmadeusService
    extends HttpService<app.operation.AmadeusListViewModel, app.operation.AmadeusFormViewModel, app.operation.AmadeusInsertUpdateViewModel>
{
    objectKey = "op.amadeus";
    objectAmadeusAdminKey = "op.amadeusAdmin";

    constructor(http: HttpClient) {
        super(http, "/api/operation/amadeus/");
    }

    getFilter(): Observable<app.operation.AmadeusFilterViewModel> {
        return this.http.get<app.operation.AmadeusFilterViewModel>(this.url + "filter");
    }

    getSummary(parameters?: any): Observable<app.operation.AmadeusSummaryViewModel> {
        return this.http.get<app.operation.AmadeusSummaryViewModel>(this.url + "summary", { params: this.getParameters(parameters) });
    }

    getProjectTaskInfo(projectTaskId: number, date?: Date): Observable<app.operation.AmadeusProjectTaskViewModel> {
        let params = new HttpParams();
        params = params.set("projectTaskId", projectTaskId.toString());       
        if (date) {
            params = params.set("date", this.getUnixDate(new Date(date)).toString());
        }
        return this.http.get<app.operation.AmadeusProjectTaskViewModel>(this.url + "projecttask", { params });
    }    

    getReportUrl(params: any) {
        let parameters = this.getParameters(params);
        parameters.set("format", "xlsx");
        return this.url + `report?${parameters.toString()}`;
    }

    get8dReportUrl(params: any) {
        let parameters = this.getParameters(params);
        parameters.set("format", "xlsx");
        return this.url + `report8d?${parameters.toString()}`;
    }
}
