<div class="top-menu" *ngIf="!isLoading">
    <ul class="navbar-nav float-end">
        <li class="nav-item d-none d-sm-none d-md-block" style="margin-right: 10px;">
            <a href="https://hollensk.sharepoint.com/sites/hxq/sys/tr1" class="nav-link" target="_blank"><img alt="" class="img-circle" src="/images/SPicon.png" /></a>
        </li>
        <li *ngIf="intranetUrl" class="nav-item d-none d-sm-none d-md-block" style="margin-right: 10px;">
            <a class="nav-link" href="https://ticketing.hollen.sk" target="_blank">{{ 'hollen.clientapp.src.app.shared.components.top-menu.top-menu.component.ticketing system' | translate }}</a>
        </li>
        <li *ngIf="intranetUrl" class="nav-item d-none d-sm-none d-md-block" style="margin-right: 10px;">
            <a class="nav-link" [href]="intranetUrl" target="_blank">{{ 'hollen.clientapp.src.app.shared.components.top-menu.top-menu.component.old intranet' | translate }}</a>
        </li>
        <li class="nav-item" style="margin-right: 10px;">
            <a href="https://hollensk.sharepoint.com/sites/hxq/ict/guide/SitePages/Home.aspx" class="nav-link" target="_blank"><i class="fa fa-question-circle"></i></a>
        </li>
        <li *ngIf="fullName" class="nav-item nav-item-account" ngbDropdown>
            <a class="nav-link" ngbDropdownToggle>
                <span class="nav-link-text">
                    {{ fullName }}
                </span>
                <span class="nav-link-text-alt">
                    <i class="far fa-user-circle" aria-hidden="true"></i>
                </span>
            </a>
            <div ngbDropdownMenu>
                <a routerLink="/account" class="dropdown-item">
                    <i class="icon-user"></i> {{ 'hollen.clientapp.src.app.shared.components.top-menu.top-menu.component.my settings' | translate }}
                </a>
                <a href="javascript:;" (click)="logout()" class="dropdown-item">
                    <i class="icon-key"></i> {{ 'hollen.clientapp.src.app.shared.components.top-menu.top-menu.component.log out' | translate }}
                </a>
            </div>
        </li>
        <li class="nav-item">
            <a href="javascript:;" class="nav-link nav-link-icon" (click)="refresh()">
                <i class="icon-refresh"></i>
            </a>
        </li>
        <li class="nav-item">
            <a href="javascript:;" class="nav-link nav-link-icon" (click)="back()">
                <i class="icon-arrow-left-circle"></i>
            </a>
        </li>
        <li class="nav-item">
            <a href="javascript:;" class="nav-link nav-link-icon menu-toggler" (click)="toggleMenu()">
                <i class="fa-solid fa-bars"></i>
            </a>
        </li>
    </ul>
</div>
