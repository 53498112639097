import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

import { DatepickerI18n } from "./datepicker-i18n";

export function createNgbDateMomentParserFormatter(i18n: DatepickerI18n) {
    return new NgbDateMomentParserFormatter(i18n);
};

export class NgbDateMomentParserFormatter extends NgbDateParserFormatter {
    constructor(private i18n: DatepickerI18n) {
        super();
    }

    format(date: NgbDateStruct): string {
        if (date === null) {
            return "";
        }
        let d = moment([date.year, date.month - 1, date.day]);
        return d.isValid() ? d.format(this.i18n.getFormat()) : "";
    }

    parse(value: string): NgbDateStruct {
        if (!value) {
            return null;
        }
        let d = moment(value, this.i18n.getFormat(), true);
        return d.isValid() ? { year: d.year(), month: d.month() + 1, day: d.date() } : null;
    }
}
