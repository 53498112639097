import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class DeskService
    extends HttpService<app.organization.DeskListViewModel, app.organization.DeskFormViewModel, app.organization.DeskInsertUpdateViewModel>
{
    objectKey = "org.Desk";

    constructor(http: HttpClient) {
        super(http, "api/organization/desk/");
    }

    //getSelectList(page?: number, pageSize?: number, searchTerm?: string,
    //    accountCenterCode?: string, branchId?: number, countryCode?: string): Observable<app.organization.DeskSelectListViewModel[]> {
    //    let params = new HttpParams();

    //    if (searchTerm) {
    //        params = params.set("searchTerm", searchTerm);
    //    }
    //    if (page) {
    //        params = params.set("page", page.toString());
    //    }
    //    if (pageSize) {
    //        params = params.set("pageSize", pageSize.toString());
    //    }
    //    if (accountCenterCode) {
    //        params = params.set("accountCenterCode", accountCenterCode);
    //    }
    //    if (branchId) {
    //        params = params.set("branchId", branchId.toString());
    //    }
    //    if (countryCode) {
    //        params = params.set("countryCode", countryCode);
    //    }
    //    return this.http.get<app.organization.DeskSelectListViewModel[]>(this.url + "list", { params });
    //}
}
