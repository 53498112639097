<af-spinner #afSpinner [loading]="isLoading"></af-spinner>

<ng-container *ngIf="!isLoading">
   <div class="mt-2" *ngIf="employeeWages">
        <div>{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.hours' | translate }}: <b>{{ employeeWages.hours | number:'.2-2' }}</b></div>
        <div>{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.bonus disabled' | translate }}: <b *ngIf="employeeWages.isBonusDisabled">{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.yes' | translate }}</b><b *ngIf="!employeeWages.isBonusDisabled">{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.no' | translate }}</b></div>
        <div>{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.hours amount' | translate }}: <b>{{ employeeWages.amount | number:'.2-2' }}</b></div>
        <div>{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.other agreements' | translate }}: <b>{{ employeeWages.otherAgreements | number:'.2-2' }}</b></div>
        <div>{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.insurance' | translate }}: <b>{{ employeeWages.insurance | number:'.2-2' }}</b></div>
        <div>{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.total amount' | translate }}: <b>{{ employeeWages.totalAmount | number:'.2-2' }}</b></div>

        <div class="table-wrapper">
            <table class="af-table table table-striped table-bordered table-hover text-end mt-2">
                <thead>
                    <tr>
                        <th></th>
                        <th *ngFor="let item of employeeWages.items">
                            <div>{{ item.day }}</div>
                                <div><small>{{ item.dayTitle }}</small></div>
                        </th>
                        <th>{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.total' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.regular day hours' | translate }}</td>
                        <td *ngFor="let item of employeeWages.items"><ng-container *ngIf="item.time.regularDayHours">{{ item.time.regularDayHours | number:'.2-2' }}</ng-container></td>
                        <td><b *ngIf="employeeWages.total.regularDayHours">{{ employeeWages.total.regularDayHours | number:'.2-2' }}</b></td>
                    </tr>
                    <tr>
                        <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.regular night hours' | translate }}</td>
                        <td *ngFor="let item of employeeWages.items"><ng-container *ngIf="item.time.regularNightHours">{{ item.time.regularNightHours | number:'.2-2' }}</ng-container></td>
                        <td><b *ngIf="employeeWages.total.regularNightHours">{{ employeeWages.total.regularNightHours | number:'.2-2' }}</b></td>
                    </tr>
                     <tr>
                        <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.saturday day hours' | translate }}</td>
                        <td *ngFor="let item of employeeWages.items"><ng-container *ngIf="item.time.saturdayHours">{{ item.time.saturdayHours | number:'.2-2' }}</ng-container></td>
                        <td><b *ngIf="employeeWages.total.saturdayHours">{{ employeeWages.total.saturdayHours | number:'.2-2' }}</b></td>
                    </tr>
                     <tr>
                        <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.saturday night hours' | translate }}</td>
                        <td *ngFor="let item of employeeWages.items"><ng-container *ngIf="item.time.saturdayNightHours">{{ item.time.saturdayNightHours | number:'.2-2' }}</ng-container></td>
                        <td><b *ngIf="employeeWages.total.saturdayNightHours">{{ employeeWages.total.saturdayNightHours | number:'.2-2' }}</b></td>
                    </tr>
                     <tr>
                        <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.sunday day hours' | translate }}</td>
                        <td *ngFor="let item of employeeWages.items"><ng-container *ngIf="item.time.sundayHours">{{ item.time.sundayHours | number:'.2-2' }}</ng-container></td>
                        <td><b *ngIf="employeeWages.total.sundayHours">{{ employeeWages.total.sundayHours | number:'.2-2' }}</b></td>
                    </tr>
                     <tr>
                        <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.sunday night hours' | translate }}</td>
                        <td *ngFor="let item of employeeWages.items"><ng-container *ngIf="item.time.sundayNightHours">{{ item.time.sundayNightHours | number:'.2-2' }}</ng-container></td>
                        <td><b *ngIf="employeeWages.total.sundayNightHours">{{ employeeWages.total.sundayNightHours | number:'.2-2' }}</b></td>
                    </tr>
                     <tr>
                        <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.holiday day hours' | translate }}</td>
                        <td *ngFor="let item of employeeWages.items"><ng-container *ngIf="item.time.holidayHours">{{ item.time.holidayHours | number:'.2-2' }}</ng-container></td>
                        <td><b *ngIf="employeeWages.total.holidayHours">{{ employeeWages.total.holidayHours | number:'.2-2' }}</b></td>
                    </tr>
                    <tr>
                        <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.holiday night hours' | translate }}</td>
                        <td *ngFor="let item of employeeWages.items"><ng-container *ngIf="item.time.holidayNightHours">{{ item.time.holidayNightHours | number:'.2-2' }}</ng-container></td>
                        <td><b *ngIf="employeeWages.total.holidayNightHours">{{ employeeWages.total.holidayNightHours | number:'.2-2' }}</b></td>
                    </tr>
                    <tr>
                        <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.country' | translate }}</td>
                        <td *ngFor="let item of employeeWages.items"><ng-container *ngIf="item.country">{{ item.country }}</ng-container></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-employee.component.amount' | translate }}</td>
                        <td *ngFor="let item of employeeWages.items"><b *ngIf="item.amount">{{ item.amount | number:'.4-4' }}</b></td>
                        <td><b>{{ employeeWages.amount | number:'.2-2' }}</b></td>
                    </tr>
                </tbody>
            </table>
        </div>
   </div>
</ng-container>
