import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class WorkClothesService
    extends HttpService<app.operation.WorkClothesListViewModel, app.operation.WorkClothesFormViewModel, app.operation.WorkClothesInsertUpdateViewModel>
{
    objectKey = "op.WorkClothes";

    constructor(http: HttpClient) {
        super(http, "/api/operation/workclothes/");
    }

    getTransfers(workClothesId: number, params: any): Observable<PagedList<app.operation.WorkClothesTransferListViewModel>> {
        return this.http.get<PagedList<app.operation.WorkClothesTransferListViewModel>>(this.url + workClothesId + "/transfer", { params: this.getParameters(params) });
    }

    insertTransfer(workClothesId: number, transfer: app.operation.WorkClothesTransferViewModel): Observable<any> {
        return this.http.put(this.url + workClothesId + "/transfer", transfer);
    }

    deleteTransfer(workClothesTransferId: number): Observable<any> {
        return this.http.delete(this.url + workClothesTransferId + "/transfer");
    }

    getCurrentState(workClothesId: number): Observable<app.operation.WorkClothesCurrentStateViewModel[]> {
        return this.http.get<app.operation.WorkClothesCurrentStateViewModel[]>(this.url + workClothesId + "/currentstate");
    }

    archive(workClothesId: number) {
        return this.http.put(this.url + workClothesId + "/archive", "");
    }

    unarchive(workClothesId: number) {
        return this.http.put(this.url + workClothesId + "/unarchive", "");
    }
}
