<div class="card">
    <div class="card-header">{{ 'hollen.clientapp.src.app.operation.report.x-to-x-report.component.report x_x' | translate }}</div>
    <div class="card-block card-report form-horizontal">
        <div>
            <div *ngIf="[1, 6, 7].indexOf(params.branchId) > -1">
                <div class="row form-group">
                    <div class="col-md-7 control-label">
                        {{ 'hollen.clientapp.src.app.operation.report.x-to-x-report.component.batch' | translate }}
                    </div>
                    <div class="col-md-5">
                       <af-checkbox [(ngModel)]="request.batch" name="batch"></af-checkbox>
                    </div>
                </div>
            </div>
            <div>
                <div class="row form-group">
                    <div class="col-md-7 control-label">
                        {{ 'hollen.clientapp.src.app.operation.report.x-to-x-report.component.merge tasks' | translate }}
                    </div>
                    <div class="col-md-5">
                        <af-checkbox [(ngModel)]="request.mergeSubTasks" name="mergeSubTasks"></af-checkbox>
                    </div>
                </div>
            </div>
            <div *ngIf="[1, 7].indexOf(params.branchId) > -1">
                <div class="row form-group">
                    <div class="col-md-7 control-label">
                        {{ 'hollen.clientapp.src.app.operation.report.x-to-x-report.component.warenfilter' | translate }}
                    </div>
                    <div class="col-md-5">
                        <af-checkbox [(ngModel)]="request.warenfilter" name="warenfilter"></af-checkbox>
                    </div>
                </div>
            </div>
            <div>
                <div class="row form-group">
                    <div class="col-md-7 control-label">
                        {{ 'hollen.clientapp.src.app.operation.report.x-to-x-report.component.40 columns' | translate }}
                    </div>
                    <div class="col-md-5">
                        <af-checkbox [(ngModel)]="request.use40Cols" name="use40Cols"></af-checkbox>
                    </div>
                </div>
            </div>
            <!-- SP -->
            <div *ngIf="params.branchId === 2">
                <div>
                    <div class="row form-group">
                        <div class="col-md-7 control-label">
                            {{ 'hollen.clientapp.src.app.operation.report.x-to-x-report.component.kilometers' | translate }}
                        </div>
                        <div class="col-md-5">
                            <af-checkbox [(ngModel)]="request.km" name="km"></af-checkbox>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="row form-group">
                        <div class="col-md-7 control-label">
                            {{ 'hollen.clientapp.src.app.operation.report.x-to-x-report.component.group by delivery note' | translate }}
                        </div>
                        <div class="col-md-5">
                            <af-checkbox [(ngModel)]="request.groupByDeliveryNote" name="groupByDeliveryNote"></af-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-12">
                    <a [href]="getUrl()" [class.disabled]="!params.projectTaskId" class="btn btn-default-alt btn-generate btn-large-icon" title="{{ 'btn.generate' | translate }}"><i class="fa fa-download"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
