import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Router } from "@angular/router";
import { finalize, tap } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { TableBaseComponent } from "../../../core";
import { ListQueryParams } from "../../../models/list-query-params.model";
import { B2bAdvanceService } from "./b2b-advance.service";

class QueryParams extends ListQueryParams {
    companyId: number;
}

@Component({
    selector: "crm-company-b2b-advance-list",
    templateUrl: "./b2b-advance-list.component.html"
})
export class B2bAdvanceListComponent extends TableBaseComponent implements OnDestroy {

    @Input() companyId: number;
    @Input() load = new Observable<any>();
    @Output() editClicked = new EventEmitter<app.b2b.AdvanceRequestListViewModel>();
    @Output() copyClicked = new EventEmitter<app.b2b.AdvanceRequestListViewModel>();
    model: PagedList<app.b2b.AdvanceRequestListViewModel>;
    objectKey: string;
    queryParams = new QueryParams();
    subscription: Subscription;

    constructor(router: Router,
        private b2bAdvanceService: B2bAdvanceService) {
        super(router);
        this.objectKey = b2bAdvanceService.objectKey;
    }

    ngOnInit() {
        super.ngOnInit();

        this.queryParams.companyId = this.companyId;

        this.subscription = this.load.subscribe(res => this.loadData());

        this.loadData();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    loadData() {
        this.isLoading = true;
        this.b2bAdvanceService.query(this.queryParams)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.model = res;
                this.saveState(this.queryParams, false);
            });
    }

    clickEdit(item: app.b2b.AdvanceRequestListViewModel) {
        this.editClicked.emit(item);
    }

    clickCopy(item: app.b2b.AdvanceRequestListViewModel) {
        this.copyClicked.emit(item);
    }

    approve(item: app.b2b.AdvanceRequestListViewModel) {
        this.isLoading = true;
        this.b2bAdvanceService.approve(item.requestId)
            .pipe(tap({ error: () => this.isLoading = false }))
            .subscribe(res => {
                this.loadData();
            });
    }

    unapprove(item: app.b2b.AdvanceRequestListViewModel) {
        this.isLoading = true;
        this.b2bAdvanceService.unapprove(item.requestId)
            .pipe(tap({ error: () => this.isLoading = false }))
            .subscribe(res => {
                this.loadData();
            });
    }
}
