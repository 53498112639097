import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Decimal } from "decimal.js";

import { HttpService } from "../../core/services/http.service";
import { toFixed } from "../../core/utils";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class InvoiceService
    extends HttpService<app.finance.InvoiceListViewModel, app.finance.InvoiceFormViewModel, app.finance.InvoiceInsertUpdateViewModel>
{
    objectKey = "fin.Invoice";
    objectKeyDoCreateAutoClaim = "fin.InvoiceDoCreateAutoClaim";
    objectIsCustomerPayingTax = "fin.InvoiceIsCustomerPayingTax"

    constructor(http: HttpClient) {
        super(http, "/api/finance/invoice/");
    }

    invoiceXmlUrl() {
        return "/api/finance/invoicefile/";
    }

    invoiceSkodaXmlUrl() {
        return "/api/finance/invoicefile/skoda/";
    }

    invoicePreviewUrl() {
        return "/api/finance/invoicefile/preview/";
    }   

    getNewForm(projectTaskIds?: string | string[]) {
        let params = new HttpParams();

        if (projectTaskIds) {
            if (typeof (projectTaskIds) === "string") {
                params = params.append("projectTaskIds", projectTaskIds);
            }
            else {
                for (let pt of projectTaskIds) {
                    params = params.append("projectTaskIds", pt);
                }
            }
        }
        return this.http.get<app.finance.InvoiceInsertFormViewModel>(this.url + "new", { params });
    }

    postNew(model: app.finance.InvoiceInsertViewModel) {
        let params = new HttpParams();
        params = params.set("showToast", "false");
        return this.http.post<app.finance.InvoiceFormViewModel>(this.url + "new", model, { params });
    }

    postCopyNew(model: app.finance.InvoiceInsertUpdateViewModel, copySelectedCompanyId: number, copySelectedEmployeeId: number, copySelectedAccountCenterCode:string) {
        let params = new HttpParams();
        params = params.set("showToast", "true");
        if (copySelectedCompanyId) {
            params = params.set("copySelectedCompanyId", copySelectedCompanyId.toString());
        }
        if (copySelectedEmployeeId) {
            params = params.set("copySelectedEmployeeId", copySelectedEmployeeId.toString());
        }
            params = params.set("copySelectedAccountCenterCode", copySelectedAccountCenterCode.toString());
        return this.http.post<app.finance.InvoiceFormViewModel>(this.url + "newcopy", model, { params });
    }

    insertSimple(model: app.finance.InvoiceInsertSimpleViewModel) {
        return this.http.post<app.finance.InvoiceInsertUpdateViewModel>(this.url + "simple", model);
    }

    updateFromList(model: app.finance.InvoiceListUpdateViewModel) {
        return this.http.put<app.finance.InvoiceListViewModel>(this.url + model.invoiceId + "/list", model);
    }

    getFilter(): Observable<app.finance.InvoiceListFilterViewModel> {
        return this.http.get<app.finance.InvoiceListFilterViewModel>(this.url + "filter");
    }

    generateXml(parameters?: any): Observable<app.finance.InvoicePohodaXmlViewModel> {
        return this.http.get<app.finance.InvoicePohodaXmlViewModel>(this.url + "xmllist", {
            params: this.getParameters(parameters)
        });
    }

    generateSkodaXml(invoiceId: number): Observable<app.finance.InvoicePohodaXmlViewModel> {
        let params = new HttpParams();
        params = params.set("invoiceId", invoiceId.toString());
        return this.http.get<app.finance.InvoicePohodaXmlViewModel>(this.url + "skodaxml", { params });
    }

    getSimple(parameters?: any) {
        return this.http
            .get<PagedList<app.finance.InvoiceSimpleListViewModel>>(this.url + "simple", {
                params: this.getParameters(parameters)
            });
    }

    getSummary(currency: string, parameters?: any) {
        return this.http
            .get<app.finance.InvoiceSummaryViewModel>(this.url + "summary/" + currency, {
                params: this.getParameters(parameters)
            });
    }

    getInvoiceNumber(invoiceType: app.finance.InvoiceType, sequenceNumber: number, accountCenterCode: string, year: number, hollenCompanyInvoiceNumber: number, employeeId:number) {
        if (!sequenceNumber || !accountCenterCode || !year) {
            return "";
        }

        var useNewEmploeePrefix = employeeId != null && year >= 2024;

        if (year >= 2019) {
            let prefix = useNewEmploeePrefix ? "63" :  "61";
            if (invoiceType === 6) prefix = useNewEmploeePrefix ? "73" : "71";
            else if (invoiceType === 7) prefix = useNewEmploeePrefix ? "83" :"81";
            return `${prefix}${hollenCompanyInvoiceNumber.toString()}${year.toString().substring(2)}${sequenceNumber.toString().padStart(4, "0")} ${accountCenterCode}`;
        }
        else {
            let prefix = "61";
            if (invoiceType === 5) prefix = "63";
            else if (invoiceType === 6) prefix = "71";
            else if (invoiceType === 7) prefix = "81";
            else if (accountCenterCode[0] !== "S") {
                prefix = "6";
            }
            return `${prefix}${year.toString().substring(2)}${sequenceNumber.toString().padStart(4, "0")} ${accountCenterCode}`;
        }
    }

    getList(page?: number, pageSize?: number, searchTerm?: string,
        accountCenterCode?: string, year?: number, sourceInvoiceType?: number): Observable<app.finance.InvoiceSelectListViewModel[]> {
        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        if (page) {
            params = params.set("page", page.toString());
        }
        if (pageSize) {
            params = params.set("pageSize", pageSize.toString());
        }
        if (accountCenterCode) {
            params = params.set("accountCenterCode", accountCenterCode);
        }
        if (year) {
            params = params.set("year", year.toString());
        }
        if (sourceInvoiceType) {
            params = params.set("sourceInvoiceType", sourceInvoiceType.toString());
        }
        return this.http.get<app.finance.InvoiceSelectListViewModel[]>(this.url + "list", { params });
    }

    getProjectTaskList(page?: number, pageSize?: number, searchTerm?: string, accountCenterCode?: string, branchId?: number,
        onlyActive?: boolean, companyId?: number, year?: number): Observable<app.finance.InvoiceProjectTaskViewModel[]> {
        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        if (page) {
            params = params.set("page", page.toString());
        }
        if (pageSize) {
            params = params.set("pageSize", pageSize.toString());
        }
        if (accountCenterCode) {
            params = params.set("accountCenterCode", accountCenterCode);
        }
        if (branchId) {
            params = params.set("branchId", branchId.toString());
        }
        if (onlyActive) {
            params = params.set("onlyActive", onlyActive.toString());
        }
        if (companyId) {
            params = params.set("companyId", companyId.toString());
        }
        if (year) {
            params = params.set("year", year.toString());
        }
        return this.http.get<app.finance.InvoiceProjectTaskViewModel[]>(this.url + "project-task-list", { params });
    }

    getTexts(companyId: number, languageId: string, searchTerm?: string) {
        let params = new HttpParams();

        if (companyId) {
            params = params.set("companyId", companyId.toString());
        }
        if (languageId) {
            params = params.set("languageId", languageId);
        }
        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }

        return this.http.get<string[]>(this.url + "text", { params });
    }

    getInvoiceTexts(languageId: string, invoiceType: number) {
        let params = new HttpParams();
        params = params.set("languageId", languageId);
        if (invoiceType) {
            params = params.set("invoiceType", invoiceType.toString());
        }

        return this.http.get<app.codebook.CodebookSelectViewModel[]>(this.url + "invoice-text", { params });
    }

    recalculateRate(rate: app.finance.InvoiceItemProductRateViewModel) {
        if (rate.hasBonuses) {
            rate.totalAmount = rate.bonuses.reduce((a, b) => a + (+b.totalAmount), 0);
            rate.restAmount = rate.bonuses.reduce((a, b) => a + (+b.restAmount - (+b.amount)), 0);
            rate.amount = rate.bonuses.reduce((a, b) => a + (+b.amount), 0);
            rate.priceTotal = rate.bonuses.reduce((a, b) => a + this.multiply(+b.amount, +b.price), 0);
            rate.priceTotalWithVat = this.multiply(rate.priceTotal, rate.vatRate);
        }
        else {
            rate.priceTotal = this.multiply(rate.amount, rate.price);
            rate.priceTotalWithVat = this.multiply(rate.priceTotal, rate.vatRate);
        }
    }

    multiply(value1, value2) {
        return +Decimal.mul(value1, value2).toString();
    }

    round(value) {
        return toFixed(value, 2);
    }

    getReportUrl(params: any) {
        let parameters = this.getParameters(params);
        parameters.set("format", "xlsx");
        return this.url + `report?${parameters.toString()}`;
    }

    getReportRecoveryUrl(params: any) {
        let parameters = this.getParameters(params);
        parameters.set("format", "xlsx");
        return this.url + `report-recovery?${parameters.toString()}`;
    }

    getReportRecoveryENUrl(params: any) {
        let parameters = this.getParameters(params);
        parameters.set("format", "xlsx");
        return this.url + `report-recovery-en?${parameters.toString()}`;
    }

    getAmountsByProjectTask(projectTaskId: number, languageId: string, invoiceId?: number) {
        let params = new HttpParams();
        params = params.set("projectTaskId", projectTaskId.toString());
        params = params.set("languageId", languageId);

        if (invoiceId) {
            params = params.set("invoiceId", invoiceId.toString());
        }

        return this.http.get<app.finance.InvoiceItemViewModel>(this.url + "project-task-amounts", { params });
    }

    getHistory(page: number, pageSize: number, invoiceId: number): Observable<af.sys.EntityHistoryViewModel[]> {
        let params = new HttpParams();
        params = params.set("page", page.toString());
        params = params.set("pageSize", pageSize.toString());
        return this.http.get<af.sys.EntityHistoryViewModel[]>(this.url + invoiceId + "/history", { params });
    }

    generateInvoice(request: app.finance.InvoiceGenerateFileRequest) {
        request.format = "pdf";

        return this.http.post<app.crm.CompanyFileInsertResponse>(this.url + "generate-file", request);
    }

    updateNote(invoiceId: number, note: string) {
        return this.http.put(this.url + invoiceId + "/note/", { note });
    }

    updateLockNote(invoiceId: number, note: string) {
        return this.http.put(this.url + invoiceId + "/locknote/", { note });
    }

    updateFlags(invoiceId: number, model: app.finance.InvoiceFlagUpdateViewModel) {
        return this.http.put<app.finance.InvoiceListViewModel>(this.url + invoiceId + "/flag", model);
    }

    getInvoiceFileLink(companyId: number, invoiceNumber: string) {
        let params = new HttpParams();
        params = params.set("companyId", companyId.toString());
        params = params.set("invoiceNumber", invoiceNumber);
        params = params.set("showToast", "true");
        return this.http.get<app.sharepoint.SharePointResponse>(this.url + "invoice-file-link", { params });
    }

    getEmailRequest(invoiceId: number): Observable<app.finance.InvoiceSendEmailRequest> {
        return this.http.get<app.finance.InvoiceSendEmailRequest>(this.url + invoiceId + "/get-email-request");
    }

    sendEmailInvoice(model: app.finance.InvoiceSendEmailRequest) {
        return this.http.put(this.url + model.invoiceId + "/send-email-invoice", model);
    }

    sendForApproval(model: app.finance.InvoiceSendEmailRequest) {
        return this.http.put(this.url + model.invoiceId + "/send-for-approval", model);
    }

    lock(invoiceId: number) {
        return this.http.put(this.url + invoiceId + "/lock", null);
    }

    unlock(invoiceId: number) {
        return this.http.put(this.url + invoiceId + "/unlock", null);
    }

    getInvoiceReviewList(params: any): Observable<PagedList<app.finance.InvoiceReviewListViewModel>> {
        return this.http.get<PagedList<app.finance.InvoiceReviewListViewModel>>(this.url + "review-list", { params: this.getParameters(params) });
    }

    pohodaBackend() {
        let params = new HttpParams();

        return this.http.get(this.url + "pohoda-backend", { params });
    }

    getRow(invoiceId: number): Observable<app.finance.InvoiceListViewModel> {
        return this.http.get<app.finance.InvoiceListViewModel>(this.url + invoiceId + "/row");
    }

    sendToB2b(invoiceId: number) {
        return this.http.put(this.url + invoiceId + "/sendtob2b",null);
    }

    refreshCurrency(invoiceId: number) {
        return this.http.put<number>(this.url + invoiceId + "/currency", {});
    }
}
