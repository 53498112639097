
import { filter } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import { AppService } from "../../app.service";
import { State } from "../../store/reducers";

@Injectable()
export class PermissionService {
    userPermission: af.usersystem.PermissionViewModel[];

    constructor(private store: Store<State>) {
        this.store.select(x => x.app.homeViewModel).pipe(
            filter(loaded => !!loaded))
            .subscribe(model => {
                this.userPermission = model.permissions;
            });
    }

    hasPermission(objectId: string, right: string): boolean {
        if (!this.userPermission) {
            return false;
        }

        for (let i = 0; i < this.userPermission.length; i++) {
            let permObject = this.userPermission[i];
            if (permObject.object.toLowerCase() === objectId.toLowerCase()) {
                for (let j = 0; j < permObject.rights.length; j++) {
                    if (permObject.rights[j].toString() === right.toString()) {
                        return true;
                    }
                }
                return false;
            }
        }

        return false;
    }

    canRead(objectId: string): boolean {
        return this.hasPermission(objectId, "Read") || this.hasPermission(objectId, "OwnRead");
    }

    canReadOnlyOwn(objectId: string): boolean {
        return !this.hasPermission(objectId, "Read") && this.hasPermission(objectId, "OwnRead");
    }
}
