import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

export enum LogLevel {
    Trace = 0,
    Debug = 1,
    Information = 2,
    Warning = 3,
    Error = 4,
    Critical = 5,
    None = 6
}

export class Log {
    level: LogLevel;
    eventId: number;
    eventName: string;
    message: string;
    stack: string;
    location: string;
}

@Injectable()
export class LogService {
    constructor(private http: HttpClient) { }

    public logError(error: any): void {
        let log = new Log();
        log.eventName = error.name;
        log.stack = error.stack;
        log.location = window.location.pathname + window.location.search;
        log.level = LogLevel.Error;
        log.message = error.message;
        this.sendToServer(log);
    }

    sendToServer(log: Log): void {
        let params = new HttpParams();
        params = params.set("showToast", "false");
        params = params.set("allowAnonymous", "true");
        this.http.post("/api/log", log, { responseType: "text", params }).subscribe();
    }
}
