import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class ProjectService
    extends HttpService<app.operation.ProjectListViewModel, app.operation.ProjectFormViewModel, app.operation.ProjectInsertUpdateViewModel>
{
    objectKey = "op.Project";

    constructor(http: HttpClient) {
        super(http, "/api/operation/project/");
    }

    getProjectByProjectTaskId(projectTaskId: number): Observable<app.operation.ProjectFormViewModel> {
        let params = new HttpParams();
        params = params.set("projectTaskId", projectTaskId.toString());
        return this.http.get<app.operation.ProjectFormViewModel>(this.url + "getByProjectTaskId", { params });
    }

    getSharePointFolder(projectId: number) {
        let params = new HttpParams();
        params = params.set("showToast", "false");
        return this.http.post<app.sharepoint.SharePointResponse>(this.url + projectId + "/sharepoint", "", { params });
    }

    getList(accountCenterCode?: string, companyId?: number, onlyWithoutProjectTasks?: boolean, ownerId?: number, searchTerm?: string, page?: number, pageSize?: number): Observable<app.operation.ProjectSelectListViewModel[]> {
        let params = new HttpParams();
        if (accountCenterCode) {
            params = params.set("accountCenterCode", accountCenterCode);
        }
        if (companyId) {
            params = params.set("companyId", companyId.toString());
        }
        if (onlyWithoutProjectTasks) {
            params = params.set("onlyWithoutProjectTasks", onlyWithoutProjectTasks.toString());
        }
        if (ownerId) {
            params = params.set("ownerId", ownerId.toString());
        }
        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        if (page) {
            params = params.set("page", page.toString());
        }
        if (pageSize) {
            params = params.set("pageSize", pageSize.toString());
        }
        return this.http.get<app.operation.ProjectSelectListViewModel[]>(this.url + "list", { params });
    }
}
