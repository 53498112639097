import { Directive, ElementRef, EventEmitter, Input, Output, HostListener, HostBinding } from "@angular/core";

import { FileUploadOptions, FileUploadService, UploadRejected, UploadedFile } from "./file-upload.service";
import { ToastService } from "../../../core/services";

@Directive({
    selector: "[afFileSelect]",
    providers: [FileUploadService]
})
export class FileSelectDirective {

    @Input() events: EventEmitter<any>;
    @Input() accept: string;
    @Output() upload: EventEmitter<any> = new EventEmitter();
    @Output() success: EventEmitter<any> = new EventEmitter();
    @Output() previewData: EventEmitter<any> = new EventEmitter();
    @Output() uploadRejected: EventEmitter<UploadRejected> = new EventEmitter<UploadRejected>();

    _options: FileUploadOptions;

    @HostBinding("attr.accept")
    get acceptValue() {
        return this.accept;
    }

    get options(): FileUploadOptions {
        return this._options;
    }

    @Input("options")
    set options(value: FileUploadOptions) {
        this._options = value;
        this.uploader.setOptions(this.options);
    }

    files: any[] = [];

    constructor(public el: ElementRef,
        private uploader: FileUploadService,
        private toastService: ToastService) {
        setTimeout(() => {
            this.uploader.setOptions(this.options);
        });

        this.uploader._emitter.subscribe((file: UploadedFile) => {
            this.upload.emit(file);
            if (file.done) {
                if (file.success) {
                    this.success.emit(file);
                }

                this.files = this.files.filter(f => f.name !== file.originalName);
                if (this.uploader.options.fieldReset) {
                    this.el.nativeElement.value = "";
                }
            }
        });

        this.uploader._previewEmitter.subscribe((data: any) => {
            this.previewData.emit(data);
        });

        setTimeout(() => {
            if (this.events) {
                this.events.subscribe((data: string) => {
                    if (data === "startUpload") {
                        this.uploader.uploadFilesInQueue();
                    }
                });
            }
        });
    }

    filterFilesByExtension(): void {
        if (!this.accept) {
            return;
        }

        let acceptRegex = new RegExp(this.accept);

        this.files = this.files.filter(f => {
            if (acceptRegex.test(f.type)) {
                return true;
            }

            if (acceptRegex.test(f.name)) {
                return true;
            }

            this.uploadRejected.emit({ file: f, reason: UploadRejected.EXTENSION_NOT_ALLOWED });

            this.toastService.error(`File type not allowed - ${f.name}`);

            return false;
        });
    }

    @HostListener("change") onChange(): void {
        this.files = Array.from(this.el.nativeElement.files);

        this.filterFilesByExtension();

        if (this.files.length) {
            this.uploader.addFilesToQueue(this.files);
        }
    }
}
