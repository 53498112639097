
<div style="display: flex; justify-content: space-between; flex-wrap: wrap; margin:15px -15px 15px 0" *ngIf="selectedCompany">
    <div style="flex-shrink: 0; flex-grow: 1; margin: 5px 15px 5px 0" *ngIf="selectedCompany.isProformaInvoiceRequired"> 
        <af-note type="danger" [margin]="false">
            {{ 'hollen.clientapp.src.app.operation.project.project-company-info.component.proforma invoices' | translate }}
        </af-note>
    </div>
    <div style="flex-shrink: 0; flex-grow: 1; margin: 5px 15px 5px 0" *ngIf="selectedCompany.invoiceAfterDueDateCount > 0"> 
        <af-note type="danger" [margin]="false">
            {{ 'hollen.clientapp.src.app.operation.project.project-company-info.component.number of invoices after due date' | translate }}: {{ selectedCompany.invoiceAfterDueDateCount }} 
        </af-note>
    </div>
    <div style="flex-shrink: 0; flex-grow: 1; margin: 5px 15px 5px 0" *ngIf="selectedCompany.invoiceAfterDueDateCount > 0">
        <af-note type="danger" [margin]="false">
            {{ 'hollen.clientapp.src.app.operation.project.project-company-info.component.sum of invoices after due date' | translate }}: {{ selectedCompany.invoiceAfterDueDateSum | afCurrency: 'EUR' : '1.2-2' }}
        </af-note>
    </div>
    <div style="flex-shrink: 0; flex-grow: 1; margin: 5px 15px 5px 0" *ngIf="selectedCompany.isInBankruptcy"> 
        <af-note type="danger" [margin]="false">
            {{ 'hollen.clientapp.src.app.operation.project.project-company-info.component.company in bankrupcy' | translate }}
        </af-note>
    </div>
    <div style="flex-shrink: 0; flex-grow: 1; margin: 5px 15px 5px 0" *ngIf="selectedCompany.isInternalOrderRequired"> 
        <af-note type="danger" [margin]="false">
            {{ 'hollen.clientapp.src.app.operation.project.project-company-info.component.internal order required' | translate }}
        </af-note>
    </div>
    <div style="flex-shrink: 0; flex-grow: 1; margin: 5px 15px 5px 0" *ngIf="selectedCompany.wasTermsAndConditionsSent"> 
        <af-note type="success" [margin]="false">
            {{ 'hollen.clientapp.src.app.operation.project.project-company-info.component.terms and conditions sent' | translate }}
        </af-note>
    </div>
    <div style="flex-shrink: 0; flex-grow: 1; margin: 5px 15px 5px 0" *ngIf="selectedCompany.isNotApproved"> 
        <af-note type="danger" [margin]="false">
            {{ 'hollen.clientapp.src.app.operation.project.project-company-info.component.unapproved company_' | translate }}
        </af-note>
    </div>
</div>

<div class="row" *ngIf="selectedCompany && selectedCompany.technicianNote" style="margin:15px -15px 15px -15px">
    <div class="col-md-12">
        <af-note type="info" [margin]="false">
            <strong>{{ 'hollen.clientapp.src.app.operation.project.project-company-info.component.technician note' | translate }}:</strong><br />
            <p style="white-space: pre-line;">{{selectedCompany.technicianNote}}</p>
        </af-note>
    </div>
</div>
