<div class="card">
    <div class="card-header">{{ 'hollen.clientapp.src.app.operation.report.x-to-one-report.component.report x_1' | translate }}</div>
    <div class="card-block card-report form-horizontal">
        <div>
            <div *ngIf="[1, 6, 7].indexOf(params.branchId) > -1">
                <div class="row form-group">
                    <div class="col-md-7 control-label">
                        {{ 'hollen.clientapp.src.app.operation.report.x-to-one-report.component.batch' | translate }}
                    </div>
                    <div class="col-md-5">
                        <af-checkbox [(ngModel)]="request.batch" name="batch"></af-checkbox>
                    </div>
                </div>
            </div>
            <div>
                <div class="row form-group">
                    <div class="col-md-7 control-label">
                        {{ 'hollen.clientapp.src.app.operation.report.x-to-one-report.component.merge tasks' | translate }}
                    </div>
                    <div class="col-md-5">
                        <af-checkbox [(ngModel)]="request.mergeSubTasks" name="mergeSubTasks"></af-checkbox>
                    </div>
                </div>
            </div>
            <div *ngIf="[1, 7].indexOf(params.branchId) > -1">
                <div class="row form-group">
                    <div class="col-md-7 control-label">
                        {{ 'hollen.clientapp.src.app.operation.report.x-to-one-report.component.warenfilter' | translate }}
                    </div>
                    <div class="col-md-5">
                        <af-checkbox [(ngModel)]="request.warenfilter" name="warenfilter"></af-checkbox>
                    </div>
                </div>
            </div>
            <div>
                <div class="row form-group">
                    <div class="col-md-7 control-label">
                        {{ 'hollen.clientapp.src.app.operation.report.x-to-one-report.component.chart3' | translate }}
                    </div>
                    <div class="col-md-5">
                        <af-checkbox [(ngModel)]="request.chart3" name="chart3"></af-checkbox>
                    </div>
                </div>
            </div>
            <div>
                <div class="row form-group">
                    <div class="col-md-7 control-label">
                        {{ 'hollen.clientapp.src.app.operation.report.x-to-one-report.component.chart4' | translate }}
                    </div>
                    <div class="col-md-5">
                        <af-checkbox [(ngModel)]="request.chart4" name="chart4"></af-checkbox>
                    </div>
                </div>
            </div>
            <div>
                <div class="row form-group">
                    <div class="col-md-7 control-label">
                        {{ 'hollen.clientapp.src.app.operation.report.x-to-one-report.component.chart5' | translate }}
                    </div>
                    <div class="col-md-5">
                        <af-checkbox [(ngModel)]="request.chart5" name="chart5"></af-checkbox>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-12">
                    <a [href]="getUrl()" [class.disabled]="!params.projectTaskId" class="btn btn-default-alt btn-generate btn-large-icon" title="{{ 'btn.generate' | translate }}"><i class="fa fa-download"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
