import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class CountryService
    extends HttpService<app.organization.CountryListViewModel, app.organization.CountryFormViewModel, app.organization.CountryInsertUpdateViewModel>
{
    objectKey = "org.Country";

    constructor(http: HttpClient) {
        super(http, "/api/organization/country/");
    }

    getCompanies(searchTerm: string): Observable<dotnet.KeyValuePair[]> {
        return this.http.get<dotnet.KeyValuePair[]>(this.url + "companies", {
            params: this.getParameters({ searchTerm })
        });
    }

    getSelectList(): Observable<app.organization.CountrySelectListViewModel[]> {
        let params = new HttpParams();

        return this.http.get<app.organization.CountrySelectListViewModel[]>(this.url + "list", { params });
    }
}
