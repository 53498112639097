
<af-page-bar title="{{ 'hollen.clientapp.src.app.operation.employee-trip.employee-trip-edit.component.editation - employee trip' | translate }}"></af-page-bar>

<div class="form">
    <form (ngSubmit)="update()" name="updateEmployeeTripForm" novalidate="" #updateEmployeeTripForm="ngForm" [class.disabled-all]="isLoading">
        <div class="form-horizontal">
            <div class="form-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group ac-group">
                            <label class="control-label col-md-4">{{ 'hollen.clientapp.src.app.operation.employee-trip.employee-trip-edit.component.employee' | translate }}</label>
                            <div class="col-md-8">
                                <div class="input-group">
                                        <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
                                    <input type="text" class="form-control" [(ngModel)]="selectedEmployee" [editable]="false" [ngbTypeahead]="employeeAutocomplete" [resultFormatter]="employeeFormatter" [inputFormatter]="employeeFormatter" id="EmployeeId" name="EmployeeId" required="" value="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group ac-group">
                            <label class="control-label col-md-4">{{ 'hollen.clientapp.src.app.operation.employee-trip.employee-trip-edit.component.project task' | translate }}</label>
                            <div class="col-md-8">
                                <div class="input-group">
                                        <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
                                    <input type="text" class="form-control" [disabled]="!selectedEmployee" [(ngModel)]="selectedProjectTask" [editable]="false" [ngbTypeahead]="projectTaskAutocomplete" [resultFormatter]="projectTaskFormatter" [inputFormatter]="projectTaskFormatter" id="ProjectTaskId" name="ProjectTaskId" value="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="TripDestinationId">{{ 'hollen.operation.employeetripinsertupdateviewmodel.trip destination' | translate }}</label>
                            <div class="col-md-8">
                                <select class="form-control form-select" [(ngModel)]="employeeTrip.tripDestinationId" id="TripDestinationId" name="TripDestinationId" required="">
                                    <option *ngFor="let tripDestination of tripDestinations" [ngValue]="tripDestination.tripDestinationId">{{tripDestination.title}}</option>
                                </select>
                                <af-help helpid="Hollen.Operation.EmployeeTripInsertUpdateViewModel.TripDestinationId"></af-help>
                                <span><span *ngIf="(updateEmployeeTripForm.controls['TripDestinationId']?.dirty || updateEmployeeTripForm.submitted) && updateEmployeeTripForm.controls['TripDestinationId']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeetripinsertupdateviewmodel.trip destination'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="DepartureDateTime">{{ 'hollen.operation.employeetripinsertupdateviewmodel.date and time of departure' | translate }}</label>
                            <div class="col-md-8">
                                <af-datepicker name="DepartureDateTime" [(ngModel)]="employeeTrip.departureDateTime" required></af-datepicker>
                                <af-help helpid="Hollen.Operation.EmployeeTripInsertUpdateViewModel.DepartureDateTime"></af-help>
                                <span><span *ngIf="(updateEmployeeTripForm.controls['DepartureDateTime']?.dirty || updateEmployeeTripForm.submitted) && updateEmployeeTripForm.controls['DepartureDateTime']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeetripinsertupdateviewmodel.date and time of departure'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="DepartureTime">{{ 'hollen.operation.employeetripinsertupdateviewmodel.time' | translate }}</label>
                            <div class="col-md-8">
                                <select name="DepartureTime" class="form-control form-select"
                                        [(ngModel)]="employeeTrip.departureTime">
                                    <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee-trip.employee-trip-edit.component.undefined time' | translate }}</option>
                                    <option *ngFor="let departureTime of departureTimeList" [ngValue]="departureTime.key">{{departureTime.value}}:00</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="ReturnDateTime">{{ 'hollen.operation.employeetripinsertupdateviewmodel.return date and time' | translate }}</label>
                            <div class="col-md-8">
                                <af-datepicker name="ReturnDateTime" [(ngModel)]="employeeTrip.returnDateTime"></af-datepicker>
                                <af-help helpid="Hollen.Operation.EmployeeTripInsertUpdateViewModel.ReturnDateTime"></af-help>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="ReturnTime">{{ 'hollen.operation.employeetripinsertupdateviewmodel.time' | translate }}</label>
                            <div class="col-md-8">
                                <select name="ReturnTime" class="form-control form-select"
                                        [(ngModel)]="employeeTrip.returnTime">
                                    <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee-trip.employee-trip-edit.component.undefined time' | translate }}</option>
                                    <option *ngFor="let returnTime of returnTimeList" [ngValue]="returnTime.key">{{returnTime.value}}:00</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="PlannedReturnDateTime">{{ 'hollen.operation.employeetripinsertupdateviewmodel.planned return date' | translate }}</label>
                            <div class="col-md-8">
                                <af-datepicker name="PlannedReturnDateTime" [(ngModel)]="employeeTrip.plannedReturnDateTime"></af-datepicker>
                                <af-help helpid="Hollen.Operation.EmployeeTripInsertUpdateViewModel.PlannedReturnDateTime"></af-help>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="Note">{{ 'hollen.operation.employeetripinsertupdateviewmodel.note' | translate }}</label>
                            <div class="col-md-8">
                                <textarea class="form-control" [(ngModel)]="employeeTrip.note" rows="2" id="Note" name="Note">
</textarea>
                                <af-help helpid="Hollen.Operation.EmployeeTripInsertUpdateViewModel.Note"></af-help>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-actions">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="offset-md-1 col-md-11">

                                    <button *afHasPerm="{obj:objectKey, perm:'Update'}" class="btn btn-update btn-primary" type="submit">{{ 'btn.save' | translate }}</button>
                                    <button afConfirmClick type="button" class="btn btn-delete btn-danger"
                                            *afHasPerm="{obj:objectKey, perm:'Delete'}"
                                            (confirmed)="delete()"
                                            message="{{ 'confirm.are you sure you want to delete this record?' | translate }}"
                                            yes="{{ 'confirm.delete.delete' | translate }}"
                                            no="{{ 'confirm.delete.cancel' | translate }}">
                                        {{ 'btn.delete' | translate }}
                                    </button>
                                    <ng-container *ngIf="employeeTripId">
                                        <a *afHasPerm="{obj:objectKey, perm: 'Insert'}" class="btn-new btn-success btn" [routerLink]="['/operation/employee',employeeTrip.employeeId,'employeeTrip','create']">{{ 'btn.new' | translate }}</a>
                                    </ng-container>
                                    <ng-container *ngIf="!employeeTripId">
                                        <a *afHasPerm="{obj:objectKey, perm: 'Insert'}" class="btn-new btn-success btn" [routerLink]="['/operation/employeeTrip/create']">{{ 'btn.new' | translate }}</a>
                                    </ng-container>
                                    <a class="btn-back btn-warning btn" onclick="history.back()">{{ 'btn.back' | translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
