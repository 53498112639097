import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { finalize, switchMap } from "rxjs/operators";
import { AppService } from "../../app.service";
import { PermissionService, TableBaseComponent } from "../../core";
import { filterAndDebounce } from "../../core/utils";
import { ListQueryParams } from "../../models/list-query-params.model";
import { EmployeeService } from "../employee/employee.service";
import { EmployeeAdvanceService } from "./employee-advance.service";


class QueryParams extends ListQueryParams {
    employeeId: number = null;
    employeeName: string = null;
    year: number = null;
    month: number = null;
    isApproved: boolean = null;
    wasGenerated: boolean = null;
    paymentType: number = null;
    accountCenterCode: string = null;
    hollenCompanyCode: string = null;
    wasSentFromNotification: boolean = null;
    projectTaskId: number = null;
}

@Component({
    selector: "op-employee-advance-list",
    templateUrl: "./employee-advance-list.component.html"
})
export class EmployeeAdvanceListComponent extends TableBaseComponent {
    @Input() employeeId: number;
    @Input() hideTitle = false;
    filter: app.operation.EmployeeAdvanceFilterViewModel;
    filterEmployee: app.operation.EmployeeAdvanceFilterViewModel;
    queryParams = new QueryParams();
    objectKey: string;
    objectKeyApprover: string;
    model: PagedList<app.operation.EmployeeAdvanceListViewModel>;
    selectedEmployee: app.operation.EmployeeSelectListViewModel;
    selectedCountryCode: app.operation.EmployeeAdvanceListViewModel;
    rfbfile: app.operation.EmployeeAdvanceFileViewModel;
    rfbUrl: string;
    currentUser: app.UserViewModel;

    constructor(router: Router,
        private employeeAdvanceService: EmployeeAdvanceService,
        private employeeService: EmployeeService,
        private permissionService: PermissionService,
        private appService: AppService
    ) {
        super(router);
        this.queryParams.sortBy = this.sortBy = "employeeAdvanceId desc";
        this.objectKey = employeeAdvanceService.objectKey;
        this.objectKeyApprover = employeeAdvanceService.objectKeyApprover;
        this.currentUser = appService.homeViewModel.user;
        this.rfbUrl = employeeAdvanceService.advanceRfbUrl();
        this.onReset();
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.employeeId) {
            this.queryParams.employeeId = this.employeeId;
        }

        if (this.queryParams.wasSentFromNotification)
        {
            this.queryParams.hollenCompanyCode = this.currentUser.hollenCompanyCode;
            this.queryParams.wasSentFromNotification = null;
        }

        if (this.hasPreviousState) {    
            if (this.queryParams.employeeId) {
                this.selectedEmployee = <app.operation.EmployeeSelectListViewModel>{
                    employeeId: this.queryParams.employeeId,
                    employeeName: this.queryParams.employeeName                
                };
            }        
        }

        this.employeeAdvanceService.getFilter()
            .pipe(finalize(() => this.loadData()))
            .subscribe(res => {
            if (!this.employeeId) {
                this.filter = res;
            }            
        });
    }

    loadData(rfbfile: app.operation.EmployeeAdvanceFileViewModel = null) {
        this.rfbfile = rfbfile;
        if (this.selectedEmployee) {
            this.queryParams.employeeId = this.selectedEmployee.employeeId;
            this.queryParams.employeeName = this.selectedEmployee.employeeName;
        }
        else {
            this.queryParams.employeeId = null;
            this.queryParams.employeeName = null;
        }

        this.isLoading = true;
        if (this.employeeId) {
            this.queryParams.year = null;
            this.queryParams.month = null;
            this.queryParams.employeeId = this.employeeId;
        }
        this.employeeAdvanceService.query(this.queryParams)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.model = res;
                this.saveState(this.queryParams);
            });
    }

    onReset() {
        this.onClear();
        this.queryParams.year = new Date().getFullYear();
        this.queryParams.month = new Date().getMonth()+1; 
    }

    onClear() {
        this.selectedEmployee = null;
        this.queryParams.year = null;
        this.queryParams.month = null;
        this.queryParams.page = 1;
        this.queryParams.pageSize = 20;
    }

    employeeAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(searchTerm => searchTerm.length < 2
                    ? of([])
                    : this.employeeService.getListByRequest(<app.operation.EmployeeSelectListRequest>{
                        page: 1,
                        pageSize: 20,
                        searchTerm,
                        onlyB2b: false,
                        isAdvanceContractTypes: true,
                        isActive:true
                    })));
    }

    employeeFormatter = (employee: app.operation.EmployeeSelectListViewModel) => employee.employeeName;

    generateRFB() {
        this.isLoading = true;
        this.employeeAdvanceService.generateRFB({ ...this.queryParams })
                .pipe(finalize(() => this.isLoading = false))
                .subscribe(res => {
                    this.loadData(res);
                });
        
    }

    onPay(item: app.operation.EmployeeAdvanceListViewModel, payType: number) {       
        this.employeeAdvanceService.pay(item.employeeAdvanceId, payType)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.loadData(res);
            });
    }

    onApprove(item: app.operation.EmployeeAdvanceListViewModel) {  
        this.employeeAdvanceService.approve(item.employeeAdvanceId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.loadData(res);
            });
    }

    onUnapprove(item: app.operation.EmployeeAdvanceListViewModel) {
        this.employeeAdvanceService.unapprove(item.employeeAdvanceId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.loadData(res);
            });
    }

    get canApprove(): boolean {
        return this.permissionService.hasPermission(this.objectKey, "Approve");           
    }


    approveFiltered() {
        this.employeeAdvanceService.approveFiltered(this.createRequestModel(this.queryParams))
            .pipe(finalize(() => {
                this.isLoading = false;
                this.loadData();
            }))
            .subscribe((res) => {
            });
    }

    unapproveFiltered() {
        this.employeeAdvanceService.unapproveFiltered(this.createRequestModel(this.queryParams))
            .pipe(finalize(() => {
                this.isLoading = false;
                this.loadData();
            }))
            .subscribe((res) => {
            });
    }

    createRequestModel(request: QueryParams) {
        return <app.operation.GetEmployeeAdvanceListRequest>{            
            accountCenterCode: request.accountCenterCode,            
            employeeId: request.employeeId,
            hollenCompanyCode: request.hollenCompanyCode,
            paymentType: request.paymentType,
            searchTerm: request.searchTerm,
            wasGenerated: request.wasGenerated,
            projectTaskId: request.projectTaskId,
            isApproved: request.isApproved,  
            month: request.month,
            year: request.year
        };
    }

    get isApprovingRole(): boolean {
        return this.permissionService.hasPermission(this.objectKeyApprover, "Approve");           
    }

}
