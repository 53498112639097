import { AccommodationTermCreateComponent } from "./accommodation-term-create.component";
import { AccommodationTermEditComponent } from "./accommodation-term-edit.component";
import { AccommodationTermListComponent } from "./accommodation-term-list.component";
import { AccommodationTermComponent } from "./accommodation-term.component";

export const components = [
    AccommodationTermComponent,
    AccommodationTermListComponent,
    AccommodationTermCreateComponent,
    AccommodationTermEditComponent,
];
