import {
    Component,
    OnInit,
    ViewChild,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from "@angular/core";
import { Observable, of } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";

import { EmployeeService } from "../employee/employee.service";
import { AccountCenterService } from "../../organization/account-center/account-center.service";
import { ListQueryParams } from "../../models/list-query-params.model";
import { PermissionService, TableBaseComponent } from "../../core";
import { filterAndDebounce } from "../../core/utils";
import { AppService } from "../../app.service";
import { CompanyService } from "../../crm/company/company.service";
import { WorkPlaceService } from "../../organization/work-place/work-place.service";
import { ProjectTaskService } from "./project-task.service";
import { switchMap } from "rxjs/operators";

class QueryParams extends ListQueryParams {
    isArchived: boolean = null;
    employeeId: number;
    employeeName: string;
    accountCenterCode: string = null;
    papersNotCompleted: boolean;
    payrollNotFinished: boolean;
    orderNotReceived: boolean;
    isMetriq: number = null;
    isOverHead: boolean = null;
    companyId: number;
    companyTitle: string = null;
    workPlaceId: number;
    workPlaceTitle: string = null;
    dateFrom: Date;
    dateTo: Date;
    productRateId: number = null;
    hasCriticalCharacteristicOfPart: boolean = null;   
}

@Component({
    selector: "op-project-task-list",
    templateUrl: "./project-task-list.component.html"
})
export class ProjectTaskListComponent extends TableBaseComponent {
    isAllColapsed = false;
    selectedTechnician: app.operation.EmployeeSelectListViewModel;
    selectedCompany: app.crm.CompanySelectListViewModel;
    selectedWorkPlace: app.organization.WorkPlaceSelectListViewModel;
    accountCenters: app.organization.AccountCenterSelectListViewModel[];
    queryParams = new QueryParams();
    objectKey: string;
    currentUser: app.UserViewModel;
    model: PagedList<app.operation.ProjectTaskListViewModel>;

    constructor(
        router: Router,
        private projectTaskService: ProjectTaskService,
        private employeeService: EmployeeService,
        private accountCenterService: AccountCenterService,
        private companyService: CompanyService,
        private workPlaceService: WorkPlaceService,
        private appService: AppService,
        private permissionService: PermissionService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super(router);
        this.currentUser = appService.homeViewModel.user;
        this.objectKey = projectTaskService.objectKey;
    }

    ngOnInit() {
        let productRateId = +this.appService.getParam("productRateId");
        if (productRateId) {
            this.queryParams.productRateId = productRateId;
        }

        super.ngOnInit();

        this.accountCenterService.getSelectList().subscribe(result => {
            if (this.permissionService.canReadOnlyOwn("op.project")) {
                this.accountCenters = result.filter(
                    x => x.branchId === this.currentUser.branchId || this.currentUser.accountCenterCodes.includes(x.code)           
                );
            } else {
                this.accountCenters = result;
            }
            this.changeDetectorRef.markForCheck();
        });

        if (this.hasPreviousState) {
            if (this.queryParams.employeeId) {
                this.selectedTechnician = <app.operation.EmployeeSelectListViewModel>{
                    employeeId: this.queryParams.employeeId,
                    employeeName: this.queryParams.employeeName
                };
            }
            if (this.queryParams.companyId) {
                this.selectedCompany = <app.crm.CompanySelectListViewModel>{
                    companyId: this.queryParams.companyId,
                    titleWithAddressCity: this.queryParams.companyTitle
                };
            }
            if (this.queryParams.workPlaceId) {
                this.selectedWorkPlace = <app.organization.WorkPlaceSelectListViewModel>{
                    workPlaceId: this.queryParams.workPlaceId,
                    title: this.queryParams.workPlaceTitle
                };
            }
        } else {
            this.onReset();
        }

        this.loadData();
    }

    onReset() {
        this.onClear();

        this.queryParams.isArchived = false;

        if (this.currentUser.employee
            && this.currentUser.employee.workPlacementType === 2
            && !this.queryParams.productRateId) {
            this.queryParams.accountCenterCode = this.currentUser.accountCenterCode;
            this.selectedTechnician = <app.operation.EmployeeSelectListViewModel>{
                employeeId: this.currentUser.employee.employeeId,
                employeeName: this.currentUser.employee.fullName
            };
        }
    }

    onClear() {
        this.queryParams.page = 1;
        this.queryParams.pageSize = 20;
        this.queryParams.sortBy = "projectTaskNumber";
        this.selectedTechnician = null;
        this.selectedCompany = null;
        this.selectedWorkPlace = null;
        this.queryParams.isArchived = null;
    }

    loadData() {
        if (this.selectedTechnician) {
            this.queryParams.employeeId = this.selectedTechnician.employeeId;
            this.queryParams.employeeName = this.selectedTechnician.employeeName;
        } else {
            this.queryParams.employeeId = null;
            this.queryParams.employeeName = null;
        }

        if (this.selectedCompany) {
            this.queryParams.companyId = this.selectedCompany.companyId;
            this.queryParams.companyTitle = this.selectedCompany.titleWithAddressCity;
        } else {
            this.queryParams.companyId = null;
            this.queryParams.companyTitle = null;
        }

        if (this.selectedWorkPlace) {
            this.queryParams.workPlaceId = this.selectedWorkPlace.workPlaceId;
            this.queryParams.workPlaceTitle = this.selectedWorkPlace.title;
        } else {
            this.queryParams.workPlaceId = null;
            this.queryParams.workPlaceTitle = null;
        }

        this.isLoading = true;
        this.projectTaskService.query(this.queryParams).subscribe(res => {
            this.model = res;
            this.isLoading = false;
            this.saveState(this.queryParams);
            this.changeDetectorRef.markForCheck();
        });
    }

    employeeAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(
                    term =>
                        term.length < 2
                            ? of([])
                            : this.employeeService.getTechnicianList(1, 20, term)
                ));
    }

    employeeFormatter = (employee: app.operation.EmployeeSelectListViewModel) => employee.employeeName;

    companyAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(
                    term =>
                        term.length < 2
                            ? of([])
                            : this.companyService.getList(1, 20, term)
                ));
    }

    companyFormatter = (company: app.crm.CompanySelectListViewModel) =>
        company.titleWithAddressCity

    workPlaceAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(
                    term =>
                        term.length < 2
                            ? of([])
                            : this.workPlaceService.getSelectList(1, 20, term)
                ));
    }

    workPlaceFormatter = (
        workPlace: app.organization.WorkPlaceSelectListViewModel
    ) => workPlace.title
}
