import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class OpportunityService
    extends HttpService<null, null, null>
{
    objectKey = "crm.Opportunity";
    
    constructor(http: HttpClient) {
        super(http, "/api/crm/opportunity/");
    }

    getForm(): Observable<any> {
        return this.http.get<app.crm.OpportunityFormViewModel>(this.url);
    }

    setOpportunity(companyId: number, opportunity: app.crm.OpportunityViewModel): Observable<app.crm.OpportunityViewModel> {
        return this.http.post<app.crm.OpportunityViewModel>(this.url + companyId, opportunity);
    }

    updateOpportunity(companyId: number, opportunity: app.dashboard.OpportunityListViewModel) {
        return this.http.put(this.url + companyId + '/updateOpportunity', opportunity);
    }
}
