import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "af-page-filter",
    styleUrls: ["./page-filter.component.scss"],
    templateUrl: "./page-filter.component.html"
})
export class PageFilterComponent {
    @Input() loading: boolean;
    @Input() defaultParams: any;
    @Input() params: any;
    @Input() hideButtons: boolean;
    @Input() control: any;
    @Input() helpid: string;
    @Input() isInline: boolean = true;
    @Output() submit = new EventEmitter();
    @Output() filter = new EventEmitter();
    @Output() reset = new EventEmitter();
    @Output() clear = new EventEmitter();

    onSubmit() {
        if (this.params && this.params.page) {
            this.params.page = 1;
        }
        this.submit.emit({});
        this.filter.emit({});
    }

    onReset() {
        this.clearParams();
        this.reset.emit({});
    }

    onClear() {
        this.clearParams();
        this.clear.emit({});
    }

    private clearParams() {
        if (this.params) {
            if (this.control && this.control.resetParams) {
                let resetParams = this.control.resetParams();
                for (let prop in this.params) {
                    if (this.params.hasOwnProperty(prop)) {
                        if (resetParams.hasOwnProperty(prop)) {
                            this.params[prop] = resetParams[prop];
                        }
                        else {
                            this.params[prop] = null;
                        }
                    }
                }
            }
            else {
                for (let prop in this.params) {
                    if (this.params.hasOwnProperty(prop)) {
                        this.params[prop] = null;
                    }
                }
            }
        }
    }
}
