import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class OpportunityOverviewService
    extends HttpService<app.dashboard.OpportunityListViewModel, null, null>
{
    objectKey = "crm.Opportunity";

    constructor(http: HttpClient) {
        super(http, "/api/dashboard/opportunityoverview/");
    }

    getOpportunity(opportunityId: number): Observable<app.crm.OpportunityViewModel>{
        return this.http.get<app.crm.OpportunityViewModel>(this.url + opportunityId + "/opportunity");
    }

    getFilter(): Observable<app.crm.OpportunityFormViewModel> {
        return this.http.get<app.crm.OpportunityFormViewModel>(this.url + "filter");
    }
}
