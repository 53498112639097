import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class IslandService
    extends HttpService<app.organization.IslandListViewModel, app.organization.IslandFormViewModel, app.organization.IslandInsertUpdateViewModel>
{
    objectKey = "org.Island";

    constructor(http: HttpClient) {
        super(http, "/api/organization/island/");
    }

    getSelectList(): Observable<app.organization.IslandSelectListViewModel[]> {
        let params = new HttpParams();

        return this.http.get<app.organization.IslandSelectListViewModel[]>(this.url + "list", { params });
    }

    getFilter(): Observable<app.organization.IslandFilterViewModel> {
        return this.http.get<app.organization.IslandFilterViewModel>(this.url + "filter");
    }
}
