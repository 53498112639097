
<af-table #afTable [value]="model" [loading]="isLoading" [params]="queryParams" [sortBy]="sortBy" (load)="loadData()">
    <af-column header="{{ 'hollen.operation.accommodationtermlistviewmodel.hostel' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.accommodationTitle}} ({{item.companyTitle}})
        </ng-template>
    </af-column>
    <af-column header=" {{ 'hollen.clientapp.src.app.operation.accommodation-term.accommodation-term-list.component.accommodated' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <ng-container *ngIf="item.isRoommate">
                {{item.note}}
            </ng-container>
            <ng-container *ngIf="!item.isRoommate">
                {{item.employeeName}}
            </ng-container>
        </ng-template>
    </af-column>
    <af-column field="unitPrice" header="{{ 'hollen.operation.accommodationtermlistviewmodel.price bed_night' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <div style="white-space: nowrap; text-align: right;">
                {{item.unitPrice | afCurrency:item.currency:'.2-9'}}
            </div>
        </ng-template>
    </af-column>
    <af-column field="typeTitle" header="{{ 'hollen.operation.accommodationtermlistviewmodel.type' | translate }}"></af-column>
    <af-column field="fromDate" header="{{ 'hollen.operation.accommodationtermlistviewmodel.from' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.fromDate | afDate:'L'}}
        </ng-template>
    </af-column>
    <af-column field="toDate" header="{{ 'hollen.operation.accommodationtermlistviewmodel.to' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.toDate | afDate:'L'}}
        </ng-template>
    </af-column>
    <af-column>
        <ng-template let-item="rowData" afTemplate>
            <button *afHasPerm="{obj:objectKey, perm: 'Update'}"
                    type="button" class="btn btn-default btn-sm"
                    (click)="clickEdit(item)">
                {{ 'btn.edit' | translate }}
            </button>
        </ng-template>
    </af-column>
</af-table>
