import { Directive } from "@angular/core";
import { NG_VALIDATORS, AbstractControl, UntypedFormControl, ValidatorFn, Validator } from "@angular/forms";

export function emailValidator(): ValidatorFn {
    return (c: AbstractControl) => {
        let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

        if (c.value && !c.value.match(EMAIL_REGEXP)) {
            return {
                email: {
                    valid: false
                }
            };
        }
        return null;
    };
}

@Directive({
    selector: "input[type=email][ngModel],[validateEmail][ngModel],[validateEmail][formControl]",
    providers: [
        { provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true }
    ]
})
export class EmailValidatorDirective implements Validator {

    validator: ValidatorFn;

    constructor() {
        this.validator = emailValidator();
    }

    validate(c: UntypedFormControl) {
        return this.validator(c);
    }
}
