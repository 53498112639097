import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges, SimpleChanges } from "@angular/core";

@Component({
    selector: "af-paginator",
    styles: [`
        .pagination { margin: 0 }
        .af-table-pager { display: flex }
        .filler { display: flex; flex-grow: 1 }
    `],
    template: `
        <div class="af-table-pager">
            <ul *ngIf="pages.length" class="pagination">
                <li class='page-item' [ngClass]="{'disabled': !page.active && !page.current, 'active': page.current}" *ngFor="let page of pages" [ngSwitch]="page.type">
                    <ng-template [ngSwitchCase]="'prev'"><a (click)="changePage(page.number)" class='page-link'>&laquo;</a></ng-template>
                    <ng-template [ngSwitchCase]="'first'"><a (click)="changePage(page.number)" class='page-link'><span>{{page.number}}</span></a></ng-template>
                    <ng-template [ngSwitchCase]="'page'"><a (click)="changePage(page.number)" class='page-link'><span>{{page.number}}</span></a></ng-template>
                    <ng-template [ngSwitchCase]="'more'"><a (click)="changePage(page.number)" class='page-link'>&#8230;</a></ng-template>
                    <ng-template [ngSwitchCase]="'last'"><a (click)="changePage(page.number)" class='page-link'><span>{{page.number}}</span></a></ng-template>
                    <ng-template [ngSwitchCase]="'next'"><a (click)="changePage(page.number)" class='page-link'>&raquo;</a></ng-template>
                </li>
            </ul>
            <div class="filler"></div>
            <div class="af-table-pager-counts btn-group" *ngIf="pageSizes.length">
                <button *ngFor="let ps of pageSizes" (click)="changePageSize(ps)" type="button" [class.active]="pageSize == ps" class="btn btn-default">{{ps}}</button>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent {
    _page = 1;
    _pageSize = 20;
    _totalCount = 0;
    pages: any[];

    @Input() name;

    @Input() pageSizes: number[] = [20, 50, 100];

    @Input() get pageSize(): number {
        return this._pageSize;
    }

    set pageSize(val: number) {
        this._pageSize = val;
        this.updatePageLinks();
    }

    @Input() pageLinkSize = 11;

    @Input() minPageLinkSize = 5;

    @Output() pageChange: EventEmitter<any> = new EventEmitter();

    @Input() style: string;

    @Input() styleClass: string;

    @Input() get page(): number {
        return this._page;
    }

    set page(val: number) {
        this._page = val;
        this.updatePageLinks();
    }

    @Input() get totalCount(): number {
        return this._totalCount;
    }

    set totalCount(val: number) {
        this._totalCount = val;
        this.updatePageLinks();
    }

    getPageCount() {
        return Math.ceil(this.totalCount / this.pageSize) || 1;
    }

    updatePageLinks() {
        let maxPage;
        let maxPivotPages;
        let minPage;
        let numPages;
        let currentPage = this.page;

        this.pages = [];
        numPages = this.getPageCount();

        if (numPages > 1) {
            this.pages.push({
                type: "prev",
                number: Math.max(1, currentPage - 1),
                active: currentPage > 1
            });
            this.pages.push({
                type: "first",
                number: 1,
                active: currentPage > 1,
                current: currentPage === 1
            });
            maxPivotPages = Math.round((this.pageLinkSize - this.minPageLinkSize) / 2);
            minPage = Math.max(2, currentPage - maxPivotPages);
            maxPage = Math.min(numPages - 1, currentPage + maxPivotPages * 2 - (currentPage - minPage));
            minPage = Math.max(2, minPage - (maxPivotPages * 2 - (maxPage - minPage)));
            let i = minPage;
            while (i <= maxPage) {
                if ((i === minPage && i !== 2) || (i === maxPage && i !== numPages - 1)) {
                    this.pages.push({
                        type: "more",
                        active: false
                    });
                } else {
                    this.pages.push({
                        type: "page",
                        number: i,
                        active: currentPage !== i,
                        current: currentPage === i
                    });
                }
                i++;
            }
            this.pages.push({
                type: "last",
                number: numPages,
                active: currentPage !== numPages,
                current: currentPage === numPages
            });
            this.pages.push({
                type: "next",
                number: Math.min(numPages, currentPage + 1),
                active: currentPage < numPages
            });
        }
    }

    changePage(p: number) {
        let pc = this.getPageCount();

        if (p > 0 && p <= pc) {
            this.page = p;

            let state = {
                page: this.page,
                pageSize: this.pageSize,
                pageCount: pc
            };

            this.pageChange.next(state);
        }
    }

    changePageSize(ps: number) {
        this.pageSize = ps;
        this.changePage(1);
    }
}
