import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "afLimit"
})
export class LimitPipe implements PipeTransform {

    transform(value: string, limit: number): string | null {
        if (!value) {
            return null;
        }
        let trail = "...";
        return value.length > limit ? value.substring(0, limit) + trail : value;
    }
}