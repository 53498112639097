
<div class="row">
    <div class="col-md-8">
        <div *ngIf="content?.folder?.path">
            <button class="btn btn-default"
                    style="margin-right: 6px;"
                    type="button"
                    [disabled]="isTopFolder"
                    (click)="goToUpperFolder()">
                <span class="fa fa-long-arrow-up"></span>
            </button>
            <span *ngFor="let folderName of content.folder.path; let $index = index">
                <a (click)="goToPath($index)">{{ folderName }}</a>
                <i class="fa fa-angle-right" style="margin-left: 6px; margin-right: 6px;"></i>
            </span>
        </div>
    </div>
    <div class="col-md-4">
        <af-page-bar>
            <a *ngIf="isSharepointEnabled && !isSharepointLinkDisabled" class="btn btn-fit-height btn-info" [class.disabled-all]="!content?.folder?.url" target="_blank" [href]="content?.folder?.url">
                <af-spinner type="circle" [isInline]="true" *ngIf="isLoadingSharePoint" [loading]="true"></af-spinner>
                SharePoint
            </a>
            <af-file-upload name="FileName"
                            [simple]="true"
                            [multiple]="true"
                            styleClass="btn btn-success"
                            [disabled]="isLoadingSharePoint"
                            *afHasPerm="{obj:objectKey, perm: 'Insert', if: isUploadPermission}"
                            (success)="onSuccessUpload($event)"
                            [options]="uploadOptions">
                {{ 'hollen.clientapp.src.app.shared.components.share-point.share-point-file.component.upload file' | translate }} <i class="fa fa-plus"></i>
            </af-file-upload>
            <af-help *ngIf="isUploadPermission && !this.queryParams.employeeId" class="help-static" style="margin-left: 3px;" helpid="sharePoint.fileUpload"></af-help>
            <af-help *ngIf="isUploadPermission && this.queryParams.employeeId" class="help-static" style="margin-left: 3px;" helpid="sharePoint.fileUploadEmployee"></af-help>
        </af-page-bar>
    </div>
</div>

<form class="form-inline" *ngIf="content?.folder?.subFolders && content.folder.subFolders.length > 0">
    <div class="form-group">
        <button class="btn btn-default" *ngFor="let folderName of content.folder.subFolders; let $index = index"
                type="button"
                (click)="goToFolder(folderName)">
            {{ folderName }}
        </button>
    </div>
</form>

<hr/>

<af-page-filter *ngIf="isFilter" [control]="afTable" [loading]="isLoading" [params]="queryParams" (filter)="loadFiles()" (reset)="onReset()" (clear)="onClear()">
    <div class="form-group">
        <input type="text" class="form-control" [(ngModel)]="queryParams.searchTerm" placeholder="{{ 'filter.keyword' | translate }}" />
    </div>
</af-page-filter>

<af-table #afTable [value]="model" [loading]="isLoading" [timeout]="0" [params]="queryParams" [sortBy]="sortBy" (load)="loadFiles()" noRecordsFoundText="{{ 'hollen.clientapp.src.app.shared.components.share-point.share-point-file.component.no files found' | translate }}">
    <af-column field="fileName" sortable="fileName" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.file' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <i *ngIf="item.wasUpdatedAfterPapersCompletition" class="fa fa-exclamation-triangle" style="color:red"></i>
            <i class="fa fa-cog" *ngIf="item.isGenerated"></i>
            <a [href]="sanitize(item.fileUrl)" [target]="target(item)">{{ item.fileName }}</a>
        </ng-template>
    </af-column>
    <af-column field="createdUtc" sortable="createdUtc" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.created' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.createdUtc | afDate : 'L LTS' : true }}
        </ng-template>
    </af-column>
    <af-column field="createdBy" sortable="createdBy" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.author' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.createdBy }}
        </ng-template>
    </af-column>
    <af-column field="lastUpdatedUtc" sortable="lastUpdatedUtc" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.edited' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.lastUpdatedUtc | afDate : 'L LTS' : true }}
        </ng-template>
    </af-column>
    <af-column field="lastUpdatedBy" sortable="lastUpdatedBy" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.editor' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.lastUpdatedBy }}
        </ng-template>
    </af-column>
    <af-column field="documentType" sortable="documentType" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.document type' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.documentType }}
        </ng-template>
    </af-column>
    <af-column field="approvalDate" sortable="approvalDate" header="{{ 'hollen.clientapp.src.app.shared.components.share-point.share-point-file.component.signed' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.approvalDate|afDate:'DD.MM.YYYY':true}}
        </ng-template>
    </af-column>
    <af-column field="length" sortable="length" styleClass="text-center" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.size' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.length | afFileSize }}
        </ng-template>
    </af-column>
    <af-column field="fileType" sortable="fileType" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.file type' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.fileType}}
        </ng-template>
    </af-column>
    <af-column styleClass="text-center">
        <ng-template let-item="rowData" afTemplate>
            <button afConfirmClick (confirmed)="delete(item);" type="button" class="btn btn-sm btn-delete btn-danger" [disabled]="item['updating']"
                    placement="left"
                    *afHasPerm="{ obj:objectKey, perm: 'Delete', if: isDeletePermission && !(item['forInv'] && getForInvLocked(item)) && !item['approvalDate'] }"
                    message="{{ 'confirm.are you sure you want to delete this file?' | translate }}"
                    yes="{{ 'confirm.delete.delete' | translate }}"
                    no="{{ 'confirm.delete.cancel' | translate }}">
                {{ 'btn.delete' | translate }}
            </button>
        </ng-template>
    </af-column>
</af-table>
