import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { FileUploadOptions } from "../../shared/components/file-upload/file-upload.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { getUnixDate } from "../../core/utils";

@Injectable()
export class EmployeeService
    extends HttpService<app.operation.EmployeeListViewModel, app.operation.EmployeeFormViewModel, app.operation.EmployeeInsertUpdateViewModel>
{
    objectKey = "op.Employee";
    objectKeyMetriqInput = "op.Employee.MetriqInputUser";
    photoUrl: string;

    constructor(http: HttpClient) {
        super(http, "/api/operation/employee/");
        this.photoUrl = this.url + "photo";
    }

    getFilter(): Observable<app.operation.EmployeeListFilterViewModel> {
        return this.http.get<app.operation.EmployeeListFilterViewModel>(this.url + "filter");
    }

    getListByRequest(request?: app.operation.EmployeeSelectListRequest): Observable<app.operation.EmployeeSelectListViewModel[]> {
        return this.http.get<app.operation.EmployeeSelectListViewModel[]>(this.url + "list", {
            params: this.getParameters(request)
        });
    }

    getList(page?: number, pageSize?: number, searchTerm?: string, workPlacementType?: number,
        accountCenterCode?: string, branchHollenCompanyCode?: string, onlyMetriqInput?:boolean,isActive?:boolean): Observable<app.operation.EmployeeSelectListViewModel[]> {
        return this.getListByRequest(<app.operation.EmployeeSelectListRequest>{
            page,
            pageSize,
            searchTerm,
            workPlacementType,
            accountCenterCode,
            branchHollenCompanyCode,
            onlyMetriqInput,
            isActive
            //branchCountryCode
        });
    }

    getListForWorkReport(onlyActive: boolean,
        pageSize?: number,
        accountCenterCode?: string,
        firstProjectTaskId?: number): Observable<app.operation.EmployeeSelectListViewModel[]> {
        let params = new HttpParams();

        if (pageSize) {
            params = params.set("pageSize", pageSize.toString());
        }
        if (accountCenterCode) {
            params = params.set("accountCenterCode", accountCenterCode);
        }
        if (firstProjectTaskId) {
            params = params.set("firstProjectTaskId", firstProjectTaskId.toString());
        }
        params = params.set("onlyActive", onlyActive.toString());

        return this.http.get<app.operation.EmployeeSelectListViewModel[]>(this.url + "listForWorkReport", { params });
    }

    getTechnicianList(page?: number, pageSize?: number, searchTerm?: string, accountCenterCode?: string): Observable<app.operation.EmployeeSelectListViewModel[]> {
        return this.getListByRequest(<app.operation.EmployeeSelectListRequest>{
            page,
            pageSize,
            searchTerm,
            workPlacementType: 2,
            accountCenterCode: accountCenterCode
        });
    }

    getShiftPlanList(parameters?: any): Observable<app.operation.EmployeeShiftPlanViewModel[]> {
        let params = Object.assign({}, parameters);
        if (params["from"]) {
            params["from"] = +moment.utc([parameters.from.getFullYear(), parameters.from.getMonth(), parameters.from.getDate()]);
        }
        if (params["to"]) {
            params["to"] = +moment.utc([parameters.to.getFullYear(), parameters.to.getMonth(), parameters.to.getDate()]);
        }
        let httpParams = new HttpParams();
        httpParams = httpParams.set("showToast", "false");
        return this.http.post<app.operation.EmployeeShiftPlanViewModel[]>(this.url + "shiftplan", params, { params: httpParams });
    }

    getSkills(employeeId: number): Observable<app.operation.EmployeeSkillFormViewModel> {
        return this.http.get<app.operation.EmployeeSkillFormViewModel>(this.url + employeeId + "/skills");
    }

    getSkillsFilter(): Observable<app.operation.EmployeeSkillFilterViewModel> {
        return this.http.get<app.operation.EmployeeSkillFilterViewModel>(this.url + "skills");
    }

    saveSkills(employeeId: number, skills: app.operation.EmployeeSkillFormViewModel) {
        return this.http.put(this.url + employeeId + "/skills", skills);
    }

    getNotes(employeeId: number): Observable<app.operation.EmployeeNoteViewModel[]> {
        return this.http.get<app.operation.EmployeeNoteViewModel[]>(this.url + employeeId + "/notes");
    }

    getEntryCardCompanies(searchTerm: string): Observable<string[]> {
        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        return this.http.get<string[]>(this.url + "entrycardcompanies", { params });
    }

    changeStatus(employeeId: number, status: number): Observable<any> {
        let model: app.operation.EmployeeStatusUpdateViewModel = {
            status
        };
        return this.http.put(this.url + employeeId + "/status", model);
    }

    getHistory(page: number, pageSize: number, employeeId: number): Observable<af.sys.EntityHistoryViewModel[]> {
        let params = new HttpParams();
        params = params.set("page", page.toString());
        params = params.set("pageSize", pageSize.toString());
        return this.http.get<af.sys.EntityHistoryViewModel[]>(this.url + employeeId + "/history", { params });
    }

    getUploadOptions(employeeId?: number): FileUploadOptions {
        let options = new FileUploadOptions();
        options.url = this.photoUrl + (employeeId ? ("/" + employeeId) : "");
        return options;
    }

    getUserListByRequest(request?: app.usersystem.UserSelectListRequest): Observable<af.usersystem.UserSelectListViewModel[]> {
        return this.http.get<af.usersystem.UserSelectListViewModel[]>(this.url + "user-list", {
            params: this.getParameters(request)
        });
    }

    generatePassword() {
        return this.http.get<af.usersystem.GeneratePasswordViewModel>(this.url + "generatepassword");
    }

    getMetriqUser(employeeId: number) {
        let params = new HttpParams();
        params = params.set("employeeId", employeeId.toString());
        return this.http.get<app.metriqsync.MetriqUserInsertUpdateViewModel>(this.url + "metriq", { params });
    }

    insertOrUpdateMetriqUser(user: app.metriqsync.MetriqUserInsertUpdateViewModel, employeeId: number) {
        return this.http.post(this.url + "metriq/" + employeeId, user);
    }

    getCooperantRates(employeeId: number, fromDateTime: Date): Observable<app.codebook.CodebookSelectViewModel[]> {
        let params = new HttpParams();
        params = params.set("employeeId", employeeId.toString());
        params = params.set("fromDateTime", getUnixDate(fromDateTime).toString())
        return this.http.get < app.codebook.CodebookSelectViewModel[]>(this.url + "cooperantrates", { params });
    }

    getCooperantRatesByMajorProvider(majorProviderId: number, fromDateTime: Date){
        let params = new HttpParams();
        params = params.set("majorProviderId", majorProviderId.toString());
        params = params.set("fromDateTime", getUnixDate(fromDateTime).toString())
        return this.http.get<app.codebook.CodebookSelectViewModel[]>(this.url + "cooperantratesbymajorprovider", { params });
    }

    getEmployeeB2bRates(fromDateTime: Date, hollenCompanyCode: string, employeeId: number) {
        let params = new HttpParams();
        if (hollenCompanyCode) params = params.set("hollenCompanyCode", hollenCompanyCode);
        if (employeeId) params = params.set("employeeId", employeeId);
        params = params.set("fromDateTime", getUnixDate(fromDateTime).toString())
        return this.http.get<app.operation.EmployeeB2bRateSelectListViewModel[]>(this.url + "employeeb2brates", { params });
    }

    getGeneratedIban(request?: app.operation.GetEmployeeIbanRequest): Observable<any> {
        return this.http.get<any>(this.url + "generatediban", {
            params: this.getParameters(request)
        });
    }

    deletePhoto(employeeId: number) {
        return this.http.delete(this.url + "photo/" + employeeId);
    }
}
