<div *ngIf="list?.totalCount > 0" class="entity-history">
    <af-request-log [id]="requestLogId"></af-request-log>
    <div *ngFor="let item of list.data" class="entity-history-item">
        <div class="entity-history-header">
            <span [ngSwitch]="item.state">
                <span class="label label-info" *ngSwitchCase="1">{{ 'hollen.clientapp.src.app.shared.components.history.history.component.added' | translate }}</span>
                <span class="label label-danger" *ngSwitchCase="2">{{ 'hollen.clientapp.src.app.shared.components.history.history.component.deleted' | translate }}</span>
                <span class="label label-success" *ngSwitchCase="3">{{ 'hollen.clientapp.src.app.shared.components.history.history.component.modified' | translate }}</span>
            </span>
            <b>{{ item.createdUtc | afDate:'LLL':true }} - {{ item.createdByName}}</b>
            <span *ngIf="item.requestLogId"> - <a *afHasPerm="{obj:'sy.RequestLog', perm: 'Read'}" (click)="requestLogId = item.requestLogId">Show request</a></span>
        </div>
        <div class="entity-history-entity-name">
            {{ 'hollen.clientapp.src.app.shared.components.history.history.component.changes in entity' | translate }} <span>{{ item.entityTypeName }}</span>
        </div>
        <div *ngFor="let prop of item.properties" class="entity-history-property">
            <span class="entity-history-property-name">
                {{ prop.propertyName }}
                <span *ngIf="item.state === 1">
                    <i class="fa fa-long-arrow-right"></i>
                    <span class="entity-history-newvalue">
                        <af-history-value [name]="prop.propertyName" [value]="prop.newValue"></af-history-value>
                    </span>
                </span>
                <span *ngIf="item.state === 2">
                    <i class="fa fa-long-arrow-right"></i>
                    <span class="entity-history-newvalue">
                        <af-history-value [name]="prop.propertyName" [value]="prop.oldValue"></af-history-value>
                    </span>
                </span>
            </span>
            <div class="entity-history-changes" *ngIf="item.state === 3">
                <span class="entity-history-oldvalue">
                    <af-history-value [name]="prop.propertyName" [value]="prop.oldValue"></af-history-value>
                </span>
                <i class="fa fa-long-arrow-right"></i>
                <span class="entity-history-newvalue">
                    <af-history-value [name]="prop.propertyName" [value]="prop.newValue"></af-history-value>
                </span>
            </div>
        </div>
    </div>
    <af-paginator [pageSize]="list.pageSize"
                  [page]="list.page"
                  [totalCount]="list.totalCount"
                  styleClass="table-paginator-bottom"
                  (pageChange)="paginate($event)">
    </af-paginator>
</div>
<af-note *ngIf="list?.totalCount === 0" type="info">
    {{ 'table.no records found' | translate }}
</af-note>
