import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class DialogService {
    defaultMessage = "You have unsaved values. Do you want to leave the form unsaved?";

    constructor(private translateService: TranslateService) {
    }

    confirm(message?: string): Promise<boolean> {
        return new Promise((resolve) => {
            let result = true;
            if (message) {
                result = window.confirm(message);
                return resolve(result);
            }
            else {
                this.translateService.get("dialog.confirm").subscribe(res => {
                    result = window.confirm(res || this.defaultMessage);
                    return resolve(result);
                });
            }
        });
    }
}
