import { Injectable } from "@angular/core";
import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class EmployeeFileService
    extends HttpService<app.sharepoint.SharePointFileViewModel, any, app.sharepoint.SharePointFileViewModel>
{
    objectKey = "op.employee";

    constructor(http: HttpClient) {
        super(http, "/api/operation/employeefile/");
    }    
}
