
<af-page-bar title="{{ 'hollen.clientapp.src.app.operation.presentation-item.presentation-item-edit.component.presentation item' | translate }}"></af-page-bar>

<div class="form mb-3">
    <form *ngIf="presentationItem" (ngSubmit)="update()" name="editForm" novalidate="" #editForm="ngForm" [class.disabled-all]="isLoading">
        <div class="form-horizontal">
            <div class="form-body" *ngIf="presentationItem">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="RecordOrder">{{ 'hollen.operation.projecttaskpresentationiteminsertupdateviewmodel.order' | translate }}</label>
                            <div class="col-md-10">
                                <input class="form-control" [(ngModel)]="presentationItem.recordOrder" type="number" id="RecordOrder" name="RecordOrder" required="" value="" /><input name="__Invariant" type="hidden" value="RecordOrder" />
                                <span><span *ngIf="(editForm.controls['RecordOrder']?.dirty || editForm.submitted) && editForm.controls['RecordOrder']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.projecttaskpresentationiteminsertupdateviewmodel.order'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="Length">{{ 'hollen.operation.projecttaskpresentationiteminsertupdateviewmodel.length' | translate }}</label>
                            <div class="col-md-10">
                                <input class="form-control" [(ngModel)]="presentationItem.length" [disabled]="isPresentationConfirmationRequired" type="number" id="Length" name="Length" required="" value="" /><input name="__Invariant" type="hidden" value="Length" />
                                <span><span *ngIf="(editForm.controls['Length']?.dirty || editForm.submitted) && editForm.controls['Length']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.projecttaskpresentationiteminsertupdateviewmodel.length'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="FileName">{{ 'hollen.operation.projecttaskpresentationiteminsertupdateviewmodel.filename' | translate }}</label>
                            <div class="col-md-10">
                                <input class="form-control" [(ngModel)]="presentationItem.fileName" type="text" id="FileName" name="FileName" required="" value="" />
                                <span><span *ngIf="(editForm.controls['FileName']?.dirty || editForm.submitted) && editForm.controls['FileName']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.projecttaskpresentationiteminsertupdateviewmodel.filename'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <ng-container *ngFor="let component of presentationItem.components; let $index = index; trackBy: trackByIndexFn">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label *ngIf="$index == 0" class="control-label col-md-2" for="Components">{{ 'hollen.operation.projecttaskpresentationiteminsertupdateviewmodel.components' | translate }}</label>
                                        <div *ngIf="$index > 0" class="col-md-2"></div>

                                        <div class="col-md-10">
                                            <div class="input-group">
                                                <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
                                                <input type="text"
                                                       class="form-control"
                                                       (blur)="checkValue(presentationItem.components, $index)"
                                                       [name]="'Component' + $index"
                                                       [ngModel]="presentationItem.components[$index]"
                                                       (ngModelChange)="onPresentationItemChange($event,$index)"
                                                       [ngbTypeahead]="componentAutocomplete"
                                                       [resultFormatter]="componentFormatter"
                                                       [inputFormatter]="componentFormatter" />

                                                <a class="btn btn-danger"
                                                   (click)="deleteComponent(presentationItem.presentationItemId,$index)">
                                                    <i class="fa fa-close"></i>
                                                </a>
                                            </div>
                                            <af-help helpid="Hollen.Operation.ProjectTaskPresentationItemInsertUpdateViewModel.Components"></af-help>
                                        </div>
                                    </div>
                                    <div class="offset-md-2 col-md-6" style="margin-left:-8px" *ngIf="presentationItem.components.length === $index + 1">
                                        <div class="my-2">
                                            <button type="button" class="btn btn-default" (click)="addComponent()">{{ 'btn.add.add item' | translate }}</button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="col-md-10 offset-md-2">
                                <img [src]="presentationItem.fileUrl" [style]="{'max-width.px':100, 'max-height.px': 100}" [width]="100" />
                                <br /> <br />
                                <a [href]="presentationItem.fileUrl" class="btn btn-success" target="_blank">{{ 'btn.download' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-actions">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="offset-md-1 col-md-11">
                                <button *afHasPerm="{obj:objectKey, perm: 'Update'}" class="btn btn-update btn-primary" type="submit">{{ 'btn.save' | translate }}</button>
                                <button afConfirmClick type="button" class="btn btn-delete btn-danger"
                                        (confirmed)="delete();"
                                        *afHasPerm="{obj:objectKey, perm: 'Delete'}"
                                        message="{{ 'confirm.are you sure you want to delete this record?' | translate }}"
                                        yes="{{ 'confirm.delete.delete' | translate }}"
                                        no="{{ 'confirm.delete.cancel' | translate }}">
                                    {{ 'btn.delete' | translate }}
                                </button>
                                <ng-container *ngIf="presentationItemId">
                                    <a *afHasPerm="{obj:objectKey, perm: 'Insert'}" class="btn-new btn-success btn" [routerLink]="['/operation/projectTask',presentationItem.firstProjectTaskId, 'presentation-item','create']">{{ 'btn.new' | translate }}</a>
                                </ng-container>
                                <ng-container *ngIf="!presentationItemId">
                                    <a *afHasPerm="{obj:objectKey, perm: 'Insert'}" class="btn-new btn-success btn" [routerLink]="['/operation/presentation-item/create']">{{ 'btn.new' | translate }}</a>
                                </ng-container>
                                <a class="btn-back btn-warning btn" onclick="history.back()">{{ 'btn.back' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </form>
</div>
