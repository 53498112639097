import { Injectable } from "@angular/core";
import { of as observableOf, from as observableFrom, Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Store } from "@ngrx/store";

import { AuthConfig } from "./auth.config";
import { StorageService } from "./storage.service";
import { State } from "../store/reducers";
import * as appActions from "../store/actions/app";

export interface LoginResponse {
    access_token: string;
    expires_in: number;
    isError: boolean;
    token_type: string;
}

@Injectable()
export class AuthService {
    get isAuthenticated() {
        return !this.storageService.isTokenExpired();
    }

    constructor(private http: HttpClient,
        private storageService: StorageService,
        private config: AuthConfig,
        private store: Store<State>) {
    }

    getToken() {
        return this.storageService.getToken();
    }

    clear() {
        return this.storageService.clear();
    }

    refreshTokens(force: boolean, allowAnonymous: boolean = false) {
        if (allowAnonymous) {
            return observableOf({
                access_token: null,
                expires_at: null,
                isError: false,
                message: null
            });
        }
        else if (force || this.storageService.isTokenExpired()) {
            //use classic request to avoid circularity in interceptor
            return observableFrom(
                new Promise((resolve, reject) => {
                    reject();
                    //let xhr = new XMLHttpRequest();
                    //xhr.responseType = "json";
                    //xhr.onreadystatechange = () => {
                    //    if (xhr.readyState === 4) {
                    //        if (xhr.status === 200) {
                    //            this.storageService.set(xhr.response);
                    //            resolve(xhr.response);
                    //        } else {
                    //            reject(xhr.response);
                    //        }
                    //    }
                    //};
                    //xhr.open("GET", "refreshtokens", true);
                    //xhr.send();
                })
            );
        } else {
            return observableOf({
                access_token: this.storageService.accessToken,
                expires_at: this.storageService.expiresDate,
                isError: false,
                message: null
            });
        }
    }

    login(loginData: af.usersystem.LoginViewModel): Observable<LoginResponse> {
        let data = "grant_type=password&client_id=web&client_secret=secret&scope=api&username="
            + encodeURIComponent(loginData.userName) + "&password=" + encodeURIComponent(loginData.password);

        let headers = new HttpHeaders();

        headers = headers.append("Content-Type", "application/x-www-form-urlencoded");

        let params = new HttpParams();
        params = params.set("allowAnonymous", "true");
        params = params.set("showToast", "false");
        return this.http.post<LoginResponse>(this.config.tokenEndpoint, data, { headers, params });
    }

    logout(deleteCookies: boolean): void {
        this.storageService.clear();
        this.clearApiCache().then(() => { }).catch((error) => {
            console.error("logout", error);
        });
        if (deleteCookies) {
            this.http.post("/logout", "").subscribe(() => {
                window.location.reload();
            });
        }
    }

    clearApiCache() {
        return this.sendMessage("clear-api-cache");
    }

    clearFullCache() {
        return this.sendMessage("clear-all-cache");
    }

    sendMessage(message) {
        return new Promise((resolve, reject) => {
            if (navigator["serviceWorker"] && navigator["serviceWorker"].controller) {
                let messageChannel = new MessageChannel();
                messageChannel.port1.onmessage = (event) => {
                    if (event.data.error) {
                        reject(event.data.error);
                    } else {
                        resolve(event.data);
                    }
                };
                navigator["serviceWorker"].controller.postMessage(message, [messageChannel.port2]);
            } else {
                // reject("This page isn't currently controlled by a service worker.");
                resolve(null);
            }
        });
    }

    forgotPassword(data: af.usersystem.ForgotPasswordViewModel): Observable<af.MessageViewModel> {
        let params = new HttpParams();
        params = params.set("allowAnonymous", "true");
        return this.http.post<af.MessageViewModel>(this.config.accountUrl + "forgotpassword", data, { params });
    }

    resetPassword(data: af.usersystem.ResetPasswordViewModel): Observable<af.MessageViewModel> {
        let params = new HttpParams();
        params = params.set("allowAnonymous", "true");
        return this.http.post<af.MessageViewModel>(this.config.accountUrl + "resetpassword", data, { params });
    }

    changePassword(data: af.usersystem.ChangePasswordViewModel): Observable<af.MessageViewModel> {
        return this.http.post<af.MessageViewModel>(this.config.accountUrl + "changePassword", data);
    }

    getSettings(appendLists?: boolean): Observable<app.usersystem.AccountSettingFormViewModel> {
        let params = new HttpParams();
        if (appendLists) {
            params = params.set("appendLists", appendLists.toString());
        }
        return this.http.get<app.usersystem.AccountSettingFormViewModel>(this.config.accountUrl, { params });
    }

    updateSettings(settings: app.usersystem.AccountSettingViewModel): Observable<any> {
        return this.http.put(this.config.accountUrl, settings);
    }

    getHome(): Observable<app.HomeViewModel> {
        return this.http.get<app.HomeViewModel>(this.config.homeUrl);
    }

    getLogin(): Observable<app.LoginViewModel> {
        let params = new HttpParams();
        params = params.set("allowAnonymous", "true");
        return this.http.get<app.LoginViewModel>(this.config.loginUrl, { params });
    }

    save(response: LoginResponse) {
        this.storageService.set(response);
    }

    initHome(home: app.HomeViewModel) {
        this.configureDates(home.user);

        this.store.dispatch(new appActions.HomeViewModelLoadedAction(home));
        this.store.dispatch(new appActions.LanguageChangedAction(home.user.language));
    }

    configureDates(currentUser: app.UserViewModel) {
        if (currentUser.language === "en") {
            moment.locale("en-gb");
        } else {
            moment.locale(currentUser.language);
        }
    }
}
