import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class HollenCompanyService
    extends HttpService<app.organization.HollenCompanyListViewModel, app.organization.HollenCompanyFormViewModel, app.organization.HollenCompanyInsertUpdateViewModel>
{
    objectKey = "org.HollenCompany";

    constructor(http: HttpClient) {
        super(http, "/api/organization/hollencompany/");
    }

    getCompanies(searchTerm: string): Observable<dotnet.KeyValuePair[]> {
        return this.http.get<dotnet.KeyValuePair[]>(this.url + "companies", {
            params: this.getParameters({ searchTerm })
        });
    }

    getSelectList(): Observable<app.organization.HollenCompanySelectListViewModel[]> {
        let params = new HttpParams();

        return this.http.get<app.organization.HollenCompanySelectListViewModel[]>(this.url + "list", { params });
    }
}
