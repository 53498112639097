import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class ShiftPlanService
    extends HttpService<app.operation.ShiftPlanListViewModel, app.operation.ShiftPlanFormViewModel, app.operation.ShiftPlanInsertUpdateViewModel>
{
    objectKey = "op.ShiftPlan";

    constructor(http: HttpClient) {
        super(http, "/api/operation/shiftplan/");
    }

    getList(page?: number, pageSize?: number, searchTerm?: string): Observable<app.operation.ShiftPlanListViewModel[]> {
        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        if (page) {
            params = params.set("page", page.toString());
        }
        if (pageSize) {
            params.set("pageSize", pageSize.toString());
        }
        return this.http.get<app.operation.ShiftPlanListViewModel[]>(this.url + "list", { params });
    }

    getCalendar(from: Date, firstProjectTaskId?: number, workPlaceId?: number, majorProviderCompanyId?: number): Observable<app.operation.ShiftPlanCalendarViewModel> {
        let params = this.getParameters({
            from,
            firstProjectTaskId,
            workPlaceId,
            majorProviderCompanyId
        });
        return this.http.get<app.operation.ShiftPlanCalendarViewModel>(this.url + "calendar", { params });
    }

    getCalendarRow(from: Date, employeeId: number, firstProjectTaskId?: number, workPlaceId?: number): Observable<app.operation.ShiftPlanCalendarRowViewModel> {
        let params = this.getParameters({
            from,
            employeeId,
            firstProjectTaskId,
            workPlaceId
        });
        return this.http.get<app.operation.ShiftPlanCalendarRowViewModel>(this.url + "calendarrow", { params });
    }

    insertCalendar(item: app.operation.ShiftPlanCalendarInsertViewModel): Observable<app.operation.ShiftPlanCalendarInsertViewModel> {
        return this.http.post<app.operation.ShiftPlanCalendarInsertViewModel>(this.url + "calendar", item);
    }

    updateCalendar(item: app.operation.ShiftPlanCalendarItemViewModel): Observable<any> {
        return this.http.put(this.url + "calendar", item);
    }

    deleteCalendar(ids: number[]): Observable<any> {
        return this.http.put(this.url + "calendardelete", ids);
    }

    getOverview(from: Date, accountCenterCodes: string, employeeId?: number, workPlaceId?: number, agencyCompanyId?: number, majorProviderId?: number): Observable<app.operation.ShiftPlanOverviewViewModel> {
        let params = this.getParameters({
            from,
            accountCenterCodes,
            employeeId,
            workPlaceId,
            agencyCompanyId,
            majorProviderId
        });
        return this.http.get<app.operation.ShiftPlanOverviewViewModel>(this.url + "overview", { params });
    }

    getOverviewDay(from: Date, accountCenterCodes: string, employeeId?: number, workPlaceId?: number, islandCode?: string, agencyCompanyId?: number, majorProviderId?: number): Observable<app.operation.ShiftPlanOverviewViewModel> {
        let params = this.getParameters({
            from,
            accountCenterCodes,
            employeeId,
            workPlaceId,
            islandCode,
            agencyCompanyId,
            majorProviderId
        });
        return this.http.get<app.operation.ShiftPlanOverviewViewModel>(this.url + "overview-day", { params });
    }

    getCalendarDay(from: Date, firstProjectTaskId?: number, workPlaceId?: number, agencyCompanyId?: number): Observable<app.operation.ShiftPlanCalendarDayViewModel[]> {
        let params = this.getParameters({
            from,
            firstProjectTaskId,
            workPlaceId,
            agencyCompanyId
        });
        return this.http.get<app.operation.ShiftPlanCalendarDayViewModel[]>(this.url + "calendar-day", { params });
    }




    sendPlansToMajorProvider(request: app.operation.ShiftPlanToAgencyRequestViewModel): Observable<string> {
        return this.http.post(this.url + "sendtomajorprovider", request, { responseType: "text" });
    }

    prepareSms(sms: app.operation.SelectedEmployeesSmsViewModel[]): Observable<app.operation.SmsDispatchingViewModel[]> {
        return this.http.put<app.operation.SmsDispatchingViewModel[]>(this.url + "preparesms",sms);
    }

    sendSms(sms: app.operation.SmsDispatchingViewModel[]): Observable<string> {
        return this.http.post(this.url + "sendsms", sms, { responseType: "text" });
    }

    getFilter(): Observable<app.operation.ShiftPlanFilterViewModel> {
        return this.http.get<app.operation.ShiftPlanFilterViewModel>(this.url + "filter");
    }
}
