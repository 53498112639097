<div *ngIf="!simple" class="af-file-button" [class.show-progress]="showProgress" [class.success]="isSuccessful" [class.active]="isUploading">
    <input afFileSelect #afFileSelect style="display: none;"
           type="file"
           (upload)="onUpload($event)"
           [options]="options"
           [multiple]="multiple"
           [id]="name"
           [accept]="accept"
           [name]="name" />
    <button type="button" class="btn btn-primary" [disabled]="isUploading || disabled" (click)="afFileSelect.click()">
        <label class="af-file-button-label">
            {{buttonText}}
            <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
        </label>
    </button>
    <div class="af-file-progress btn">
        <div class="fill" [style.width]="progress">
        </div>
        <span>{{ progress }}</span>
    </div>
</div>
<ng-container *ngIf="simple">
    <input afFileSelect #afFileSelect style="display: none;"
           type="file"
           (upload)="onUpload($event)"
           [options]="options"
           [multiple]="multiple"
           [id]="name"
           [accept]="accept"
           [name]="name" />
    <a [ngClass]="styleClass" [title]="title" (click)="afFileSelect.click()" [class.active]="isUploading" [class.disabled]="isUploading || disabled">
        <ng-container *ngIf="!isUploading">
            <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
        </ng-container>
        <ng-container *ngIf="isUploading">
            {{ 'hollen.clientapp.src.app.shared.components.file-upload.file-upload.component.uploading...' | translate }}
        </ng-container>
    </a>
</ng-container>
<ng-template #contentTpl><ng-content></ng-content></ng-template>
