<div class="card">
    <div class="card-header">{{ item.title }}</div>
    <div class="card-block card-report form-horizontal">
        <div>
            <div *ngIf="item.reports">
                <ng-container *ngFor="let entry of item.reports">
                    <div class="row form-group">
                        <div class="col-md-7 control-label">
                            {{entry.title}} <i [ngbPopover]="entry.description" class="fa fa-info-circle fa-primary" *ngIf="entry.description"></i>
                        </div>
                        <div class="col-md-5">
                            <div class="form-check">
                                <input type="radio"
                                       class="form-check-input"
                                        [name]="'ReportId' + item.id"
                                        [value]="entry.reportId"
                                        (change)="onReportSelected(entry)">
                                <label class="form-check-label"></label>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="entry.parameters && selectedReport && entry.reportId === selectedReport.reportId">
                        <ng-container *ngFor="let param of entry.parameters; let $index = index">
                            <div class="row form-group" *ngIf="param.isVisible">
                                <div class="col-md-7 control-label">
                                    {{ param.label }}
                                </div>
                                <div class="col-md-5">
                                    <ng-container [ngSwitch]="param.typeName">
                                        <ng-container *ngSwitchCase="'Boolean'">
                                            <af-checkbox [(ngModel)]="param.value" [name]="'Item' + $index"></af-checkbox>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'DateTime'">
                                            <af-datepicker [(ngModel)]="param.value" [name]="'Item' + $index"></af-datepicker>
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            <input *ngIf="!param.availableValues || param.availableValues.length === 0" type="text" [(ngModel)]="param.value" class="form-control" [name]="'Item' + $index" />
                                            <select *ngIf="param.availableValues && param.availableValues.length > 0" [(ngModel)]="param.value" [name]="'Item' + $index" class="form-control form-select">
                                            <option *ngIf="!param.isRequired" [ngValue]="null"></option>
                                                <option *ngFor="let availableValue of param.availableValues" [ngValue]="availableValue.value">{{availableValue.title}}</option>
                                        </select>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
            <div class="row form-group">
                <div class="col-md-12">
                    <a [href]="getUrl()" [class.disabled]="!params.employeeId || !selectedReport" class="btn btn-default-alt btn-generate btn-large-icon" title="{{ 'btn.generate' | translate }}"><i class="fa fa-download"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
