import { Component, EventEmitter } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { AppService } from "../../app.service";
import { AccommodationTermService } from "./accommodation-term.service";

enum DisplayMode {
    Create = 'create',
    Edit = 'edit',
}

@Component({
    selector: "op-accommodation-term",
    templateUrl: "./accommodation-term.component.html"
})
export class AccommodationTermComponent {

    collectionChanged = new EventEmitter<any>();
    edit = new ReplaySubject<{ accommodationTermId: number }>(1);
    objectKey: string;
    displayMode: DisplayMode;
    employeeId: number;

    constructor(private accommodationTermService: AccommodationTermService,
        private appService: AppService) {
        this.objectKey = accommodationTermService.objectKey;
        this.employeeId = +this.appService.getParam("employeeId");
        this.displayMode = DisplayMode.Create;
    }

    onInsert(event: app.operation.AccommodationTermInsertUpdateViewModel) {
        this.displayMode = DisplayMode.Create;
        this.collectionChanged.emit();
    }

    onEditClicked(event: app.operation.AccommodationTermInsertUpdateViewModel) {
        this.displayMode = DisplayMode.Edit;
        let id = { accommodationTermId: event.accommodationTermId };
        this.edit.next(id);
    }

    onUpdate() {
        this.displayMode = DisplayMode.Create;
        this.collectionChanged.emit();
    }

    onCancel() {
        this.displayMode = DisplayMode.Create;
    }
}
