import { Component } from "@angular/core";
import { Routes } from "@angular/router";

import { PresentationItemCreateComponent } from "./presentation-item-create.component";
import { PresentationItemEditComponent } from "./presentation-item-edit.component";
import { PresentationItemListComponent } from "./presentation-item-list.component";

export const presentationItemRoutes: Routes = [
    {
        path: "presentation-item",
        children: [
            {
                path: "",
                component: PresentationItemListComponent
            }
        ]
    }
];

export const presentationItemRoutedComponents = [
    PresentationItemCreateComponent,
    PresentationItemEditComponent,
    PresentationItemListComponent
];
