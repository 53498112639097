import { DOCUMENT } from "@angular/common";
import {
    Component,
    Directive,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
    Injector,
    Renderer2,
    ComponentRef,
    ElementRef,
    TemplateRef,
    ViewContainerRef,
    ComponentFactoryResolver,
    NgZone,
    Inject,
    ChangeDetectorRef,
    ApplicationRef,
    ViewEncapsulation,
    SimpleChanges,
    OnChanges
} from "@angular/core";

import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { ngbAutoClose } from "../utils/autoclose";
import { PopupService } from "../utils/popup.service";
import { ngbPositioning, PlacementArray } from "../utils/positioning";
import { addPopperOffset } from "../utils/positioning-util";
import { listenToTriggers } from "../utils/triggers";

/* tslint:disable */
@Component({
    selector: "af-confirm-click-window",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class]': '"popover" + (popoverClass ? " " + popoverClass : "")',
        '[class.fade]': 'animation',
        'role': 'tooltip',
        '[id]': 'id'
    },
    template: `
    <div class="popover-arrow" data-popper-arrow></div>
    <h3 class="popover-title">
        <div [innerHtml]="message ? message : ('confirm.are you sure you want to delete this record?' | translate)"></div>
    </h3>
    <div class="popover-content">
        <div>
            <button class="btn btn-sm btn-danger" (click)="confirm()" type="button">
                {{ yes ? yes : ('confirm.delete.delete' | translate) }}
            </button>
            <button class="btn btn-sm btn-default" (click)="close()" type="button">
                {{ no ? no : ('confirm.delete.cancel' | translate) }}
            </button>
        </div>
    </div>
    `
})
export class ConfirmClickWindow {
    @Input() animation: boolean;
    @Input() id: string;
    @Input() popoverClass: string;

    @Input() yes: string;
    @Input() no: string;
    @Input() message: string;
    @Output() confirmed = new EventEmitter();
    @Output() closed = new EventEmitter();

    confirm() {
        this.confirmed.emit({});
    }

    close() {
        this.closed.emit({});
    }
}

let nextId = 0;

/* tslint:enable */

@Directive({ selector: "[afConfirmClick]", exportAs: "afConfirmClick" })
export class ConfirmClickDirective implements OnInit, OnDestroy, OnChanges {
    static ngAcceptInputType_autoClose: boolean | string;

    @Input() afConfirmClick: string | TemplateRef<any>;
    @Input() popoverTitle: string;
    @Input() placement: any = "auto";
    @Input() container: string;
    @Input() triggers: string;
    @Output() shown = new EventEmitter();
    @Output() hidden = new EventEmitter();
    @Output() confirmed = new EventEmitter();
    @Input() message: string;
    @Input() yes: string;
    @Input() no: string;
    @Input() openDelay: number;
    @Input() closeDelay: number;
    @Input() animation: boolean;
    @Input() autoClose: boolean | 'inside' | 'outside';
    @Input() disablePopover: boolean;
    @Input() popoverClass: string;
    @Input() ngbPopover: string | TemplateRef<any> | null | undefined;

    private _ngbPopoverWindowId = `ngb-popover-confirm-${nextId++}`;
    private _popupService: PopupService<ConfirmClickWindow>;
    private _windowRef: ComponentRef<ConfirmClickWindow> | null = null;
    private _unregisterListenersFn;
    private _positioning = ngbPositioning();
    private _zoneSubscription: Subscription;
    private _isDisabled(): boolean {
        if (this.disablePopover) {
            return true;
        }
        if (!this.ngbPopover && !this.popoverTitle && !this.message) {
            return true;
        }
        return false;
    }

    constructor(
        private _elementRef: ElementRef<HTMLElement>, private _renderer: Renderer2, injector: Injector,
        viewContainerRef: ViewContainerRef, config: NgbPopoverConfig, private _ngZone: NgZone,
        @Inject(DOCUMENT) private _document: any, private _changeDetector: ChangeDetectorRef,
        applicationRef: ApplicationRef
    ) {
        this.animation = config.animation;
        this.autoClose = config.autoClose;
        this.triggers = config.triggers;
        this.container = config.container;
        this.disablePopover = config.disablePopover;
        this.popoverClass = config.popoverClass;
        this.openDelay = config.openDelay;
        this.closeDelay = config.closeDelay;

        this.container = "body";
        this.animation = false;

        this._popupService = new PopupService<ConfirmClickWindow>(ConfirmClickWindow, injector, viewContainerRef, _renderer, this._ngZone, applicationRef);
    }

    open() {
        if (!this._windowRef && !this._isDisabled()) {
            const { windowRef, transition$ } = this._popupService.open();
            this._windowRef = windowRef;
            this._windowRef.instance.animation = this.animation;
            this._windowRef.instance.message = this.message;
            this._windowRef.instance.yes = this.yes;
            this._windowRef.instance.no = this.no;
            this._windowRef.instance.popoverClass = this.popoverClass;
            this._windowRef.instance.id = this._ngbPopoverWindowId;

            this._windowRef.instance.confirmed.subscribe(() => {
                this.confirm();
            });
            this._windowRef.instance.closed.subscribe(() => {
                this.close();
            });
            if (this.container === "body") {
                window.document.querySelector(this.container).appendChild(this._windowRef.location.nativeElement);
            }

            // We need to detect changes, because we don't know where .open() might be called from.
            // Ex. opening popover from one of lifecycle hooks that run after the CD
            // (say from ngAfterViewInit) will result in 'ExpressionHasChanged' exception
            this._windowRef.changeDetectorRef.detectChanges();

            // We need to mark for check, because popover won't work inside the OnPush component.
            // Ex. when we use expression like `{{ popover.isOpen() : 'opened' : 'closed' }}`
            // inside the template of an OnPush component and we change the popover from
            // open -> closed, the expression in question won't be updated unless we explicitly
            // mark the parent component to be checked.
            this._windowRef.changeDetectorRef.markForCheck();

            // Setting up popper and scheduling updates when zone is stable
            this._ngZone.runOutsideAngular(() => {
                this._positioning.createPopper({
                    hostElement: this._elementRef.nativeElement,
                    targetElement: this._windowRef!.location.nativeElement,
                    placement: this.placement,
                    appendToBody: this.container === 'body',
                    baseClass: 'bs-popover',
                    updatePopperOptions: addPopperOffset([0, 8]),
                });

                Promise.resolve().then(() => {
                    // This update is required for correct arrow placement
                    this._positioning.update();
                    this._zoneSubscription = this._ngZone.onStable.subscribe(() => this._positioning.update());
                });
            });

            ngbAutoClose(
                this._ngZone, this._document, this.autoClose, () => this.close(), this.hidden,
                [this._windowRef.location.nativeElement]);

            transition$.subscribe(() => this.shown.emit());
        }
    }

    close(animation = this.animation) {
        if (this._windowRef) {
            this._renderer.removeAttribute(this._elementRef.nativeElement, 'aria-describedby');
            this._popupService.close(animation).subscribe(() => {
                this._windowRef = null;
                this._positioning.destroy();
                this._zoneSubscription?.unsubscribe();
                this.hidden.emit();
                this._changeDetector.markForCheck();
            });
        }
    }

    confirm(animation = this.animation) {
        if (this._windowRef) {
            this._renderer.removeAttribute(this._elementRef.nativeElement, 'aria-describedby');
            let result = this._popupService.close(animation);
            result.subscribe(() => {
                this._windowRef = null;
                this._positioning.destroy();
                this._zoneSubscription?.unsubscribe();
                this.confirmed.emit();
                this._changeDetector.markForCheck();
            });
        }
    }

    toggle(): void {
        if (this._windowRef) {
            this.close();
        } else {
            this.open();
        }
    }

    isOpen(): boolean {
        return this._windowRef != null;
    }

    ngOnInit() {
        this._unregisterListenersFn = listenToTriggers(
            this._renderer, this._elementRef.nativeElement, this.triggers, this.isOpen.bind(this), this.open.bind(this),
            this.close.bind(this), +this.openDelay, +this.closeDelay);
    }

    ngOnChanges({ ngbPopover, popoverTitle, disablePopover, popoverClass }: SimpleChanges) {
        if (popoverClass && this.isOpen()) {
            this._windowRef!.instance.popoverClass = popoverClass.currentValue;
        }
        // close popover if title and content become empty, or disablePopover set to true
        if ((ngbPopover || popoverTitle || disablePopover) && this._isDisabled()) {
            this.close();
        }
    }

    ngOnDestroy() {
        this.close(false);
        this._unregisterListenersFn?.();
    }
}
