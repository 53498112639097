
import { of as observableOf, Observable } from "rxjs";
import { PreloadingStrategy, Route } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()
export class PreloadSelectedModulesList implements PreloadingStrategy {
    preload(route: Route, load: Function): Observable<any> {
        return route.data && route.data["preload"] ? load() : observableOf(null);
    }
}
