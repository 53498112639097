import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { ListQueryParams } from "../../models/list-query-params.model";
import { AppService } from "../../app.service";
import { DialogService, PermissionService, TableBaseComponent } from "../../core";
import { finalize, switchMap } from "rxjs/operators";
import { InventoryService } from "../inventory-tabs/inventory/inventory.service";
import { forkJoin as observableForkJoin,Observable, of } from "rxjs";
import { filterAndDebounce } from "../../core/utils";
import { CodebookService } from "../../codebook/codebook/codebook.service";
import { InventoryTransferService } from "../inventory-tabs/inventory-transfer/inventory-transfer.service";
import { InventoryCheckService } from "../inventory-tabs/inventory-check/inventory-check.service";
import { NgForm } from "@angular/forms";
import { EmployeeService } from "./employee.service";

class QueryParams extends ListQueryParams {
    accountCenterCode: string;
    employeeId: number;
    employeeName: string;
    searchTerm: string;
    status: number = null;
    hollenCompanyCode: string = null;
    onlyITInventory: boolean = null;
    categoryId: number;
    categoryTitle: string;
}

class QueryTransferParams extends ListQueryParams {
    hollenCompanyCode: string = null;
    createdBy: number = null;
    employeeId: number = null;
    transferType: number = null;
    onlyITInventory: boolean = null;
    IsFinishedRequest: boolean = null;
    startDate?: Date = null;
    endDat?: Date = null;
    createdByName: string = null;
}

class QuerySelfCheckParams extends ListQueryParams {
    accountCenterCode: string = null;
    status: number = null;
}

@Component({
    selector: "op-employee-inventory",
    templateUrl: "./employee-inventory.component.html"
})
export class EmployeeInventoryComponent extends TableBaseComponent implements OnInit {
    @ViewChild(NgForm, { static: false }) updateSelfCheckForm: NgForm;
    queryParams = new QueryParams();
    queryTransferParams = new QueryTransferParams();
    querySelfCheckParams = new QuerySelfCheckParams();
    filter: app.operation.InventoryListFilterViewModel;
    filterTransfer: app.operation.InventoryTransferListFilterViewModel;
    hollenCompaniesWithoutConcern: app.organization.HollenCompanySelectListViewModel[];
    employeeId: number;
    objectKey: string;
    model: PagedList<app.operation.InventoryListViewModel>;
    isLoading: boolean;
    sortBy: string;
    currentUser: app.UserViewModel;
    hasUpdatePermission = false;
    selectedCategory: app.codebook.CodebookSelectViewModel;
    transferModel: PagedList<app.operation.InventoryTransferListViewModel>;
    isUserPage = false; //If transfer will be only for Account / My settings
    checkModel: app.operation.InventorySelfCheckFormViewModel;
    selfcheckPage = false;
    employees: app.operation.EmployeeSelectListViewModel[];
    usageTypes: dotnet.KeyValuePair[];
    selectedCreatedBy: af.usersystem.UserSelectListViewModel;
    constructor(router: Router,
        private route: ActivatedRoute,
        private inventoryService: InventoryService,
        private inventoryTransferService: InventoryTransferService,
        private inventoryCheckService: InventoryCheckService,
        private employeeService: EmployeeService,
        private appService: AppService,
        private codebookService: CodebookService,
        private permissionService: PermissionService,
        private dialogService: DialogService) {
        super(router);
        this.queryParams.sortBy = this.sortBy = "title";
        this.queryTransferParams.sortBy = this.sortBy = "isFinishedRequest";
        this.objectKey = inventoryService.objectKey;
        this.currentUser = appService.homeViewModel.user;
        this.hasUpdatePermission = this.permissionService.hasPermission(this.objectKey, "Update");
    }

    ngOnInit() {
        this.onReset();
        if (this.route.snapshot.data && this.route.snapshot.data.currentUser) {
            this.employeeId = this.currentUser.employeeId;
            this.queryTransferParams.employeeId = this.currentUser.employeeId;
            this.queryTransferParams.transferType = 1;
            this.isUserPage = true;
        }
        else {
            this.employeeId = +this.route.parent.snapshot.params["employeeId"];
            this.queryTransferParams.employeeId = this.employeeId;
            this.queryTransferParams.transferType = 1;
            this.isUserPage = false;
        }
       
        observableForkJoin(
            this.inventoryService.getFilter(),
            this.inventoryTransferService.getFilter(),
            this.employeeService.getList(undefined,undefined, undefined, undefined, undefined, undefined, undefined,true)
        ).subscribe((result) => {
            this.filter = result[0];
            this.usageTypes = result[0].usageTypes;
            this.hollenCompaniesWithoutConcern = result[0].hollenCompanies.filter(this.hollenCompaniesWithoutConc);
            this.loadData();
            this.filterTransfer = result[1];
            this.loadTransferData();
            this.employees = result[2];
        });

        if (this.isUserPage) {
            this.loadCheckData();
        }

        if (this.hasPreviousState) {
            if (this.queryParams.categoryId) {
                this.selectedCategory = <app.codebook.CodebookSelectViewModel>{
                    id: this.queryParams.categoryId,
                    title: this.queryParams.categoryTitle
                };
            }
            if (this.queryTransferParams.createdBy) {
                this.selectedCreatedBy = <af.usersystem.UserSelectListViewModel>{
                    userId: this.queryTransferParams.createdBy,
                    fullName: this.queryTransferParams.createdByName
                };
            }
        }     
    }

    onReset() {
        this.queryParams.status = 1; //only active inventory
        this.queryTransferParams.createdBy = null;
        this.queryTransferParams.createdByName = null;
        this.selectedCreatedBy = null;
    }
    onClear() {
        this.onReset(); 
    }

    loadData() {
        this.queryParams.employeeId = this.employeeId;

        if (this.selectedCategory) {
            this.queryParams.categoryId = this.selectedCategory.id;
            this.queryParams.categoryTitle = this.selectedCategory.title;
        } else {
            this.queryParams.categoryId = null;
            this.queryParams.categoryTitle = null;
        }

        this.isLoading = true;

        this.inventoryService.query(this.queryParams)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.model = res;
            });
    }

    loadTransferData() {
        this.isLoading = true;

        if (this.selectedCreatedBy) {
            this.queryTransferParams.createdBy = this.selectedCreatedBy.userId;
            this.queryTransferParams.createdByName = this.selectedCreatedBy.fullName;
        } else {
            this.queryTransferParams.createdBy = null;
            this.queryTransferParams.createdByName = null;
        }


        this.inventoryTransferService.query(this.queryTransferParams)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.transferModel = res;
            });
    }

    loadCheckData() {
        new Promise(resolve => {
            if (this.isSelfCheckDirty()) {          
                resolve(this.dialogService.confirm());
            } else {    
                resolve(true);
            }
        }).then(isOkToChange => {
            if (isOkToChange) {
                this.isLoading = true; 
                this.inventoryCheckService.getSelfCheckForm(this.employeeId, this.querySelfCheckParams)
                    .pipe(finalize(() => this.isLoading = false))
                    .subscribe(res => {
                        this.checkModel = res;
                    });
            }
        });
    }

    isSelfCheckDirty() {
        if (this.updateSelfCheckForm && this.updateSelfCheckForm.controls) {
            if (this.updateSelfCheckForm.control.dirty) return true;

            for (let prop in this.updateSelfCheckForm.controls) {
                if (this.updateSelfCheckForm.controls[prop].dirty) {
                    return true;
                }
            }
        }

        return false;
    }

    hollenCompaniesWithoutConc(hollenCompany: any) {
        return hollenCompany.code.substring(0, 1) !== "X";
    }

    categoryAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(term => term.length < 2 ? of([]) : this.codebookService.getSelectList("inventoryCategory", 1, 20, term, true)));
    }

    categoryFormatter = (taskObject: app.codebook.CodebookSelectViewModel) => taskObject.title;

    selfcheck() {
        this.selfcheckPage = true;
    }

    chooseAnswer(item: app.operation.InventorySelfCheckItemViewModel, value: number) {
        this.updateSelfCheckForm.control.markAsDirty()       
        item.answer = value;
    }

    get isAllAnswered() {
        return this.checkModel.items.findIndex(f => f.answer == null) == -1;
    }

    lastInventoryStateTitle(key: number) {
        let status = this.checkModel.checkItemStatuses.find(f => f.key == key)?.value ?? "";
        return status;
    }

    saveSelfCheck(closeAfter: boolean = false)
    {
        let updateModel = <app.operation.InventorySelfCheckUpdateViewModel>{
            employeeId: this.checkModel.employeeId,
            items: this.checkModel.items
        };

        this.inventoryCheckService.saveSelfCheckForm(this.employeeId, updateModel)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                if (closeAfter) {
                    this.selfcheckPage = false;
                    this.checkModel.items.forEach(x => x.answer = 0);
                }       
            });
    }

    getEmployeeName(employeeId: number) {
        if (this.employees) {
            let employee = this.employees.find(f => f.employeeId == employeeId);
            if (employee) return employee.employeeName;
        }

        return "";
    }

    sort(column: string) {
        if (this.querySelfCheckParams.sortField() === column) {
            this.querySelfCheckParams.setSortOrder(
                this.querySelfCheckParams.sortOrder() === "desc" ? null : "desc"
            );
        } else {
            this.querySelfCheckParams.sortBy = column;
        }
        this.loadCheckData();
    }

    isSorted(column: string, order: string) {
        return (
            this.queryParams.sortField() === column &&
            this.queryParams.sortOrder() === order
        );
    }

    paginate(event) {
        this.querySelfCheckParams.page = event.page;
        this.querySelfCheckParams.pageSize = event.pageSize;
        this.loadCheckData();
    }


    getRowClass(item: app.operation.InventoryCheckItemViewModel) {
        return { "table-warning": item.usingEmployeeIsArchived };
    }

    onClearSelfCheck() {     
        this.onResetSelfCheck();
    }
    onResetSelfCheck() {
        this.querySelfCheckParams.accountCenterCode = null;
        this.querySelfCheckParams.searchTerm = null;
    }

    userAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(searchTerm => searchTerm.length < 2
                    ? of([])
                    : this.employeeService.getUserListByRequest(<app.usersystem.UserSelectListRequest>{
                        searchTerm
                    })));
    }

    userFormatter = (user: af.usersystem.UserSelectListViewModel) => user.fullName;
}
