import { Injectable } from "@angular/core";

@Injectable()
export class AuthConfig {
    public tokenEndpoint = "/core/connect/token";
    public loginPath = "/login";
    public accountUrl = "/api/usersystem/account/";
    public homeUrl = "/api/home";
    public loginUrl = "/api/login";
    public headerName = "Authorization";
    public headerPrefix = "Bearer ";
    public tokenName = "ls.token";
    public tokenExpName = "ls.token_expire";
    public defaultUrl = "/operation/project";
}
