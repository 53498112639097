import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class TechnicianChecklistService
    extends HttpService<app.codebook.CodebookListViewModel, app.codebook.CodebookFormViewModel, dotnet.KeyValuePair[]>
{
    objectKey: string;
    baseUrl = "/api/codebook/technicianchecklist/";

    constructor(http: HttpClient) {
        super(http, null);
    }

    getPagedList(parameters?: any): Observable<PagedList<app.codebook.TechnicianChecklistListViewModel>> {

        return this.http.get<PagedList<app.codebook.TechnicianChecklistListViewModel>>(this.baseUrl + "custom", {
            params: this.getParameters(parameters)
        });
    }

    getNew(parameters?: any) {
        return this.http.get<app.codebook.TechnicianChecklistFormViewModel>(this.baseUrl + "new", {
            params: this.getParameters(parameters)
        });
    }

    getFilter(): Observable<app.codebook.TechnicianChecklistListFilterViewModel> {
        return this.http.get<app.codebook.TechnicianChecklistListFilterViewModel>(this.baseUrl + "customfilter");
    }

    updateOrderlist(parentId: number, orderlist: app.codebook.TechnicianChecklistListViewModel[]) {
        return this.http.put(this.baseUrl + "list-order/" + parentId, orderlist);
    }

    private getList(url: string, page?: number, pageSize?: number,
        searchTerm?: string, onlyPossibleParents?: boolean): Observable<app.codebook.TechnicianChecklistSelectViewModel[]> {
        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        if (page) {
            params = params.set("page", page.toString());
        }
        if (pageSize) {
            params = params.set("pageSize", pageSize.toString());
        }
        if (onlyPossibleParents) {
            params = params.set("onlyPossibleParents", onlyPossibleParents.toString());
        }
        return this.http.get<app.codebook.TechnicianChecklistSelectViewModel[]>(url + "customlist", { params });
    }

    getCustomPossibleParentList(page?: number, pageSize?: number, searchTerm?: string): Observable<app.codebook.TechnicianChecklistSelectViewModel[]> {
        return this.getList(this.baseUrl, page, pageSize, searchTerm, true);
    }
}
