import { HostListener, Injectable } from "@angular/core";

import { Subject, Observable } from "rxjs";

export class ScreenEventArgs {
    width: number;
    height: number;
}

@Injectable()
export class ScreenService {
    resize$: Observable<ScreenEventArgs>;

    private resizeSource = new Subject<ScreenEventArgs>();
    private largeBreakpoint = 800;
    private screenWidth = 1000;
    private screenHeight = 800;

    constructor() {
        this.resize$ = this.resizeSource.asObservable();

        try {
            this.screenWidth = window.innerWidth;
            this.screenHeight = window.innerHeight;
            window.addEventListener("resize", (event) => this.onResize(event));
        } catch (e) {
            // we"re going with default screen dimensions
        }
    }

    get isLarge(): boolean {
        return this.screenWidth >= this.largeBreakpoint;
    }

    onResize($event): void {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;

        let args = new ScreenEventArgs();
        args.height = this.screenHeight;
        args.width = this.screenWidth;
        this.resizeSource.next(args);
    }

}