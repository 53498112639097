import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store } from "@ngrx/store";

import { AuthService } from "../../auth/auth.service";
import { AppService } from "../../app.service";
import { MenuService } from "./menu.service";
import { State } from "../../store/reducers";
import * as appActions from "../../store/actions/app";
import { take, switchMap, tap } from "rxjs/operators";

export interface IHomeResolver { }

@Injectable()
export class HomeResolver implements Resolve<IHomeResolver> {
    constructor(private authService: AuthService,
        private store: Store<State>,
        private menuService: MenuService) {
    }

    resolve(): Observable<any> {
        return this.getHome(false);
    }

    resolveForce(): Observable<any> {
        return this.getHome(true);
    }

    getHome(force: boolean): Observable<any> {
        return this.store.select(x => x.app.homeViewModel)
            .pipe(
                take(1),
                switchMap(model => {
                    if (!force) {
                        if (model) {
                            return of(model);
                        }
                    }
                    return this.authService.getHome().pipe(tap(res => {
                        this.authService.initHome(res);
                    }));
                }));
    }
}
