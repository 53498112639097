
<div class="modal-header">
    <h4 class="modal-title">{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-modal.component.set plan' | translate }}</h4>
</div>
<div class="modal-body">
    <div class="form-group" *ngIf="!isEditable">
        <label>
            {{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-modal.component.plan on' | translate }}
            <af-help class="help-static" helpid="shiftplan.modal.plan.on"></af-help>
        </label>
        <input class="form-control" type="text" [(ngModel)]="title" [disabled]="true" />
    </div>
    <div class="form-group" *ngIf="isEditable">
        <label>{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-modal.component.projecttask' | translate }}</label>
        <af-help helpid="shiftplan.modal.projecttask"></af-help>
        <div class="input-group">
            <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
            <input type="text" class="form-control" [disabled]="selectedWorkPlace" [(ngModel)]="selectedProjectTask" [editable]="false" [ngbTypeahead]="projectTaskAutocomplete" [resultFormatter]="projectTaskFormatter" [inputFormatter]="projectTaskFormatter" />
        </div>
    </div>
    <div class="form-group" *ngIf="isEditable">
        <label>{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-modal.component.workplace' | translate }}</label>
        <af-help helpid="shiftplan.modal.workplace"></af-help>
        <div class="input-group">
            <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
            <input type="text" name="FirstProjectTaskId" class="form-control" [disabled]="selectedProjectTask" [(ngModel)]="selectedWorkPlace" [editable]="false" [ngbTypeahead]="workPlaceAutocomplete" [resultFormatter]="workPlaceFormatter" [inputFormatter]="workPlaceFormatter" />
        </div>
    </div>
    <div class="form-group" style="flex-direction: column">
        <label>{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-modal.component.type' | translate }}</label>
        <af-help helpid="shiftplan.modal.type"></af-help>
        <div class="btn-group btn-group-toggle shift-type-group">
            <label class="btn btn-default" ngbButtonLabel [title]="shiftType.shiftTypeTitle" *ngFor="let shiftType of shiftTypes">
                <input type="radio" [value]="shiftType.shiftTypeId" [(ngModel)]="model.shiftTypeId" (ngModelChange)="shiftTypeChanged($event)" />
                <span class="icomoon-shifttype-{{ shiftType.shiftTypeAbbreviation }}"></span>
            </label>
        </div>
    </div>
    <div *ngIf="isSliderVisible">
        <label class="mb-4">{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-modal.component.hours count' | translate }}: <span class="badge rounded-pill bg-success">{{ totalHours | number }}</span></label>
        <af-help helpid="shiftplan.modal.workplace"></af-help>
        <br />
        <div>
            <nouislider [config]="sliderOptions" [(ngModel)]="timeRange" (change)="sliderChanged();" name="Slider"></nouislider>
        </div>
    </div>
    <br />
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-modal.component.note' | translate }}</label>
        <af-help helpid="shiftplan.modal.note"></af-help>
        <textarea class="form-control" rows="5" [(ngModel)]="model.note"></textarea>
    </div>
</div>
<div class="modal-footer">
    <button *ngIf="!isEditable" class="btn btn-primary" type="button" (click)="ok()" [disabled]="isSubmittingCalendar">{{ 'btn.insert' | translate }}</button>
    <button *ngIf="isEditable" class="btn btn-update btn-primary" type="button" (click)="ok()" [disabled]="isSubmittingCalendar">{{ 'btn.save' | translate }}</button>
    <button *ngIf="isEditable" afConfirmClick (confirmed)="delete();" class="btn btn-delete btn-danger" type="button" message="{{ 'confirm .are you sure you want to delete this record?' | translate }}" yes="{{ 'confirm.delete .delete' | translate }}" no="{{ 'confirm.delete .cancel' | translate }}">{{ 'btn.delete' | translate }}</button>
    <button class="btn btn-warning" type="button" (click)="cancel()">{{ 'confirm.delete.cancel' | translate }}</button>
</div>
