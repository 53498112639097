<ng-container *ngIf="isUserPage && !selfcheckPage">
    <div class="d-flex  flex-wrap justify-content-between align-items-start">
        <div></div>
        <div class="d-flex flex-wrap" style="align-items: center;">
            <span *ngIf="checkModel" style="margin-right:5px;">{{ 'hollen.clientapp.src.app.operation.employee.employee-inventory.component.last self check inventory date' | translate }}: {{checkModel.lastCheckDateTime | afDate:'DD.MM.YY HH:mm'}} </span>
            <button type="button" class="ml-1 btn btn-fit-height btn-success" (click)="selfcheck()">
                {{ 'btn.inventory.list.check. self inventory check' | translate }}
            </button>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="selfcheckPage && checkModel">
    <div class="form">
    <form (ngSubmit)="saveSelfCheck(false)" name="updateSelfCheckForm" novalidate="" #updateSelfCheckForm="ngForm" [class.disabled-all]="isLoading">
        <div class="form-horizontal">
            <div class="form-body">
                <h3 class="form-section mb-3">{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-self-check.component.self inventory check' | translate }}</h3>

                <af-page-filter *ngIf="filter" [loading]="isLoading" [params]="querySelfCheckParams" (filter)="loadCheckData()" (reset)="onResetSelfCheck()" (clear)="onClearSelfCheck()">
                    <div class="form-group">
                        <label>{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-self-check.component.keyword' | translate }}</label>
                        <input type="text" class="form-control" name="searchtherm" [(ngModel)]="querySelfCheckParams.searchTerm" />
                        <af-help helpid="inventory.check.filter.searchTerm"></af-help>
                    </div>
                    <div class="form-group">
                        <label>{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-self-check.component.account center' | translate }}</label>
                        <select class="form-control form-select" name="accountCenterCode" [(ngModel)]="querySelfCheckParams.accountCenterCode">
                            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-self-check.component.all' | translate }}</option>
                            <option *ngFor="let accountCenter of filter.accountCenters" [ngValue]="accountCenter.code">{{accountCenter.title}}</option>
                        </select>
                    </div>
                </af-page-filter>

                <div *ngIf="!isLoading && !!checkModel && !!checkModel.items">
                    <div *ngIf="checkModel.totalCount > 0">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-count">
                                    {{ 'table.record count_' | translate }} {{checkModel.totalCount + (checkModel.totalCount >= 500 ? '+' : '')}}
                                </div>
                            </div>
                        </div> 
                        <div class="table-wrapper">
                            <af-paginator [pageSize]="checkModel.pageSize" [page]="checkModel.page" [totalCount]="checkModel.totalCount"
                                          styleClass="table-paginator-bottom" (pageChange)="paginate($event)"></af-paginator>
                            <table class="table table-bordered table-hover af-table mt-3 mb-3">
                                <thead>
                                    <tr>
                                        <th class="sortable" width="80px" (click)="sort('inventoryNumber')" [ngClass]="{
                                            'sort-asc': isSorted('inventoryNumber', null),
                                            'sort-desc': isSorted('inventoryNumber', 'desc')}">
                                            <div class="sort-indicator">
                                                {{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-self-check.component.inventory number' | translate }}
                                            </div>
                                        </th>
                                        <th width="200px" class="sortable" (click)="sort('title')"
                                            [ngClass]="{
                                            'sort-asc': isSorted('title', null),
                                            'sort-desc': isSorted('title', 'desc')}">
                                            <div class="sort-indicator">
                                                {{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-self-check.component.inventory' | translate }}
                                            </div>
                                        </th>
                                        <th width="40px" class="sortable" (click)="sort('accountCenterCode')"
                                            [ngClass]="{
                                            'sort-asc': isSorted('accountCenterCode', null),
                                            'sort-desc': isSorted('accountCenterCode', 'desc')}">
                                            <div class="sort-indicator">
                                                {{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-self-check.component.ac' | translate }}
                                            </div>
                                        </th>

                                        <th width="250px" class="sortable" (click)="sort('physicalLocation')"
                                            [ngClass]="{
                                            'sort-asc': isSorted('physicalLocation', null),
                                            'sort-desc': isSorted('physicalLocation', 'desc')}">
                                            <div class="sort-indicator">
                                                {{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-self-check.component.physical location' | translate }}
                                            </div>
                                        </th>
                                        <th width="300px" class="sortable" (click)="sort('usingEmployeeId')"
                                            [ngClass]="{
                                            'sort-asc': isSorted('usingEmployeeId', null),
                                            'sort-desc': isSorted('usingEmployeeId', 'desc')}">
                                            <div class="sort-indicator">
                                                {{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-self-check.component.is using' | translate }}
                                            </div>
                                        </th>
                                        <th class="sortable" (click)="sort('usageType')"
                                            [ngClass]=" {
                                            'sort-asc' : isSorted('usageType', null),
                                            'sort-desc' : isSorted('usageType', 'desc' )}">
                                            <div class="sort-indicator">
                                                {{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-self-check.component.usage type' | translate }}
                                            </div>
                                        </th>
                                        <th style="min-width:300px">{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-self-check.component.note' | translate }}</th>
                                        <th width="100px" class="sortable" (click)="sort('lastInventoryCheckDate')"
                                            [ngClass]="{
                                            'sort-asc': isSorted('lastInventoryCheckDate', null),
                                            'sort-desc': isSorted('lastInventoryCheckDate', 'desc')}">
                                            <div class="sort-indicator">
                                                {{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-self-check.component.last self check' | translate }}
                                            </div>
                                        </th>
                                        <th class="sortable" (click)="sort('lastInventoryCheckState')"
                                            [ngClass]="{
                                            'sort-asc': isSorted('lastInventoryCheckState', null),
                                            'sort-desc': isSorted('lastInventoryCheckState', 'desc')}">
                                            <div class="sort-indicator">
                                                {{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-self-check.component.last state' | translate }}
                                            </div>
                                        </th>
                                        <th>
                                            {{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-self-check.component.state' | translate }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor=" let item of checkModel.items ;let $itemIndex = index">
                                        <tr [ngClass]="getRowClass(item)">
                                            <td>[{{item.inventoryNumber}}]</td>
                                            <td><a [routerLink]="['/operation/inventory-main', item.inventoryId]" target="_blank">{{item.title}}</a></td>
                                            <td>{{item.accountCenterCode}}</td>
                                            <td>
                                                <input [name]="'PhysicalLocation' + $itemIndex" class="form-control" [(ngModel)]="item.physicalLocation" />
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <select [name]="'UsingEmployeeId' + $itemIndex" class="form-control form-select" [(ngModel)]="item.usingEmployeeId">
                                                        <option *ngFor="let employee of employees" class="{{employee.hasHintAccess ? 'employee-access' : 'employee-noaccess'}}" [ngValue]="employee.employeeId">
                                                            <ng-container *ngIf="employee.hasHintAccess; else noAccess">
                                                                {{employee.employeeName}}
                                                            </ng-container>
                                                            <ng-template #noAccess>
                                                                {{employee.employeeName}} {{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-self-check.component.- does not have access to hint_' | translate }}
                                                            </ng-template>
                                                        </option>

                                                    </select>
                                                    <ng-container *ngIf="item.usingEmployeeId">
                                                        <a class="btn btn-info"
                                                           [routerLink]="['/operation/inventory-main/']"
                                                           [queryParams]="
                                                        {
                                                            employeeId: item.usingEmployeeId,
                                                            employeeName: getEmployeeName(item.usingEmployeeId),
                                                            accountCenterCode: item.accountCenterCode,
                                                            status: null
                                                        }">
                                                            <i class="fa fa-external-link"></i>
                                                        </a>
                                                    </ng-container>
                                                </div>
                                            </td>
                                            <td>
                                                <select [name]="'UsageType' + $itemIndex" class="form-control form-select" [(ngModel)]="item.usageType">
                                                    <option *ngFor="let item of usageTypes" [ngValue]="item.key">
                                                        {{item.value}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <textarea class="form-control" [name]="'checknote' + $itemIndex" [(ngModel)]="item.checkNote" rows="3"></textarea>
                                            </td>
                                            <td>{{item.lastInventoryCheckDate | afDate:'DD.MM.YY HH:mm'}}</td>
                                            <td>
                                                <span [ngClass]="item.lastInventoryCheckState == 1 ? 'label label-success' : 'label label-warning'">
                                                    {{lastInventoryStateTitle(item.lastInventoryCheckState)}}
                                                </span>
                                            </td>
                                            <td>
                                                <ng-container *ngFor="let status of checkModel.checkItemStatuses;let $statusIndex = index">
                                                    <ng-container *ngIf="status.key > 0">
                                                        <button style="margin-left:20px" type="button"
                                                                [ngClass]="{'btn-selected': ($statusIndex == item.answer) && (item.answer > 2),
                                                                'btn-success': ($statusIndex == item.answer) && (item.answer === 1),
                                                                'btn-warning': ($statusIndex == item.answer) && (item.answer === 2),
                                                                'btn-default': ($statusIndex != item.answer) }"
                                                                class="btn mb-1"
                                                                (click)="chooseAnswer(item, $statusIndex)">
                                                            {{status.value}}
                                                        </button>
                                                    </ng-container>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            <af-paginator [pageSize]="checkModel.pageSize" [page]="checkModel.page" [totalCount]="checkModel.totalCount"
                                          styleClass="table-paginator-bottom" (pageChange)="paginate($event)"></af-paginator>
                        </div>
                    </div>
                </div>

            </div>
            <div class="form-actions">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="offset-md-1 col-md-11">
                                <button class="btn btn-insert btn-primary" type="submit">{{ 'btn.save' | translate }}</button>
                                <button class="btn btn-insert btn-primary" (click)="saveSelfCheck(true)">{{ 'btn.save and close' | translate }}</button>
                                <a class="btn-back btn-warning btn" onclick="history.back()">{{ 'btn.back' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
    
</ng-container>     
 
<ng-container *ngIf="!selfcheckPage">
    <h3 class="form-section">{{ 'hollen.clientapp.src.app.operation.employee.employee-inventory.component.inventory transfers' | translate }}</h3>
    <af-page-filter *ngIf="filterTransfer" [control]="afTable" [loading]="isLoading" [params]="queryTransferParams" (filter)="loadTransferData()" (reset)="onReset()" (clear)="onClear()">
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-transfer.component.keyword' | translate }}</label>
        <input type="text" class="form-control" [(ngModel)]="queryTransferParams.searchTerm" />
        <af-help helpid="inventory.list.filter.searchTerm"></af-help>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-transfer.component.hollen company' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryTransferParams.hollenCompanyCode">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-transfer.component.all' | translate }}</option>
            <option *ngFor="let hollenCompany of hollenCompaniesWithoutConcern" [ngValue]="hollenCompany.code">{{hollenCompany.title}}</option>
        </select>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-transfer.component.it inventory' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryTransferParams.onlyITInventory">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-transfer.component.all' | translate }}</option>
            <option [ngValue]="true">{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-transfer.component.yes' | translate }}</option>
            <option [ngValue]="false">{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-transfer.component.no' | translate }}</option>
        </select>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-transfer.component.transfer state' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryTransferParams.isFinishedRequest">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-transfer.component.all' | translate }}</option>
            <option [ngValue]="true">{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-transfer.component.finished' | translate }}</option>
            <option [ngValue]="false">{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-transfer.component.ongoing' | translate }}</option>
        </select>
    </div>
    <div class="form-group ac-group">
        <label>{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-transfer.component.create by' | translate }}</label>
        <div class="input-group" style="width: 400px;">
            <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
            <input type="text" name="CreatedBy" class="form-control"
                   [(ngModel)]="selectedCreatedBy"
                   [editable]="false"
                   [ngbTypeahead]="userAutocomplete"
                   [resultFormatter]="userFormatter"
                   [inputFormatter]="userFormatter" />
        </div>
    </div>
    <div class="filter-group">
        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-transfer.component.from' | translate }}</label>
            <af-datepicker [(ngModel)]="queryTransferParams.startDate" name="startDate"></af-datepicker>
        </div>
        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.employee._employee-inventory-transfer.component.to' | translate }}</label>
            <af-datepicker [(ngModel)]="queryTransferParams.endDate" name="endDate"></af-datepicker>
        </div>
    </div>

</af-page-filter>

<af-table #afTable [value]="transferModel" [loading]="isLoading" [params]="queryTransferParams" [sortBy]="sortBy" (load)="loadTransferData()">
    <af-column field="inventoryTransferNumber" sortable="inventoryTransferNumber" header="{{ 'hollen.operation.inventorytransferlistviewmodel.number' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <span>
                <a [routerLink]="['/operation/inventory-main/transfer', item.inventoryTransferId ]" style="margin-right: 3px;">{{item.inventoryTransferNumber}}</a>
            </span>
        </ng-template>
    </af-column>
    <af-column field="createdUtc" sortable="createdUtc" header="{{ 'hollen.operation.inventorytransferlistviewmodel.created at' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.createdUtc | afDate : 'L LTS' : true }}
        </ng-template>
    </af-column>
    <af-column field="transferToEmployeeFullName" sortable="transferToEmployeeFullName" header="{{ 'hollen.operation.inventorytransferlistviewmodel.target' | translate }}"></af-column>
    <af-column field="createdFullName" sortable="createdFullName" header="{{ 'hollen.operation.inventorytransferlistviewmodel.created' | translate }}"></af-column>
    <af-column field="statusTitle" sortable="isFinishedRequest" header="{{ 'hollen.operation.inventorytransferlistviewmodel.status' | translate }}"></af-column>
    <af-column field="transferTypeText" sortable="transferType" header="{{ 'hollen.operation.inventorytransferlistviewmodel.type' | translate }}"></af-column>

</af-table>


    <h3 class="form-section">{{ 'hollen.clientapp.src.app.operation.employee.employee-inventory.component.inventory' | translate }}</h3>

    <af-page-filter *ngIf="filter" [control]="afTable" [loading]="isLoading" [params]="queryParams" (filter)="loadData()" (reset)="onReset()" (clear)="onClear()">
        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.employee.employee-inventory.component.keyword' | translate }}</label>
            <input type="text" class="form-control" [(ngModel)]="queryParams.searchTerm" />
            <af-help helpid="inventory.list.filter.searchTerm"></af-help>
        </div>

        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.employee.employee-inventory.component.hollen company' | translate }}</label>
            <select class="form-control form-select" [(ngModel)]="queryParams.hollenCompanyCode">
                <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee.employee-inventory.component.all' | translate }}</option>
                <option *ngFor="let hollenCompany of hollenCompaniesWithoutConcern" [ngValue]="hollenCompany.code">{{hollenCompany.title}}</option>
            </select>
        </div>

        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.employee.employee-inventory.component.account center' | translate }}</label>
            <select class="form-control form-select" [(ngModel)]="queryParams.accountCenterCode">
                <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee.employee-inventory.component.all' | translate }}</option>
                <option *ngFor="let accountCenter of filter.accountCenters" [ngValue]="accountCenter.code">{{accountCenter.title}}</option>
            </select>
        </div>
        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.employee.employee-inventory.component.status' | translate }}</label>
            <select class="form-control form-select" [(ngModel)]="queryParams.status">
                <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee.employee-inventory.component.all' | translate }}</option>
                <option *ngFor="let status of filter.statuses" [ngValue]="status.key">{{status.value}}</option>
            </select>
        </div>
        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.employee.employee-inventory.component.it inventory' | translate }}</label>
            <select class="form-control form-select" [(ngModel)]="queryParams.onlyITInventory">
                <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee.employee-inventory.component.all' | translate }}</option>
                <option [ngValue]="true">{{ 'hollen.clientapp.src.app.operation.employee.employee-inventory.component.yes' | translate }}</option>
                <option [ngValue]="false">{{ 'hollen.clientapp.src.app.operation.employee.employee-inventory.component.no' | translate }}</option>
            </select>
        </div>

        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.employee.employee-inventory.component.category' | translate }}</label>
            <div class="input-group">
                <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
                <input type="text" name="CategoryId" class="form-control"
                       [(ngModel)]="selectedCategory"
                       [editable]="false"
                       [ngbTypeahead]="categoryAutocomplete"
                       [resultFormatter]="categoryFormatter"
                       [inputFormatter]="categoryFormatter" />
            </div>
            <af-help helpid="inventory.list.filter.Category"></af-help>
        </div>
    </af-page-filter>

    <af-table #afTable [value]="model" [loading]="isLoading" [params]="queryParams" [sortBy]="sortBy" (load)="loadData()">
        <af-column field="title" sortable="title" header="{{ 'hollen.operation.inventorylistviewmodel.title' | translate }}">
            <ng-template let-item="rowData" afTemplate>
                <span *afHasPerm="{obj:'op.itinventory', perm: 'Update'}">
                    <a [routerLink]="['/operation/inventory-main/it-inventory', item.inventoryId]" class="fa fa-keyboard" *ngIf="item.isItInventory" style="margin-right: 3px;"></a>
                </span>
                <ng-container *ngIf="hasUpdatePermission">
                    <a [routerLink]="['/operation/inventory-main', item.inventoryId]">{{item.title}}</a>
                </ng-container>
                <span *ngIf="!hasUpdatePermission">{{item.title}}</span>
            </ng-template>
        </af-column>
        <af-column field="titleEn" sortable="titleEn" header="{{ 'hollen.operation.inventorylistviewmodel.title in english' | translate }}"></af-column>
        <af-column field="titleDe" sortable="titleDe" header="{{ 'hollen.operation.inventorylistviewmodel.title in deutsch' | translate }}"></af-column>
        <af-column field="inventoryNumber" sortable="inventoryNumber" header="{{ 'hollen.operation.inventorylistviewmodel.inventory number' | translate }}"></af-column>
        <af-column field="categoryTitle" sortable="categoryTitle" header="{{ 'hollen.operation.inventorylistviewmodel.category' | translate }}"></af-column>
        <af-column field="count" sortable="count" header="{{ 'hollen.operation.inventorylistviewmodel.count' | translate }}"></af-column>
        <af-column field="description" sortable="description" header="{{ 'hollen.operation.inventorylistviewmodel.description' | translate }}"></af-column>
        <af-column field="accountCenterCode" sortable="accountCenterCode" header="{{ 'hollen.operation.inventorylistviewmodel.account center' | translate }}"></af-column>
    </af-table>
</ng-container>
