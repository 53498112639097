<af-spinner [loading]="isLoading"></af-spinner>

<div *ngIf="!isLoading && !!model">
    <a (click)="onHide()" class="hidden-print"><i class="fa fa-minus-square"></i></a>

    <div class="row">
        <div class="mt-3 col-9">
            <b>{{getTitle()}}</b>
        </div>
        <div class="col-3 float-end" *ngIf="projectTask">
            <button class="btn btn-sm btn-update btn-primary float-end mt-2 mb-2" type="button" (click)="sendToMajorProvider()">
                {{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table-day.component.send plans to major provider' | translate }}<i class="fa fa-paper-plane" style="padding-left:7px" aria-hidden="true"></i>
            </button>
        </div>
    </div>

    <span *ngIf="showLink && this.projectTask" class="hidden-print">
        - <a routerLink="/operation/employee/shiftplans" [queryParams]="{ firstProjectTaskId: this.projectTask.firstProjectTaskId }">{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table-day.component.plan' | translate }}</a>
    </span>
    <span *ngIf="showLink && this.workPlace" class="hidden-print">
        - <a routerLink="/operation/employee/shiftplans" [queryParams]="{ workPlaceId: this.workPlace.workPlaceId }">{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table-day.component.plan' | translate }}</a>
    </span>
    <div *ngIf="model.length > 0">
        <div class="dynamic-table">
            <ng-container *ngFor="let shifType of checkedShiftTypes; let $odd = odd; trackBy: shifType?.shifTypeId">
                <div class="dynamic-column" [ngClass]="{'dynamic-column-odd': $odd, 'dynamic-column-long': shifType.shiftTypeAbbreviation === 'CUSTOM'}">
                    <div class="dynamic-header">
                        <span class="icomoon-shifttype-{{ shifType.shiftTypeAbbreviation }}" style="font-size:22px;"></span>
                    </div>
                    <div class="dynamic-body">
                        <div class="dynamic-row" *ngFor="let item of getByShiftType(shifType.shiftTypeId); trackBy: item?.employeeId">
                            <a *ngIf="item && item.employeeId" [routerLink]="['/operation/employee', item.employeeId ]" [title]="item.fullName">
                                {{ item.fullName }} 
                                <b class="overview-day-time" *ngIf="shifType.shiftTypeAbbreviation === 'CUSTOM'">{{ item.fromDateTime | afDate: "HH:mm" }} - {{ item.toDateTime | afDate: "HH:mm" }}</b>
                            </a><b *ngIf="item && item.isFromAgency">(A)</b><b *ngIf="item && item.isFromMajorProvider">(VP)</b>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="dynamic-column dynamic-column-long" *ngIf="!isAllChecked" [ngClass]="{'dynamic-column-odd': checkedShiftTypes.length % 2 !== 0}">
                <div class="dynamic-header">
                    {{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table-day.component.other mix' | translate }}
                </div>
                <div class="dynamic-body">
                    <div class="dynamic-row" *ngFor="let item of getUncheckedSorted(); trackBy: item?.employeeId">
                        <a *ngIf="item && item.employeeId" [routerLink]="['/operation/employee', item.employeeId ]">
                            {{ item.fullName }} <span class="overview-day-time">{{ item.fromDateTime | afDate: "HH:mm" }} - {{ item.toDateTime | afDate: "HH:mm" }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="model.length === 0">
        <af-note type="info" size="small" style="margin: 0;">
            {{ 'table.no records found' | translate }}
        </af-note>
    </div>
</div>
