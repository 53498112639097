import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { Observable, of } from "rxjs";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AppService } from "../../app.service";

import { EmployeeTripService } from "./employee-trip.service";
import { EmployeeService } from "../../operation/employee/employee.service";
import { ProjectTaskService } from "../../operation/project-task/project-task.service";
import { FormComponent } from "../../core";
import { finalize, switchMap } from "rxjs/operators";
import { filterAndDebounce } from "../../core/utils";
import { KeyValue } from "@angular/common";

@Component({
    selector: "op-employee-trip-create",
    templateUrl: "./employee-trip-create.component.html"
})
export class EmployeeTripCreateComponent extends FormComponent {
    @Input() employeeId: number;
    @Output() inserted = new EventEmitter<app.operation.EmployeeTripInsertUpdateViewModel>();

    isLoading: boolean;
    @ViewChild(NgForm, { static: true }) createEmployeeTripForm: NgForm;
    employeeTrip = <app.operation.EmployeeTripInsertUpdateViewModel>{};
    employeeTripNew = <app.operation.EmployeeTripInsertUpdateViewModel>{};
    employeeTripKeepValues = <app.operation.EmployeeTripInsertUpdateViewModel>{};
    selectedEmployee: app.operation.EmployeeSelectListViewModel;
    selectedProjectTask: app.operation.ProjectTaskSelectListViewModel;
    tripDestinations: app.operation.TripDestinationListViewModel[];
    objectKey: string;
    departureTimeList: dotnet.KeyValuePair[];
    returnTimeList: dotnet.KeyValuePair[];
    currentUser: app.UserViewModel;
    disableProjectTask: boolean = true;
    selectedEmployeeCountryCode: string;
    paramId: number;
    modelName: string;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private employeeTripService: EmployeeTripService,
        private employeeService: EmployeeService,
        private projectTaskService: ProjectTaskService,
        private appService: AppService,

    ) {
        super(router, "employeeTrip");

        this.objectKey = employeeTripService.objectKey;
    }

    ngOnInit() {
        super.ngOnInit();

        this.isLoading = true;
        this.paramId = +this.appService.getParam("employeeId");

        this.employeeTripService.getNew().subscribe((result) => {

            this.departureTimeList = result.departureTime;
            this.returnTimeList = result.returnTime;
            this.tripDestinations = result.destinations;

            this.employeeTripNew = Object.assign(<app.operation.EmployeeTripInsertUpdateViewModel>{}, result.model);

            if (this.hasPreviousState) {
                if (this.employeeTrip.employeeId && this.employeeTrip.employeeName) {
                    this.selectedEmployee = <app.operation.EmployeeSelectListViewModel>{
                        employeeId: this.employeeTrip.employeeId,
                        employeeName: this.employeeTrip.employeeName,
                        countryCode: this.employeeTrip.countryCode,
                    }
                    if (this.employeeTrip.projectTaskId != null) {
                        this.selectedProjectTask = <app.operation.ProjectTaskSelectListViewModel>{
                            firstProjectTaskId: this.employeeTrip.projectTaskId,
                            projectTaskNumber: this.employeeTrip.projectTaskNumber,
                            title: this.employeeTrip.projectTaskTitle,
                        }
                    }
                }
            }
            else {
                this.employeeTrip = result.model;
                this.employeeTrip.returnDateTime = null;
                this.employeeTrip.plannedReturnDateTime = null;
            }
            let tripDestinationId = this.route.snapshot.queryParams.tripDestinationId;

            if (tripDestinationId) {
                this.employeeTrip.tripDestinationId = +tripDestinationId;
            }

            this.isLoading = false;
            if (this.paramId != null && this.paramId != 0) {

                this.selectedEmployee = <app.operation.EmployeeSelectListViewModel>{
                    employeeId: this.paramId,
                    employeeName: this.paramId.toString(),
                }
            }
        });

    }

    onSave() {
        if (this.selectedEmployee != null && this.employeeTrip != null) {
            this.employeeTrip.employeeId = this.selectedEmployee.employeeId;
            this.employeeTrip.employeeName = this.selectedEmployee.employeeName;
            this.employeeTrip.countryCode = this.selectedEmployeeCountryCode;
        }

        if (this.selectedProjectTask == null) {
            this.employeeTrip.projectTaskId = null;
            this.employeeTrip.projectTaskNumber = null;
            this.employeeTrip.projectTaskTitle = null;
        }
        else {
            this.employeeTrip.projectTaskId = this.selectedProjectTask.firstProjectTaskId;
            this.employeeTrip.projectTaskNumber = this.selectedProjectTask.projectTaskNumber;
            this.employeeTrip.projectTaskTitle = this.selectedProjectTask.title;
        }
    }

    insert(): void {
        if (this.createEmployeeTripForm.invalid || this.isLoading) {
            return;
        }

        this.isLoading = true;

        this.onSave();

        this.employeeTripService.insert(this.employeeTrip)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((result) => {

                if (this.employeeId) {
                    this.inserted.emit(result);
                    this.clearState(Object.assign(<app.operation.EmployeeTripInsertUpdateViewModel>{}, this.employeeTripNew));
                    this.employeeTrip.returnDateTime = null;
                    this.employeeTrip.plannedReturnDateTime = null;
                    this.selectedProjectTask = null;
                    //< app.operation.ProjectTaskSelectListViewModel > {
                    //firstProjectTaskId: null,
                    //projectTaskNumber: null,
                    //title: null,
                    //}
                }
                else {
                    this.router.navigate(["../", result.employeeTripId], { relativeTo: this.route, replaceUrl: true });
                    this.clearState();
                }
            });

    }

    employeeAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(searchTerm => searchTerm.length < 2
                    ? of([])
                    : this.employeeService.getListByRequest(<app.operation.EmployeeSelectListRequest>{
                        page: 1,
                        pageSize: 20,
                        searchTerm,
                        onlyB2b: true,
                        isActive: true
                    })));
    }

    employeeFormatter = (employee: app.operation.EmployeeSelectListViewModel) => employee.employeeName;

    projectTaskAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(term => term.length < 2 ? of([]) : this.projectTaskService.getList(1, 20, term, null, null, null, this.selectedEmployee.countryCode)));
    }

    projectTaskFormatter = (projectTask: app.operation.ProjectTaskSelectListViewModel) => (projectTask.projectTaskNumber + " - " + projectTask.title);

}
