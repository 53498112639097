import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class EmployeeAdvanceService
    extends HttpService<app.operation.EmployeeAdvanceListViewModel, app.operation.EmployeeAdvanceFormViewModel, app.operation.EmployeeAdvanceInsertUpdateViewModel>
{
    objectKey = "op.EmployeeAdvance";
    objectRFBKey = "op.EmployeeAdvance.RFB";
    objectKeyApprover = "op.EmployeeAdvanceApprover";
    constructor(http: HttpClient) {
        super(http, "/api/operation/employeeadvance/");
    }

    getFilter(): Observable<app.operation.EmployeeAdvanceFilterViewModel> {
        return this.http.get<app.operation.EmployeeAdvanceFilterViewModel>(this.url + "filter");
    }

    generateRFB(parameters?: any): Observable<app.operation.EmployeeAdvanceFileViewModel> {
        return this.http.get<app.operation.EmployeeAdvanceFileViewModel>(this.url + "rfb", {
            params: this.getParameters(parameters)
        });
    }

    approve(employeeAdvanceId: number): Observable<any> {
        return this.http.put(this.url + employeeAdvanceId + "/approve",null);
    }

    unapprove(employeeAdvanceId: number): Observable<any> {
        return this.http.put(this.url + employeeAdvanceId + "/unapprove", null);
    }

    pay(employeeAdvanceId: number, paymentType: number): Observable<any> {
        return this.http.put(this.url + employeeAdvanceId + "/pay/" + paymentType, null);
    }

    advanceRfbUrl() {
        return "/api/operation/employeeadvance/";
    }

    approveFiltered(query: app.operation.GetEmployeeAdvanceListRequest): Observable<any> {
        return this.http.put(this.url + "approveFiltered", query);
    }

    unapproveFiltered(query: app.operation.GetEmployeeAdvanceListRequest): Observable<any> {
        return this.http.put(this.url + "unapproveFiltered", query);
    }

}
