import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { HttpService } from "../../core";

@Injectable()
export class AccommodationService
    extends HttpService<app.operation.AccommodationListViewModel, app.operation.AccommodationFormViewModel, app.operation.AccommodationInsertUpdateViewModel>
{
    objectKey = "op.accommodation";

    constructor(http: HttpClient) {
        super(http, "/api/operation/accommodation/");
    }

    getTypeSelectList(id: number): Observable<app.operation.AccommodationTypeSelectListViewModel[]> {
        return this.http.get<app.operation.AccommodationTypeSelectListViewModel[]>(this.url + id + "/typeselectlist");
    }

    getDeletableType(id: number, typeId: number) {
        return this.http.get<boolean>(this.url + id + "/deletable/" + typeId);
    }
}
