import { RouterStateSnapshot } from "@angular/router";
import { Action } from "@ngrx/store";

import { type } from "../util";

export const ActionTypes = {
    NEW_VERSION_AVAILABLE: type("[App] New version available"),
    USER_AUTHENTICATED: type("[App] User Authenticated"),
    HOMEVIEWMODEL_LOADED: type("[App] HomeViewModel loaded"),
    LOGINVIEWMODEL_LOADED: type("[App] LoginViewModel loaded"),
    LANGUAGE_CHANGED: type("[App] Language changed"),
    ROUTE_CHANGED: type("[App] Route changed"),
    LOADING: type("[App] Loading"),
    THEME_CHANGED: type("[App] theme changed"),
};

export class NewVersionAvailableAction implements Action {
    type = ActionTypes.NEW_VERSION_AVAILABLE;
}

export class UserAuthenticatedAction implements Action {
    type = ActionTypes.USER_AUTHENTICATED;
    constructor(public payload: boolean) { }
}

export class HomeViewModelLoadedAction implements Action {
    type = ActionTypes.HOMEVIEWMODEL_LOADED;
    constructor(public payload: app.HomeViewModel) { }
}

export class LoginViewModelLoadedAction implements Action {
    type = ActionTypes.LOGINVIEWMODEL_LOADED;
    constructor(public payload: app.LoginViewModel) { }
}

export class LanguageChangedAction implements Action {
    type = ActionTypes.LANGUAGE_CHANGED;
    constructor(public payload: string) { }
}

export class RouteChangedAction implements Action {
    type = ActionTypes.ROUTE_CHANGED;
    constructor(public payload: RouterStateSnapshot) { }
}

export class LoadingAction implements Action {
    type = ActionTypes.LOADING;
    constructor(public payload: boolean) { }
}

export class ThemeChangedAction implements Action {
    type = ActionTypes.THEME_CHANGED;
    constructor(public payload: string) { }
}

export type Actions
    = NewVersionAvailableAction
    | UserAuthenticatedAction
    | HomeViewModelLoadedAction
    | LoginViewModelLoadedAction
    | LanguageChangedAction
    | RouteChangedAction
    | LoadingAction
    | ThemeChangedAction;
