
<div class="modal-header">
    <h4 class="modal-title">{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-sms-modal.component.bulk sms sending' | translate }}</h4>
</div>
<div class="modal-body" *ngIf="!model">
    <b>{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-sms-modal.component.loading...' | translate }}</b>
</div>
<div class="modal-body" *ngIf="model">
    <ng-container *ngFor="let item of model; let $index = index">
        <div class=row>
            <div class="col-2">
                {{item.employeeName}}
            </div>
            <div class="col-6">
                <div class="form-group">
                    <textarea class="form-control" rows="5" [(ngModel)]="item.text" required maxlength="765"></textarea>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <textarea class="form-control" rows="5" [(ngModel)]="item.additionalText" maxlength="160"></textarea>
                </div>
            </div>
            <div class="col-2">
                <button class="btn btn-danger" type="button" (click)="deleteItem($index)">{{ 'btn.remove' | translate }}</button>
            </div>
        </div>
    </ng-container>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" type="button" (click)="send()" >{{ 'btn.send' | translate }}</button>

    <button class="btn btn-warning" type="button" (click)="cancel()">{{ 'confirm.delete.cancel' | translate }}</button>
</div>
