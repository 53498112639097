import { map } from './map';
export function pluck(...properties) {
  const length = properties.length;
  if (length === 0) {
    throw new Error('list of properties cannot be empty.');
  }
  return source => map(plucker(properties, length))(source);
}
function plucker(props, length) {
  const mapper = x => {
    let currentProp = x;
    for (let i = 0; i < length; i++) {
      const p = currentProp[props[i]];
      if (typeof p !== 'undefined') {
        currentProp = p;
      } else {
        return undefined;
      }
    }
    return currentProp;
  };
  return mapper;
}
