import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { CodebookService } from "../codebook/codebook.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class ProductService extends CodebookService {
    objectKey: string;
    baseUrl = "/api/codebook/";
    codebookType = "product";

    constructor(http: HttpClient) {
        super(http);
        this.setCodebook(this.codebookType);
    }

    getProductSelect(id: number): Observable<app.codebook.ProductSelectListViewModel> {
        let url = this.baseUrl + this.codebookType + "/select/" + id.toString();

        return this.http.get<app.codebook.ProductSelectListViewModel>(url);
    }

    getProductSelectList(searchTerm: string, languageId?: string): Observable<app.codebook.ProductSelectListViewModel[]> {
        let url = this.baseUrl + this.codebookType + "/select";
        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        if (languageId) {
            params = params.set("languageId", languageId);
        }

        return this.http.get<app.codebook.ProductSelectListViewModel[]>(url, { params });
    }
}
