import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class AccountConverterService
    extends HttpService<app.codebook.CodebookListViewModel, app.codebook.CodebookFormViewModel, dotnet.KeyValuePair[]>
{
    objectKey: string;
    baseUrl = "/api/codebook/accountconverter/";

    constructor(http: HttpClient) {
        super(http, null);
    }

    getPagedList(parameters?: any): Observable<PagedList<app.codebook.CodebookListViewModel>> {

        return this.http.get<PagedList<app.codebook.CodebookListViewModel>>(this.baseUrl + "custom", {
            params: this.getParameters(parameters)
        });
    }

    getFilter(): Observable<app.codebook.AccountConverterListFilterViewModel> {
        return this.http.get<app.codebook.AccountConverterListFilterViewModel>(this.baseUrl + "customfilter");
    }
}
