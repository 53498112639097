
<div class="form">
    <form (ngSubmit)="insert()" name="createForm" novalidate="" #createForm="ngForm" [class.disabled-all]="isLoading">
        <div class="form-horizontal">
            <div class="form-body">
                <div class="row">
                    <div class="col-xl-6 col-lg-12">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="AdvanceType">{{ 'hollen.b2b.advancerequestinsertupdateviewmodel.advance type' | translate }}</label>
                            <div *ngFor="let type of advanceTypes" class="col-md-4">
                                <af-radio [value]="type.key" name="AdvanceType" [label]="type.value" [disabled]="isCopy"
                                          [ngModel]="model.advanceType"
                                          (ngModelChange)="onAdvanceTypeChange($event)">
                                </af-radio>
                            </div>
                            <div *ngIf="model.advanceType == 2" class="col-md-2">
                                max. {{maxAmount | number:'1.2-2'}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-6 col-lg-12">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="Description">{{ 'hollen.b2b.advancerequestinsertupdateviewmodel.description' | translate }}</label>
                            <div class="col-md-10">
                                <input class="form-control" [(ngModel)]="model.description" type="text" id="Description" name="Description" value="" />
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-12">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="Amount">{{ 'hollen.b2b.advancerequestinsertupdateviewmodel.amount' | translate }}</label>
                            <div class="col-md-2">
                                <input class="form-control" [(ngModel)]="model.amount" type="text" afNumber="" id="Amount" name="Amount" required="" value="" />
                                <span><span *ngIf="(createForm.controls['Amount']?.dirty || createForm.submitted) && createForm.controls['Amount']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.b2b.advancerequestinsertupdateviewmodel.amount'} }}</span>
<span *ngIf="(createForm.controls['Amount']?.dirty || createForm.submitted) && createForm.controls['Amount']?.hasError('number')" class="field-validation-error">{{ 'validator.the field {0} must be a number.' | translate:{'0': 'translate|hollen.b2b.advancerequestinsertupdateviewmodel.amount'} }}</span>
</span>
                            </div>
                            <label class="control-label col-md-2" for="Note">{{ 'hollen.b2b.advancerequestinsertupdateviewmodel.note' | translate }}</label>
                            <div class="col-md-6">
                                <input class="form-control" [(ngModel)]="model.note" type="text" id="Note" name="Note" value="" />
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-6 col-lg-12">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="MonthOfValidity">{{ 'hollen.b2b.advancerequestinsertupdateviewmodel.month of validity' | translate }}</label>
                            <div *ngFor="let month of allowedMonths; let i = index" class="col-md-4">
                                <af-radio [value]="month" name="MonthOfValidity" [label]="month.title"
                                          [disabled]="model.advanceType != 1"
                                          [ngModel]="model.monthOfValidity"
                                          [compareWith]="compareWithFn"
                                          (ngModelChange)="onMonthOfValidityChange($event, i)">
                                </af-radio>
                            </div>
                            <span></span>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-12">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="ProjectTask">{{ 'hollen.b2b.advancerequestinsertupdateviewmodel.project task number' | translate }}</label>
                            <div class="col-md-10">
                                <div class="input-group">
                                    <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
                                    <input type="text" name="projectTask" class="form-control"
                                           [(ngModel)]="model.projectTask"
                                           [editable]="false"
                                           [disabled]="model.advanceType == 2 || isCopy || model.monthOfValidity == null"
                                           [ngbTypeahead]="projectTaskAutocomplete"
                                           [resultFormatter]="projectTaskFormatter"
                                           [inputFormatter]="projectTaskFormatter" />
                                </div>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-actions">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="offset-md-1 col-md-11">
                                <button *afHasPerm="{obj:objectKey, perm: 'Insert'}" class="btn btn-insert btn-primary" type="submit">{{ 'btn.insert' | translate }}</button>
                                <button class="btn btn-update btn-warning" (click)="cancel()" type="button">{{ 'btn.cancel' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
