<ng-container *ngIf="reportData">
    <ng-container *ngIf="!['SP', 'SN', 'SV', 'CC', 'CS', 'CH'].includes(reportData.accountCenterCode)">
        <div class="col-md-6 mb-md-2" *ngIf="isVisible(-11)">
            <div class="card">
                <div class="card-header">{{ 'hollen.clientapp.src.app.operation.project-task.project-task-documents.component.working procedure' | translate }}</div>
                <div class="card-block form-horizontal">
                    <div>
                        <div class="row form-group">
                            <div class="col-md-3 control-label">
                                {{ 'hollen.clientapp.src.app.operation.project-task.project-task-documents.component.template' | translate }}
                            </div>
                            <div class="col-md-9">
                                <select class="form-control form-select" [(ngModel)]="workingProcedureTemplate">
                                    <option *ngFor="let template of reportData.workingProcedureTemplates" [ngValue]="template">{{template}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row form-group">
                            <div class="col-md-3 control-label">
                                {{ 'hollen.clientapp.src.app.operation.project-task.project-task-documents.component.export' | translate }}
                            </div>
                            <div class="col-md-9">
                                <a [href]="getWorkingProcedureUrl()" class="btn btn-primary">{{ 'hollen.clientapp.src.app.operation.project-task.project-task-documents.component.export' | translate }}</a>
                                <label *ngIf="reportData.isSecondPageVisible">
                                    <input type="checkbox" [(ngModel)]="workingProcedureSecondPage" />
                                    {{ 'hollen.clientapp.src.app.operation.project-task.project-task-documents.component.delete second page' | translate }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="reportData.isSupplementVisible">
                        <div class="row form-group">
                            <div class="col-md-3 control-label">
                                {{ 'hollen.clientapp.src.app.operation.project-task.project-task-documents.component.export supplement' | translate }}
                            </div>
                            <div class="col-md-9">
                                <select class="form-control form-select" [(ngModel)]="workingProcedureSupplementTemplate">
                                    <option *ngFor="let template of reportData.workingProcedureSupplementTemplates" [ngValue]="template">{{template}}</option>
                                </select>
                                <a [href]="getWorkingProcedureSupplementUrl()" class="btn btn-primary">{{ 'hollen.clientapp.src.app.operation.project-task.project-task-documents.component.export' | translate }}</a>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="reportData.workingProcedureFile">
                        <div class="row form-group">
                            <div class="col-md-3 control-label">
                                {{ 'hollen.clientapp.src.app.operation.project-task.project-task-documents.component.download' | translate }}
                            </div>
                            <div class="col-md-9">
                                <a [href]="reportData.workingProcedureFilePath">{{reportData.workingProcedureFile}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
