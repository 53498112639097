import { Component, Input, Output, EventEmitter } from "@angular/core";

import { MenuService } from "../../../core/services/menu.service";
import { MenuItem } from "../../../models/menu-item";

@Component({
    selector: "af-sub-menu",
    styleUrls: ["./sub-menu.component.scss"],
    templateUrl: "./sub-menu.component.html"
})
export class SubMenuComponent {
    @Input() items: MenuItem[];
    @Input() isOpen: boolean;
    @Input() isSidebarClosed: boolean;
    @Output() change = new EventEmitter();

    constructor(private menuService: MenuService) {
    }

    getUrl(item) {
        return this.menuService.getUrl(item);
    }

    onClick() {
        this.change.emit({});
    }
}
