import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { HttpService } from "../../../core/services/http.service";

@Injectable()
export class GroupService
    extends HttpService<app.crm.GroupListViewModel, app.crm.GroupFormViewModel, app.crm.GroupInsertUpdateViewModel>
{
    objectKey = "crm.Group";

    constructor(http: HttpClient) {
        super(http, "/api/crm/group/");
    }

    getDetail(params: any): Observable<PagedList<app.crm.CompanyListViewModel>> {
        return this.http.get<PagedList<app.crm.CompanyListViewModel>>(this.url + "detail", { params: this.getParameters(params) });
    }

    //getInvoiceReviewList(params: any): Observable<PagedList<app.finance.InvoiceReviewListViewModel>> {
    //    return this.http.get<PagedList<app.finance.InvoiceReviewListViewModel>>(this.url + "review-list", { params: this.getParameters(params) });
    //}

    //getDetail(groupId: number) {
    //    return this.http.get<app.crm.CompanyListViewModel>(this.url + "detail/" + groupId);
    //}

}

