import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "af-history",
    templateUrl: "./history.component.html",
    styles: [`
      :host { font-size: 0.8rem;  }
      .entity-history { padding: 10px; }
      .entity-history-item {
        margin-bottom: 10px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
      }
      .label { width: 70px; display: inline-block; margin-right: 3px; }
      .entity-history-header { margin-bottom: 3px; }
      .entity-history-changes { padding-left: 15px; }
      .entity-history-changes i { margin: 0 5px; }
      .entity-history-changes span { display: inline-block; }
      .entity-history-newvalue { font-weight: 600; margin-left: 3px; }
      .entity-history-entity-name { margin-bottom: 5px; }
      .entity-history-entity-name span { text-decoration: underline; }
    `]
})
export class HistoryComponent {
    @Input() list: PagedList<af.sys.EntityHistoryViewModel>;
    @Output() change: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    paginate($event) {
        this.change.next($event);
    }
}
