<ul class="nav nav-tabs" *ngIf="tabs && tabs.length > 0">
    <ng-container *ngFor="let tab of tabs">
        <li class="nav-item" *ngIf="tab.isVisible">
            <a [routerLink]="tab.url"
            class="nav-link"
            [class.active]="tab.isActive"
            [queryParamsHandling]="queryParamsHandling"
            (click)="setTab(tab)">
                {{tab.title}}
            </a>
        </li>
    </ng-container>
</ul>
