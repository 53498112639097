import { isArray } from '../util/isArray';
import { fromArray } from './fromArray';
import { OuterSubscriber } from '../OuterSubscriber';
import { subscribeToResult } from '../util/subscribeToResult';
export function race(...observables) {
  if (observables.length === 1) {
    if (isArray(observables[0])) {
      observables = observables[0];
    } else {
      return observables[0];
    }
  }
  return fromArray(observables, undefined).lift(new RaceOperator());
}
export class RaceOperator {
  call(subscriber, source) {
    return source.subscribe(new RaceSubscriber(subscriber));
  }
}
export class RaceSubscriber extends OuterSubscriber {
  constructor(destination) {
    super(destination);
    this.hasFirst = false;
    this.observables = [];
    this.subscriptions = [];
  }
  _next(observable) {
    this.observables.push(observable);
  }
  _complete() {
    const observables = this.observables;
    const len = observables.length;
    if (len === 0) {
      this.destination.complete();
    } else {
      for (let i = 0; i < len && !this.hasFirst; i++) {
        let observable = observables[i];
        let subscription = subscribeToResult(this, observable, observable, i);
        if (this.subscriptions) {
          this.subscriptions.push(subscription);
        }
        this.add(subscription);
      }
      this.observables = null;
    }
  }
  notifyNext(outerValue, innerValue, outerIndex, innerIndex, innerSub) {
    if (!this.hasFirst) {
      this.hasFirst = true;
      for (let i = 0; i < this.subscriptions.length; i++) {
        if (i !== outerIndex) {
          let subscription = this.subscriptions[i];
          subscription.unsubscribe();
          this.remove(subscription);
        }
      }
      this.subscriptions = null;
    }
    this.destination.next(innerValue);
  }
}
