import { Component, forwardRef, Input, Inject, LOCALE_ID } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormControl } from "@angular/forms";
import { NgbDateStruct, NgbDatepicker, NgbInputDatepicker } from "@ng-bootstrap/ng-bootstrap";

export const texts = {
    en: {
        today: "Today"
    },
    sk: {
        today: "Dnes"
    }
};

@Component({
    selector: "af-datepicker",
    styles: [`
        .custom-day {      
            text-align: center;
            padding: 0.185rem 0.25rem;
            border-radius: 0.25rem;
            display: inline-block;
            width: 2rem;
        }
        .bg-primary {
            color: #fff;
        }
        .dp-other-month {
            color: #ccc;
        }
        .ngb-dp-today {
            background-color: #f0ad4e;
            border-radius: 1rem;
            color: white;
        }
        af-timepicker ::ng-deep input {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    `],
    template: `
        <ng-template #customDay let-date="date" let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled" let-focused="focused">
          <span class="custom-day" [class.ngb-dp-today]="isToday(date)" [class.focused]="focused"
                [class.bg-primary]="selected" [class.dp-other-month]="date.month !== currentMonth" [class.text-muted]="disabled">
            {{ date.day }}
          </span>
        </ng-template>

        <ng-template #footerTemplate>
	        <hr class="my-0" />
	        <button class="btn btn-primary btn-sm m-2 float-end" (click)="onButtonChange($event);dp.close()">{{todayButtonName}}</button>
        </ng-template>

        <div class="input-group" style="flex-wrap:nowrap">
            <input class="form-control"
                    [disabled]="disabled"
                    [minDate]="_minDate"
                    [maxDate]="_maxDate"
                    [ngModel]="_date"
                    (ngModelChange)="onChange($event)"
                    [placeholder]="placeholder"
                    [dayTemplate]="customDay"
                    [required]="required"
	                [footerTemplate]="footerTemplate"
                    [placement]="placement"
                    container="body"
                    ngbDatepicker
                    #dp="ngbDatepicker">     
            <span class="input-group-text" (click)="disabled ? null : dp.toggle()"><i class="fa fa-calendar fa-regular"></i></span>
            <af-timepicker *ngIf="timepicker" [disabled]="disabled" [defaultTime]="defaultTime" [ngModel]="_jsDate" (ngModelChange)="_onChange($event)" [required]="required"></af-timepicker>
            <ng-content select="[buttons]"></ng-content>
        </div>
    `,
    //       <span class="input-group-text" (click)="open(dp)"><i class="fa fa-calendar fa-regular"></i></span>
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => DatePickerComponent) },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => DatePickerComponent), multi: true }
    ]
})

export class DatePickerComponent implements ControlValueAccessor {
    constructor(@Inject(LOCALE_ID) private locale: string) {      
    }

    @Input() disabled: boolean;
    @Input() required: boolean;
    @Input() placeholder = "";
    @Input() timepicker = false;
    @Input() defaultTime = "00:00:00";
    @Input() placement = "bottom-left";

    @Input() set minDate(value: Date) {
        this._jsMinDate = value;
        this._minDate = this.isDate(value) ? { year: value.getFullYear(), month: value.getMonth() + 1, day: value.getDate() } : null;
    }

    @Input() set maxDate(value: Date) {
        this._jsMaxDate = value;
        this._maxDate = this.isDate(value) ? { year: value.getFullYear(), month: value.getMonth() + 1, day: value.getDate() } : null;
    }

    _jsDate: Date;
    _jsMinDate: Date;
    _jsMaxDate: Date;
    _date: NgbDateStruct;
    _minDate: NgbDateStruct;
    _maxDate: NgbDateStruct;
    _currentDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    _onChange: (value: any) => void;

    isDate(value: any) {
        return value instanceof Date;
    }

    get todayButtonName(): string {
        return texts[this.locale].today;
    }

    writeValue(value: Date) {
        if (this.isDate(value)) {
            this._jsDate = value;
            this._date = { year: value.getFullYear(), month: value.getMonth() + 1, day: value.getDate() };
        }
        else {
            this._jsDate = null;
            this._date = null;
        }
    }

    onButtonChange() {
        this._date = this._currentDate;
        this._jsDate = new Date(this._currentDate.year, this._currentDate.month - 1, this._currentDate.day);
        this._onChange(this._jsDate);
    }

    onChange(event: NgbDateStruct) {  
        this._date = event;
        if (event && event.year && event.month && event.day) {
            if (this._jsDate) {
                this._jsDate = new Date(event.year,
                    event.month - 1,
                    event.day,
                    this._jsDate.getHours(),
                    this._jsDate.getMinutes(),
                    this._jsDate.getSeconds());
            } else {
                this._jsDate = new Date(event.year, event.month - 1, event.day);
                this._jsDate = this.setDefaultTime(this._jsDate);
            }
            this._onChange(this._jsDate);
        } else {
            if (this._jsDate) {
                this._jsDate = null;
                this._onChange(null);
            }
        }
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }

    registerOnTouched() { }

    isToday(date: NgbDateStruct) {
        let currentDate = new Date();
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();

        return date.year === year && date.month === month && date.day === day;
    }

    validate(control: UntypedFormControl): { [key: string]: any } {
        if (this._jsDate) {
            if (this._jsMinDate && this._jsMinDate > this._jsDate) {
                return { daterange: true, minDate: true };
            }
            if (this._jsMaxDate && this._jsMaxDate < this._jsDate) {
                return { daterange: true, maxDate: true };
            }
        }
        return null;
    }

    open(datePicker: NgbInputDatepicker) {
        if ((typeof (this.disabled) === "undefined") || this.disabled === false) {
            datePicker.toggle();
        }
    }

    setDefaultTime(date: Date) {
        if (this.defaultTime) {
            let hours = +this.defaultTime.substr(0, 2);
            let mins = +this.defaultTime.substr(3, 2);
            let secs = +this.defaultTime.substr(6, 2);
            date.setHours(hours);
            date.setMinutes(mins);
            date.setSeconds(secs);
        }
        return date;
    }
}
