
import { filter } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";
import { RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";

import { AppService } from "../../app.service";
import { State } from "../../store/reducers";
import * as menuActions from "../../store/actions/menu";
import { MenuItem } from "../../models/menu-item";

@Injectable()
export class MenuService {
    menu: MenuItem[];
    state: RouterStateSnapshot;

    constructor(private store: Store<State>) {
        this.store.select(x => x.app.routeChanged).subscribe(state => {
            if (state) {
                this.state = state;
            }
            this.updateMenu();
        });

        this.store.select(x => x.app.homeViewModel).pipe(
            filter(loaded => !!loaded))
            .subscribe(data => {
                this.menu = [...<any>data.menu];
                this.updateMenu();
            });
    }

    getMenu() {
        return this.menu;
    }

    getActiveItem(): MenuItem {
        if (!this.state) {
            return null;
        }

        let trimmedUrl = this.state.url;
        let queryStringIndex = trimmedUrl.indexOf("?");
        let url = queryStringIndex > -1 ? trimmedUrl.substring(0, queryStringIndex) : trimmedUrl;
        let params = this.getCurrentParams();
        return this.getActive(url, this.menu, params);
    }

    getCurrentParams(): any {
        let params = {};

        if (!this.state) {
            return params;
        }

        let item = this.state.root;

        while (item != null) {
            for (let property in item.params) {
                if (item.params.hasOwnProperty(property)) {
                    params[property] = item.params[property];
                }
            }
            item = item.children && item.children.length > 0 ? item.children[0] : null;
        }

        return params;
    }

    getActiveItemTitle(): string {
        let item = this.getActiveItem();
        if (item) {
            return item.title;
        }
        return null;
    }

    getChildren(id: number): MenuItem[] {
        let item = this.getItemByCategoryId(id, this.menu);
        return item.children;
    }

    getItem(id: number): MenuItem {
        return this.getItemByCategoryId(id, this.menu);
    }

    getUrl(item: MenuItem, params?: any) {
        if (!item.url || !this.shouldHaveUrl(item)) {
            return null;
        }

        let url = item.url;

        if (!params) {
            return url;
        }

        for (let name in params) {
            if (params.hasOwnProperty(name)) {
                if (url.indexOf(":" + name) !== -1) {
                    url = url.replace(":" + name, params[name]);
                }
            }
        }

        return url;
    }

    updateMenu() {
        if (!this.menu) {
            return;
        }

        this.resetActiveState(this.menu);
        this.updateActiveState(this.menu);

        this.store.dispatch(new menuActions.MenuUpdatedAction(this.menu));
    }

    updateActiveState(items: MenuItem[]) {
        let activeItem = this.getActiveItem();
        while (activeItem) {
            activeItem.isActive = activeItem.isOpen = true;
            activeItem = this.getItem(activeItem.parentId);
        }
    }

    resetActiveState(items: MenuItem[]) {
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            if (item.isActive || item.isOpen) {
                item.isActive = item.isOpen = false;
                this.resetActiveState(item.children);
            }
        }
    }

    toggleOpen(item: MenuItem) {
        if (item.isOpen) {
            item.isOpen = false;
        }
        else {
            this.hideItems(this.menu);
            let openItem = item;
            while (openItem) {
                openItem.isOpen = true;
                openItem = this.getItem(openItem.parentId);
            }
        }

        this.store.dispatch(new menuActions.MenuToggledAction(this.menu));
    }

    hideItems(items: MenuItem[]) {
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            item.isOpen = false;
            this.hideItems(item.children);
        }
    }

    private getActive(url: string, items: MenuItem[], params: any): MenuItem {
        if (!items || items.length === 0) {
            return null;
        }

        for (let i = 0; i < items.length; i++) {
            let item = items[i];

            let active = this.getActive(url, item.children, params);
            if (active) {
                return active;
            }

            let itemUrl = this.getUrl(item, params);

            if (itemUrl === url) {
                return item;
            }
        }

        return null;
    }

    private getItemByCategoryId(categoryId: number, items: MenuItem[]): MenuItem {
        if (!items || items.length === 0) {
            return null;
        }

        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            if (item.id === categoryId) {
                return item;
            }
            let found = this.getItemByCategoryId(categoryId, item.children);
            if (found) {
                return found;
            }
        }

        return null;
    }

    private shouldHaveUrl(item: MenuItem) {
        return !(item.type === "Menu" && item.hasSubMenu);
    }
}
