import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class RateContractService
    extends HttpService<app.finance.RateContractListViewModel, app.finance.RateContractFormViewModel, app.finance.RateContractInsertUpdateViewModel>
{
    objectKey = "fin.RateContract";

    constructor(http: HttpClient) {
        super(http, "/api/finance/ratecontract/");
    }

    getProjectRateContracts(accountCenterCode: string, companyId: number, workPlaceId?: number): Observable<app.finance.RateContractWrapperViewModel> {
        let params = new HttpParams();
        params = params.set("accountCenterCode", accountCenterCode);
        params = params.set("companyId", companyId.toString());

        if (workPlaceId) {
            params = params.set("workPlaceId", workPlaceId.toString());
        }

        return this.http.get<app.finance.RateContractWrapperViewModel>(this.url + "list", { params });
    }
}
