import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { HttpService } from "src/app/core/services/http.service";
import { FileUploadOptions } from '../../shared/components/file-upload/file-upload.service';
import { Observable } from "rxjs";

@Injectable()
export class ProjectTaskPropertyService extends HttpService<any, app.operation.ProjectTaskPropertyFormViewModel, app.operation.ProjectTaskCustomPropertyInsertUpdateViewModel> {
    objectKey = "op.ProjectTaskCustomProperty";

    constructor(http: HttpClient) {
        super(http, "/api/operation/projecttaskcustomproperty/");
    }

    getForm(projectTaskId: number): Observable<app.operation.ProjectTaskPropertyFormViewModel> {

        return this.http.get<app.operation.ProjectTaskPropertyFormViewModel>(this.url + projectTaskId);
    }

    getList(query: any) {
        let params = this.getParameters(query);
        return this.http.get<app.operation.ProjectTaskCustomPropertyListViewModel[]>(this.url, { params });
    }

    upsert(projectTaskId:number, items: app.operation.ProjectTaskCustomPropertyInsertUpdateViewModel[]) {
        let url = this.url;

        return this.http.put(this.url + projectTaskId, items);
    }
}
