import { Directive, Attribute, HostListener, Input, forwardRef, ElementRef, Renderer2 } from "@angular/core";
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, UntypedFormControl } from "@angular/forms";
import { toFixed } from '../../core/utils';

@Directive({
    selector: "input[afNumber]",
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NumberDirective), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => NumberDirective), multi: true }
    ]
})
export class NumberDirective implements ControlValueAccessor {
    DECIMAL_SEP = ",";
    @Input() min = 0;
    @Input() max: number;
    @Input() step = 1;
    @Input() round;

    private NUMBER_REGEXP = /-?[0-9]*/;

    // tslint:disable-next-line:no-attribute-parameter-decorator
    constructor(private elementRef: ElementRef, private renderer: Renderer2, @Attribute("afNumber") private numberType?: string) {
    }

    registerOnChange(fn: (value: any) => any): void { this.onChange = fn; }

    registerOnTouched(fn: () => any): void { this.onTouched = fn; }

    writeValue(value: number) {
        if (this.numberType === "percent" && value != null) {
            this.writeModelValue(value * 100);
        }
        else {
            this.writeModelValue(value);
        }
    }

    @HostListener("change", ["$event.target.value"])
    onManualChange(input: string) {
        let model = this.parse(input);
        this.handleChange(model);
    }

    setDisabledState(isDisabled: boolean): void {
        this.renderer.setProperty(this.elementRef.nativeElement, "disabled", isDisabled);
    }

    handleChange(model: number) {
        if (this.round !== undefined) {
            model = toFixed(model, +this.round);
        }
        if (this.numberType === "percent") {
            this.onChange(model / 100);
        }
        else {
            this.onChange(model);
        }
        this.writeModelValue(model);
    }

    writeModelValue(model: number) {
        this.renderer.setProperty(this.elementRef.nativeElement, "value", this.format(model));
    }

    @HostListener("keydown", ["$event"])
    onKeyDown(event: any) {
        if (event.keyCode === 38 || event.keyCode === 40) {
            event.preventDefault();
            let currentStep = (event.shiftKey) ? (this.step * 10) : this.step;

            if (event.keyCode === 40) // Arrow down
            {
                currentStep *= -1;
            }

            let modelValue = this.parse(event.target.value);

            if (modelValue) {
                modelValue += currentStep;
            } else {
                modelValue = currentStep;
            }

            let max = this.max;
            let min = this.min;

            if (this.numberType === "percent") {
                max *= 100;
                min *= 100;
            }

            if (max !== undefined && modelValue > max) {
                modelValue = this.max;
            }
            else if (min !== undefined && modelValue < min) {
                modelValue = undefined;
            }

            this.handleChange(modelValue);
        }
    }

    parse(value: string) {
        if (typeof value !== "string" || value === "") {
            return undefined;
        }

        value = value.replace(/,(\d*)$/, ".$1");

        let out = +value;

        if (isNaN(out)) {
            return undefined;
        }

        return out;
    }

    format(value: number) {
        if (value == null) {
            return "";
        }

        let formattedValue = value.toString();

        if (this.DECIMAL_SEP === ",") {
            formattedValue = formattedValue.replace(/\.(\d*)$/, ",$1");
        }
        else {
            formattedValue = formattedValue.replace(/,(\d*)$/, ".$1");
        }
        return formattedValue;
    }

    validate(control: UntypedFormControl): { [key: string]: any } {
        if (control.value) {
            if (!control.value.toString().match(this.NUMBER_REGEXP)) {
                return { number: true };
            }
            if (this.max !== undefined && control.value > this.max) {
                return { number: { max: this.max, actual: control.value } };
            }
            else if (this.min !== undefined && control.value < this.min) {
                return { number: { min: this.min, actual: control.value } };
            }
        }
        return null;
    }

    private onChange = (_: any) => { };
    private onTouched = () => { };
}
