import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { AppService } from "../../app.service";
import { BaseComponent } from "../../core";
import { WagesService } from "./wages.service";

@Component({
    selector: "b2b-wages",
    templateUrl: "./wages.component.html"
})
export class WagesComponent extends BaseComponent implements OnInit {
    isLoading = false;
    model: app.b2b.WagesAllEmployeeViewModel;
    filter = <app.b2b.WagesFilterViewModel>{};
    queryParams = {
        year: 0,
        month: 0,
        countryCode: '',
        accountCenterCode: '',
        includeCooperants: false
    };
    selectedIndex = -1;

    constructor(private wagesService: WagesService, private appService: AppService)
    {
        super();

        this.onReset();

        //this.queryParams.month = 5;
        //this.queryParams.countryCode = 'S';
        //this.queryParams.accountCenterCode = 'SV';
    }

    ngOnInit() {
        super.ngOnInit();

        this.wagesService.getFilter().subscribe(res => {
            this.filter = res;
        });
    }

    loadData() {
        this.isLoading = true;
        this.selectedIndex = -1;

        this.wagesService
            .getWagesAsync(this.queryParams)
            .subscribe((result) => {
                this.model = result;
                this.isLoading = false;
            });
    }

    onReset() {
        this.onClear();
    }

    onClear() {
        var date = new Date();
        this.queryParams = {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            countryCode: this.appService.homeViewModel.user.countryCode,
            accountCenterCode: this.appService.homeViewModel.user.accountCenterCode,
            includeCooperants: false
        };

    }

    onRowClick(i) {
        if (this.selectedIndex === i) {
            this.selectedIndex = -1;
        }
        else {
            this.selectedIndex = i;
        }
    }

    getReportUrl() {
        return this.wagesService.getReportUrl(this.queryParams);
    }
}
