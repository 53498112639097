import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule, NgbDateParserFormatter, NgbDatepickerI18n } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { NouisliderModule } from "ng2-nouislider";
import { CKEditorModule } from "ng2-ckeditor";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";

import { FileUploadService } from "./components/file-upload/file-upload.service";
import { ConfirmClickWindow, ConfirmClickDirective } from "./directives/confirm-click.directive";
import { TimePickerComponent } from "./components/datepicker/timepicker.component";
import { DatePickerComponent } from "./components/datepicker/datepicker.component";
import { LoadingBarComponent } from "./components/loading-bar/loading-bar.component";
import { CheckboxComponent } from "./components/checkbox.component";
import { RadioComponent } from "./components/radio.component";
import { CodemirrorComponent } from "./components/codemirror.component";
import { TABLE_DIRECTIVES } from "./components/table/directives";
import { PageBarComponent } from "./components/page-bar/page-bar.component";
import { PageFilterComponent } from "./components/page-filter/page-filter.component";
import { PageHeaderComponent } from "./components/page-header/page-header.component";
import { PageFooterComponent } from "./components/page-footer/page-footer.component";
import { PaginatorComponent } from "./components/paginator.component";
import { SubMenuComponent } from "./components/sub-menu/sub-menu.component";
import { HelpComponent } from "./components/help.component";
import { HasPermDirective } from "./directives/has-perm.directive";
import { MinutesDirective } from "./directives/minutes.directive";
import { FormValidationSummaryDirective } from "./directives/form-valid.directive";
import { NumberDirective } from "./directives/number.directive";
import { StickyDirective } from "./directives/sticky.directive";
import { TextareaAutosizeDirective } from "./directives/textarea-autosize.directive";
import { DatePipe } from "./pipes/date.pipe";
import { LimitPipe } from "./pipes/limit.pipe";
import { TimePipe } from "./pipes/time.pipe";
import { CeilPipe } from "./pipes/ceil.pipe";
import { FileSizePipe } from "./pipes/file-size.pipe";
import { StripDiacriticsPipe } from "./pipes/strip-diacritics.pipe";
import { SumPipe } from "./pipes/sum.pipe";
import { CurrencyPipe } from "./pipes/currency.pipe";
import { UrlEncodePipe } from "./pipes/url-encode.pipe";
import { ReplacePipe } from "./pipes/replace.pipe";
import { KeepHtmlPipe } from "./pipes/keepHtml.pipe"
import { EmailValidatorDirective } from "./validators/email-validator.directive";
import { EqualValidatorDirective } from "./validators/equal-validator.directive";
import { SpinnerComponent } from "./components/spinner.component";
import { FixedTableHeadersDirective } from "./directives/fixed-table-headers.directive";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { TabComponent } from "./components/tabs/tabs.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TopMenuComponent } from "./components/top-menu/top-menu.component";
import { NoteComponent } from "./components/note/note.component";
import { FixedActionButtonsComponent } from "./components/fixed-action-buttons/fixed-action-buttons.component";
import { FileSelectDirective } from "./components/file-upload/file-select.directive";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { HistoryComponent } from "./components/history/history.component";
import { HistoryValueComponent } from "./components/history/history-value.component";
import { RequestLogComponent } from "./components/request-log/request-log.component";
import { LoadingBarService } from "./components/loading-bar/loading-bar.service";
import { createNgbDateMomentParserFormatter } from "./components/datepicker/ngb-date-moment-parser-formatter";
import { DatepickerI18n } from "./components/datepicker/datepicker-i18n";
import { ScreenLargeDirective } from "./directives/screen-large.directive";
import { ScreenBelowLargeDirective } from "./directives/screen-below-large.directive";
import { AbsPipe } from "./pipes/abs.pipe";
import { LongTouchDirective } from "./directives/long-touch.directive";
import { TitleComponent } from "./components/title.component";
import { components as b2bComponents } from "../b2b/shared.components";
import { components as crmComponents } from "../crm/shared.components";
import { components as organizationComponents } from "../organization/shared.components";
import { components as operationComponents } from "../operation/shared.components";
import { FixedActionButtonsService } from "./components/fixed-action-buttons/fixed-action-buttons.service.component";
import { SharePointFileComponent } from "./components/share-point/share-point-file.component";
import { SharePointFileService } from "./components/share-point/share-point-file.service";
import { PanelComponent } from "./components/panel/panel.component";
import { AppVersionComponent } from "./components/app-version.component";
import { MaxDecimalsDirective } from "./validators/max-decimals-validator.directive";
import { PurePipe } from "./pipes/pure.pipe";
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ClipboardDirective } from "./directives/clipboard.directive";
import { FilterPipe } from "./pipes/filter.pipe";
import { NoopOptionValidatorDirective } from "./validators/noop-option-validator.directive";
import { MultiselectComponent } from "./components/multiselect/multiselect.component";
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgbModule,
        TranslateModule,
        NouisliderModule,
        NgSelectModule,
        ScrollingModule,
        NgOptionHighlightModule,
        QRCodeModule
    ],
    providers: [
        FileUploadService,
        SharePointFileService
    ],
    declarations: [
        TimePickerComponent,
        DatePickerComponent,
        LoadingBarComponent,
        CheckboxComponent,
        RadioComponent,
        CodemirrorComponent,
        TABLE_DIRECTIVES,
        PageBarComponent,
        PageFilterComponent,
        PageHeaderComponent,
        PageFooterComponent,
        PaginatorComponent,
        SubMenuComponent,
        HelpComponent,
        HasPermDirective,
        ConfirmClickDirective,
        ConfirmClickWindow,
        MinutesDirective,
        FormValidationSummaryDirective,
        NumberDirective,
        StickyDirective,
        TextareaAutosizeDirective,
        ClipboardDirective,
        AbsPipe,
        DatePipe,
        LimitPipe,
        TimePipe,
        CeilPipe,
        FileSizePipe,
        StripDiacriticsPipe,
        SumPipe,
        FilterPipe,
        CurrencyPipe,
        UrlEncodePipe,
        ReplacePipe,
        KeepHtmlPipe,
        PurePipe,
        EmailValidatorDirective,
        EqualValidatorDirective,
        NoopOptionValidatorDirective,
        MaxDecimalsDirective,
        SpinnerComponent,
        FixedTableHeadersDirective,
        ScreenLargeDirective,
        ScreenBelowLargeDirective,
        BreadcrumbComponent,
        TabComponent,
        SidebarComponent,
        TopMenuComponent,
        NoteComponent,
        FixedActionButtonsComponent,
        FileSelectDirective,
        FileUploadComponent,
        SharePointFileComponent,
        HistoryComponent,
        HistoryValueComponent,
        RequestLogComponent,
        LongTouchDirective,
        TitleComponent,
        PanelComponent,
        AppVersionComponent,
        MultiselectComponent,
        ...crmComponents,
        ...organizationComponents,
        ...operationComponents,
        ...b2bComponents
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgbModule,
        TranslateModule,
        NouisliderModule,
        NgSelectModule,
        NgOptionHighlightModule,
        QRCodeModule,
        CKEditorModule,
        TimePickerComponent,
        DatePickerComponent,
        LoadingBarComponent,
        CheckboxComponent,
        RadioComponent,
        CodemirrorComponent,
        TABLE_DIRECTIVES,
        PageBarComponent,
        PageFilterComponent,
        PageHeaderComponent,
        PageFooterComponent,
        PaginatorComponent,
        SubMenuComponent,
        HelpComponent,
        HasPermDirective,
        ConfirmClickDirective,
        ConfirmClickWindow,
        MinutesDirective,
        FormValidationSummaryDirective,
        NumberDirective,
        StickyDirective,
        TextareaAutosizeDirective,
        ClipboardDirective,
        AbsPipe,
        DatePipe,
        LimitPipe,
        TimePipe,
        CeilPipe,
        FileSizePipe,
        StripDiacriticsPipe,
        SumPipe,
        FilterPipe,
        CurrencyPipe,
        UrlEncodePipe,
        ReplacePipe,
        KeepHtmlPipe,
        PurePipe,
        EmailValidatorDirective,
        EqualValidatorDirective,
        NoopOptionValidatorDirective,
        MaxDecimalsDirective,
        SpinnerComponent,
        FixedTableHeadersDirective,
        ScreenLargeDirective,
        ScreenBelowLargeDirective,
        BreadcrumbComponent,
        TabComponent,
        SidebarComponent,
        TopMenuComponent,
        NoteComponent,
        FixedActionButtonsComponent,
        FileSelectDirective,
        FileUploadComponent,
        SharePointFileComponent,
        HistoryComponent,
        HistoryValueComponent,
        RequestLogComponent,
        LongTouchDirective,
        TitleComponent,
        PanelComponent,
        ScrollingModule,
        AppVersionComponent,
        MultiselectComponent,
        ...crmComponents,
        ...organizationComponents,
        ...operationComponents,
        ...b2bComponents
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                LoadingBarService,
                FixedActionButtonsService,
                {
                    provide: NgbDateParserFormatter,
                    useFactory: createNgbDateMomentParserFormatter,
                    deps: [DatepickerI18n]
                },
                DatepickerI18n,
                { provide: NgbDatepickerI18n, useClass: DatepickerI18n }
            ]
        };
    }
}
