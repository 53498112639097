import { OnInit, Directive } from "@angular/core";
import { Router } from "@angular/router";
import moment from "moment";

import { BaseComponent } from "./base.component";
import { isEmptyObject, isPresent, isBlank, convertDateStringsToDates, getParameters, isNumber } from "../utils";
import { ListQueryParams } from "../../models/list-query-params.model";

@Directive()
export abstract class TableBaseComponent extends BaseComponent implements OnInit {
    objectKey: string;
    sortBy: string;
    stateKey: string;
    isLoading = false;
    queryParams: any;
    _hasPreviousState = false;

    get hasPreviousState() {
        return this._hasPreviousState;
    }

    constructor(protected router: Router) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.stateKey = this.getKey();
        this.loadState(this.stateKey);
    }

    getKey() {
        if (!this.objectKey) {
            return null;
        }

        let url = this.router.url;
        if (url) {
            let queryIndex = url.indexOf("?");

            if (queryIndex >= 0) {
                url = url.substring(0, queryIndex);
            }
        }
        return `table.${this.objectKey}.${url}`;
    }

    loadState(key: string) {
        try {
            let params = this.router.routerState.snapshot.root.queryParams;

            if (!isEmptyObject(params)) {
                this.setStateFromQueryString({ ...params });
                return;
            }
        }
        catch (err) { console.error(err) }

        if (isBlank(key)) {
            return;
        }

        if (isPresent(localStorage)) {
            let model = localStorage.getItem(key);
            if (isPresent(model)) {
                let parsedModel = JSON.parse(model);
                this.setState(parsedModel);
            }
        }
    }

    setStateFromQueryString(parsedModel) {
        if (isPresent(parsedModel) && !isEmptyObject(parsedModel)) {

            for (let key in parsedModel) {
                if (!parsedModel.hasOwnProperty(key)) {
                    continue;
                }                

                var value = parsedModel[key];

                if (Array.isArray(value)) {
                    for (let i = 0; i < value.length; i++) {
                        value[i] = this.parseValueFromQueryString(value[i]);
                    }
                }
                else {
                    parsedModel[key] = this.parseValueFromQueryString(parsedModel[key]);
                }
            }

            let params = new ListQueryParams();

            Object.assign(this.queryParams, params, parsedModel);

            this._hasPreviousState = true;
        }
    }

    parseValueFromQueryString(value: string) {
        if (value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        }
        else if (value === "true" || value === "false") {
            return value === "true";
        }
        else if (moment(value, "YYYY-MM-DDTHH:mm:ss", true).isValid()) {
            let date = moment(value, moment.ISO_8601).toDate();
            return date;
        }
        else if (isNumber(value)) {
            return +value;
        }

        return value;
    }

    saveStateToQueryString(params: any)
    {
        let httpParams = getParameters(params, false, true);

        let queryParams = {};

        for (let key of httpParams.keys()) {
            let value = httpParams.getAll(key);
            if (value) {
                queryParams[key] = value;
            }
        }

        this.router.navigate([], {
            queryParams: queryParams,
            replaceUrl: true
        });
    }

    setState(parsedModel) {
        if (isPresent(parsedModel) && !isEmptyObject(parsedModel)) {
            convertDateStringsToDates(parsedModel);

            let params = new ListQueryParams();

            Object.assign(this.queryParams, params, parsedModel);

            this._hasPreviousState = true;
        }
    }

    saveState(params: any, saveStateToQueryString = true) {
        if (saveStateToQueryString) {
            this.saveStateToQueryString(params);
        }

        if (isPresent(localStorage)) {
            let key = this.getKey();
            let json = JSON.stringify(params);
            if (key) {
                window.localStorage.setItem(key, json);
            }
        }
    }
}
