import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from "@angular/core";

import { ReportProjectTaskService, ReportProjectTaskRequest } from "./report-project-task.service";

@Component({
    selector: "op-project-task-overview-report",
    templateUrl: "./project-task-overview-report.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectTaskOverviewReportComponent implements OnChanges {
    @Input() params: ReportProjectTaskRequest;
    @Input() branch: app.organization.BranchExtendedSelectListViewModel;

    request = <app.operation.ProjectTaskOverviewRequest>{};

    constructor(private reportService: ReportProjectTaskService) { }

    getUrl() {
        let requestParams = Object.assign({}, this.params, this.request);
        return this.reportService.getProjectTaskOverview(requestParams);
    }

    ngOnChanges(changes: SimpleChanges) {
        let branch = changes["branch"];
        if (branch && branch.currentValue.accountCenters.length > 0) {
            this.request.accountCenterCode = branch.currentValue.accountCenters[0];
        }
    }
}
