import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class PurchaseService
    extends HttpService<app.finance.PurchaseListViewModel, app.finance.PurchaseFormViewModel, app.finance.PurchaseInsertUpdateViewModel>
{
    objectKey = "fin.Purchase";

    constructor(http: HttpClient) {
        super(http, "/api/finance/purchase/");
    }

    getFilter(): Observable<app.finance.PurchaseListFilterViewModel> {
        let params = new HttpParams();

        return this.http.get<app.finance.PurchaseListFilterViewModel>(this.url + "filter", { params });
    }

    getSupplierSelectList(searchTerm: string, existing: string[] = []): Observable<string[]> {
        let params = new HttpParams();
        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }

        return this.http.get<string[]>(this.url + "supplier", { params }).pipe(map(list => {
            let currentIndex = existing.findIndex(value => value == searchTerm);
            list = list.concat(existing.filter((value, index) => index !== currentIndex && value && value.toLowerCase().includes(searchTerm.toLowerCase())));
            return list.filter((item, i, ar) => { return ar.indexOf(item) === i; }); // remove duplicates
        }));
    }

    action(request: app.finance.PurchaseActionRequest) {
        let url = this.url + request.purchaseId + "/action";
        return this.http.put(url, request);
    }

    getList(page?: number, pageSize?: number, searchTerm?: string): Observable<app.finance.PurchaseSelectListViewModel[]> {

        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        if (page) {
            params = params.set("page", page.toString());
        }
        if (pageSize) {
            params = params.set("pageSize", pageSize.toString());
        }

        return this.http.get<app.finance.PurchaseSelectListViewModel[]>(this.url + "list", { params });
    }

    getByPurchaseItemId(purchaseItemId: number): Observable<app.finance.PurchaseFormViewModel> {
        return this.http.get<app.finance.PurchaseFormViewModel>(this.url + "by-item/" + purchaseItemId);
    }

    getSummary(parameters?: any) {
        return this.http
            .get<app.finance.PurchaseSummaryViewModel>(this.url + "summary", {
                params: this.getParameters(parameters)
            });
    }

}