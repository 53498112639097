import { Component, OnInit } from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { ShiftPlanService } from "./shift-plan.service";
import { TimeObject } from "./../../models/time-object.model";
import { TimeService } from "./../../core/services/time.service";
import { WorkReportSliderFormatter } from "./../work-report/work-report-slider-formatter";
import { finalize } from "rxjs/operators";
import { ToastService } from '../../core/services/toast.service';

@Component({
    selector: "op-shift-plan-create-modal",
    templateUrl: "./shift-plan-modal.component.html"
})
export class ShiftPlanCreateModalComponent {
    model: any;
    title: string;
    shiftTypes: app.operation.ShiftTypeSelectListViewModel[];
    isEditable = false;
    isSliderVisible = true;
    times: TimeObject[];
    customShiftTypeId = null;
    totalHours: number;
    timeRange: [480, 960];
    formatter: WorkReportSliderFormatter;
    sliderOptions: any;
    isSubmittingCalendar: boolean;

    constructor(public activeModal: NgbActiveModal,
        private shiftPlanService: ShiftPlanService,
        private timeService: TimeService,
        private toastService: ToastService) {
        this.times = timeService.getTimes(0, 30);
        this.formatter = new WorkReportSliderFormatter(this.times);

        this.sliderOptions = {
            connect: true,
            tooltips: [this.formatter, this.formatter],
            keyboard: true,
            step: 15,
            range: {
                min: [this.times[0].value],
                max: [this.times[this.times.length - 1].value]
            },
            format: new WorkReportSliderFormatter(this.times, false),
            pips: {
                mode: "positions",
                values: [0, 100],
                format: this.formatter
            }
        };
    }

    ok() {
        this.isSubmittingCalendar = true;

        this.shiftPlanService.insertCalendar(this.model)
            .pipe(finalize(() => this.isSubmittingCalendar = false))
            .subscribe((res) => {       
                if (res != null && res.items != null)
                {
                    var resArr = [];
                    res.items.forEach(function (item) {
                        var i = resArr.findIndex(x => x.employeeId == item.employeeId && x.errorMessage == item.errorMessage);
                        if (i <= -1) {
                            if (item.errorMessage.length > 0)
                            {
                                resArr.push({ employeeId: item.employeeId, errorMessage: item.errorMessage });
                            }
                        }
                    });                  
                    if (resArr.length > 0) {                       
                        resArr.forEach(x =>
                            this.toastService.error(x.errorMessage)
                        );
                    }
                }
                this.activeModal.close(res);
            });
    }

    cancel() {
        this.activeModal.dismiss("cancel");
    }

    countHours() {
        this.totalHours = (this.model.toMinutes - this.model.fromMinutes) / 60;
    }

    sliderChanged() {
        this.model.shiftTypeId = this.customShiftTypeId;
        this.model.fromMinutes = this.timeRange[0];
        this.model.toMinutes = this.timeRange[1];
        this.countHours();
    }

    updateSlider() {
        this.timeRange = [this.model.fromMinutes, this.model.toMinutes];
    }

    shiftTypeChanged(value: number) {
        this.model.shiftTypeId = value;
        let shiftType = this.shiftTypes.filter(x => x.shiftTypeId === this.model.shiftTypeId);
        if (shiftType.length > 0 && (shiftType[0].toMinutes !== shiftType[0].fromMinutes || shiftType[0].shiftTypeId === this.customShiftTypeId)) {
            this.isSliderVisible = true;
            if (shiftType[0].shiftTypeId === this.customShiftTypeId) {
                this.model.fromMinutes = 480;
                this.model.toMinutes = 960;
                this.updateSlider();
            }
            else {
                this.model.fromMinutes = shiftType[0].fromMinutes;
                this.model.toMinutes = shiftType[0].toMinutes;
                this.updateSlider();
            }
            this.countHours();
        }
        else {
            this.isSliderVisible = false;
        }
    }
}
