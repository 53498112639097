import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { BaseComponent } from "../../core";
import { WagesService } from "./wages.service";

@Component({
    selector: "b2b-wages-employee",
    templateUrl: "./wages-employee.component.html"
})
export class WagesEmployeeComponent extends BaseComponent implements OnInit {
    @Input() deliveryNoteId: number;
    isLoading = false;
    employeeWages: app.b2b.WagesEmployeeViewModel;

    constructor(private wagesService: WagesService)
    {
        super();
    }

    ngOnInit() {
        super.ngOnInit();       
        this.loadData();
    }

    loadData() {
        this.isLoading = true;

        if (this.deliveryNoteId) {
            this.wagesService
                .getEmployeeWagesAsync(this.deliveryNoteId)
                .subscribe((result) => {
                    this.employeeWages = result;
                    this.isLoading = false;
                });
        }
    }
}
