import { EmployeeService } from "./employee/employee.service";
import { EmployeeFileService } from "./employee-file/employee-file.service";
import { ProjectTaskService } from "./project-task/project-task.service";
import { ProjectTaskProductRateService } from "./project-task-product-rate/project-task-product-rate.service";
import { ProjectTaskFileService } from "./project-task/project-task-file.service";
import { WorkClothesService } from "./work-clothes/work-clothes.service";
import { InventoryService } from "./inventory-tabs/inventory/inventory.service";
import { EmployeeTripService } from './employee-trip/employee-trip.service';
import { ReportProjectTaskService } from './report/report-project-task.service';
import { ProjectService } from './project/project.service';
import { PresentationItemService } from './presentation-item/presentation-item.service';
import { CourseService } from './course/course.service';
import { OpportunityService } from '../crm/opportunity/opportunity.service';    
import { AmadeusService } from './amadeus/amadeus.service';
import { EmployeeRelationshipService } from "./employee-relationship/employee-relationship.service";
import { SettingsRentService } from "./employee/b2b/settings-rent/settings-rent.service";
import { ReportEmployeeService } from './report/report-employee.service';
import { EmployeeWageRedistributionService } from "./employee-wage-redistribution/employee-wage-redistribution.service";
import { EmployeeAdvanceService } from "./employee-advance/employee-advance.service";
import { ShiftPlanService } from "./shift-plan/shift-plan.service";
import { ShiftTypeService } from "./shift-type/shift-type.service";
import { InventoryTransferService } from "./inventory-tabs/inventory-transfer/inventory-transfer.service";
import { InventoryCheckService } from "./inventory-tabs/inventory-check/inventory-check.service";
import { ProjectTaskPropertyService } from "./project-task-properties/project-task-property.service";

export const providers = [
    EmployeeService,
    EmployeeFileService,
    SettingsRentService,
    ProjectService,
    ProjectTaskService,
    ProjectTaskFileService,
    ProjectTaskPropertyService,
    PresentationItemService,
    ProjectTaskProductRateService,
    WorkClothesService,
    InventoryService,
    InventoryTransferService,
    InventoryCheckService,
    PresentationItemService,
    EmployeeTripService,
    EmployeeAdvanceService,
    EmployeeWageRedistributionService,
    EmployeeRelationshipService,
    ReportProjectTaskService,
    ReportEmployeeService,
    CourseService,
    OpportunityService,
    AmadeusService,
    ShiftPlanService,
    ShiftTypeService,
];
