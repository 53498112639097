import { PersonService } from "./person/person.service";
import { CompanyService } from "./company/company.service";
import { CompanyFileService } from "./company/company-file.service";
import { BudgetService } from "./budget/budget.service";
import { SupplyChainService } from "./supply-chain/supply-chain.service";
import { OpportunityOverviewService } from '../dashboard/opportunity-overview/opportunity-overview.service';
import { GroupService } from "./supply-chain/group/group.service";
import { CompanyB2bService } from "./company/b2b/b2b.service";
import { OtherAgreementsService } from "./company/other-agreement/company-other-agreements.service";
import { B2bRateService } from "./company/b2b-rate/company-b2b-rate.service";
import { CompanyEvaluationService } from "./company-evaluation/company-evaluation.service";
import { MetriqGroupService } from "./metriq-group/metriq-group.service";
import { B2bAdvanceService } from "./company/b2b-advance/b2b-advance.service";

export const providers = [
    PersonService,
    CompanyService,
    CompanyFileService,
    BudgetService,
    SupplyChainService,
    OpportunityOverviewService,
    GroupService,
    CompanyB2bService,
    OtherAgreementsService,
    B2bRateService,
    CompanyEvaluationService,
    MetriqGroupService,
    B2bAdvanceService,
]
