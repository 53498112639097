<af-spinner [loading]="isLoading"></af-spinner>

<div class="table-shiftplan-wrapper" *ngIf="!isLoading && !!calendar">
    <div *ngIf="calendar.rows.length > 0">
        <div class="form-horizontal hidden-print">
            <div class="row" *ngIf="markModeForSms">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-2">{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table.component.number of days while marking in row' | translate }}</label>
                        <div class="col-md-2">
                            <input type="number" class="form-control" [(ngModel)]="markSelectorSize" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div *ngIf="isEditable" class="buttons hidden-print col-12">
                <div class="float-end">
                    <af-help class="help-static" helpid="employee.shiftplan.table.button.add"></af-help>
                    <button type="button"
                            *afHasPerm="{obj:objectKey,perm:'Insert', if:markModeForSms}"
                            class="btn btn-sm btn-success btn-add"
                            (click)="changeToMarkMode()">
                        {{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table.component.marking' | translate }}
                    </button>

                    <button type="button"
                            *afHasPerm="{obj:objectKey,perm:'Insert', if:!markModeForSms}"
                            class="btn btn-sm btn-success btn-add"
                            (click)="changeToMarkMode()">
                        {{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table.component.mark' | translate }}
                    </button>

                    <button (click)="openSmsModal();"
                            *afHasPerm="{obj:objectKey,perm:'Delete'}"
                            [disabled]="selectedCellsSms.length === 0"
                            type="button"
                            class="btn btn-sm btn-warning">
                        {{ 'btn.sms bulk sending' | translate }}
                    </button>
                    <button type="button"
                            *afHasPerm="{obj:objectKey,perm:'Insert'}"
                            class="btn btn-sm btn-primary btn-add"
                            [disabled]="selectedCells.length === 0"
                            (click)="openModal()">
                        {{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table.component.set plan' | translate }}
                    </button>

                    <button (click)="deleteCalendar();"
                            *afHasPerm="{obj:objectKey,perm:'Delete'}"
                            [disabled]="selectedCells.length === 0"
                            type="button"
                            class="btn btn-sm btn-delete btn-danger">
                        {{ 'btn.delete' | translate }}
                    </button>
                </div>
            </div>
            <br class="clearfix" />
        </div>

        <div class="row">
            <div class="mt-3 col-9">
                <b>{{getTitle()}}</b>
            </div>
            <div class="col-3 float-end" *ngIf="projectTask">
                <button *afHasPerm="{obj:objectKey, perm: 'Update'}"
                        class="btn btn-sm btn-update btn-primary float-end mt-2 mb-2" type="button" (click)="sendToMajorProvider()">
                    {{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table.component.send plans to major provider' | translate }}<i class="fa fa-paper-plane" style="padding-left:7px" aria-hidden="true"></i>
                </button>
            </div>
        </div>

        <span *ngIf="showLink && this.projectTask">
            - <a routerLink="/operation/employee/shiftplans" [queryParams]="{ firstProjectTaskId: this.projectTask.firstProjectTaskId }">{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table.component.plan on projecttask _ workplace' | translate }}</a>
        </span>
        <span *ngIf="showLink && this.workPlace">
            - <a routerLink="/operation/employee/shiftplans" [queryParams]="{ workPlaceId: this.workPlace.workPlaceId }">{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table.component.plan on projecttask _ workplace' | translate }}</a>
        </span>

        <div class="table-shiftplan-fixed" (scroll)="onScroll($event)" #tableFixed>
            <table class="table-shiftplan">
                <thead>
                    <tr class="header-months">
                        <th class="fixed-column">{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table.component.month' | translate }}</th>
                        <th *ngFor="let month of calendar.months; let index = index" [attr.colspan]="month.value" [ngClass]="{'month-separator': index > 0}">
                            {{ getMonthName(month.key)}}
                        </th>
                    </tr>
                    <tr class="header-days">
                        <th class="fixed-column">
                            {{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table.component.name' | translate }}
                        </th>
                        <th *ngFor="let col of calendar.columns; let index = index" [ngClass]="{'not-working-day': !calendar.columns[index].isWorkingDay, 'holiday': calendar.columns[index].isHoliday, 'today': isToday(index), 'month-separator': !isSameMonth(index)}">
                            <a *ngIf="markModeForSms" (click)="markInColSms(index)">{{ col.day }}</a>
                            <ng-container *ngIf="!markModeForSms">{{ col.day }}</ng-container>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of calendar.rows" [ngClass]="{'row-loading': row.isLoading }">
                        <td class="fixed-column">
                            <a *ngIf="!row.isLoading" [routerLink]="['/operation/employee', row.employeeId ]">{{ row.fullName }} </a><b *ngIf="row && row.isFromAgency">(A)</b><b *ngIf="row && row.isFromMajorProvider">(VP)</b><br />
                            <i *ngIf="!row.hasPhone && markModeForSms" class="fa fa-phone" style="color:red;font-size:larger"><br /></i>
                            <a *ngIf="row.hasPhone && markModeForSms" (click)="markInRowSms(row.employeeId)"><span class="selectBadge"> </span></a>
                            <b *ngIf="row.isLoading">{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table.component.loading...' | translate }}</b>
                        </td>
                        <td oncontextmenu="return false" #cellElement *ngFor="let cell of row.cells; let index = index"
                            [ngClass]="{
                            'not-working-day': !calendar.columns[index].isWorkingDay,
                            'holiday': calendar.columns[index].isHoliday,
                            'today': isToday(index),
                            'month-separator': !isSameMonth(index),
                            'selected': cell.selected,
                            'selectedSms':cell.selectedSms,
                            'selectedBoth':cell.selectedSms && cell.selected ,
                            'is-same': isSame(cell),
                            'error': cell.selected && cell.selected.errorMessage
                            }">
                            <div class="cell-detail" [ngClass]="{'smswassend': cell.items[0]?.smsWasSend}" long-touch (onLongPress)="longPress(cell)" (mousedown)="handleClick($event, cell, row.employeeId, index)" oncontextmenu="return false">
                                <span *ngIf="cell.hasNote" class="cell-note-indicator"></span>
                                <ng-container *ngIf="cell.items.length !== 0">                                   
                                    <span class="icomoon-shifttype-{{ cell.items[0].shiftPlanTypeAbbrevation }}"></span>                                   
                                    <br />
                                    <div *ngFor="let item of cell.items" style="font-size:8px;">
                                        <span *ngIf="item.fromMinutes != 0 && item.toMinutes != 0">{{ formatTime(item) }}</span>
                                    </div> 
                                </ng-container>                           
                                <span *ngIf="cell.selected && cell.selected.errorMessage" class="fa fa-exclamation" title="{{ cell.selected ? cell.selected.errorMessage : '' }}"></span>
                            </div>
                            <div *ngIf="cell.isShowingDetail && cell.items.length > 0" long-touch (onLongPress)="longPress(cell)" (mousedown)="handleClick($event, cell, row.employeeId, index, true, cellElement)" class="detail-popover" [ngStyle]="{'top': cellElement.offsetTop + 60 + 'px', 'left': cellElement.offsetLeft - tableFixed.scrollLeft + 160 + 'px'}" oncontextmenu="return false">
                                <div *ngFor="let item of cell.items">
                                    <div>
                                        <a *ngIf="isEditable" (click)="openEditModal(item); cell.isShowingDetail = false;">
                                            <span class="icomoon-shifttype-{{ item.shiftPlanTypeAbbrevation }}"></span>
                                        </a>
                                        <span *ngIf="!isEditable" class="icomoon-shifttype-{{ item.shiftPlanTypeAbbrevation }}"></span>
                                        <div class="detail-popover-line">
                                            <span>({{ formatTime(item) }})</span>
                                            <span *ngIf="item.projectTaskNumber"> - {{item.projectTaskNumber}}</span>
                                            <span *ngIf="item.workPlaceTitle"> - {{item.workPlaceTitle}}</span>
                                            <span *ngIf="item.note"> - {{ item.note }}</span>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="cell.items.length > 0">
                                    <ng-container *ngFor="let sms of cell.items[0].smsLogs">
                                        <hr />
                                        <b>{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table.component.sent_' | translate }}</b> {{sms.createdUtc | afDate : 'L HH:mm' : true}}<br />
                                        <b>{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table.component.status_' | translate }}</b> {{sms.smsStatusText}}
                                        <a style="padding-left:15px" (click)="checkSmsStatus(sms)">{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table.component.verify status' | translate }}</a>                                      
                                        <span style="color: green;">
                                             <i class="fa fa-refresh"></i>
                                        </span>
                                        <br />
                                        <b>{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table.component.text_' | translate }}</b> <i> "{{sms.text}}"</i><br />
                                    </ng-container>
                                </ng-container>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div *ngIf="calendar.rows.length === 0 && !showOnlyPlanned">
        <b>{{getTitle()}}</b>
        <span *ngIf="showLink && this.projectTask">
            - <a routerLink="/operation/employee/shiftplans" [queryParams]="{ firstProjectTaskId: this.projectTask.firstProjectTaskId }">{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table.component.plan on projecttask _ workplace' | translate }}</a>
        </span>
        <span *ngIf="showLink && this.workPlace">
            - <a routerLink="/operation/employee/shiftplans" [queryParams]="{ workPlaceId: this.workPlace.workPlaceId }">{{ 'hollen.clientapp.src.app.operation.shift-plan.shift-plan-table.component.plan on projecttask _ workplace' | translate }}</a>
        </span>
        <af-note type="info" size="small">
            {{ 'table.no records found' | translate }}
        </af-note>
    </div>
</div>
