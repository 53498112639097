import { Injectable, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class FixedActionButtonsService {
    refresh = new EventEmitter<any>();

    refreshButtons() {
        setTimeout(() => {
            this.refresh.emit();
        }, 0);
    }

    subscribe(generatorOrNext?: any, error?: any, complete?: any) {
        return this.refresh.subscribe(generatorOrNext, error, complete);
    }
}
