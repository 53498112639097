import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { FixedActionButtonsService } from "./fixed-action-buttons.service.component";
import { Subscription } from "rxjs";
import { LoadingBarService, LoadingBarEvent, LoadingBarEventType } from "../loading-bar/loading-bar.service";
import { filter } from "rxjs/operators";

@Component({
    selector: "af-fixed-action-buttons",
    styleUrls: ["./fixed-action-buttons.component.scss"],
    templateUrl: "./fixed-action-buttons.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixedActionButtonsComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];

    buttonPrimaryText = "Update";
    isVisible = false;
    buttonSave: any;
    interval: any;
    buttonDisabled: false;

    constructor(protected router: Router,
        private loadingBarService: LoadingBarService,
        private changeDetectorRef: ChangeDetectorRef,
        private fixedActionButtonsService: FixedActionButtonsService) {
        router
            .events
            .pipe(filter(ev => ev instanceof NavigationEnd))
            .subscribe(ev => {
                setTimeout(() => {
                    this.handleButtons();
                });
            });
    }

    ngOnInit() {
        this.loadingBarService.observable.subscribe((event: LoadingBarEvent) => {
            if (event.type === LoadingBarEventType.VISIBLE) {
                this.buttonDisabled = event.value;
            }
            this.changeDetectorRef.markForCheck();
        });

        this.subscriptions.push(this.fixedActionButtonsService.subscribe(() => {
            this.handleButtons();
        }));
    }

    isDisabled() {
        return (this.buttonSave
            && (this.buttonSave.disabled
                || (this.buttonSave.form && this.buttonSave.form.className.split(" ").indexOf("disabled-all") > -1)));
    }

    onPrimaryClicked() {
        if (this.buttonSave && !this.isDisabled()) {
            this.buttonSave.click();
        }
    }

    handleButtons() {
        this.buttonSave = document.querySelector("form .form-actions .btn-primary, .btn-float");
        if (this.buttonSave && !this.buttonSave.hidden) {
            this.isVisible = true;
            if (this.buttonSave.textContent) {
                this.buttonPrimaryText = this.buttonSave.textContent;
                this.changeDetectorRef.markForCheck();
            } else {
                this.interval = setInterval(() => {
                    if (this.buttonSave && this.buttonSave.textContent) {
                        this.buttonPrimaryText = this.buttonSave.textContent;
                        clearInterval(this.interval);
                        this.changeDetectorRef.markForCheck();
                    }
                }, 100);
            }
        } else {
            this.isVisible = false;
            this.changeDetectorRef.markForCheck();
            setTimeout(() => {
                this.handleButtons();
            }, 1000);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
