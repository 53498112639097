import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { HttpService } from "../../core";

@Injectable()
export class AccommodationTermService
    extends HttpService<app.operation.AccommodationTermListViewModel, app.operation.AccommodationTermFormViewModel, app.operation.AccommodationTermInsertUpdateViewModel>
{
    objectKey = "op.accommodationterm";

    constructor(http: HttpClient) {
        super(http, "/api/operation/accommodationterm/");
    }
}
