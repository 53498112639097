
<af-page-bar title="{{ 'hollen.clientapp.src.app.operation.employee-relationship.employee-relationship-edit.component.editation - employee relationship n.' | translate }}{{employeeRelationship.relationshipOrder}}"></af-page-bar>

<div class="form">
    <form (ngSubmit)="update()" name="updateEmployeeRelationshipForm" novalidate="" #updateEmployeeRelationshipForm="ngForm" [class.disabled-all]="isLoading">
        <div class="form-horizontal">
            <div class="form-body">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="EmployeeContractTypeId">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.contract type' | translate }}</label>
                            <div class="col-md-10">
                                <select class="form-control form-select" [ngModel]="employeeRelationship.employeeContractTypeId" (ngModelChange)="onContractTyperChange($event)" [required] id="EmployeeContractTypeId" name="EmployeeContractTypeId" required="">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let contractType of employeeContractTypes" [ngValue]="contractType.id">{{contractType.title}}</option>
                                </select>
                                <af-help helpid="Hollen.Operation.EmployeeRelationshipInsertUpdateViewModel.EmployeeContractTypeId"></af-help>
                                <span><span *ngIf="(updateEmployeeRelationshipForm.controls['EmployeeContractTypeId']?.dirty || updateEmployeeRelationshipForm.submitted) && updateEmployeeRelationshipForm.controls['EmployeeContractTypeId']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.contract type'} }}</span>
</span>
                            </div>
                            <label class="control-label col-md-2" for="RelationshipOrder">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.relationship n.' | translate }}</label>
                        </div>

                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="StartDate">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.cooperation start date' | translate }}</label>
                            <div class="col-md-4">
                                <div class="fixed-datepicker">
                                    <af-datepicker [(ngModel)]="employeeRelationship.startDate" name="StartDate" required></af-datepicker>
                                    <af-help helpid="Hollen.Operation.EmployeeRelationshipInsertUpdateViewModel.StartDate"></af-help>
                                </div>
                                <span><span *ngIf="(updateEmployeeRelationshipForm.controls['StartDate']?.dirty || updateEmployeeRelationshipForm.submitted) && updateEmployeeRelationshipForm.controls['StartDate']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.cooperation start date'} }}</span>
<span *ngIf="(updateEmployeeRelationshipForm.controls['StartDate']?.dirty || updateEmployeeRelationshipForm.submitted) && updateEmployeeRelationshipForm.controls['StartDate']?.hasError('date')" class="field-validation-error">{{ 'validator.{0} is not valid date format.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.cooperation start date'} }}</span>
<span *ngIf="(updateEmployeeRelationshipForm.controls['StartDate']?.dirty || updateEmployeeRelationshipForm.submitted) && updateEmployeeRelationshipForm.controls['StartDate']?.hasError('daterange')" class="field-validation-error">{{ 'validator.{0} is out of its valid range.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.cooperation start date'} }}</span>
</span>
                            </div>
                            <label class="control-label col-md-2" for="EndDate">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.cooperation end date' | translate }}</label>
                            <div class="col-md-4">
                                <div class="fixed-datepicker">
                                    <af-datepicker [(ngModel)]="employeeRelationship.endDate" name="EndDate"></af-datepicker>
                                    <af-help helpid="Hollen.Operation.EmployeeRelationshipInsertUpdateViewModel.EndDate"></af-help>
                                </div>
                                <span><span *ngIf="(updateEmployeeRelationshipForm.controls['EndDate']?.dirty || updateEmployeeRelationshipForm.submitted) && updateEmployeeRelationshipForm.controls['EndDate']?.hasError('date')" class="field-validation-error">{{ 'validator.{0} is not valid date format.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.cooperation end date'} }}</span>
<span *ngIf="(updateEmployeeRelationshipForm.controls['EndDate']?.dirty || updateEmployeeRelationshipForm.submitted) && updateEmployeeRelationshipForm.controls['EndDate']?.hasError('daterange')" class="field-validation-error">{{ 'validator.{0} is out of its valid range.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.cooperation end date'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="AgencyId">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.agency' | translate }}</label>
                            <div class="col-md-8">
                                <select class="form-control form-select" [(ngModel)]="employeeRelationship.agencyId" [disabled]="contractTypes && !(employeeRelationship.employeeContractTypeId == contractTypes.agency || employeeRelationship.employeeContractTypeId == contractTypes.assignedemployee)" id="AgencyId" name="AgencyId">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let agency of agencies" [ngValue]="agency.id">{{agency.title}}</option>
                                </select>
                                <af-help helpid="Hollen.Operation.EmployeeInsertUpdateViewModel.AgencyId"></af-help>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="MajorProviderId">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.major provider' | translate }}</label>
                            <div class="col-md-8">
                                <select class="form-control form-select" [(ngModel)]="employeeRelationship.majorProviderId" [disabled]="contractTypes && employeeRelationship.employeeContractTypeId != contractTypes.majorprovider" [required]=" employeeRelationship.employeeContractTypeId == contractTypes.majorprovider" id="MajorProviderId" name="MajorProviderId">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let provider of majorProviders" [ngValue]="provider.id">{{provider.title}}</option>
                                </select>
                                <af-help helpid="Hollen.Operation.EmployeeInsertUpdateViewModel.EmployeeMajorProviderId"></af-help>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="BranchHollenCompanyCode">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.hollen company' | translate }}</label>
                            <div class="col-md-8">
                                <select class="form-control form-select" [ngModel]="employeeRelationship.branchHollenCompanyCode" (ngModelChange)="onHollenCompanyCodeChange($event)" id="BranchHollenCompanyCode" maxlength="3" name="BranchHollenCompanyCode" required="">
                                    <option *ngFor="let hollenCompany of hollenCompanies" [ngValue]="hollenCompany.code">{{hollenCompany.title}}</option>
                                </select>
                                <af-help helpid="Hollen.Operation.EmployeeRelationshipInsertUpdateViewModel.BranchHollenCompanyCode"></af-help>
                                <span><span *ngIf="(updateEmployeeRelationshipForm.controls['BranchHollenCompanyCode']?.dirty || updateEmployeeRelationshipForm.submitted) && updateEmployeeRelationshipForm.controls['BranchHollenCompanyCode']?.hasError('maxlength')" class="field-validation-error">{{ 'validator.the field {0} must be a string or array type with a maximum  length of \'{1}\'.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.hollen company', '1': '3'} }}</span>
<span *ngIf="(updateEmployeeRelationshipForm.controls['BranchHollenCompanyCode']?.dirty || updateEmployeeRelationshipForm.submitted) && updateEmployeeRelationshipForm.controls['BranchHollenCompanyCode']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.hollen company'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="EmploymentContractExpiryDate">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.employment contract expiration date' | translate }}</label>
                            <div class="col-md-8">
                                <div class="fixed-datepicker">
                                    <af-datepicker [(ngModel)]="employeeRelationship.employmentContractExpiryDate" name="EmploymentContractExpiryDate"></af-datepicker>
                                    <af-help helpid="Hollen.Operation.EmployeeRelationshipInsertUpdateViewModel.EmploymentContractExpiryDate"></af-help>
                                </div>
                                <span><span *ngIf="(updateEmployeeRelationshipForm.controls['EmploymentContractExpiryDate']?.dirty || updateEmployeeRelationshipForm.submitted) && updateEmployeeRelationshipForm.controls['EmploymentContractExpiryDate']?.hasError('date')" class="field-validation-error">{{ 'validator.{0} is not valid date format.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.employment contract expiration date'} }}</span>
<span *ngIf="(updateEmployeeRelationshipForm.controls['EmploymentContractExpiryDate']?.dirty || updateEmployeeRelationshipForm.submitted) && updateEmployeeRelationshipForm.controls['EmploymentContractExpiryDate']?.hasError('daterange')" class="field-validation-error">{{ 'validator.{0} is out of its valid range.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.employment contract expiration date'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="EmployeePositionTypeId">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.position' | translate }}</label>
                            <div class="col-md-8">
                                <select class="form-control form-select" [(ngModel)]="employeeRelationship.employeePositionTypeId" id="EmployeePositionTypeId" name="EmployeePositionTypeId">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let position of employeePositionTypes" [ngValue]="position.id">{{position.title}}</option>
                                </select>
                                <af-help helpid="Hollen.Operation.EmployeeRelationshipInsertUpdateViewModel.EmployeePositionTypeId"></af-help>
                                <span></span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-md-12">
                                <button type="button"
                                        class="btn btn-default btn-sm float-end"
                                        (click)="addEmployeePositionItem()">
                                    {{ 'hollen.clientapp.src.app.operation.employee-relationship.employee-relationship-edit.component.add other position' | translate }}
                                </button>
                            </div>
                        </div>
                        <div class="form-group" *ngFor="let item of employeeRelationship.employeePositionTypeIds; let $index = index; trackBy: trackByIndexFn">
                            <label [for]="'EmployeePositionTypeId' + $index" class="control-label col-md-4">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.other position' | translate }}</label>
                            <div class="col-md-8">
                                <div class="input-group">
                                    <select [name]="'EmployeePositionTypeId' + $index" class="form-control form-select" [ngModel]="item" (ngModelChange)="setEmployeePositionItem($event, $index)">
                                        <option [ngValue]="0"></option>
                                        <option *ngFor="let position of employeePositionTypes" [ngValue]="position.id">{{position.title}}</option>
                                    </select>
                                    <button type="button" class="btn btn-danger btn-sm" (click)="deleteEmployeePositionItem($index)">{{ 'hollen.clientapp.src.app.operation.employee-relationship.employee-relationship-edit.component.remove' | translate }}</button>
                                </div>
                                <af-help helpid="Hollen.Operation.EmployeeRelationshipInsertUpdateViewModel.EmployeePositionTypeIds"></af-help>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="DirectSupervisorUserId">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.direct supervisor' | translate }}</label>
                            <div class="col-md-8">
                                <select class="form-control form-select" [(ngModel)]="employeeRelationship.directSupervisorUserId" id="DirectSupervisorUserId" name="DirectSupervisorUserId">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let user of users" [ngValue]="user.userId">{{user.fullName}}</option>
                                </select>
                                <af-help helpid="Hollen.Operation.EmployeeRelationshipInsertUpdateViewModel.DirectSupervisorUserId"></af-help>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-actions">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="offset-md-1 col-md-11">

                                    <button *afHasPerm="{obj:objectKey, perm:'Update'}" class="btn btn-update btn-primary" type="submit">{{ 'btn.save' | translate }}</button>
                                    <ng-container>
                                        <a *afHasPerm="{obj:objectKey, perm: 'Insert'}" class="btn-new btn-success btn" [routerLink]="['/operation/employee',employeeRelationship.employeeId,'employeeRelationship','create']">{{ 'btn.new' | translate }}</a>
                                    </ng-container>
                                    <a class="btn-back btn-warning btn" onclick="history.back()">{{ 'btn.back' | translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
