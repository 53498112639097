import { async } from '../scheduler/async';
import { isDate } from '../util/isDate';
import { OuterSubscriber } from '../OuterSubscriber';
import { subscribeToResult } from '../util/subscribeToResult';
export function timeoutWith(due, withObservable, scheduler = async) {
  return source => {
    let absoluteTimeout = isDate(due);
    let waitFor = absoluteTimeout ? +due - scheduler.now() : Math.abs(due);
    return source.lift(new TimeoutWithOperator(waitFor, absoluteTimeout, withObservable, scheduler));
  };
}
class TimeoutWithOperator {
  constructor(waitFor, absoluteTimeout, withObservable, scheduler) {
    this.waitFor = waitFor;
    this.absoluteTimeout = absoluteTimeout;
    this.withObservable = withObservable;
    this.scheduler = scheduler;
  }
  call(subscriber, source) {
    return source.subscribe(new TimeoutWithSubscriber(subscriber, this.absoluteTimeout, this.waitFor, this.withObservable, this.scheduler));
  }
}
class TimeoutWithSubscriber extends OuterSubscriber {
  constructor(destination, absoluteTimeout, waitFor, withObservable, scheduler) {
    super(destination);
    this.absoluteTimeout = absoluteTimeout;
    this.waitFor = waitFor;
    this.withObservable = withObservable;
    this.scheduler = scheduler;
    this.action = null;
    this.scheduleTimeout();
  }
  static dispatchTimeout(subscriber) {
    const {
      withObservable
    } = subscriber;
    subscriber._unsubscribeAndRecycle();
    subscriber.add(subscribeToResult(subscriber, withObservable));
  }
  scheduleTimeout() {
    const {
      action
    } = this;
    if (action) {
      this.action = action.schedule(this, this.waitFor);
    } else {
      this.add(this.action = this.scheduler.schedule(TimeoutWithSubscriber.dispatchTimeout, this.waitFor, this));
    }
  }
  _next(value) {
    if (!this.absoluteTimeout) {
      this.scheduleTimeout();
    }
    super._next(value);
  }
  _unsubscribe() {
    this.action = null;
    this.scheduler = null;
    this.withObservable = null;
  }
}
