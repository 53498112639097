<div class="card">
    <div class="card-header">{{ 'hollen.clientapp.src.app.operation.report.leistungsnachweis-report.component.leistungsnachweis' | translate }}</div>
    <div class="card-block card-report form-horizontal">
        <div>
            <!-- SV -->
            <div *ngIf="params.branchId === 1">
                <div>
                    <div class="row form-group">
                        <div class="col-md-7 control-label">
                            {{ 'hollen.clientapp.src.app.operation.report.leistungsnachweis-report.component.bonus vw' | translate }}
                        </div>
                        <div class="col-md-5">
                            <af-checkbox [(ngModel)]="request.bonusVw" name="bonusVw"></af-checkbox>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="row form-group">
                        <div class="col-md-7 control-label">
                            {{ 'hollen.clientapp.src.app.operation.report.leistungsnachweis-report.component.bonus faurecia' | translate }}
                        </div>
                        <div class="col-md-5">
                            <af-checkbox [(ngModel)]="request.bonusFaurecia" name="bonusFaurecia"></af-checkbox>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="row form-group">
                        <div class="col-md-7 control-label">
                            {{ 'hollen.clientapp.src.app.operation.report.leistungsnachweis-report.component.bonus' | translate }}
                        </div>
                        <div class="col-md-5">
                            <af-checkbox [(ngModel)]="request.bonusNoWarenfilter" name="bonusNoWarenfilter"></af-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <!-- SP -->
            <div *ngIf="params.branchId === 2">
                <div>
                    <div class="row form-group">
                        <div class="col-md-7 control-label">
                            {{ 'hollen.clientapp.src.app.operation.report.leistungsnachweis-report.component.bonus psa' | translate }}
                        </div>
                        <div class="col-md-5">
                            <af-checkbox [(ngModel)]="request.bonusPsa" name="bonusPsa"></af-checkbox>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="row form-group">
                        <div class="col-md-7 control-label">
                            {{ 'hollen.clientapp.src.app.operation.report.leistungsnachweis-report.component.bonus faurecia' | translate }}
                        </div>
                        <div class="col-md-5">
                            <af-checkbox [(ngModel)]="request.bonusFaurecia" name="bonusFaurecia"></af-checkbox>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="row form-group">
                        <div class="col-md-7 control-label">
                            {{ 'hollen.clientapp.src.app.operation.report.leistungsnachweis-report.component.gefco' | translate }}
                        </div>
                        <div class="col-md-5">
                            <af-checkbox [(ngModel)]="request.gefco" name="gefco"></af-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ZA -->
            <div *ngIf="params.branchId === 3">
                <div>
                    <div class="row form-group">
                        <div class="col-md-7 control-label">
                            {{ 'hollen.clientapp.src.app.operation.report.leistungsnachweis-report.component.bonus faurecia' | translate }}
                        </div>
                        <div class="col-md-5">
                            <af-checkbox [(ngModel)]="request.bonusFaurecia" name="bonusFaurecia"></af-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <!-- CS, CT -->
            <div *ngIf="params.branchId === 4 || params.branchId === 5">
                <div>
                    <div class="row form-group">
                        <div class="col-md-7 control-label">
                            {{ 'hollen.clientapp.src.app.operation.report.leistungsnachweis-report.component.outland' | translate }}
                        </div>
                        <div class="col-md-5">
                            <af-checkbox [(ngModel)]="request.outland" name="outland"></af-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-12">
                    <a [href]="getUrl()" [class.disabled]="!params.projectTaskId" class="btn btn-default-alt btn-generate btn-large-icon" title="{{ 'btn.generate' | translate }}"><i class="fa fa-download"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
