import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { finalize, switchMap, tap } from "rxjs/operators";
import { FormComponent } from "../../core";
import { AccommodationService } from "../accommodation/accommodation.service";
import { AccommodationTermService } from "./accommodation-term.service";

@Component({
    selector: "op-accommodation-term-edit",
    templateUrl: "./accommodation-term-edit.component.html"
})
export class AccommodationTermEditComponent extends FormComponent {

    @Input() input = new Observable<{ accommodationTermId: number }>();
    @Output() updated = new EventEmitter<any>();
    @Output() canceled = new EventEmitter<any>();
    @ViewChild("editForm", { static: true }) editForm: NgForm;
    subscription: Subscription;
    model = <app.operation.AccommodationTermInsertUpdateViewModel>{};
    objectKey: string;
    accommodations: app.operation.AccommodationSelectListViewModel[];
    accommodationTypes: app.operation.AccommodationTypeSelectListViewModel[];

    constructor(protected router: Router,
        private accommodationTermService: AccommodationTermService,
        private accommodationService: AccommodationService) {
        super(router);
        this.objectKey = accommodationTermService.objectKey;
    }

    ngOnInit() {
        super.ngOnInit();

        this.subscription = this.input
            .pipe(tap(() => this.isLoading = true),
                switchMap(x => this.accommodationTermService.get(x.accommodationTermId, true)
                    .pipe(finalize(() => this.isLoading = false))))
            .subscribe(res => {
                this.model = res.model;
                this.accommodations = res.accommodations;
                this.accommodationTypes = res.accommodationTypes;
            });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    update() {
        if (this.editForm.invalid) {
            return;
        }

        if (this.model.invoicedRate != null) this.model.invoicedRate = Math.floor(this.model.invoicedRate);

        this.isLoading = true;
        this.accommodationTermService.update(this.model, this.model.accommodationTermId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.updated.emit();
            });
    }

    cancel() {
        this.canceled.emit();
    }

    onChange(event: number) {
        if (!event) return;

        this.model.accommodationTypeId = null;

        this.accommodationService.getTypeSelectList(event)
            .subscribe(res => this.accommodationTypes = res)
    }

    reset() {
        this.model.note = null;
    }
}
