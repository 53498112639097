
<af-table #afTable [value]="model" [loading]="isLoading" [params]="queryParams" [sortBy]="sortBy" (load)="loadData()">

    <af-column field="relationshipOrder" sortable="relationshipOrder" header="{{ 'hollen.operation.employeerelationshiplistviewmodel.relationship n.' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a [routerLink]="['/operation/employee', item.employeeId, 'employeeRelationship', item.employeeRelationshipId]">{{item.relationshipOrder}}</a>
        </ng-template>
    </af-column>
    <af-column field="employeeContractTypeTitle" sortable="employeeContractTypeTitle" header="{{ 'hollen.operation.employeerelationshiplistviewmodel.contract type' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a *ngIf="item.agencyId" [routerLink]="['/crm/company', item.agencyId]"> {{item.employeeContractTypeTitle}} - {{item.agencyTitle}}</a>
            <a *ngIf="item.majorProviderId" [routerLink]="['/crm/company', item.majorProviderId]"> {{item.employeeContractTypeTitle}} - {{item.majorProviderTitle}}</a>
            <ng-container *ngIf="!item.agencyId && !item.majorProviderId">{{item.employeeContractTypeTitle}}</ng-container>

        </ng-template>
    </af-column>
    <af-column field="companyTitle" sortable="companyTitle" header="{{ 'hollen.operation.employeerelationshiplistviewmodel.company' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.companyTitle}}
        </ng-template>
    </af-column>
    <af-column field="employeePositionTypeTitle" sortable="employeePositionTypeTitle" header="{{ 'hollen.operation.employeerelationshiplistviewmodel.position' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.employeePositionTypeTitle }}
        </ng-template>
    </af-column>
    <af-column field="employmentContractExpiryDate" sortable="employmentContractExpiryDate" header="{{ 'hollen.operation.employeerelationshiplistviewmodel.employment contract expiry date' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.employmentContractExpiryDate | afDate:'DD.MM.YYYY'}}
        </ng-template>
    </af-column>
    <af-column field="directSupervisorFullName" sortable="directSupervisorFullName" header="{{ 'hollen.operation.employeerelationshiplistviewmodel.direct supervisor' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.directSupervisorFullName}}
        </ng-template>
    </af-column>
    <af-column field="startDate" sortable="startDate" header="{{ 'hollen.operation.employeerelationshiplistviewmodel.cooperation start date' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.startDate | afDate:'DD.MM.YYYY'}}
        </ng-template>
    </af-column>
    <af-column field="endDate" sortable="endDate" header="{{ 'hollen.operation.employeerelationshiplistviewmodel.cooperation end date' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.endDate | afDate:'DD.MM.YYYY'}}
        </ng-template>
    </af-column>

    <af-column field="statusTitle" header="{{ 'hollen.operation.employeerelationshiplistviewmodel.status' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.statusTitle}}
        </ng-template>
    </af-column>
    <af-column>
        <ng-template let-item="rowData" afTemplate>
            <button *afHasPerm="{obj:objectKey, perm: 'Update'}" type="button" class="btn btn-default btn-sm" [routerLink]="['/operation/employee', item.employeeId, 'employeeRelationship', item.employeeRelationshipId]" >{{ 'btn.edit' | translate }}</button>
        </ng-template>
    </af-column>
</af-table>
