import { Observable, of } from "rxjs";
import { Component, OnInit, EventEmitter } from "@angular/core";
import { NgbModal, NgbActiveModal, NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute } from "@angular/router";

import { ReportProjectTaskService, ReportProjectTaskRequest, ReportType } from "./report-project-task.service";
import { ProjectTaskService } from "./../project-task/project-task.service";
import { WorkPlaceService } from "./../../organization/work-place/work-place.service";
import { AppService } from "../../app.service";
import { filterAndDebounce } from "../../core/utils";
import { switchMap, distinctUntilChanged, debounceTime, tap, finalize } from "rxjs/operators";

@Component({
    selector: "op-report-project-task",
    styleUrls: ["./report-project-task.component.scss"],
    templateUrl: "./report-project-task.component.html"
})
export class ReportProjectTaskComponent implements OnInit {
    objectKey: string;
    queryParams = new ReportProjectTaskRequest();
    reports: app.sys.ReportViewModel[];
    branches: app.organization.BranchExtendedSelectListViewModel[];
    languages: af.SelectViewModel[];
    projectTask: app.operation.ProjectTaskUpdateViewModel;
    selectedReport = ReportType.None;
    selectedProjectTask: app.operation.ProjectTaskFullSelectListViewModel;
    selectedBranch: app.organization.BranchExtendedSelectListViewModel;
    isProjectTaskDetail = false;
    currentUser: app.UserViewModel;
    isLoading: boolean;
    projectTaskFilterItems = [];
    projectTaskTypeahead = new EventEmitter<string>();
    projectTaskAutocompletePage = 1;
    isProjectTaskAutocompleteLoading = false;
    reportData: app.operation.ProjectTaskReportViewModel;

    constructor(private route: ActivatedRoute,
        private reportService: ReportProjectTaskService,
        private projectTaskService: ProjectTaskService,
        private appService: AppService) {
        this.currentUser = appService.homeViewModel.user;
        this.objectKey = reportService.objectKey;
        this.resetFilter();
    }

    ngOnInit() {
        let projectTaskId = this.route.parent.snapshot.params["projectTaskId"];

        this.isProjectTaskDetail = !!projectTaskId;

        this.isLoading = true;

        this.reportService.getFilter(projectTaskId).subscribe(res => {
            this.branches = res.branches;
            this.languages = res.languages.filter(x => ["sk","cs","en","de"].includes(x.key));
            if (res.branchId) {
                this.queryParams.branchId = res.branchId;
            }
            if (res.projectTask) {
                this.projectTask = res.projectTask;
                this.selectedProjectTask = {
                    projectTaskId: res.projectTask.projectTaskId,
                    title: res.projectTask.title,
                    workPlaceTitle: res.projectTask.workPlaceTitle,
                    fullProjectTaskNumber: res.projectTask.projectTaskNumber,
                    accountCenterCode: null
                };
                this.selectProjectTask(res.projectTask);
            }
            this.updateBranch();
            this.loadReports();
        });

        this.projectTaskAutocomplete();
    }

    loadReports() {
        this.isLoading = true;
        this.reportService.getReports(this.queryParams).subscribe(result => {
            this.reports = result;
            this.isLoading = false;
        });
    }

    projectTaskAutocomplete() {
        this.projectTaskTypeahead.pipe(
            distinctUntilChanged(),
            debounceTime(300),
            tap(() => { this.isProjectTaskAutocompleteLoading = true; }),
            switchMap(term => (!term || term.length < 2) ? of([]) : this.projectTaskService.getFullList(1, 20, term, null, this.queryParams.branchId)),
            tap(() => { this.isProjectTaskAutocompleteLoading = false; this.projectTaskAutocompletePage = 1; })
        ).subscribe(x => {            
            this.projectTaskFilterItems = x;
        }, (err) => {
            console.log(err);
            this.isProjectTaskAutocompleteLoading = false;
            this.projectTaskFilterItems = [];
        });
    }

    projectTaskAutocompleteFetchMore(term: string) {
        this.projectTaskAutocompletePage++;
        this.isProjectTaskAutocompleteLoading = true;
        this.projectTaskService.getFullList(this.projectTaskAutocompletePage, 20, term, null, this.queryParams.branchId)
            .pipe(finalize(() => this.isProjectTaskAutocompleteLoading = false))
            .subscribe(res => {                
                this.projectTaskFilterItems = [...this.projectTaskFilterItems, ...res];
            });
    }

    onProjectTaskSelect($event) {
        this.selectedProjectTask = $event;
        if ($event) {
            this.projectTaskService.get($event.projectTaskId, false).subscribe(res => {
                this.selectProjectTask(res.model);
                this.loadReports();
            });
        }
    }

    selectProjectTask(model: app.operation.ProjectTaskUpdateViewModel) {
        this.projectTaskService.getReportData(model.projectTaskId, this.queryParams.languageId).subscribe(res => {
            this.reportData = res;
        });

        let startDate = moment(model.startDate);
        let now = moment();

        if (model.reportLanguageId) {
            this.queryParams.languageId = model.reportLanguageId;
        }
        this.queryParams.from = startDate.startOf("month").toDate();
        this.queryParams.to = (startDate.year() === now.year() && startDate.month() === now.month()) ? now.toDate() : startDate.endOf("month").toDate();
        this.updateParams();
    }

    updateParams() {
        if (this.selectedProjectTask) {
            this.queryParams.projectTaskId = this.selectedProjectTask.projectTaskId;
        }
        else {
            this.queryParams.projectTaskId = 0;
        }
        this.updateBranch();
        this.queryParams = Object.assign({}, this.queryParams);
    }

    branchChanged(branchId: number) {
        this.queryParams.branchId = +branchId;
        this.updateParams();
    }

    updateBranch() {
        this.selectedBranch = this.queryParams.branchId ? this.branches.filter(x => x.branchId === this.queryParams.branchId)[0] : null;
    }

    selectReport(type: ReportType) {
        this.selectedReport = type;
    }

    isAvailable(type: ReportType) {
        if (!this.branches) {
            return false;
        }

        return this.reportService.isReportAvailable(type, this.selectedBranch.accountCenters);
    }

    isVisible(type: ReportType) {
        return (this.selectedReport === ReportType.None || this.selectedReport === type) && this.isAvailable(type);
    }

    onLanguageChange() {
        if (this.selectedReport > 0) {
            this.selectedReport = 0;
        }
        this.updateParams();
        this.loadReports();
    }

    resetFilter() {
        this.queryParams.branchId = this.currentUser.branchId;
        this.queryParams.languageId = this.currentUser.languageByCountry;
        this.queryParams.from = moment().startOf("month").toDate();
        this.queryParams.to = moment().toDate();
    }

    cancelFilter() {
        this.selectedReport = ReportType.None;
    }
}
