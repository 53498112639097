import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";

@Injectable()
export class EventService extends HttpService<af.sys.EventListViewModel, af.sys.EventFormViewModel, af.sys.EventInsertUpdateViewModel> {
    objectKey = "sy.event";

    constructor(http: HttpClient) {
        super(http, "/api/sys/event/");
    }

    getByUserId(userId: number) {
        return this.http.get<af.sys.EventUserViewModel>(this.url + "user-subscriptions/" + userId);
    }

    updateUserSubscriptions(userId: number, subscriptions: af.sys.EventSubscriptionViewModel[]) {
        return this.http.put(this.url + "user-subscriptions/" + userId, subscriptions);
    }

    getByCurrentUser() {
        return this.http.get<af.sys.EventUserViewModel>(this.url + "user-subscriptions/me");
    }

    updateCurrentUserSubscriptions(subscriptions: af.sys.EventSubscriptionViewModel[]) {
        return this.http.put(this.url + "user-subscriptions/me", subscriptions);
    }

    getByEventId(eventId: number) {
        return this.http.get<af.sys.EventSubscriptionListViewModel[]>(this.url + eventId + "/subscriptions");
    }

    getEventTypes() {
        return this.http.get<af.sys.EventTypeSelectViewModel[]>(this.url + "eventtypes");
    }

    updateSubscriptions(eventId: number, subscriptions: af.sys.EventSubscriptionListViewModel[]) {
        return this.http.put(this.url + eventId + "/subscriptions", subscriptions);
    }

    getLogs(parameters?: any) {
        return this.http.get<PagedList<af.sys.EventLogViewModel>>(this.url + "logs", {
            params: this.getParameters(parameters)
        });
    }

    getMetriqByCurrentUser() {
        return this.http.get<af.sys.EventUserViewModel>(this.url + "metriq/user-subscriptions/me");
    }

    updateMetriqCurrentUserSubscriptions(subscriptions: af.sys.EventSubscriptionViewModel[]) {
        return this.http.put(this.url + "metriq/user-subscriptions/me", subscriptions);
    }
}
