import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { getParameters } from "../../core/utils";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class ProjectTaskProductRateService {
    url = "/api/operation/projecttaskproductrate/";
    objectKey = "op.ProjectTaskProductRate";

    constructor(private http: HttpClient) {
    }


    get(projectTaskId: number): Observable<app.operation.ProjectTaskProductRateInsertUpdateViewModel[]> {
        let params = new HttpParams();
        params = params.set("projectTaskId", projectTaskId.toString());
        return this.http.get<app.operation.ProjectTaskProductRateInsertUpdateViewModel[]>(this.url, { params });
    }

    getAmounts(projectTaskId: number): Observable<app.operation.ProjectTaskProductRateAmountViewModel[]> {
        let params = new HttpParams();
        params = params.set("projectTaskId", projectTaskId.toString());
        return this.http.get<app.operation.ProjectTaskProductRateAmountViewModel[]>(this.url + "amounts", { params });
    }

    getFilter(): Observable<app.operation.ProjectTaskProductRateFilterViewModel> {
        return this.http.get<app.operation.ProjectTaskProductRateFilterViewModel>(this.url + "filter");
    }

    insert(projectTaskId: number, rates: app.operation.ProjectTaskProductRateInsertUpdateViewModel[]): Observable<any> {
        let params = new HttpParams();
        params = params.set("projectTaskId", projectTaskId.toString());
        return this.http.post(this.url, rates, { params });
    }

    update(rate: app.operation.ProjectTaskProductRateInsertUpdateViewModel): Observable<any> {
        return this.http.put(this.url, rate);
    }

    change(item: app.operation.ProjectTaskProductRateChangeViewModel): Observable<any> {
        return this.http.post(this.url + "change", item);
    }

    delete(projectTaskId: number, productRateId: number): Observable<any> {
        let params = new HttpParams();
        params = params.set("projectTaskId", projectTaskId.toString());
        params = params.set("productRateId", productRateId.toString());
        return this.http.delete(this.url, { params });
    }

    getInvoice(params: any): Observable<PagedList<app.operation.ProjectTaskProductRateInvoiceViewModel>> {
        return this.http.get<PagedList<app.operation.ProjectTaskProductRateInvoiceViewModel>>(this.url + "invoice", { params: getParameters(params) });
    }

    getInvoiceReport(params: any) {
        let parameters = getParameters(params);
        return this.url + `invoice-report?${parameters.toString()}`;
    }

    getNotInvoicedProjectTaskReport(params: any) {
        let parameters = getParameters(params);
        return this.url + `not-invoiced-projecttask-report?${parameters.toString()}`;
    }
}
