<div class="page-sidebar-wrapper" *ngIf="!!menu">
    <div class="page-sidebar navbar-collapse collapse" [class.in]="!isCollapsed">
        <ul class="page-sidebar-menu" [ngClass]="{'page-sidebar-menu-closed': isClosed}">
            <li class="sidebar-toggler-wrapper">
                <div class="sidebar-toggler" (click)="toggleSidebar()">
                    <i class="fa-solid fa-bars"></i>
                </div>
            </li>
            <li *ngFor="let item of menu; let i = index" [ngClass]="{start: (i === 0), last: (i === menu.length - 1), open: item.isOpen, active: item.isActive, 'has-children': item.hasSubMenu}">
                <ng-container *ngIf="item.hasSubMenu;else without_children">
                    <a (click)="toggleMenu(item)">
                        <i class="icon-menu-{{ item.code | afReplace:'\\.':'-' }}"></i>
                        <span class="title">{{ item.title }}</span>
                        <span class="arrow" *ngIf="item.children.length > 0" [ngClass]="{open: item.isOpen}"></span>
                    </a>
                    <af-sub-menu [items]="item.children" [isOpen]="item.isOpen" (change)="onSubMenuChange()"></af-sub-menu>
                </ng-container>
                <ng-template #without_children>
                    <a [routerLink]="item.url" [title]="item.title">
                        <i class="sidebar-icon-menu-{{ item.code | afReplace:'\\.':'-' }}"></i>
                        <span class="title">{{ item.title }}</span>
                    </a>
                </ng-template>
            </li>
        </ul>
        <div class="app-version" *ngIf="!isClosed">
            {{ 'app.version.version' | translate }} {{ versionDate | afDate: 'L LTS' }}
            <af-app-version></af-app-version>
        </div>
    </div>
</div>
