import { B2bService } from "../b2b/b2b.service";
import { OrderNoteService } from "../b2b/order-note.service";
import { OtherAgreementsService } from "../b2b/other-agreements.service";
import { IntDocService } from "./int-doc.service";
import { WagesService } from "./wages/wages.service";

export const providers = [
    B2bService,
    OrderNoteService,
    OtherAgreementsService,
    WagesService,
    IntDocService,
];
