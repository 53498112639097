import { Component, Input, SimpleChange, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "af-page-bar",
    styleUrls: ["./page-bar.component.scss"],
    template: `
        <div class="page-bar">
            <h3 class="page-title">
                <af-title [title]="title"></af-title>
                {{ header }}
                <ng-container *ngIf="helpid">
                    <af-help class="help-static" [helpid]="helpid"></af-help>
                </ng-container>
            </h3>
            <div class="page-toolbar" [ngStyle]="contentstyle">
                <div class="page-toolbar-buttons float-end">
                    <ng-content></ng-content>
                 </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageBarComponent {
    @Input() title: string;
    @Input() header: string;
    @Input() contentstyle: string;
    @Input() helpid: string;
}
