import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class ReportMetricTemplateService
    extends HttpService<app.codebook.ReportMetricTemplateListViewModel, app.codebook.ReportMetricTemplateFormViewModel, app.codebook.ReportMetricTemplateInsertUpdateViewModel>
{
    objectKey = "cb.ReportMetricTemplate";

    constructor(http: HttpClient) {
        super(http, "/api/codebook/reportmetrictemplate/");
    }

    getFilter(): Observable<app.codebook.ReportMetricTemplateFilterViewModel> {
        return this.http.get<app.codebook.ReportMetricTemplateFilterViewModel>(this.url + "filter");
    }

    getList(page?: number, pageSize?: number, searchTerm?: string, accountCenterCode?: string): Observable<app.codebook.ReportMetricTemplateListViewModel[]> {

        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        if (page) {
            params = params.set("page", page.toString());
        }
        if (pageSize) {
            params = params.set("pageSize", pageSize.toString());
        }
        if (accountCenterCode) {
            params = params.set("accountCenterCode", accountCenterCode);
        }
    
        return this.http.get<app.codebook.ReportMetricTemplateListViewModel[]>(this.url + "list", { params });
    }

}
