import { Component, Input, ChangeDetectionStrategy } from "@angular/core";

import { ReportProjectTaskService, ReportProjectTaskRequest } from "./report-project-task.service";

@Component({
    selector: "op-warenfilter-report",
    templateUrl: "./warenfilter-report.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarenfilterReportComponent {
    @Input() params: ReportProjectTaskRequest;

    request = <app.operation.WarenFilterRequest>{};

    constructor(private reportService: ReportProjectTaskService) { }

    getUrl() {
        let requestParams = Object.assign({}, this.params, this.request);
        return this.reportService.getWarenfilter(requestParams);
    }
}
