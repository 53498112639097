import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class EmployeePositionTypeService
    extends HttpService<app.codebook.CodebookListViewModel, app.codebook.CodebookFormViewModel, dotnet.KeyValuePair[]>
{
    objectKey: string;
    baseUrl = "/api/codebook/employeePositionType/";

    constructor(http: HttpClient) {
        super(http, null);
    }

    getNew(parameters?: any) {
        return this.http.get<app.codebook.EmployeePositonTypeFormViewModel>(this.baseUrl + "new", {
            params: this.getParameters(parameters)
        });
    }
}
