import { Pipe, PipeTransform, LOCALE_ID, Inject } from "@angular/core";
import { DecimalPipe } from "@angular/common";

@Pipe({
    name: "afFileSize"
})
export class FileSizePipe implements PipeTransform {
    decimalPipe: DecimalPipe;

    constructor(@Inject(LOCALE_ID) locale: string) {
        this.decimalPipe = new DecimalPipe(locale);
    }

    transform(bytes, precision) {
        if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
            return "";
        }

        if (typeof precision === "undefined") {
            precision = "1";
        }

        const units = ["b", "kB", "MB", "GB", "TB", "PB"];

        if (bytes === 0) {
            return "0 " + units[0];
        }

        let num = Math.floor(Math.log(bytes) / Math.log(1024));

        let val = bytes / Math.pow(1024, Math.floor(num));

        return this.decimalPipe.transform(val, "1." + "0-" + precision) + " " + units[num];
    }
}
