import { Component, AfterContentInit, OnInit, DoCheck, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { ChangeDetectorRef, ChangeDetectionStrategy, ContentChild, ContentChildren, IterableDiffers, QueryList } from "@angular/core";

import { ColumnComponent } from "./column.component";
import { TableHeaderComponent } from "./table-header.component";
import { TableFooterComponent } from "./table-footer.component";
import { ListQueryParams } from "../../../models/list-query-params.model";
import { DomHandler } from "../dom/domhandler";
import { TableExpandedRowComponent } from "./table-expanded-row.component";
import { FixedTableHeadersDirective } from "../../directives/fixed-table-headers.directive";

@Component({
    selector: "af-table",
    templateUrl: "./table.component.html",
    providers: [DomHandler],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TableComponent implements DoCheck, AfterContentInit {
    @ViewChild(FixedTableHeadersDirective) afFixedTableHeaders: FixedTableHeadersDirective;
    @Input() loading = false;
    @Input() timeout = 1000;
    @Input() noRecordsFoundText: string = null;
    @Input() get value(): PagedList<any> {
        return this._value;
    }

    set value(val: PagedList<any>) {
        if (val) {
            this.loading = false;
        }
        this._value = val;
        if (this.afFixedTableHeaders) this.afFixedTableHeaders.resize();
        this.changeDetectorRef.markForCheck();
    }

    @Input() params: ListQueryParams;

    @Input() paginator = true;

    @Input() showCount = true;

    @Input() pageSize = 20;

    @Input() pageSizes: number[] = [20, 50, 100];

    @Input() pageLinks = 11;

    @Input() selectionMode: string;

    @Input() selection: any;

    @Input() hover: boolean = true;

    @Input() tableClasses: string;

    @Output() selectionChange: EventEmitter<any> = new EventEmitter();

    @Input() editable: boolean;

    @Output() rowSelect: EventEmitter<any> = new EventEmitter();

    @Output() rowUnselect: EventEmitter<any> = new EventEmitter();

    @Output() rowDblclick: EventEmitter<any> = new EventEmitter();

    @Output() load: EventEmitter<any> = new EventEmitter();

    @Input() headerRows: any;

    @Input() footerRows: any;

    @Input() tableClass: string;

    @Input() rowClass: (value: any) => string[];

    @Input() sortBy: string;

    @Output() editInit: EventEmitter<any> = new EventEmitter();

    @Output() editComplete: EventEmitter<any> = new EventEmitter();

    @Output() edit: EventEmitter<any> = new EventEmitter();

    @Output() editCancel: EventEmitter<any> = new EventEmitter();

    @ContentChild(TableHeaderComponent, {static: true}) header;

    @ContentChild(TableFooterComponent, {static: true}) footer;

    @ContentChild(TableExpandedRowComponent, {static: true}) expanded;

    @ContentChildren(ColumnComponent) cols: QueryList<ColumnComponent>;

    protected dataToRender: ReadonlyArray<any>;
    private page = 1;
    protected columns: ColumnComponent[];
    private differ: any;
    private preventBlurOnEdit: boolean;
    private _value: PagedList<any>;

    constructor(private domHandler: DomHandler, differs: IterableDiffers, private changeDetectorRef: ChangeDetectorRef) {
        this.differ = differs.find([]).create(null);
    }

    loadData() {
        this.params.page = this.page;
        this.params.pageSize = this.pageSize;
        this.load.next({});
    }

    ngAfterContentInit() {
        this.updateColumns();
        this.cols.changes.subscribe(_ => {
            this.updateColumns();
        });
    }

    updateColumns() {
        this.columns = this.cols.toArray();
    }

    ngDoCheck() {
        if (!this.value) {
            return;
        }

        let changes = this.differ.diff(this.value.data);

        if (changes) {
            this.updateDataToRender(this.value.data);
        }
    }

    resolveFieldData(data: any, field: string): any {
        if (data && field) {
            if (field.indexOf(".") === -1) {
                return data[field];
            } else {
                let fields: string[] = field.split(".");
                let value = data;
                for (let i = 0, len = fields.length; i < len; ++i) {
                    value = value[fields[i]];
                }
                return value;
            }
        } else {
            return null;
        }
    }

    paginate(event) {
        this.page = event.page;
        this.pageSize = event.pageSize;
        this.loadData();
    }

    updateDataToRender(datasource: ReadonlyArray<any>) {
        this.dataToRender = datasource;
    }

    sort(event, column: ColumnComponent) {
        if (!column.sortable) {
            return;
        }

        let sortField = this.params.sortField();
        let sortOrder = this.params.sortOrder();

        if (sortField === column.sortable) {
            sortOrder = sortOrder ? null : "desc";
        } else {
            sortField = column.sortable;
            sortOrder = null;
        }

        this.page = 1;
        this.params.sortBy = sortField ? (sortOrder ? sortField + " " + sortOrder : sortField) : null;

        this.loadData();
    }

    isSorted(column: ColumnComponent) {
        return (this.params && column.sortable === this.params.sortField());
    }

    getSortOrder(column: ColumnComponent) {
        let sortField = this.params.sortField();
        let sortOrder = this.params.sortOrder();

        if (sortField && column.sortable === sortField) {
            return sortOrder;
        }
        return null;
    }

    onRowClick(event, rowData) {
        if (!this.selectionMode) {
            return;
        }

        let selectionIndex = this.findIndexInSelection(rowData);
        let selected = selectionIndex !== -1;
        let metaKey = (event.metaKey || event.ctrlKey);

        if (selected && metaKey) {
            if (this.isSingleSelectionMode()) {
                this.selection = null;
                this.selectionChange.next(null);
            } else {
                this.selection.splice(selectionIndex, 1);
                this.selectionChange.next(this.selection);
            }

            this.rowUnselect.next({ originalEvent: event, data: rowData });
        } else {
            if (this.isSingleSelectionMode()) {
                this.selection = rowData;
                this.selectionChange.next(rowData);
            } else if (this.isMultipleSelectionMode()) {
                this.selection = (!metaKey) ? [] : this.selection || [];
                this.selection.push(rowData);
                this.selectionChange.next(this.selection);
            }

            this.rowSelect.next({ originalEvent: event, data: rowData, index: this.findIndexInSelection(rowData) });
        }
    }

    onRowDblclick(event, rowData) {
        this.rowDblclick.next({ originalEvent: event, data: rowData });
    }

    isSingleSelectionMode() {
        return this.selectionMode === "single";
    }

    isMultipleSelectionMode() {
        return this.selectionMode === "multiple";
    }

    findIndexInSelection(rowData: any) {
        let index: number = -1;

        if (this.selectionMode && this.selection) {
            if (this.isSingleSelectionMode()) {
                index = (this.selection === rowData) ? 0 : - 1;
            } else if (this.isMultipleSelectionMode()) {
                for (let i = 0; i < this.selection.length; i++) {
                    if (this.selection[i] === rowData) {
                        index = i;
                        break;
                    }
                }
            }
        }

        return index;
    }

    isSelected(rowData) {
        return this.findIndexInSelection(rowData) !== -1;
    }

    track(index) {
        return index;
    }

    switchCellToEditMode(element: any, column: ColumnComponent, rowData: any) {
        if (!this.selectionMode && this.editable && column.editable) {
            this.editInit.next({ column, data: rowData });
            let cell = this.findCell(element);
            if (!this.domHandler.hasClass(cell, "table-cell-editing")) {
                this.domHandler.addClass(cell, "table-cell-editing");
                this.domHandler.addClass(cell, "table-row-highlight");
                cell.querySelector(".table-cell-editor").focus();
            }
        }
    }

    switchCellToViewMode(element: any, column: ColumnComponent, rowData: any, complete: boolean) {
        if (this.editable) {
            if (this.preventBlurOnEdit) {
                this.preventBlurOnEdit = false;
            } else {
                if (complete) {
                    this.editComplete.next({ column, data: rowData });
                } else {
                    this.editCancel.next({ column, data: rowData });
                }
                let cell = this.findCell(element);
                this.domHandler.removeClass(cell, "table-cell-editing");
                this.domHandler.removeClass(cell, "table-row-highlight");
            }
        }
    }

    onCellEditorKeydown(event, column: ColumnComponent, rowData: any) {
        if (this.editable) {
            this.edit.next({ originalEvent: event, column, data: rowData });

            // enter
            if (event.keyCode === 13) {
                this.switchCellToViewMode(event.target, column, rowData, true);
                this.preventBlurOnEdit = true;
            }
            // escape
            if (event.keyCode === 27) {
                this.switchCellToViewMode(event.target, column, rowData, false);
                this.preventBlurOnEdit = true;
            }
        }
    }

    findCell(element) {
        let cell = element;
        while (cell.tagName !== "TD") {
            cell = cell.parentElement;
        }
        return cell;
    }

    hasFooter() {
        if (this.footerRows) {
            return true;
        } else {
            if (this.columns) {
                for (let i = 0; i < this.columns.length; i++) {
                    if (this.columns[i].footer) {
                        return true;
                    }
                }
            }

        }
        return false;
    }

    isEmpty() {
        return !this.dataToRender || (this.dataToRender.length === 0);
    }

    resetParams(): ListQueryParams {
        let params = new ListQueryParams();
        params.sortBy = this.sortBy;
        return params;
    }

    setRowClasses(rowData, even, odd, rowElement) {
        let classes = {
            "table-row-even": even,
            "table-row-odd": odd,
            "table-row-selectable": this.selectionMode,
            "table-row-selected": this.isSelected(rowData),
            "table-row-expanded": rowData.isExpanded
        };

        if (this.rowClass) {
            let addin = this.rowClass(rowData);
            if (addin && typeof (addin) === "object") {
                for (let prop in addin) {
                    if (addin.hasOwnProperty(prop)) {
                        classes[prop] = addin[prop];
                    }
                }
            }
        }

        return classes;
    }

    setColClasses(rowData, col) {
        let classes = {
            "table-editable-column": col.editable
        };

        if (col.dataColClass) {
            let addin = col.dataColClass(rowData);
            if (addin && typeof (addin) === "object") {
                for (let prop in addin) {
                    if (addin.hasOwnProperty(prop)) {
                        classes[prop] = addin[prop];
                    }
                }
            }
        }

        return classes;
    }

    getRowSpan(rowData, col) {
        if (col.dataRowSpan) {
            return col.dataRowSpan(rowData);
        }
        return 1;
    }

    getColSpan(rowData, col) {
        if (col.dataColSpan) {
            return col.dataColSpan(rowData);
        }
        return 1;
    }
}
