
import { ChangeDetectorRef, Component, OnInit, VERSION } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { PermissionService } from "../../core";

@Component({
    selector: "af-app-version",
    template: `
        <div *ngIf="canRead">
            <div *ngIf="info.framework">Powered by: {{ info.framework }}</div>
            <div *ngIf="version">Angular v.{{version}}</div>
            <div *ngIf="info.buildInfo.buildUrl"><a [href]="info.buildInfo.buildUrl" target="_blank">Build Number: {{ info.buildInfo.buildNumber }}</a></div>
        </div>
    `
})
export class AppVersionComponent implements OnInit {    
    canRead: boolean;
    info: any = {
        buildInfo: {}
    };
    version = VERSION.full;

    constructor(
        private http: HttpClient,
        private permissionService: PermissionService,
        private cdf: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.canRead = this.permissionService.hasPermission("sy.appversioninfo", "Read");

        if (this.canRead) {
            this.http
                .get("/api/sys/versioninfo")
                .subscribe(res => {
                    this.info = res;
                    this.cdf.markForCheck();
                });
        }
    }    
}
