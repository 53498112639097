<div *afHasPerm="{obj:objectKey, perm: 'Read'}">
    <div class="row">
        <div class="col-md-12">
            <h3 class="form-section">
                {{ 'hollen.clientapp.src.app.crm.company.b2b-advance.b2b-advance.component.documents for creating an advance b2b invoice' | translate }}
            </h3>
        </div>
    </div>

    <crm-company-b2b-advance-create *ngIf="displayMode == 'create'"
                                    [companyId]="companyId"
                                    [input]="create"
                                    (inserted)="onInsert($event)"
                                    (canceled)="onCancel()">
    </crm-company-b2b-advance-create>

    <crm-company-b2b-advance-edit *ngIf="displayMode == 'edit'"
                                  [input]="edit"
                                  (updated)="onUpdate()"
                                  (canceled)="onCancel()">
    </crm-company-b2b-advance-edit>

    <crm-company-b2b-advance-list [companyId]="companyId"
                                  [load]="collectionChanged"
                                  (editClicked)="onEditClicked($event)"
                                  (copyClicked)="onCopyClicked($event)">
    </crm-company-b2b-advance-list>
</div>
