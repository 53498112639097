<div *ngIf="!employeeId">
    <af-page-bar title="{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-create.component.documents for issuing an advance' | translate }}" helpid="advance-employee.guide"></af-page-bar>
</div>
<div *ngIf="employeeId">
    <span style="font-size: 28px;font-weight: 300;color: #666;font-family: 'Open Sans',sans-serif;">{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-create.component.documents for issuing an advance invoice' | translate }}  </span>
</div>
<div class="form">
    <form (ngSubmit)="insert()" name="createEmployeeAdvanceForm" novalidate="" #createEmployeeAdvanceForm="ngForm" [class.disabled-all]="isLoading">
        <div class="form-horizontal">
            <div class="form-body">

                <ng-container *ngIf="!employeeId">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group ac-group">
                                <label class="control-label col-md-2">{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-create.component.employee' | translate }}</label>
                                <div class="col-md-10">
                                    <div class="input-group">
                                            <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
                                        <input type="text" class="form-control" [(ngModel)]="selectedEmployee" [editable]="false" [ngbTypeahead]="employeeAutocomplete" [resultFormatter]="employeeFormatter" [inputFormatter]="employeeFormatter" id="EmployeeId" name="EmployeeId" required="" value="" />
                                        <span><span *ngIf="(createEmployeeAdvanceForm.controls['EmployeeId']?.dirty || createEmployeeAdvanceForm.submitted) && createEmployeeAdvanceForm.controls['EmployeeId']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeeadvanceinsertupdateviewmodel.employee'} }}</span>
</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group ac-group">
                            <label class="control-label col-md-2">{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-create.component.project task' | translate }}</label>
                            <div class="col-md-10">
                                <div class="input-group">
                                        <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
                                    <input type="text" class="form-control" [disabled]="!(employeeAdvance.advanceType == 1)" [(ngModel)]="selectedProjectTask" [editable]="false" [ngbTypeahead]="projectTaskAutocomplete" [resultFormatter]="projectTaskFormatter" [inputFormatter]="projectTaskFormatter" [required]="employeeAdvance.advanceType == 1" id="ProjectTaskId" name="ProjectTaskId" value="" />
                                </div>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <div *ngIf="advanceTypes" class="form-group">
                            <label class="control-label col-md-2" for="AdvanceType">{{ 'hollen.operation.employeeadvanceinsertupdateviewmodel.advance type' | translate }}</label>
                            <div class="col-md-10">
                                <af-radio *ngFor="let item of advanceTypes"
                                          [inline]="true"
                                          [name]="'AdvanceType' + item.key"
                                          [value]="item.key"
                                          [label]="item.value"
                                          [(ngModel)]="employeeAdvance.advanceType"
                                          required>
                                </af-radio>
                                <span><span *ngIf="(createEmployeeAdvanceForm.controls['AdvanceType']?.dirty || createEmployeeAdvanceForm.submitted) && createEmployeeAdvanceForm.controls['AdvanceType']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeeadvanceinsertupdateviewmodel.advance type'} }}</span>
</span>
                            </div>               
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="Description">{{ 'hollen.operation.employeeadvanceinsertupdateviewmodel.description' | translate }}</label>
                            <div class="col-md-10">
                                <input class="form-control" [(ngModel)]="employeeAdvance.description" type="text" id="Description" name="Description" value="" />
                                <af-help helpid="Hollen.Operation.EmployeeAdvanceInsertUpdateViewModel.Description"></af-help>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="Amount">{{ 'hollen.operation.employeeadvanceinsertupdateviewmodel.amount' | translate }}</label>
                            <div class="col-md-10">
                                <input class="form-control" [(ngModel)]="employeeAdvance.amount" required type="text" afNumber="" id="Amount" name="Amount" value="" />
                                <af-help helpid="Hollen.Operation.EmployeeAdvanceInsertUpdateViewModel.Amount"></af-help>
                                <span><span *ngIf="(createEmployeeAdvanceForm.controls['Amount']?.dirty || createEmployeeAdvanceForm.submitted) && createEmployeeAdvanceForm.controls['Amount']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeeadvanceinsertupdateviewmodel.amount'} }}</span>
<span *ngIf="(createEmployeeAdvanceForm.controls['Amount']?.dirty || createEmployeeAdvanceForm.submitted) && createEmployeeAdvanceForm.controls['Amount']?.hasError('number')" class="field-validation-error">{{ 'validator.the field {0} must be a number.' | translate:{'0': 'translate|hollen.operation.employeeadvanceinsertupdateviewmodel.amount'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="Note">{{ 'hollen.operation.employeeadvanceinsertupdateviewmodel.note' | translate }}</label>
                            <div class="col-md-10">
                                <input class="form-control" [(ngModel)]="employeeAdvance.note" type="text" id="Note" name="Note" value="" />
                                <af-help helpid="Hollen.Operation.EmployeeAdvanceInsertUpdateViewModel.Note"></af-help>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="ValidityMonth">{{ 'hollen.operation.employeeadvanceinsertupdateviewmodel.validity month' | translate }}</label>
                            <div class="col-md-10">
                                <af-radio [value]="1" name="ValidityMonth"
                                          [ngModel]="validityMonth"
                                          (ngModelChange)="validityMonth = $event; updateValidityMonth()"
                                          [inline]="true"
                                          required>
                                    {{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-create.component.previous month' | translate }}
                                </af-radio>
                                <af-radio [value]="2" name="ValidityMonth"
                                          [ngModel]="validityMonth"
                                          (ngModelChange)="validityMonth = $event; updateValidityMonth()"
                                          [inline]="true"
                                          required>
                                    {{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-create.component.current month' | translate }}
                                </af-radio>
                                <af-radio [value]="3" name="ValidityMonth"
                                          [ngModel]="validityMonth"
                                          [inline]="true"
                                          (ngModelChange)="validityMonth = $event; updateValidityMonth()"
                                          required>
                                    {{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-create.component.next month' | translate }}
                                </af-radio>
                                <label class="control-label col-md-2">{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-create.component.the chosen month' | translate }}</label>
                                {{employeeAdvance.validityMonth | afDate:'MM/YYYY' }}
                                <span><span *ngIf="(createEmployeeAdvanceForm.controls['ValidityMonth']?.dirty || createEmployeeAdvanceForm.submitted) && createEmployeeAdvanceForm.controls['ValidityMonth']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeeadvanceinsertupdateviewmodel.validity month'} }}</span>
<span *ngIf="(createEmployeeAdvanceForm.controls['ValidityMonth']?.dirty || createEmployeeAdvanceForm.submitted) && createEmployeeAdvanceForm.controls['ValidityMonth']?.hasError('date')" class="field-validation-error">{{ 'validator.{0} is not valid date format.' | translate:{'0': 'translate|hollen.operation.employeeadvanceinsertupdateviewmodel.validity month'} }}</span>
<span *ngIf="(createEmployeeAdvanceForm.controls['ValidityMonth']?.dirty || createEmployeeAdvanceForm.submitted) && createEmployeeAdvanceForm.controls['ValidityMonth']?.hasError('daterange')" class="field-validation-error">{{ 'validator.{0} is out of its valid range.' | translate:{'0': 'translate|hollen.operation.employeeadvanceinsertupdateviewmodel.validity month'} }}</span>
</span>                 
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="PaymentType">{{ 'hollen.operation.employeeadvanceinsertupdateviewmodel.payment type' | translate }}</label>
                            <div class="col-md-10">
                                <select class="form-control form-select" [(ngModel)]="employeeAdvance.paymentType" id="PaymentType" name="PaymentType" required="">
                                    <option *ngFor="let type of paymentTypes" [ngValue]="type.key">{{type.value}}</option>
                                </select>
                                <af-help helpid="Hollen.Operation.EmployeeAdvanceInsertUpdateViewModel.PaymentType"></af-help>
                                <span><span *ngIf="(createEmployeeAdvanceForm.controls['PaymentType']?.dirty || createEmployeeAdvanceForm.submitted) && createEmployeeAdvanceForm.controls['PaymentType']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeeadvanceinsertupdateviewmodel.payment type'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-actions">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="offset-md-1 col-md-11">
                                    <button *afHasPerm="{obj:objectKey, perm: 'Insert'}" class="btn btn-insert btn-primary" type="submit">{{ 'btn.insert' | translate }}</button>
                                    <a class="btn-back btn-warning btn" onclick="history.back()">{{ 'btn.back' | translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
