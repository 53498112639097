import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { FileUploadOptions } from "../../shared/components/file-upload/file-upload.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class ProjectTaskFileService
    extends HttpService<app.sharepoint.SharePointFileViewModel, app.operation.ProjectTaskFileFormViewModel, app.sharepoint.SharePointFileViewModel>
{
    objectKey = "op.projecttaskfile";

    constructor(http: HttpClient) {
        super(http, "/api/operation/projecttaskfile/");
    }

    getUploadOptions(projectTaskId: number, forInv: boolean = null, isIssuePicture: boolean = false, isPresentationFile: boolean = false): FileUploadOptions {        
        let options = new FileUploadOptions();
        options.url = this.url + projectTaskId;
        if (isIssuePicture) {
            options.showToast = false;           
        }
        if (forInv != null) {
            options.data.forInv = forInv;
            options.data.isIssuePicture = isIssuePicture;
            options.data.isPresentationFile = isPresentationFile;      
        } 
        return options;
    }

    delete(item: app.sharepoint.SharePointFileViewModel) {
        let params = new HttpParams();
        params = params.set("listCode", item.listCode);
        params = params.set("projectTaskId", item.projectTaskId.toString());

        let url = this.url + item.uniqueId.toString();

        return this.http.delete(url, { params });
    }

    deletePicture(projectTaskId: string, id: string, listCode: string) {
        let params = new HttpParams();
        params = params.set("projectTaskId", projectTaskId);
        params = params.set("listCode", listCode);
        params = params.set("showToast", "false");
        let url = this.url + id;

        return this.http.delete(url, {
            params
        });
    }

    getSharePointFolder(projectTaskId: number) {
        let params = new HttpParams();
        params = params.set("showToast", "false");
        return this.http.post<app.sharepoint.SharePointResponse>("/api/operation/projecttask/" + projectTaskId + "/sharepoint", "", { params });
    }
}
