import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { FileUploadOptions } from "../../shared/components/file-upload/file-upload.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { deepFreeze } from '../../core/utils';
import { map } from 'rxjs/operators';

export class SharePointFolderAndFiles {
    files: PagedList<app.sharepoint.SharePointFileViewModel>;
    folder: app.sharepoint.SharePointResponse;
    metriqFilesFolder: app.sharepoint.SharePointResponse;
}

@Injectable()
export class CompanyFileService
    extends HttpService<app.sharepoint.SharePointFileViewModel, app.crm.CompanyFileFormViewModel, app.sharepoint.SharePointFileViewModel>
{
    objectKey = "crm.companyfile";

    constructor(http: HttpClient) {
        super(http, "/api/crm/companyfile/");
    }

    getUploadOptions(companyId: number,
        path: string = null,
        documentType: string = null,
        invoiceNumber: string = null,
        archiveLocation: string = null,
        isMetriqFiles: boolean = false): FileUploadOptions {
        let options = new FileUploadOptions();

        if (documentType) {
            options.url = this.url + "doctype/" + documentType + "/" + companyId;
        } else if (invoiceNumber) {
            options.url = this.url + "invoice-number/" + invoiceNumber + "/" + companyId;
        } else {
            options.url = this.url + companyId;
        }

        if (path) {
            options.data.path = path;
        }

        if (archiveLocation) {
            options.data.archiveLocation = archiveLocation;
        }

        if (isMetriqFiles) {
            options.data.isMetriqFiles = isMetriqFiles;
        }
        return options;
    }

    list(companyId: number): Observable<app.crm.CompanyFileSelectViewModel[]> {
        return this.http.get<app.crm.CompanyFileSelectViewModel[]>(this.url + "list/" + companyId);
    }

    getSharePointFolder(companyId: number, path: string = null, archiveLocation: string = null) {
        return this.getSharePointFolderAndFiles({
            companyId: companyId,
            path: path,
            archiveLocation: archiveLocation,
            showToast: false
        }).pipe(
            map<SharePointFolderAndFiles, app.sharepoint.SharePointResponse>(res => res.folder));
    }

    getSharePointFolderAndFiles(parameters?: any): Observable<SharePointFolderAndFiles> {
        let params = this.getParameters(parameters);
        params = params.set("showToast", "false");

        return this.http
            .get<SharePointFolderAndFiles>(this.url + "folder", { params }).pipe(
                map<any, SharePointFolderAndFiles>(res => {
                    return { ...res, files: deepFreeze(res.files) };
                }));
    }

    getNotificationList(companyId: number): Observable<app.crm.CompanyFileNotificationSimpleViewModel[]> {
        return this.http.get<app.crm.CompanyFileNotificationSimpleViewModel[]>(this.url + "notification-list/" + companyId);
    }

    getArchiveLocationList(companyId: number = null) {
        let params = new HttpParams();
        params = params.set("showToast", "false");

        return this.http.get<string[]>(this.url + "archive/" + (companyId ? companyId.toString() : ""), { params });
    }

    checkArchiveLocation(companyId: number, location: string) {
        let params = new HttpParams();
        params = params.set("showToast", "false");

        return this.http.get<boolean>(this.url + "archive/" + companyId.toString() + "/" + location, { params });
    }

    renameFolder(request: app.crm.RenameFolderRequest) {
        return this.http.put(this.url + "rename", request);
    }

    deleteFolder(companyId: number, path: string, folderName: string) {
        let params = new HttpParams();
        params = params.set("showToast", "true");
        params = params.set("path", path);
        params = params.set("folderName", folderName);
        return this.http.delete(this.url + "deletefolder/" + companyId.toString(), { params });
    }
}
