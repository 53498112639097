<af-spinner #afSpinner [loading]="isLoading"></af-spinner>

<ng-container *ngIf="!isLoading">
    <div class="mt-2" *ngIf="providerWages">
        <div>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.hours' | translate }}: <b>{{ providerWages.hours | number:'.2-2' }}</b></div>
        <div>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.bonus disabled' | translate }}: <b *ngIf="providerWages.isBonusDisabled">{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.yes' | translate }}</b><b *ngIf="!providerWages.isBonusDisabled">{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.no' | translate }}</b></div>
        <div>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.hours amount' | translate }}: <b>{{ providerWages.amount | number:'.2-2' }}</b></div>
        <div>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.other agreements' | translate }}: <b>{{ providerWages.otherAgreements | number:'.2-2' }}</b></div>
        <div>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.overall documentation surcharge' | translate }}: <b>{{ providerWages.overallDocumentationSurcharge | number:'.2-2' }}</b></div>
        <div>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.total amount' | translate }}: <b>{{ providerWages.totalAmount | number:'.2-2' }}</b></div>

        <div *ngFor="let employee of providerWages.items" class="mt-2">
            <hr />
            <div>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.name' | translate }}: <b>{{ employee.employeeName }}</b></div>
            <div>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.hours' | translate }}: <b>{{ employee.hours | number:'.2-2' }}</b></div>
            <div>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.hours amount' | translate }}: <b>{{ employee.amount | number:'.2-2' }}</b></div>
            <div>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.other agreements' | translate }}: <b>{{ employee.otherAgreements | number:'.2-2' }}</b></div>
            <div>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.total amount' | translate }}: <b>{{ employee.totalAmount | number:'.2-2' }}</b></div>

            <div class="table-wrapper">
                <table class="af-table table table-striped table-bordered table-hover text-end mt-2">
                    <thead>
                        <tr>
                            <th></th>
                            <th *ngFor="let item of employee.items">
                                <div>{{ item.day }}</div>
                                <div><small>{{ item.dayTitle }}</small></div>
                            </th>
                            <th>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.total' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.regular day hours' | translate }}</td>
                            <td *ngFor="let item of employee.items"><ng-container *ngIf="item.time.regularDayHours">{{ item.time.regularDayHours | number:'.2-2' }}</ng-container></td>
                            <td><b *ngIf="employee.total.regularDayHours">{{ employee.total.regularDayHours | number:'.2-2' }}</b></td>
                        </tr>
                        <tr>
                            <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.regular night hours' | translate }}</td>
                            <td *ngFor="let item of employee.items"><ng-container *ngIf="item.time.regularNightHours">{{ item.time.regularNightHours | number:'.2-2' }}</ng-container></td>
                            <td><b *ngIf="employee.total.regularNightHours">{{ employee.total.regularNightHours | number:'.2-2' }}</b></td>
                        </tr>
                        <tr>
                            <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.saturday day hours' | translate }}</td>
                            <td *ngFor="let item of employee.items"><ng-container *ngIf="item.time.saturdayHours">{{ item.time.saturdayHours | number:'.2-2' }}</ng-container></td>
                            <td><b *ngIf="employee.total.saturdayHours">{{ employee.total.saturdayHours | number:'.2-2' }}</b></td>
                        </tr>
                        <tr>
                            <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.saturday night hours' | translate }}</td>
                            <td *ngFor="let item of employee.items"><ng-container *ngIf="item.time.saturdayNightHours">{{ item.time.saturdayNightHours | number:'.2-2' }}</ng-container></td>
                            <td><b *ngIf="employee.total.saturdayNightHours">{{ employee.total.saturdayNightHours | number:'.2-2' }}</b></td>
                        </tr>
                        <tr>
                            <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.sunday day hours' | translate }}</td>
                            <td *ngFor="let item of employee.items"><ng-container *ngIf="item.time.sundayHours">{{ item.time.sundayHours | number:'.2-2' }}</ng-container></td>
                            <td><b *ngIf="employee.total.sundayHours">{{ employee.total.sundayHours | number:'.2-2' }}</b></td>
                        </tr>
                        <tr>
                            <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.sunday night hours' | translate }}</td>
                            <td *ngFor="let item of employee.items"><ng-container *ngIf="item.time.sundayNightHours">{{ item.time.sundayNightHours | number:'.2-2' }}</ng-container></td>
                            <td><b *ngIf="employee.total.sundayNightHours">{{ employee.total.sundayNightHours | number:'.2-2' }}</b></td>
                        </tr>
                        <tr>
                            <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.holiday day hours' | translate }}</td>
                            <td *ngFor="let item of employee.items"><ng-container *ngIf="item.time.holidayHours">{{ item.time.holidayHours | number:'.2-2' }}</ng-container></td>
                            <td><b *ngIf="employee.total.holidayHours">{{ employee.total.holidayHours | number:'.2-2' }}</b></td>
                        </tr>
                        <tr>
                            <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.holiday night hours' | translate }}</td>
                            <td *ngFor="let item of employee.items"><ng-container *ngIf="item.time.holidayNightHours">{{ item.time.holidayNightHours | number:'.2-2' }}</ng-container></td>
                            <td><b *ngIf="employee.total.holidayNightHours">{{ employee.total.holidayNightHours | number:'.2-2' }}</b></td>
                        </tr>
                        <tr>
                            <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.holiday saturday day hours' | translate }}</td>
                            <td *ngFor="let item of employee.items"><ng-container *ngIf="item.time.holidaySaturdayHours">{{ item.time.holidaySaturdayHours | number:'.2-2' }}</ng-container></td>
                            <td><b *ngIf="employee.total.holidaySaturdayHours">{{ employee.total.holidaySaturdayHours | number:'.2-2' }}</b></td>
                        </tr>
                        <tr>
                            <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.holiday saturday night hours' | translate }}</td>
                            <td *ngFor="let item of employee.items"><ng-container *ngIf="item.time.holidaySaturdayNightHours">{{ item.time.holidaySaturdayNightHours | number:'.2-2' }}</ng-container></td>
                            <td><b *ngIf="employee.total.holidaySaturdayNightHours">{{ employee.total.holidaySaturdayNightHours | number:'.2-2' }}</b></td>
                        </tr>
                        <tr>
                            <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.holiday sunday day hours' | translate }}</td>
                            <td *ngFor="let item of employee.items"><ng-container *ngIf="item.time.holidaySundayHours">{{ item.time.holidaySundayHours | number:'.2-2' }}</ng-container></td>
                            <td><b *ngIf="employee.total.holidaySundayHours">{{ employee.total.holidaySundayHours | number:'.2-2' }}</b></td>
                        </tr>
                        <tr>
                            <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.holiday sunday night hours' | translate }}</td>
                            <td *ngFor="let item of employee.items"><ng-container *ngIf="item.time.holidaySundayNightHours">{{ item.time.holidaySundayNightHours | number:'.2-2' }}</ng-container></td>
                            <td><b *ngIf="employee.total.holidaySundayNightHours">{{ employee.total.holidaySundayNightHours | number:'.2-2' }}</b></td>
                        </tr>
                        <tr>
                            <td>{{ 'hollen.clientapp.src.app.b2b.wages.wages-provider.component.amount' | translate }}</td>
                            <td *ngFor="let item of employee.items"><b *ngIf="item.amount">{{ item.amount | number:'.2-2' }}</b></td>
                            <td><b>{{ employee.amount | number:'.2-2' }}</b></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-container>
