import { Directive, OnInit, ElementRef, Input } from "@angular/core";

@Directive({
    selector: "[afFixedTableHeaders]"
})
export class FixedTableHeadersDirective implements OnInit {
    @Input() auto = true;

    constructor(private elementRef: ElementRef) {

    }

    ngOnInit() {
        if (this.auto) {
            this.create();
        }
    }

    create() {
        setTimeout(() => {
            jQuery(this.elementRef.nativeElement)["stickyTableHeaders"]({ fixedOffset: jQuery(".page-header"), cacheHeaderHeight: true });
        }, 0);
    }

    resize() {
        setTimeout(() => {
            jQuery(this.elementRef.nativeElement).trigger("resize.stickyTableHeaders");
        }, 0);
    }
}
