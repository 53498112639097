import { Injectable, Inject, LOCALE_ID } from "@angular/core";
import { NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { getLocaleDateFormat, FormatWidth, getLocaleDayNames, TranslationWidth, FormStyle, getLocaleMonthNames, formatDate } from '@angular/common';

export const I18N_VALUES = {
    en: {
        weekdays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
        months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        monthsFull: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        format: "DD/MM/YYYY"
    },
    sk: {
        weekdays: ["Po", "Ut", "St", "Št", "Pi", "So", "Ne"],
        months: ["Jan", "Feb", "Mar", "Apr", "Máj", "Jún", "Júl", "Aug", "Sep", "Okt", "Nov", "Dec"],
        monthsFull: ["Január", "Február", "Marec", "Apríl", "Máj", "Jún", "Júl", "August", "September", "Október", "November", "December"],
        format: "DD.MM.YYYY"
    }
};

@Injectable()
export class DatepickerI18n extends NgbDatepickerI18n {

    constructor(@Inject(LOCALE_ID) private locale: string) {
        super();
    }

    getWeekdayShortName(weekday: number): string {
        return I18N_VALUES[this.locale].weekdays[weekday - 1];
    }
    getMonthShortName(month: number): string {
        return I18N_VALUES[this.locale].months[month - 1];
    }
    getMonthFullName(month: number): string {
        return I18N_VALUES[this.locale].monthsFull[month - 1];
    }

    getFormat(): string {
        return I18N_VALUES[this.locale].format;
    }

getWeekdayLabel(
		weekday: number,
		width: Exclude<Intl.DateTimeFormatOptions['weekday'], undefined> = 'narrow',
	): string {
        const weekdaysStartingOnSunday = [...Array(7).keys()].map((day) =>
			Intl.DateTimeFormat(this.locale, { weekday: width }).format(new Date(Date.UTC(2021, 5, day - 1))),
		);
		const weekdays = weekdaysStartingOnSunday.map((day, index) => weekdaysStartingOnSunday[(index + 1) % 7]);
		return weekdays[weekday - 1] || '';
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return null;
    }
}
