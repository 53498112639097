<form [ngClass]="{'form-inline': isInline}" class="page-filter hidden-print" [class.disabled-all]="loading" (ngSubmit)="onSubmit()">
	<ng-content></ng-content>
	<div class="page-filter-buttons" *ngIf="!hideButtons">
		<button type="submit" class="btn btn-primary">{{ 'btn.search' | translate }}&nbsp;<i class="fa fa-search"></i></button>
		<button type="button" class="btn btn-default" (click)="onReset()">{{ 'btn.reset' | translate }}</button>
		<button type="button" class="btn btn-default" (click)="onClear()">{{ 'btn.clear' | translate }}</button>
	</div>
	<div class="form-group" *ngIf="helpid">
		<af-help class="help-static" [helpid]="helpid"></af-help>
	</div>
</form>


