import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { FileUploadOptions } from "../../../shared/components/file-upload/file-upload.service";

@Injectable()
export class InventoryCheckService
    extends HttpService<app.operation.InventoryCheckListViewModel, app.operation.InventoryCheckFormViewModel, app.operation.InventoryCheckInsertUpdateViewModel>
{
    objectKey = "op.InventoryCheck";

    constructor(http: HttpClient) {
        super(http, "/api/operation/inventorycheck/");
    }

    queryInventoryCheck(inventoryCheckId: number, parameters?: any): Observable<app.operation.InventoryCheckFormViewModel> {
        return this.http
            .get<app.operation.InventoryCheckFormViewModel>(this.url + inventoryCheckId, {
                params: this.getParameters(parameters)
            });
    }

    getInventoryCheckPreview(parameters?: any): Observable<app.operation.InventoryCheckInsertUpdateViewModel> {
        return this.http
            .get<app.operation.InventoryCheckInsertUpdateViewModel>(this.url + "preview", {
                params: this.getParameters(parameters)
            });
    }

    getSelfCheckForm(employeeId: number, parameters?: any): Observable<app.operation.InventorySelfCheckFormViewModel> {
        return this.http.get<app.operation.InventorySelfCheckFormViewModel>(this.url + "self-inventory/" + employeeId, {
            params: this.getParameters(parameters)
        });
    }

    saveSelfCheckForm(employeeId: number, request: app.operation.InventorySelfCheckUpdateViewModel) {
        let url = this.url + "self-inventory/" + employeeId;
        return this.http.put(url, request);
    }

    createInventoryCheck(employeeId: number, accountCenterCode:string, type:number, isExcludingCurrentYear: boolean): Observable<app.operation.InventoryCheckInsertUpdateViewModel> {
        let params = new HttpParams();

        if (isExcludingCurrentYear) {
            params = params.set("isExcludingCurrentYear", isExcludingCurrentYear.toString());
        }

        if (accountCenterCode) {
            params = params.set("accountCenterCode", accountCenterCode);
        }

        if (type) {
            params = params.set("type", type.toString());  
        }    
   
        return this.http.post<app.operation.InventoryCheckInsertUpdateViewModel>(this.url + employeeId,null, { params });
    }

    getFilter(): Observable<app.operation.InventoryCheckListFilterViewModel> {
        return this.http.get<app.operation.InventoryCheckListFilterViewModel>(this.url + "filter");
    }


    getPreviewFilter(employeeId:number): Observable<app.operation.InventoryCheckPreviewFilterViewModel> {
        return this.http.get<app.operation.InventoryCheckPreviewFilterViewModel>(this.url + "previewFilter/" + employeeId);
    }

    complete(inventoryCheckId: number) {
        let url = this.url + "complete/" + inventoryCheckId;
        return this.http.put(url, null);
    }

    getRandomNumberList(request?: app.operation.GetRandomInventoryNumbersRequest): Observable<app.operation.InventoryRandomViewModel[]> {
        return this.http.get<app.operation.InventoryRandomViewModel[]>(this.url + "random", {
            params: this.getParameters(request)
        });
    }

    getUploadOptions(inventoryCheckId: number): FileUploadOptions {
        let options = new FileUploadOptions();
        options.url = this.url + inventoryCheckId + "/import";
        return options;
    }

}
