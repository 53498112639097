import { Component, OnInit } from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ShiftPlanService } from "./shift-plan.service";
import { ToastService } from '../../core/services/toast.service';

@Component({
    selector: "op-shift-plan-sms-modal",
    templateUrl: "./shift-plan-sms-modal.component.html"
})
export class ShiftPlanSmsModalComponent {
    model: app.operation.SmsDispatchingViewModel[];
    title: string;

    constructor(public activeModal: NgbActiveModal,
        private shiftPlanService: ShiftPlanService,
        private toastService: ToastService) {       
    }

    send() {
        this.shiftPlanService.sendSms(this.model)
            .subscribe((res) => {
                toastr.info(res);
                this.activeModal.close(res);
            });
    }

    cancel() {
        this.activeModal.dismiss("cancel");
    }

    deleteItem(index: number) {
        
        if (index > -1) {
            this.model.splice(index, 1);
        }
    }
}
