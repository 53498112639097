import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "afUrlEncode"
})
export class UrlEncodePipe implements PipeTransform {

    transform(value: any): string | null {
        return encodeURIComponent(value);
    }
}
