import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import moment from "moment";

@Component({
    selector: "af-timepicker",
    styles: [`
        :host {
            display: contents;            
        }
        input {
            width: 60px;
        }
    `],
    template: `
        <input class="form-control"
                [pattern]="timePattern"
                [disabled]="disabled || !date"
                [value]="time"
                [required]="required"
                (change)="onChange($event.target.value)">
    `,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => TimePickerComponent) }
    ]
})
export class TimePickerComponent implements ControlValueAccessor {
    @Input() disabled: boolean;
    @Input() defaultTime = "00:00:00";
    @Input() required: boolean;

    timePattern = /^([01]?\d|2[0-3])(:([0-5]\d))?(:([0-5]\d))?$|^$/;
    date: Date;
    time = null;
    _onChange: (value: any) => void;

    writeValue(value: Date) {
        this.date = value;
        if (value) {
            this.time = moment(value).format("HH:mm");
        }
        else {
            this.time = null;
        }
    }

    onChange(event: any) {
        if (!event || !this.timePattern.test(event)) {
            event = this.defaultTime;
        }

        let res = this.timePattern.exec(event);

        this.date.setHours(+res[1] || 0);
        this.date.setMinutes(+res[3] || 0);
        this.date.setSeconds(+res[5] || 0);

        this.time = moment(this.date).format("HH:mm");
        this._onChange(this.date);
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }

    registerOnTouched() { }
}
