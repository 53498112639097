import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";

import { State } from "../../../store/reducers";
import * as layout from "../../../store/actions/layout";
import { filter } from 'rxjs/operators';

@Component({
    selector: "af-page-header",
    styleUrls: ["./page-header.component.scss"],
    templateUrl: "./page-header.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderComponent implements OnInit, OnDestroy {
    isCollapsed$: Observable<boolean>;
    isCollapsed: boolean;
    subscriptions: Subscription[] = [];
    environment: string;

    constructor(private store: Store<State>, private changeDetectorRef: ChangeDetectorRef) {
        this.isCollapsed$ = store.select(x => x.layout.mobileMenuIsCollapsed);
    }

    ngOnInit() {
        this.subscriptions.push(this.isCollapsed$.subscribe(isCollapsed => {
            this.isCollapsed = isCollapsed;
            this.changeDetectorRef.markForCheck();
        }));

        this.subscriptions.push(this.store.select(x => x.app.homeViewModel)
            .pipe(filter(loaded => !!loaded))
            .subscribe(home => {
                this.environment = home.environment ? home.environment.toLowerCase() : null;
                this.changeDetectorRef.markForCheck();
            }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    toggleMenu() {
        if (this.isCollapsed) {
            this.store.dispatch(new layout.OpenMobileMenuAction());
        }
        else {
            this.store.dispatch(new layout.CloseMobileMenuAction());
        }
    }
}
