
    <ng-container *ngIf="!routeParameter">
        <af-page-bar title="{{ 'hollen.clientapp.src.app.organization.work-place.work-place-list.component.workplaces' | translate }}" *ngIf="!islandCode">
            <ng-container>
                <button type="button" class="btn btn-fit-height btn-success" *afHasPerm="{obj:objectKey,perm:'Insert'}" [routerLink]="['create']">
                    {{ 'btn.list.add.add item' | translate }} <i class="fa fa-plus"></i>
                </button>
            </ng-container>
        </af-page-bar>
    </ng-container>
    <ng-container *ngIf="islandCode">
        <span style="padding: 0px;
    font-size: 28px;
    letter-spacing: -1px;
    display: inline-block;
    color: #666;
    margin: 0px 0px 15px 0px;
    font-weight: 300;">{{ 'hollen.clientapp.src.app.organization.work-place.work-place-list.component.workplaces' | translate }}</span>
    </ng-container>

<af-page-filter *ngIf="!islandCode" [control]="afTable" [loading]="isLoading" [params]="queryParams" (filter)="loadData()" (reset)="onReset()" (clear)="onClear()">
    <div class="form-group">
        <input type="text" class="form-control" [(ngModel)]="queryParams.searchTerm" placeholder="{{ 'filter.keyword' | translate }}" />
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.organization.work-place.work-place-list.component.account center' | translate }}</label>
        <select class="form-control form-select" [ngModel]="queryParams.accountCenterCode" (ngModelChange)="onChangeAccountCenter($event)">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.organization.work-place.work-place-list.component.all' | translate }}</option>
            <option *ngFor="let accountCenter of accountCenters" [ngValue]="accountCenter.code">{{accountCenter.title}}</option>
        </select>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.organization.work-place.work-place-list.component.island' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryParams.islandCode">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.organization.work-place.work-place-list.component.all' | translate }}</option>
            <option *ngFor="let island of islands" [ngValue]="island.code">{{island.title}}</option>
        </select>
    </div>
    <div class="form-group ac-group">
        <label>{{ 'hollen.clientapp.src.app.organization.work-place.work-place-list.component.company' | translate }}</label>
        <div class="input-group">
                <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
            <input type="text" name="CompanyId" class="form-control"
                   [(ngModel)]="selectedCompany"
                   [editable]="false"
                   [ngbTypeahead]="companyAutocomplete"
                   [resultFormatter]="companyFormatter"
                   [inputFormatter]="companyFormatter" />
        </div>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.organization.work-place.work-place-list.component.active' | translate }}</label>
        <select class="form-control form-select"
                [(ngModel)]="queryParams.isArchived">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.organization.work-place.work-place-list.component.all' | translate }}</option>
            <option [ngValue]="false">{{ 'hollen.clientapp.src.app.organization.work-place.work-place-list.component.yes' | translate }}</option>
            <option [ngValue]="true">{{ 'hollen.clientapp.src.app.organization.work-place.work-place-list.component.no' | translate }}</option>
        </select>
    </div>
</af-page-filter>

<af-table #afTable [value]="model" [loading]="isLoading" [params]="queryParams" [sortBy]="sortBy" (load)="loadData()">
    <af-column field="title" sortable="title" header="{{ 'hollen.organization.workplacelistviewmodel.title' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a [routerLink]="['/','organization','workPlace',item.workPlaceId,'supply-chain']">{{item.title}}</a>
        </ng-template>
    </af-column>
    <af-column field="accountCenterCode" *ngIf="!islandCode" sortable="accountCenterCode" header="{{ 'hollen.organization.workplacelistviewmodel.account center' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a [routerLink]="['/organization/accountCenter', item.accountCenterCode]">{{item.accountCenterCode}}</a>
        </ng-template>
    </af-column>
    <af-column field="islandTitle" *ngIf="!islandCode" sortable="island.Title" header="{{ 'hollen.organization.workplacelistviewmodel.island' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a [routerLink]="['/organization/island', item.islandCode]">{{item.islandTitle}}</a>
        </ng-template>
    </af-column>
    <af-column field="place" sortable="place" header="{{ 'hollen.organization.workplacelistviewmodel.place' | translate }}"></af-column>
    <af-column field="country" sortable="country" header="{{ 'hollen.organization.workplacelistviewmodel.country' | translate }}"></af-column>
    <af-column field="companyTitleWithAddressCity" sortable="company.Title" header="{{ 'hollen.organization.workplacelistviewmodel.company' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a *ngIf="item.companyId" [routerLink]="['/crm/company', item.companyId]">{{item.companyTitleWithAddressCity}}</a>
        </ng-template>
    </af-column>
    <af-column field="isArchived" *ngIf="islandCode" sortable="isArchived" header="{{ 'hollen.organization.workplacelistviewmodel.status' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <ng-container *ngIf="item.isArchived">
                {{ 'hollen.clientapp.src.app.organization.work-place.work-place-list.component.archived' | translate }}
            </ng-container>
            <ng-container *ngIf="!item.isArchived">
                {{ 'hollen.clientapp.src.app.organization.work-place.work-place-list.component.active' | translate }}
            </ng-container>
        </ng-template>
    </af-column>
</af-table>

