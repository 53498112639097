import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
    name: 'afCurrency',
})
export class CurrencyPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private locale: string) { }

    transform(value: any, currency?: string, digits?: string) {
        let result = new DecimalPipe(this.locale).transform(value, digits) + " " + (currency ? currency : "EUR");
        return result;
    }
}
