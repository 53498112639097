import { Directive, Input, TemplateRef, ViewContainerRef, EmbeddedViewRef } from "@angular/core";

import { PermissionService } from "../../core/services/permission.service";
import { isPresent } from "../../core/utils";

export interface PermObject {
    obj: string;
    perm: string;
    if: boolean;
    exact: boolean;
}

@Directive({
    selector: "[afHasPerm]"
})
export class HasPermDirective {
    embeddedViewRef: EmbeddedViewRef<Object>;

    constructor(private templateRef: TemplateRef<Object>,
        private viewContainerRef: ViewContainerRef,
        private permissionService: PermissionService) {
    }

    @Input("afHasPerm") set afHasPerm(permObj: PermObject) {
        let exact = false;
        if (permObj.hasOwnProperty("exact") && permObj.exact) {
            exact = true;
        }
        if (this.hasPerm(permObj.perm, permObj.obj, exact)) {
            if (permObj.hasOwnProperty("if") && !permObj.if) {
                this.viewContainerRef.clear();
                this.embeddedViewRef = null;
                return;
            }

            if (isPresent(this.embeddedViewRef)) {
                return;
            }
            this.embeddedViewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainerRef.clear();
            this.embeddedViewRef = null;
        }
    }

    private hasPerm(perm: string, object: string, exact: boolean): boolean {
        if (!object) {
            return false;
        }

        let result = this.permissionService.hasPermission(object, perm);

        if (!result && !exact) {
            if (perm === "Read") {
                result = this.permissionService.hasPermission(object, "OwnRead");
            }
            else if (perm === "Insert") {
                result = this.permissionService.hasPermission(object, "OwnInsert");
            }
            else if (perm === "Update") {
                result = this.permissionService.hasPermission(object, "OwnUpdate");
            }
            else if (perm === "Delete") {
                result = this.permissionService.hasPermission(object, "OwnDelete");
            }
        }

        return result;
    }
}
