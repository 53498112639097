<div *afHasPerm="{obj:objectKey, perm: 'Read'}">
    <div class="row">
        <div class="col-md-12">
            <h3 class="form-section">
                {{ 'employee.panel.accommodation' | translate }}
            </h3>
        </div>
    </div>

    <op-accommodation-term-create *ngIf="displayMode == 'create'"
                                  [employeeId]="employeeId"
                                  (inserted)="onInsert($event)">
    </op-accommodation-term-create>

    <op-accommodation-term-edit *ngIf="displayMode == 'edit'"
                                [input]="edit"
                                (updated)="onUpdate()"
                                (canceled)="onCancel()">
    </op-accommodation-term-edit>

    <op-accommodation-term-list [employeeId]="employeeId"
                                [load]="collectionChanged"
                                (editClicked)="onEditClicked($event)">
    </op-accommodation-term-list>
</div>
