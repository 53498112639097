import { Injectable, EventEmitter } from "@angular/core";
import {
    Router, NavigationCancel, NavigationEnd, RoutesRecognized,
    RouteConfigLoadStart, RouteConfigLoadEnd, ActivatedRouteSnapshot, RouterStateSnapshot
} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";

import { TextParser } from "./core/services/text-parser.service";
import { LoadingBarService } from "./shared/components/loading-bar/loading-bar.service";
import { State } from "./store/reducers";
import * as appAction from "./store/actions/app";
import { AuthService } from "./auth/auth.service";
import { NgSelectConfig } from "@ng-select/ng-select";
import { environment } from '../environments/environment';
import { ApplicationInsightsService } from './core/services/application-insights.service';

@Injectable()
export class AppService {
    public languageId: string = "sk";
    public homeViewModel: app.HomeViewModel;
    public get isSharepointEnabled(): boolean {
        return !!this.homeViewModel.sharePointUrl;
    }

    _state: RouterStateSnapshot;
    _previousState: RouterStateSnapshot;

    constructor(private translateService: TranslateService,
        private router: Router,
        private authService: AuthService,
        private store: Store<State>,
        private ngSelectConfig: NgSelectConfig,
        private loadingService: LoadingBarService,
        private applicationInsightsService: ApplicationInsightsService) {

        translateService["parser"] = new TextParser(translateService); //hacky

        this.store.select(x => x.app.isAuthenticated)
            .subscribe(isAuthenticated => {
                if (!isAuthenticated) {
                    //applicationInsightsService.clearUser();
                }
            });

        this.store.select(x => x.app.homeViewModel).subscribe(home => {
            this.homeViewModel = home;
            if (home) {
                //issue with override toISOstring in vendor.ts
                //applicationInsightsService.setup(home.applicationInsightsInstrumentationKey);
                //applicationInsightsService.setUser(home.user.userName);
            }
        });

        this.store.select(x => x.app.language).subscribe(language => {
            this.languageId = language;
            translateService.setDefaultLang(language);
            translateService.use(language);

            this.translateService.get("ng-select.not-found").subscribe(res => this.ngSelectConfig.notFoundText = res);
            this.translateService.get("ng-select.loading").subscribe(res => this.ngSelectConfig.loadingText = res);
            this.translateService.get("ng-select.add-tag").subscribe(res => this.ngSelectConfig.addTagText = res);
            this.translateService.get("ng-select.clear-all").subscribe(res => this.ngSelectConfig.clearAllText = res);
            this.translateService.get("ng-select.type-to-search").subscribe(res => this.ngSelectConfig.typeToSearchText = res);
        });

        router.events.subscribe(event => {
            if (event instanceof RoutesRecognized) {
                if (this._state) {
                    this._previousState = this._state;
                }
                this._state = event.state;
            }
            else if (event instanceof RouteConfigLoadStart) {
                loadingService.start();
            }
            else if (event instanceof RouteConfigLoadEnd) {
                loadingService.complete();
            }
            else if (event instanceof NavigationCancel) {
                this._state = this._previousState;
                this.store.dispatch(new appAction.RouteChangedAction(this._state));
            }
            else if (event instanceof NavigationEnd) {
                this.store.dispatch(new appAction.RouteChangedAction(this._state));
            }
        });
    }

    isDebugEnabled() {
        return !environment.production;
    }

    getParam(paramName: string, snapshot: ActivatedRouteSnapshot = this._state.root): string {
        if (snapshot.params[paramName]) {
            return snapshot.params[paramName];
        } else if (snapshot.firstChild) {
            return this.getParam(paramName, snapshot.firstChild);
        }
        return null;
    }
}
