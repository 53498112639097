import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class CompanyService
    extends HttpService<app.crm.CompanyListViewModel, app.crm.CompanyFormViewModel, app.crm.CompanyInsertUpdateViewModel>
{
    objectKey = "crm.company";
    objectKeyDoCreateAutoClaim = "crm.CompanyAutoClaimAndResponsibleForFD";
    responsiblePersonObjectKey = "crm.ResponsiblePersonForCompany";
    groupObjectKey = "crm.CompanyGroup";

    constructor(http: HttpClient) {
        super(http, "/api/crm/company/");
    }

    getList(page?: number, pageSize?: number, searchTerm?: string, country?: string, accountCenterCode?: string, islandCode?: string, archived?: boolean, isAgency?: boolean, isMajorProvider?: boolean, hollenCompanyCode?:string): Observable<app.crm.CompanySelectListViewModel[]> {

        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        if (page) {
            params = params.set("page", page.toString());
        }
        if (pageSize) {
            params = params.set("pageSize", pageSize.toString());
        }
        if (country) {
            params = params.set("country", country);
        }
        if (accountCenterCode) {
            params = params.set("accountCenterCode", accountCenterCode);
        }
        if (islandCode) {
            params = params.set("islandCode", islandCode);
        }
        if (archived) {
            params = params.set("archived", archived.toString());
        }
        if (isAgency) {
            params = params.set("isAgency", isAgency.toString());
        }
        if (isMajorProvider) {
            params = params.set("isMajorProvider", isMajorProvider.toString());
        }
        if (hollenCompanyCode) {
            params = params.set("hollenCompanyCode", hollenCompanyCode);
        }
        return this.http.get<app.crm.CompanySelectListViewModel[]>(this.url + "list", { params });
    }

    getInfo(companyId: number, accountCenterCode: string = null): Observable<app.crm.CompanySelectListViewModel> {
        let params = new HttpParams();

        if (accountCenterCode) {
            params = params.set("accountCenterCode", accountCenterCode);
        }
        return this.http.get<app.crm.CompanySelectListViewModel>(this.url + companyId.toString() + "/info", { params });
    }

    getFilter(): Observable<app.crm.CompanyListFilterViewModel> {
        return this.http.get<app.crm.CompanyListFilterViewModel>(this.url + "filter");
    }

    getParentCompanyList(companyId: number, searchTerm?: string): Observable<app.crm.ParentCompanyViewModel[]> {
        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        return this.http.get<app.crm.ParentCompanyViewModel[]>(this.url + companyId.toString() + "/parentlist", { params });
    }

    getCompanyHollenCompany(companyId: number, hollenCompanyCode: string): Observable<app.crm.CompanyHollenCompanyInsertUpdateViewModel> {
        return this.http.get<app.crm.CompanyHollenCompanyInsertUpdateViewModel>(this.url + companyId.toString() + "/hollencompanycode/" + hollenCompanyCode);
    }

    getDepartmentList(searchTerm: string): Observable<string[]> {
        let params = new HttpParams();

        params = params.set("searchTerm", searchTerm);

        return this.http.get<string[]>(this.url + "department", { params });
    }

    updateTechnicianNote(companyId: number, item: app.crm.CompanyHollenCompanyTechnicianNoteViewModel): Observable<any> {
        return this.http.put(this.url + companyId.toString() + "/techniciannote", item);
    }

    updateClaimsAndFD(companyId: number, company: app.crm.CompanyInsertUpdateViewModel): Observable<any> {
        return this.http.put(this.url + companyId + "/claimsandfd", company);
    }

    getHistory(page: number, pageSize: number, id: number): Observable<af.sys.EntityHistoryViewModel[]> {
        let params = new HttpParams();
        params = params.set("page", page.toString());
        params = params.set("pageSize", pageSize.toString());
        return this.http.get<af.sys.EntityHistoryViewModel[]>(this.url + id + "/history", { params });
    }

    getExisting(item: app.crm.CompanyInsertUpdateViewModel): Observable<app.crm.CompanyListViewModel> {
        let request = {
            companyId: item.companyId,
            isParentCompany: item.isParentCompany,
            idNumber: item.idNumber,
            taxIdNumber: item.taxIdNumber,
            vatIdNumber: item.vatIdNumber,
            otherIdNumber: item.otherIdNumber,
            title: item.title,
            addressStreetWithNumber: item.addressStreetWithNumber,
            addressCity: item.addressCity,
            addressCountry: item.addressCountry
        };

        let params = new HttpParams();
        params = params.set("item", JSON.stringify(request));
        params = params.set("showLoadingBar", "false");

        return this.http.get<app.crm.CompanyListViewModel>(this.url + "existing", { params });
    }

    registrySearch(item: app.crm.CompanyInsertUpdateViewModel): Observable<app.crm.CompanyRegistrySearchResponse> {
        let request = {
            searchTerm: item.title,
            addressCountry: item.addressCountry
        };

        let params = new HttpParams();

        params = params.set("search", JSON.stringify(request));

        return this.http.get<app.crm.CompanyRegistrySearchResponse>(this.url + "registrysearch", { params });
    }

    registryGetByHandle(item: app.crm.CompanyRegistryItem): Observable<app.crm.CompanyRegistryGetResponse> {
        let params = new HttpParams();

        params = params.set("item", JSON.stringify(item));

        return this.http.get<app.crm.CompanyRegistryGetResponse>(this.url + "registrygetbyhandle", { params });
    }

    registryGetByIdNumber(item: app.crm.CompanyInsertUpdateViewModel): Observable<app.crm.CompanyRegistryGetResponse> {
        let request = {
            idNumber: item.idNumber,
            addressCountry: item.addressCountry
        };

        let params = new HttpParams();

        params = params.set("item", JSON.stringify(request));

        return this.http.get<app.crm.CompanyRegistryGetResponse>(this.url + "registrygetbyidnumber", { params });
    }

    registrySearchByVatIdNumber(item: app.crm.CompanyInsertUpdateViewModel): Observable<app.crm.CompanyRegistrySearchResponse> {
        let request = {
            vatIdNumber: item.vatIdNumber
        };

        let params = new HttpParams();

        params = params.set("search", JSON.stringify(request));

        return this.http.get<app.crm.CompanyRegistrySearchResponse>(this.url + "registrysearch", { params });
    }

    getFDPeopleList(companyId: number): Observable<app.crm.CompanyFDResponsiblePeopleViewModel[]> {
        return this.http.get<app.crm.CompanyFDResponsiblePeopleViewModel[]>(this.url + companyId+ "/responsiblepeople");
    }

    getFDPeopleForSelectList(companyId: number): Observable<app.crm.PersonSelectListViewModel[]> { 
        return this.http.get<app.crm.PersonSelectListViewModel[]>(this.url + companyId + "/responsiblepeoplelist");
    }

    updateFDPeople(companyId: number, peopleIds: number[], hollenCompanyCode: string): Observable<any> {
        return this.http.put(this.url + companyId + "/updateresponsible/" + hollenCompanyCode , peopleIds);
    }

    metriqWorkReportSyncByCompany(companyId: number) {
        return this.http.put(this.url + companyId + "/metriq-workreport-sync-by-company", "");
    }

    updateMetriqDetails(companyId: number, viewModel: app.crm.CompanyMetriqUpdateViewModel): Observable<any> {
        return this.http.put(this.url + companyId.toString() + "/metriq", viewModel);
    }
    
    getMetriqDetails(companyId: number): Observable<app.crm.CompanyMetriqFormViewModel> {
        let params = new HttpParams();

        params = params.set("appendLists", "true");

        return this.http.get<app.crm.CompanyMetriqFormViewModel>(this.url + companyId.toString() + "/metriq", { params });
    }

}
