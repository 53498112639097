
<af-page-bar title="{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-edit.component.editation - advance' | translate }}" helpid="advance-employee.guide"></af-page-bar>

<div class="form">
    <form (ngSubmit)="update()" name="updateEmployeeAdvanceForm" novalidate="" #updateEmployeeAdvanceForm="ngForm" [class.disabled-all]="isLoading">
        <div class="form-horizontal">
            <div class="form-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="EmployeeId">{{ 'hollen.operation.employeeadvanceinsertupdateviewmodel.employee' | translate }}</label>
                            <div class="col-md-10">
                                {{employeeAdvance.employeeName}}
                                <span><span *ngIf="(updateEmployeeAdvanceForm.controls['EmployeeId']?.dirty || updateEmployeeAdvanceForm.submitted) && updateEmployeeAdvanceForm.controls['EmployeeId']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeeadvanceinsertupdateviewmodel.employee'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group ac-group">
                            <label class="control-label col-md-2">{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-edit.component.project task' | translate }}</label>
                            <div class="col-md-10">
                                <div class="input-group">
                                        <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
                                    <input type="text" class="form-control" [disabled]="!(employeeAdvance.advanceType == 1)" [(ngModel)]="selectedProjectTask" [editable]="false" [ngbTypeahead]="projectTaskAutocomplete" [resultFormatter]="projectTaskFormatter" [inputFormatter]="projectTaskFormatter" [required]="employeeAdvance.advanceType == 1" id="ProjectTaskId" name="ProjectTaskId" value="" />
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="AdvanceType">{{ 'hollen.operation.employeeadvanceinsertupdateviewmodel.advance type' | translate }}</label>
                            <div class="col-md-10">
                                <af-radio *ngFor="let item of advanceTypes"
                                          [inline]="true"
                                          [name]="'AdvanceType' + item.key"
                                          [value]="item.key"
                                          [label]="item.value"
                                          [(ngModel)]="employeeAdvance.advanceType"
                                          required>
                                </af-radio>
                                <span><span *ngIf="(updateEmployeeAdvanceForm.controls['AdvanceType']?.dirty || updateEmployeeAdvanceForm.submitted) && updateEmployeeAdvanceForm.controls['AdvanceType']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeeadvanceinsertupdateviewmodel.advance type'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="Description">{{ 'hollen.operation.employeeadvanceinsertupdateviewmodel.description' | translate }}</label>
                            <div class="col-md-10">
                                <input class="form-control" [(ngModel)]="employeeAdvance.description" type="text" id="Description" name="Description" value="" />
                                <af-help helpid="Hollen.Operation.EmployeeAdvanceInsertUpdateViewModel.Description"></af-help>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="Amount">{{ 'hollen.operation.employeeadvanceinsertupdateviewmodel.amount' | translate }}</label>
                            <div class="col-md-10">
                                <input class="form-control" [(ngModel)]="employeeAdvance.amount" type="text" afNumber="" id="Amount" name="Amount" required="" value="" />
                                <af-help helpid="Hollen.Operation.EmployeeAdvanceInsertUpdateViewModel.Amount"></af-help>
                                <span><span *ngIf="(updateEmployeeAdvanceForm.controls['Amount']?.dirty || updateEmployeeAdvanceForm.submitted) && updateEmployeeAdvanceForm.controls['Amount']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeeadvanceinsertupdateviewmodel.amount'} }}</span>
<span *ngIf="(updateEmployeeAdvanceForm.controls['Amount']?.dirty || updateEmployeeAdvanceForm.submitted) && updateEmployeeAdvanceForm.controls['Amount']?.hasError('number')" class="field-validation-error">{{ 'validator.the field {0} must be a number.' | translate:{'0': 'translate|hollen.operation.employeeadvanceinsertupdateviewmodel.amount'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="Note">{{ 'hollen.operation.employeeadvanceinsertupdateviewmodel.note' | translate }}</label>
                            <div class="col-md-10">
                                <input class="form-control" [(ngModel)]="employeeAdvance.note" type="text" id="Note" name="Note" value="" />
                                <af-help helpid="Hollen.Operation.EmployeeAdvanceInsertUpdateViewModel.Note"></af-help>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="ValidityMonth">{{ 'hollen.operation.employeeadvanceinsertupdateviewmodel.validity month' | translate }}</label>
                            <div class="col-md-10">
                                <af-radio [value]="1" name="ValidityMonth"
                                          [ngModel]="validityMonth"
                                          (ngModelChange)="validityMonth = $event; updateValidityMonth()"
                                          [inline]="true"
                                          required>
                                    {{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-edit.component.previous month' | translate }}
                                </af-radio>
                                <af-radio [value]="2" name="ValidityMonth"
                                          [ngModel]="validityMonth"
                                          (ngModelChange)="validityMonth = $event; updateValidityMonth()"
                                          [inline]="true"
                                          required>
                                    {{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-edit.component.current month' | translate }}
                                </af-radio>
                                <af-radio [value]="3" name="ValidityMonth"
                                          [ngModel]="validityMonth"
                                          [inline]="true"
                                          (ngModelChange)="validityMonth = $event; updateValidityMonth()"
                                          required>
                                    {{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-edit.component.next month' | translate }}
                                </af-radio>
                                <label class="control-label col-md-2">{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-edit.component.the chosen month' | translate }}</label>
                                {{employeeAdvance.validityMonth | afDate:'MM/YYYY' }}
                                <span><span *ngIf="(updateEmployeeAdvanceForm.controls['ValidityMonth']?.dirty || updateEmployeeAdvanceForm.submitted) && updateEmployeeAdvanceForm.controls['ValidityMonth']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeeadvanceinsertupdateviewmodel.validity month'} }}</span>
<span *ngIf="(updateEmployeeAdvanceForm.controls['ValidityMonth']?.dirty || updateEmployeeAdvanceForm.submitted) && updateEmployeeAdvanceForm.controls['ValidityMonth']?.hasError('date')" class="field-validation-error">{{ 'validator.{0} is not valid date format.' | translate:{'0': 'translate|hollen.operation.employeeadvanceinsertupdateviewmodel.validity month'} }}</span>
<span *ngIf="(updateEmployeeAdvanceForm.controls['ValidityMonth']?.dirty || updateEmployeeAdvanceForm.submitted) && updateEmployeeAdvanceForm.controls['ValidityMonth']?.hasError('daterange')" class="field-validation-error">{{ 'validator.{0} is out of its valid range.' | translate:{'0': 'translate|hollen.operation.employeeadvanceinsertupdateviewmodel.validity month'} }}</span>
</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="PaymentType">{{ 'hollen.operation.employeeadvanceinsertupdateviewmodel.payment type' | translate }}</label>
                            <div class="col-md-10">
                                <select class="form-control form-select" [(ngModel)]="employeeAdvance.paymentType" id="PaymentType" name="PaymentType" required="">
                                    <option *ngFor="let type of paymentTypes" [ngValue]="type.key">{{type.value}}</option>
                                </select>
                                <af-help helpid="Hollen.Operation.EmployeeAdvanceInsertUpdateViewModel.PaymentType"></af-help>
                                <span><span *ngIf="(updateEmployeeAdvanceForm.controls['PaymentType']?.dirty || updateEmployeeAdvanceForm.submitted) && updateEmployeeAdvanceForm.controls['PaymentType']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeeadvanceinsertupdateviewmodel.payment type'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-actions">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="offset-md-1 col-md-11">

                                    <button *afHasPerm="{obj:objectKey, perm:'Update', if: 'this.employeeAdvance.approvedAt == null && this.employeeAdvance.approvedBy == null'}" class="btn btn-update btn-primary" type="submit">{{ 'btn.save' | translate }}</button>
                                    <button afConfirmClick type="button" class="btn btn-delete btn-danger"
                                            *afHasPerm="{obj:objectKey, perm:'Delete'}"
                                            (confirmed)="delete()"
                                            message="{{ 'confirm.are you sure you want to delete this record?' | translate }}"
                                            yes="{{ 'confirm.delete.delete' | translate }}"
                                            no="{{ 'confirm.delete.cancel' | translate }}">
                                        {{ 'btn.delete' | translate }}
                                    </button>
                                    <ng-container>
                                        <a *afHasPerm="{obj:objectKey, perm: 'Insert'}" class="btn-new btn-success btn" [routerLink]="['/operation/employeeAdvance/create']">{{ 'btn.new' | translate }}</a>
                                    </ng-container>


                                    <a class="btn-back btn-warning btn" onclick="history.back()">{{ 'btn.back' | translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
