import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { Observable, of } from "rxjs";
import { Router } from "@angular/router";
import { EmployeeRelationshipService } from "./employee-relationship.service";
import { EmployeeService } from "../employee/employee.service";
import { ListQueryParams } from "../../models/list-query-params.model";
import { TableBaseComponent } from "../../core";
import { AppService } from "../../app.service";
import { finalize } from "rxjs/operators";


class QueryParams extends ListQueryParams {
    employeeId: number = null;
}

@Component({
    selector: "op-employee-relationship-list",
    templateUrl: "./employee-relationship-list.component.html"
})
export class EmployeeRelationshipListComponent extends TableBaseComponent {
    @Input() employeeId: number;
    @Input() hideTitle = false;
    queryParams = new QueryParams();
    objectKey: string;
    model: PagedList<app.operation.EmployeeRelationshipListViewModel>; 
    constructor(router: Router,
        private employeeRelationshipService: EmployeeRelationshipService,
        private employeeService: EmployeeService,
        private appService: AppService
    ) {
        super(router);
        this.queryParams.sortBy = this.sortBy = "employeeRelationshipId desc";
        this.objectKey = employeeRelationshipService.objectKey;
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.employeeId) {
            this.queryParams.employeeId = this.employeeId;
        }
        this.loadData();
    }

    loadData() {   
        this.isLoading = true;

        this.employeeRelationshipService.query(this.queryParams)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.model = res;
            });
    }

}
