import { AccountCenterService } from "../organization/account-center/account-center.service";
import { CountryService } from "./country/country.service";
import { IslandService } from "../organization/island/island.service";
import { WorkPlaceService } from "./work-place/work-place.service";
import { DeskService } from './desk/desk.service';
import { HollenCompanyService } from "./hollen-company/hollen-company.service";

export const providers = [
    AccountCenterService,
    CountryService,
    HollenCompanyService,
    IslandService,
    WorkPlaceService,
    DeskService
]
