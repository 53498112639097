import { Component, Directive, Input, ContentChild, TemplateRef, AfterContentInit, ContentChildren, QueryList } from "@angular/core";

@Directive({
    selector: "[afTemplate]"
})
export class ColumnTemplateDirective {

    @Input() type: string;

    constructor(public template: TemplateRef<any>) { }
}

@Component({
    selector: "af-column",
    template: ``
})
export class ColumnComponent implements AfterContentInit {
    @Input() field: string;
    @Input() header: string;
    @Input() footer: string;
    @Input() sortable: string;
    @Input() editable: boolean;
    @Input() rowspan: number;
    @Input() colspan: number;
    @Input() style: string;
    @Input() styleClass: string;
    @Input() dataColClass: (value: any) => string[];
    @Input() dataRowSpan: (value: any) => number;
    @Input() dataColSpan: (value: any) => number;
    @ContentChildren(ColumnTemplateDirective, { descendants: false }) templates: QueryList<ColumnTemplateDirective>;
    public template: TemplateRef<any>;
    public headerTemplate: TemplateRef<any>;

    ngAfterContentInit(): void {
        this.template = this.templates.first ? this.templates.first.template : null;
        this.headerTemplate = this.templates && this.templates.length === 2 ? this.templates.last.template : null;
    }
}
