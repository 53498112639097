import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpService } from "../core/services/http.service";
import { Observable } from "rxjs";

@Injectable()
export class IntDocService extends HttpService<app.b2b.IntDocListViewModel, null, null> {
    objectKey = "b2b.IntDoc";
    xmlUrl = "/api/b2b/intdoc/";

    constructor(http: HttpClient) {
        super(http, "/api/b2b/intdoc/");
    }

    getFilter(): Observable<app.b2b.IntDocListFilterViewModel> {
        return this.http.get<app.b2b.IntDocListFilterViewModel>(this.url + "filter");
    }

    generateXml(parameters?: any): Observable<app.b2b.InvoicePohodaXmlViewModel> {
        return this.http.get<app.b2b.InvoicePohodaXmlViewModel>(this.url + "xmllist", {
            params: this.getParameters(parameters)
        });
    }
}
