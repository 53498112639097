import { NouiFormatter } from "ng2-nouislider";

import { TimeObject } from "../../models/time-object.model";

export class WorkReportSliderFormatter implements NouiFormatter {
    constructor(private times: TimeObject[], private showAsText = true) {

    }

    from = (value: string) => {
        return +parseFloat(value).toFixed(0);
    }

    to = (value: number): string => {
        let val = +parseFloat(value.toString()).toFixed(0);
        if (this.showAsText) {
            return this.times.filter(x => x.value === val)[0].title;
        }
        else {
            return val.toString();
        }
    }
}
