import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class BudgetService
    extends HttpService<app.crm.BudgetListViewModel, app.crm.BudgetFormViewModel, app.crm.BudgetInsertUpdateViewModel>
{
    objectKey = "crm.budget";

    constructor(http: HttpClient) {
        super(http, "/api/crm/budget/");
    }

    getCurrencies() {
        return this.http.get<string[]>(this.url + "currencies");
    }

    savePurchaseOrder(item: app.crm.BudgetPurchaseOrderListViewModel) {
        if (item.budgetPurchaseOrderId) {
            return this.http.put<app.crm.BudgetPurchaseOrderListViewModel>(this.url + "purchase-order", item);
        } else {
            return this.http.post<app.crm.BudgetPurchaseOrderListViewModel>(this.url + "purchase-order", item);
        }
    }

    deletePurchaseOrder(item: app.crm.BudgetPurchaseOrderListViewModel) {
        return this.http.delete(this.url + "purchase-order/" + item.budgetPurchaseOrderId);
    }

    getSelectList(searchTerm?: string, companyId?: number, accountCenterCode?: string, currency?: string): Observable<app.crm.BudgetPurchaseOrderSelectListViewModel[]> {
        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        if (companyId) {
            params = params.set("companyId", companyId.toString());
        }
        if (accountCenterCode) {
            params = params.set("accountCenterCode", accountCenterCode);
        }
        if (currency) {
            params = params.set("currency", currency);
        }
        return this.http.get<app.crm.BudgetPurchaseOrderSelectListViewModel[]>(this.url + "list", { params });
    }

    getProjectTaskSelectList(searchTerm?: string, companyId?: number, accountCenterCode?: string, currency?: string): Observable<app.crm.BudgetProjectTaskListViewModel[]> {
        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        if (companyId) {
            params = params.set("companyId", companyId.toString());
        }
        if (accountCenterCode) {
            params = params.set("accountCenterCode", accountCenterCode);
        }
        if (currency) {
            params = params.set("currency", currency);
        }
        return this.http.get<app.crm.BudgetProjectTaskListViewModel[]>(this.url + "projecttasklist", { params });
    }

    addProjectTask(budgetId: number, projectTaskId: number) {
        return this.http.put(this.url + budgetId.toString() + "/project-task/" + projectTaskId, null);
    }

    deleteProjectTask(budgetId: number, projectTaskId: number) {
        return this.http.delete(this.url + budgetId.toString() + "/project-task/" + projectTaskId);
    }
}
