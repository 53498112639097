import { Injectable } from "@angular/core";

@Injectable()
export class ToastService {
    constructor() { }

    success(message: string) {
        toastr.success(message);
    }

    error(message: string) {
        toastr.error(message);
    }
}
