import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { FileUploadOptions } from "../../shared/components/file-upload/file-upload.service";

@Injectable()
export class CompanyEvaluationService
    extends HttpService<app.crm.CompanyEvaluationListViewModel, app.crm.CompanyEvaluationInsertUpdateViewModel, app.crm.CompanyEvaluationInsertUpdateViewModel>
{
    objectKey = "crm.CompanyEvaluation";
    //In the past there was import from file and they wanted to keep this permissions
    objectKeyImport = "crm.CompanyEvaluationImportHistory";

    constructor(http: HttpClient) {
        super(http,"/api/crm/companyevaluation/")
    }

    getFilter(): Observable<app.crm.CompanyEvaluationFilterViewModel> {
        return this.http.get<app.crm.CompanyEvaluationFilterViewModel>(this.url + "filter");
    }

    getTable(request: app.crm.GetCompanyEvaluationListRequest): Observable<PagedList<app.crm.CompanyEvaluationListViewModel>>{
        let params = new HttpParams();
        params = params.set("year", request.year.toString());
        params = params.set("month", request.month.toString());

        if (request.companyId) {
            params = params.set("companyId", request.companyId.toString());
        }
        if (request.searchTerm) {
            params = params.set("searchTerm", request.searchTerm);
        }

        if (request.page) {
            params = params.set("page", request.page.toString());
        }

        if (request.pageSize) {
            params = params.set("pageSize", request.pageSize.toString());
        }

        if (request.sortBy) {
            params = params.set("sortBy", request.sortBy.toString());
        }
        
        return this.http.get < PagedList<app.crm.CompanyEvaluationListViewModel>>(this.url + "table", { params });
    }
}
