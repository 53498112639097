import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../core/services/http.service";
import { FileUploadOptions } from "../shared/components/file-upload/file-upload.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class OtherAgreementsService
    extends HttpService<app.b2b.OtherAgreementsListViewModel, app.b2b.OtherAgreementsFormViewModel, app.b2b.OtherAgreementsInsertUpdateViewModel> {
    objectKey = "b2b.OtherAgreements";

    constructor(http: HttpClient) {
        super(http, "/api/b2b/otheragreements/");
    }

    approve(otherAgreementsId: number): Observable<any> {
        return this.http.put(this.url + otherAgreementsId + "/approve", "");
    }

    unapprove(otherAgreementsId: number): Observable<any> {
        return this.http.put(this.url + otherAgreementsId + "/unapprove", "");
    }

    getUploadOptions(otherAgreementsId: number): FileUploadOptions {
        let options = new FileUploadOptions();
        options.url = this.url + "file/" + otherAgreementsId;
        return options;
    }

    deleteFile(invoiceFileId: number): Observable<any> {
        return this.http.delete(this.url + "file/" + invoiceFileId);
    }

    getFilter(): Observable<app.b2b.OtherAgreementsListFilterViewModel> {
        return this.http.get<app.b2b.OtherAgreementsListFilterViewModel>(this.url + "filter");
    }
}
