import { Component, EventEmitter, OnInit } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { AppService } from "../../../app.service";
import { B2bAdvanceService } from "./b2b-advance.service";

enum DisplayMode {
    Create = 'create',
    Edit = 'edit',
}

@Component({
    selector: "crm-company-b2b-advance",
    templateUrl: "./b2b-advance.component.html"
})
export class B2bAdvanceComponent implements OnInit {

    collectionChanged = new EventEmitter<any>();
    create = new ReplaySubject<{ advanceId: number }>(1);
    edit = new ReplaySubject<{ advanceId: number }>(1);
    objectKey: string;
    displayMode: DisplayMode;
    companyId: number;

    constructor(private b2bAdvanceService: B2bAdvanceService,
        private appService: AppService) {
        this.objectKey = b2bAdvanceService.objectKey;
        this.companyId = +this.appService.getParam("companyId");
    }

    ngOnInit() {
        this.displayMode = DisplayMode.Create;
        this.create.next();
    }

    onInsert(event: app.b2b.AdvanceRequestInsertUpdateViewModel) {
        this.displayMode = DisplayMode.Create;
        this.create.next();
        this.collectionChanged.emit();
    }

    onEditClicked(event: app.b2b.AdvanceRequestListViewModel) {
        this.displayMode = DisplayMode.Edit;
        let id = { advanceId: event.requestId };
        this.edit.next(id);
    }

    onCopyClicked(event: app.b2b.AdvanceRequestListViewModel) {
        this.displayMode = DisplayMode.Create;
        let id = { advanceId: event.requestId };
        this.create.next(id);
    }

    onUpdate(event: any) {
        this.displayMode = DisplayMode.Create;
        this.create.next();
        this.collectionChanged.emit();
    }

    onCancel() {
        this.displayMode = DisplayMode.Create;
        this.create.next();
    }
}
