<div class="card">
    <div class="card-header">{{ 'hollen.clientapp.src.app.operation.report.project-task-overview-report.component.project task overview' | translate }}</div>
    <div class="card-block card-report form-horizontal">
        <div>
            <div *ngIf="branch">
                <div class="row form-group">
                    <div class="col-md-7 control-label">
                        {{ 'hollen.clientapp.src.app.operation.report.project-task-overview-report.component.account center' | translate }}
                    </div>
                    <div class="col-md-5 col-fixed-md">
                        <select class="form-control form-select" [(ngModel)]="request.accountCenterCode">
                            <option *ngFor="let accountCenter of branch.accountCenters" [ngValue]="accountCenter">{{accountCenter}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-12">
                    <a [href]="getUrl()" class="btn btn-default-alt btn-generate btn-large-icon" title="{{ 'btn.generate' | translate }}"><i class="fa fa-download"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
