import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { Observable, of } from "rxjs";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ListQueryParams } from "../../models/list-query-params.model";
import { EmployeeTripService } from "./employee-trip.service";
import { EmployeeService } from "../../operation/employee/employee.service";
import { ProjectTaskService } from "../../operation/project-task/project-task.service";
import { FormComponent } from "../../core";
import { finalize, switchMap } from "rxjs/operators";
import { filterAndDebounce } from "../../core/utils";
import { KeyValue } from "@angular/common";



@Component({
    selector: "op-employee-trip-edit",
    templateUrl: "./employee-trip-edit.component.html"
})
export class EmployeeTripEditComponent extends FormComponent implements OnChanges {
    @Input() employeeTripId: number;
    @Output() updated = new EventEmitter;
    isLoading: boolean;

    @ViewChild(NgForm, { static: true }) editEmployeeTripForm: NgForm;
    employeeTrip = <app.operation.EmployeeTripInsertUpdateViewModel>{};
    selectedEmployee: app.operation.EmployeeSelectListViewModel;
    selectedProjectTask: app.operation.ProjectTaskSelectListViewModel;
    tripDestinations: app.operation.TripDestinationListViewModel[];
    objectKey: string;
    departureTimeList: dotnet.KeyValuePair[];
    returnTimeList: dotnet.KeyValuePair[];
    currentUser: app.UserViewModel;
    disableProjectTask: boolean = true;
    selectedEmployeeCountryCode: string;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private employeeTripService: EmployeeTripService,
        private employeeService: EmployeeService,
        private projectTaskService: ProjectTaskService
    ) {
        super(router, "employeeTrip");

        this.objectKey = employeeTripService.objectKey;
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadData();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.loadData();
    }

    loadData() {
        let employeeTripId = 0;

        if (this.employeeTripId) {
            employeeTripId = this.employeeTripId;
        }
        else {
            employeeTripId = +(this.route.snapshot.params["employeeTripId"] || this.route.parent.snapshot.params["employeeTripId"]);
        }

        this.isLoading = true;

        this.employeeTripService.get(employeeTripId, true).subscribe((result) => {
            this.employeeTrip = result.model;
            this.departureTimeList = result.departureTime;
            this.returnTimeList = result.returnTime;
            this.tripDestinations = result.destinations;
            this.selectedProjectTask = result.projectTask;
            this.selectedEmployee = <app.operation.EmployeeSelectListViewModel>{
                employeeId: this.employeeTrip.employeeId,
                employeeName: this.employeeTrip.employeeName,
                countryCode: this.employeeTrip.countryCode
            };

            this.isLoading = false;
        });
    }

    update() {
        if (this.editEmployeeTripForm.invalid) {
            return;
        }
        this.employeeTrip.employeeId = this.selectedEmployee.employeeId;
        this.employeeTrip.employeeName = this.selectedEmployee.employeeName;
        if (this.selectedProjectTask == null) {
            this.employeeTrip.projectTaskId = null;
        }
        else {
            this.employeeTrip.projectTaskId = this.selectedProjectTask.firstProjectTaskId;
        }

        this.isLoading = true;
        this.employeeTripService.update(this.employeeTrip, this.employeeTrip.employeeTripId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(() => {
                this.editEmployeeTripForm.control.markAsPristine();
                this.updated.emit();
            });

    }

    delete() {
        this.isLoading = true;
        this.employeeTripService.delete(this.employeeTrip, this.employeeTrip.employeeTripId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((result) => {
                this.editEmployeeTripForm.reset();

                if (!(this.employeeTripId)) {
                    this.router.navigate(["/operation/employeeTrip"]);
                }

                else {
                    this.router.navigate(["/operation/employee/" + this.employeeTrip.employeeId + "/employeeTrip/create"]);
                }

            });
    }

    employeeAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(searchTerm => searchTerm.length < 2
                    ? of([])
                    : this.employeeService.getListByRequest(<app.operation.EmployeeSelectListRequest>{
                        page: 1,
                        pageSize: 20,
                        searchTerm,
                        onlyB2b: true,
                        isActive: true
                    })));
    }

    employeeFormatter = (employee: app.operation.EmployeeSelectListViewModel) => employee.employeeName;

    projectTaskAutocomplete = (text$: Observable<string>) => {

        return text$
            .pipe(filterAndDebounce,
                switchMap(term => term.length < 2 ? of([]) : this.projectTaskService.getList(1, 20, term, null, null, null, this.selectedEmployee.countryCode)));
    }

    projectTaskFormatter = (projectTask: app.operation.ProjectTaskSelectListViewModel) => (projectTask.projectTaskNumber + " - " + projectTask.title);

}
