import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { finalize, switchMap, tap } from "rxjs/operators";
import { TableBaseComponent } from "../../core";
import { ListQueryParams } from "../../models";
import { AccommodationTermService } from "./accommodation-term.service";

class QueryParams extends ListQueryParams {
    employeeId: number;
}

@Component({
    selector: "op-accommodation-term-list",
    templateUrl: "./accommodation-term-list.component.html"
})
export class AccommodationTermListComponent extends TableBaseComponent {

    @Input() employeeId: number;
    @Input() load = new EventEmitter<any>();
    @Output() editClicked = new EventEmitter<app.operation.AccommodationTermListViewModel>();
    model: PagedList<app.operation.AccommodationTermListViewModel>;
    objectKey: string;
    queryParams = new QueryParams();

    constructor(router: Router,
        private accommodationTermService: AccommodationTermService) {
        super(router);
        this.objectKey = accommodationTermService.objectKey;
    }

    ngOnInit() {
        super.ngOnInit();

        this.queryParams.employeeId = this.employeeId;

        this.load
            .pipe(tap(() => this.isLoading = true),
                switchMap(x => this.accommodationTermService.query(this.queryParams)
                    .pipe(finalize(() => this.isLoading = false))))
            .subscribe(res => {
                this.model = res;
                this.saveState(this.queryParams, false);
            });

        this.loadData();
    }

    loadData() {
        this.load.emit();
    }

    clickEdit(item: app.operation.AccommodationTermListViewModel) {
        this.editClicked.emit(item);
    }
}
