import {
    Directive,
    Output,
    EventEmitter,
    HostBinding,
    HostListener
} from "@angular/core";

@Directive({
    selector: "[long-touch]"
})
export class LongTouchDirective {
    _pressing: boolean;
    _longPressing: boolean;
    _timeout: any;

    @Output()
    onLongPress = new EventEmitter();

    @HostListener("touchstart", ["$event"])
    onTouchStart(event) {
        //event.preventDefault();
        //event.stopPropagation();

        this._pressing = true;
        this._longPressing = false;

        this._timeout = setTimeout(() => {
            this._longPressing = true;
            this.onLongPress.emit(event);
        }, 500);
    }

    @HostListener("touchend", ["$event"])
    onTouchEnd(event) {
        //event.preventDefault();
        //event.stopPropagation();

        clearTimeout(this._timeout);

        this._longPressing = false;
        this._pressing = false;
    }
}