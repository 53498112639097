export class ListQueryParams {
    page = 1;
    pageSize = 20;
    searchTerm: string = null;
    sortBy: string = null;

    copy(params: any) {
        for (let key in params) {
            if (params.hasOwnProperty(key)) {
                this[key] = params[key];
            }
        }
    }

    sortOrder(): string {
        if (!this.sortBy) {
            return null;
        }
        return this.sortBy.indexOf(" desc") !== -1 ? "desc" : null;
    }

    sortField(): string {
        if (!this.sortBy) {
            return null;
        }

        let index = this.sortBy.indexOf(" desc");
        if (index !== -1) {
            return this.sortBy.substring(0, index);
        } else {
            return this.sortBy;
        }
    }

    setSortOrder(order: string) {
        let field = this.sortField();
        if (order) {
            this.sortBy = field + " " + order;
        }
        else {
            this.sortBy = field;
        }

    }
}
