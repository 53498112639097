import {
    Component, Input, OnChanges, OnInit, OnDestroy, SimpleChanges, Inject, Output,
    EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef
} from "@angular/core";
import { Observable, Subscription } from "rxjs";

import { ShiftPlanService } from "./shift-plan.service";
import { ShiftTypeService } from "../shift-type/shift-type.service";
import { ProjectTaskService } from "../project-task/project-task.service";
import { WorkPlaceService } from "../../organization/work-place/work-place.service";
import { AppService } from "../../app.service";
import { TimeService } from "../../core/services";
import { TimeObject } from "../../models/time-object.model";
import { finalize } from "rxjs/operators";

@Component({
    selector: "op-shift-plan-table-day",
    templateUrl: "./shift-plan-table-day.component.html",
    styles: [`
        .overview-day-time {
            display: inline-block;
            font-weight: bold;
            margin-left: 10px;
            float: right;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShiftPlanTableDayComponent implements OnInit, OnDestroy {
    @Input() from: Date;
    @Input() projectTask: app.operation.ProjectTaskSelectListViewModel;
    @Input() workPlace: app.organization.WorkPlaceSelectListViewModel;
    @Input() agency: app.crm.CompanySelectListViewModel;
    @Input() majorProvider: app.crm.CompanySelectListViewModel;
    @Input() showOnlyPlanned: boolean;
    @Input() showLink = false;
    @Input() shiftTypes: app.operation.ShiftTypeSelectListViewModel[];
    @Input() changed: EventEmitter<any>;
    @Output() hide = new EventEmitter<any>();
    @Output() loaded = new EventEmitter<any>();

    isLoading: boolean;
    model: app.operation.ShiftPlanCalendarDayViewModel[];
    intranetUrl: string;
    maxRows = 0;
    changeSubscription: Subscription = null;
    subscription: Subscription = null;

    constructor(private shiftPlanService: ShiftPlanService, private appService: AppService, private changeDetectorRef: ChangeDetectorRef) {
        this.intranetUrl = appService.homeViewModel.intranetUrl;
    }

    ngOnInit() {
        this.loadCalendar();

        if (this.changed) {
            this.changeSubscription = this.changed.subscribe(res => {
                this.calculateMaxRows();
                this.changeDetectorRef.markForCheck();
            });
        }
    }

    ngOnDestroy() {
        if (this.changeSubscription) {
            this.changeSubscription.unsubscribe();
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    getTitle() {
        if (this.projectTask) {
            return this.projectTask.projectTaskNumber + " - " + this.projectTask.title + " / " + this.projectTask.workPlaceTitle;
        }

        if (this.workPlace) {
            return this.workPlace.title;
        }
    }

    loadCalendar() {
        if (!this.from) {
            return;
        }

        let observable: Observable<app.operation.ShiftPlanCalendarDayViewModel[]> = null;

        if (this.projectTask) {
            observable = this.shiftPlanService.getCalendarDay(this.from, this.projectTask.firstProjectTaskId, null, this.majorProvider ? this.majorProvider.companyId : null);
        }
        else if (this.workPlace) {
            observable = this.shiftPlanService.getCalendarDay(this.from, null, this.workPlace.workPlaceId, this.majorProvider ? this.majorProvider.companyId : null);
        } else {
            return;
        }

        this.isLoading = true;

        this.subscription = observable
            .pipe(finalize(() => {
                this.isLoading = false;
                this.loaded.emit({});
            }))
            .subscribe((res) => {
                this.model = res;
                if (this.projectTask) {
                    this.projectTask["hasPlans"] = this.model.length > 0;
                }
                else if (this.workPlace) {
                    this.workPlace["hasPlans"] = this.model.length > 0;
                }
                this.calculateMaxRows();
            });
    }

    calculateMaxRows() {
        if (!this.model) {
            return;
        }

        this.maxRows = 0;

        for (let item of this.model) {
            if (item.plans && item.plans.length > this.maxRows) {
                this.maxRows = item.plans.length;
            }
        }

        let unchecked = this.getUnchecked();
        if (unchecked && unchecked.length > this.maxRows) {
            this.maxRows = unchecked.length;
        }
    }

    get checkedShiftTypes() {
        return this.shiftTypes.filter(item => item["checked"]);
    }

    get isAllChecked() {
        return this.shiftTypes.length === this.checkedShiftTypes.length;
    }

    getByShiftType(shiftTypeId) {
        let item = this.model.filter(x => x.shiftTypeId === shiftTypeId);
        if (item.length === 0) {
            return new Array(this.maxRows);
        }

        this.matchMaxRows(item[0].plans);

        return item[0].plans;
    }

    getUnchecked() {
        let result = <app.operation.ShiftPlanCalendarDayItemViewModel[]>[];

        for (let item of this.shiftTypes) {
            if (!item["checked"]) {
                let model = this.model.filter(x => x.shiftTypeId === item.shiftTypeId);
                if (model.length > 0) {
                    for (let plan of model[0].plans) {
                        if (plan.employeeId) {
                            result.push(plan);
                        }
                    }
                }
            }
        }

        return result;
    }

    getUncheckedSorted() {
        let result = this.getUnchecked();

        let sorted = result.sort((a, b) => {
            return a.fullName === b.fullName ? 0 : +(a.fullName > b.fullName) || -1;
        });

        this.matchMaxRows(sorted);

        return sorted;
    }

    matchMaxRows(items: app.operation.ShiftPlanCalendarDayItemViewModel[]) {
        if (this.maxRows <= items.length) {
            return;
        }

        for (let i = items.length; i < this.maxRows; i++) {
            items.push(<app.operation.ShiftPlanCalendarDayItemViewModel>{});
        }
    }

    onHide() {
        this.hide.emit(this.projectTask || this.workPlace);
    }

    update() {
        this.changeDetectorRef.detectChanges();
    }

    sendToMajorProvider() {
        if (this.projectTask) {
            let request = <app.operation.ShiftPlanToAgencyRequestViewModel>{
                from: this.from,
                firstProjectTaskId: this.projectTask.firstProjectTaskId,
                companyId: this.majorProvider ? this.majorProvider.companyId : null
            };

            this.shiftPlanService.sendPlansToMajorProvider(request)
                .subscribe((res) => {
                    toastr.info(res);
                });

        }
    }
}
