import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class CodebookService
    extends HttpService<app.codebook.CodebookListViewModel, app.codebook.CodebookFormViewModel, dotnet.KeyValuePair[]>
{
    objectKey: string;
    baseUrl = "/api/codebook/";

    constructor(http: HttpClient) {
        super(http, null);
    }

    setCodebook(codebookType: string) {
        this.url = this.baseUrl + codebookType + "/";
        this.objectKey = "cb." + codebookType;
    }

    setCodebookFromLocationPath(path: string) {
        let tokens = path.split("/");
        let codebookType = tokens[2];
        this.url = this.baseUrl + codebookType + "/";
        this.objectKey = "cb." + codebookType;
    }

    getPossibleParentList(page?: number, pageSize?: number, searchTerm?: string): Observable<app.codebook.CodebookSelectViewModel[]> {
        return this.getList(this.url, page, pageSize, searchTerm, true);
    }

    getSelectList(codebookType: string, page?: number, pageSize?: number,
        searchTerm?: string, onlyPossibleParents?: boolean): Observable<app.codebook.CodebookSelectViewModel[]> {
        let url = this.baseUrl + codebookType + "/";
        return this.getList(url, page, pageSize, searchTerm, onlyPossibleParents);
    }

    getSelectListItem(codebookType: string, id: number): Observable<app.codebook.CodebookSelectViewModel> {
        return this.http.get<app.codebook.CodebookSelectViewModel>(this.baseUrl + codebookType + "/listitem/" + id.toString());
    }

    getNextCode(id: number) {
        let params = new HttpParams();
        params = params.set("parentId", id.toString());
        return this.http.get(this.url + "code", { params });
    }

    getFilter(): Observable<app.codebook.CodebookFilterViewModel> {
        return this.http.get<app.codebook.CodebookFilterViewModel>(this.url + "filter");
    }

    private getList(url: string, page?: number, pageSize?: number,
        searchTerm?: string, onlyPossibleParents?: boolean): Observable<app.codebook.CodebookSelectViewModel[]> {
        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        if (page) {
            params = params.set("page", page.toString());
        }
        if (pageSize) {
            params = params.set("pageSize", pageSize.toString());
        }
        if (onlyPossibleParents) {
            params = params.set("onlyPossibleParents", onlyPossibleParents.toString());
        }
        return this.http.get<app.codebook.CodebookSelectViewModel[]>(url + "list", { params });
    }
}
