import { Action } from "@ngrx/store";

import { type } from "../util";
import { MenuItem } from "../../models/menu-item";

export const ActionTypes = {
    MENU_TOGGLED: type("[Menu] Toggled"),
    MENU_UPDATED: type("[Menu] Updated")
};

export class MenuToggledAction implements Action {
    type = ActionTypes.MENU_TOGGLED;
    constructor(public payload: MenuItem[]) { }
}

export class MenuUpdatedAction implements Action {
    type = ActionTypes.MENU_UPDATED;
    constructor(public payload: MenuItem[]) { }
}

export type Actions
    = MenuToggledAction
    | MenuUpdatedAction;