import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";

import { ProjectService } from "./project.service";

@Component({
    selector: "op-project-read",
    templateUrl: "./project-read.component.html"
})
export class ProjectReadComponent implements OnInit {
    project = <app.operation.ProjectInsertUpdateViewModel>{};
    selectedCompany: app.crm.CompanySelectListViewModel;
    objectKey: string;

    constructor(private route: ActivatedRoute, private projectService: ProjectService) {
        this.objectKey = projectService.objectKey;
    }

    ngOnInit() {
        let projectTaskId = +this.route.snapshot.params["projectTaskId"];
        this.projectService.getProjectByProjectTaskId(projectTaskId).subscribe((result) => {
            this.project = result.model;
            this.selectedCompany = result.company;
        });
    }
}

