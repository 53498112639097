import { Component, Input, Output, ViewChild, OnInit, EventEmitter, OnChanges } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

import { AppService } from "../../app.service";
import { ProjectTaskFileService } from "./project-task-file.service";
import { FormComponent } from "../../core";
import { deepCopy, isArray } from "../../core/utils";
import { ListQueryParams } from "../../models/list-query-params.model";
import { TableComponent } from "../../shared/components/table/table.component";
import { FileUploadOptions } from "../../shared/components/file-upload/file-upload.service";
import { Immutable } from "../../core/immutable";
import { finalize } from "rxjs/operators";
import { ProjectTaskService } from "./project-task.service";
import { Subscription } from "rxjs";
import { MetriqSyncService } from "../../metriq-sync/metriq-sync.service";

class QueryParams extends ListQueryParams {
    projectTaskId: number;
    forInv: boolean;
}

@Component({
    selector: "op-project-task-file",
    templateUrl: "./project-task-file.component.html"
})
export class ProjectTaskFileComponent implements OnInit, OnChanges {
    @Input() projectTaskId: string;
    @Input() forInv: boolean;
    @Input() forInvLocked: boolean;
    @Input() isFilter: boolean = true;
    @Input() isUpload: boolean = true;
    @Input() content: any;
    @Input() paginator = true;
    @Input() refreshData: boolean = false;

    @Output() contentChange = new EventEmitter();
    @Output() countChange = new EventEmitter<number>();

    objectKey: string;
    queryParams = new QueryParams();
    uploadOptions: FileUploadOptions;
    model: PagedList<app.sharepoint.SharePointFileViewModel>;
    projectTask = <app.operation.ProjectTaskUpdateViewModel>{};
    isLoading: boolean;
    sortBy: string;
    isSharepointEnabled = false;
    isLoadingSharePoint = false;
    sharePointUrl: string;
    showProjectTaskHeader = false;
    subscriptions: Subscription[] = [];

    constructor(protected router: Router, protected route: ActivatedRoute,
        private appService: AppService,
        private projectTaskFileService: ProjectTaskFileService,
        private projectTaskService: ProjectTaskService,
        private metriqSyncService: MetriqSyncService,
        private sanitizer: DomSanitizer) {
        this.queryParams.sortBy = this.sortBy = "lastUpdatedUtc desc";
        this.objectKey = projectTaskFileService.objectKey;
        this.isSharepointEnabled = this.appService.isSharepointEnabled;
    }

    ngOnInit() {
        this.subscriptions.push(this.route.parent.params.subscribe(params => {
            this.onReset();
            this.uploadOptions = this.projectTaskFileService.getUploadOptions(this.queryParams.projectTaskId, this.forInv);

            if (this.content == null) {
                this.loadData();
                this.loadSharePoint();
            } else {
                this.model = this.content.data;
                this.sharePointUrl = this.content.sharePointUrl;
            }
            if (this.refreshData) {
                this.loadData();
                this.refreshData = false;
            }

            if (this.route.snapshot.data && this.route.snapshot.data.showHeader) {
                this.showProjectTaskHeader = true;
                this.projectTaskService.get(this.queryParams.projectTaskId, false).subscribe((result) => {
                    this.projectTask = result.model;
                    this.loadData();
                });
            }
        }));
    }

    ngOnChanges() {
        if (this.content && this.content.data) {
            this.model = this.content.data;
        }
    }

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    target(item: app.sharepoint.SharePointFileViewModel) {
        if (item.fileUrl.startsWith("http") || item.fileUrl.startsWith("/")) return "_blank";
        else return "";
    }

    loadData() {
        this.isLoading = true;
       
        this.projectTaskFileService.query(this.queryParams)
            .pipe(finalize(() => {
                this.isLoading = false;
            }))
            .subscribe(res => {
                this.model = res;
                this.countChange.emit(res.totalCount);
                if (this.projectTask.projectTaskId)
                {
                    this.metriqSyncService.getFileVersions(this.projectTask.projectTaskId)
                        .pipe(finalize(() => {                 
                        })).subscribe(result => {
                            if (!result || !isArray(result)) return;

                            this.model.data
                                .forEach(item => {
                                    let indexMatch = result.findIndex((x) => x.customId == item.uniqueId); // Index in Metriq Files 
                                    let modelDataIndex = -1;
                                   
                                    if (indexMatch > -1) {                                        
                                        modelDataIndex = this.model.data.findIndex((x) => x.uniqueId == item.uniqueId) // Index in Sharepoint                          
                                    }
                                    
                                    if (modelDataIndex > -1) {
                                        let matchingGuidFile = result[indexMatch]; // File from Metriq

                                        this.model = Immutable.setInPagedList(this.model, modelDataIndex, { isMetriqVersionMatching: matchingGuidFile.version.localeCompare(item.metriqVersion) == 0, approvalDate: matchingGuidFile.approvalDate });
                                    }
                                });
                        });
                }

                
                this.content = { ...this.content, data: res };
                this.contentChange.emit(this.content);
            });
    }

    loadSharePoint() {
        if (this.isSharepointEnabled) {
            this.isLoadingSharePoint = true;
            this.projectTaskFileService.getSharePointFolder(this.queryParams.projectTaskId)
                .pipe(finalize(() => {
                    this.isLoadingSharePoint = false;
                }))
                .subscribe(res => {
                    this.sharePointUrl = res.url;

                    this.content = { ...this.content, sharePointUrl: res.url };
                    this.contentChange.emit(this.content);
                });
        }
    }

    getForInvLocked(item: app.sharepoint.SharePointFileViewModel) {
        if (this.forInvLocked != null) {
            return this.forInvLocked;
        } else {
            return item.forInvLocked;
        }
    }

    onSuccessUpload(data: any): void {
        this.loadData();
    }

    delete(item: app.sharepoint.SharePointFileViewModel) {
        this.projectTaskFileService.delete(item).subscribe(() => this.loadData());
    }

    updateItem(item: app.sharepoint.SharePointFileViewModel, propObj: any, syncMetriq: boolean = false) {
        let index = this.model.data.indexOf(item);
        this.model = Immutable.setInPagedList(this.model, index, propObj);
        this.model = Immutable.setInPagedList(this.model, index, { updating: true });
        if (syncMetriq) {           
            let now = moment();
            this.model = Immutable.setInPagedList(this.model, index, { lastSyncTime: now  });
        }

        this.projectTaskFileService.update(this.model.data[index], item.uniqueId.toString())
            .pipe(finalize(() => {
                this.model = Immutable.setInPagedList(this.model, index, { updating: false });

                if (syncMetriq) this.syncFile(this.model.data[index]);   
            })).subscribe(() => {
                if (this.forInv && propObj && propObj.forInv != null) {
                    this.loadData(); 
                }            
                if (propObj && (propObj.isSwpVisible || propObj.isVisibleForCustomer))
                {
                    this.loadData();
                }
            });
    }

    onReset() {
        this.onClear();
    }

    onClear() {
        this.queryParams.projectTaskId = +(this.appService.getParam("projectTaskId") || this.projectTaskId);
        this.queryParams.forInv = this.forInv;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    isRightPrefix(documentTypePrefix: string): boolean {
        const prefixes = ['TD', 'TA', 'HS', 'GD', 'PI', 'PP', 'PF', 'VI'];
        return !prefixes.includes(documentTypePrefix); 
    }

    syncFile(item: app.sharepoint.SharePointFileViewModel)
    {
        this.metriqSyncService.syncFile(item)
            .pipe(finalize(() => {   
            })).subscribe(() => {
                this.loadData();
            });
    }
}
