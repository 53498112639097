import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class SupplyChainService
{
    objectKey = "crm.supplyChain";
    url = "/api/crm/supplychain/";

    constructor(private http: HttpClient) {
    }

    query(companyId: number) {
        return this.http.get<PagedList<app.crm.SupplyChainListViewModel>>(this.url + (companyId ? companyId + "/filter" : ""));
    }

    getSupplyChain(id: number, parameters?: any) {
        return this.http.get<app.crm.SupplyChainViewModel>(this.url + id, { params: this.getParameters(parameters) });
    }

    getWorkPlaceSupplyChain(parameters?: any) {
        return this.http.get<app.crm.SupplyChainViewModel>(this.url + 'workplacesupplychain', { params: this.getParameters(parameters) });
    }

    protected getParameters(parameters?: any): HttpParams {
        let params = new HttpParams();

        for (let property in parameters) {
            if (parameters.hasOwnProperty(property) && (parameters[property] !== null || typeof parameters[property] !== "undefined")) {
                if (parameters[property] instanceof Date) {
                    let date = parameters[property];
                    let unix = +moment.utc([date.getFullYear(), date.getMonth(), date.getDate()]);
                    params = params.set(property, unix.toString());
                } else if (parameters[property] instanceof Array) {
                    parameters[property].forEach((item) => {
                        params = params.append(property, item);
                    });
                } else {
                    if (parameters[property] !== null && typeof parameters[property] !== "undefined") {
                        params = params.set(property, parameters[property]);
                    }
                }
            }
        }

        return params;
    }
}

