import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "af-history-value",
    template: `
        <ng-container *ngIf="url">
            <a [href]="url">{{ value }}</a>
        </ng-container>
        <ng-container *ngIf="!url">
            {{ value ? value : '(null)' }}
        </ng-container>
    `,
    styles: [`
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryValueComponent implements OnInit {
    @Input() name: string;
    @Input() value: string;
    url: string;

    constructor(private router: Router) {

    }

    ngOnInit(): void {
        this.url = this.getUrl(this.name, this.value);
    }

    getUrl(propertyName: string, value: any) {
        if (!value) return;

        if (propertyName.endsWith("EmployeeId") || propertyName.endsWith("TechnicianId")) {
            let tree = this.router.createUrlTree([`/operation/employee/${value}`]);
            return this.router.serializeUrl(tree);
        }
        if (propertyName.endsWith("ProjectId")) {
            let tree = this.router.createUrlTree([`/operation/project/${value}`]);
            return this.router.serializeUrl(tree);
        }
        if (propertyName.endsWith("ProjectTaskId")) {
            let tree = this.router.createUrlTree([`/operation/projectTask/${value}`]);
            return this.router.serializeUrl(tree);
        }

        if (propertyName.endsWith("ProductRateId")) {
            let tree = this.router.createUrlTree([`/finance/product-rate/${value}`]);
            return this.router.serializeUrl(tree);
        }

        if (propertyName.endsWith("WorkPlaceId")) {
            let tree = this.router.createUrlTree([`/organization/workPlace/${value}`]);
            return this.router.serializeUrl(tree);
        }
        if (propertyName.endsWith("HollenCompanyCode")) {
            let tree = this.router.createUrlTree([`/organization/hollenCompany/${value}`]);
            return this.router.serializeUrl(tree);
        }
        if (propertyName.endsWith("BranchCode")) {
            let tree = this.router.createUrlTree([`/organization/branch/${value}`]);
            return this.router.serializeUrl(tree);
        }
        if (propertyName.endsWith("AccountCenterCode")) {
            let tree = this.router.createUrlTree([`/organization/accountCenter/${value}`]);
            return this.router.serializeUrl(tree);
        }
        if (propertyName.endsWith("IslandCode")) {
            let tree = this.router.createUrlTree([`/organization/island/${value}`]);
            return this.router.serializeUrl(tree);
        }

        if (propertyName.endsWith("CompanyId")) {
            let tree = this.router.createUrlTree([`/crm/company/${value}`]);
            return this.router.serializeUrl(tree);
        }
        if (propertyName.endsWith("PersonId")) {
            let tree = this.router.createUrlTree([`/crm/person/${value}`]);
            return this.router.serializeUrl(tree);
        }

        if (propertyName.endsWith("InventoryTransferId")) {
            let tree = this.router.createUrlTree([`/operation/inventory-main/transfer/${value}`]);
            return this.router.serializeUrl(tree);
        }

        if (propertyName.endsWith("IssuedBy")) {
            let tree = this.router.createUrlTree([`/usersystem/user/${value}`]);
            return this.router.serializeUrl(tree);
        }
        
        return null;
    }
}
