import { RateContractService } from "./rate-contract/rate-contract.service";
import { ProductRateService } from "./product-rate/product-rate.service";
import { PurchaseService } from "./purchase/purchase.service";
import { InvoiceService } from "./invoice/invoice.service";

export const providers = [
    RateContractService,
    ProductRateService,
    PurchaseService,
    InvoiceService
];
