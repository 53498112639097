
<div class="form">
    <form (ngSubmit)="update()" name="editForm" novalidate="" #editForm="ngForm" [class.disabled-all]="isLoading">
        <div class="form-horizontal">
            <div class="form-body">
                <div class="row">
                    <div class="col-xl-6 col-lg-12">
                        <div class="form-group">
                            <label class="control-label col-md-2">{{ 'hollen.clientapp.src.app.operation.accommodation-term.accommodation-term-edit.component.hostel' | translate }}</label>
                            <div class="col-md-10">
                                <select class="form-control form-select"
                                        [(ngModel)]="model.accommodationId"
                                        [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="onChange($event)">
                                    <option *ngFor="let accommodation of accommodations" [ngValue]="accommodation.accommodationId">
                                        {{accommodation.title}} ({{accommodation.companyTitle}})
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-12">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="AccommodationTypeId">{{ 'hollen.operation.accommodationterminsertupdateviewmodel.price bed_night' | translate }}</label>
                            <div class="col-md-4">
                                <select class="form-control form-select" [(ngModel)]="model.accommodationTypeId" id="AccommodationTypeId" name="AccommodationTypeId" required="">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let type of accommodationTypes" [ngValue]="type.accommodationTypeId">
                                        {{type.price | afCurrency:type.currency:'.2-9'}}{{type.title ? ' - ' : ''}}{{type.title}}
                                    </option>
                                </select>
                                <span><span *ngIf="(editForm.controls['AccommodationTypeId']?.dirty || editForm.submitted) && editForm.controls['AccommodationTypeId']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.accommodationterminsertupdateviewmodel.price bed_night'} }}</span>
</span>
                            </div>
                            <label class="control-label col-md-2" for="InvoicedRate">{{ 'hollen.operation.accommodationterminsertupdateviewmodel.invoiced amount in _' | translate }}</label>
                            <div class="col-md-4">
                                <input class="form-control" [(ngModel)]="model.invoicedRate" afNumber type="text" id="InvoicedRate" name="InvoicedRate" value="" />
                                <span><span *ngIf="(editForm.controls['InvoicedRate']?.dirty || editForm.submitted) && editForm.controls['InvoicedRate']?.hasError('range')" class="field-validation-error">{{ 'validator.the field {0} must be between {1} and {2}.' | translate:{'0': 'translate|hollen.operation.accommodationterminsertupdateviewmodel.invoiced amount in _', '1': '0', '2': '100'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-6 col-lg-12">
                        <div class="form-group">
                            <label class="control-label col-md-2">{{ 'hollen.clientapp.src.app.operation.accommodation-term.accommodation-term-edit.component.accommodated' | translate }}</label>
                            <div class="col-md-3">
                                <af-radio [value]="false" name="isRoommate" [label]="model.employeeName"
                                          [(ngModel)]="model.isRoommate"
                                          (ngModelChange)="reset()">
                                </af-radio>
                            </div>
                            <div class="col-md-2">
                                <af-radio [value]="true" name="isRoommate" label="{{ 'hollen.clientapp.src.app.operation.accommodation-term.accommodation-term-edit.component.other' | translate }}"
                                          [(ngModel)]="model.isRoommate">
                                </af-radio>
                            </div>
                            <div class="col-md-5">
                                <input class="form-control" [(ngModel)]="model.note" [disabled]="!model.isRoommate" type="text" id="Note" name="Note" value="" />
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-12">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="InvoicedFromDate">{{ 'hollen.operation.accommodationterminsertupdateviewmodel.invoiced from' | translate }}</label>
                            <div class="col-md-4">
                                <div class="">
                                    <af-datepicker [(ngModel)]="model.invoicedFromDate" name="InvoicedFromDate"></af-datepicker>
                                </div>
                                <span><span *ngIf="(editForm.controls['InvoicedFromDate']?.dirty || editForm.submitted) && editForm.controls['InvoicedFromDate']?.hasError('date')" class="field-validation-error">{{ 'validator.{0} is not valid date format.' | translate:{'0': 'translate|hollen.operation.accommodationterminsertupdateviewmodel.invoiced from'} }}</span>
<span *ngIf="(editForm.controls['InvoicedFromDate']?.dirty || editForm.submitted) && editForm.controls['InvoicedFromDate']?.hasError('daterange')" class="field-validation-error">{{ 'validator.{0} is out of its valid range.' | translate:{'0': 'translate|hollen.operation.accommodationterminsertupdateviewmodel.invoiced from'} }}</span>
</span>
                            </div>
                            <label class="control-label col-md-2" for="InvoicedToDate">{{ 'hollen.operation.accommodationterminsertupdateviewmodel.invoiced to' | translate }}</label>
                            <div class="col-md-4">
                                <div class="">
                                    <af-datepicker [(ngModel)]="model.invoicedToDate" name="InvoicedToDate"></af-datepicker>
                                </div>
                                <span><span *ngIf="(editForm.controls['InvoicedToDate']?.dirty || editForm.submitted) && editForm.controls['InvoicedToDate']?.hasError('date')" class="field-validation-error">{{ 'validator.{0} is not valid date format.' | translate:{'0': 'translate|hollen.operation.accommodationterminsertupdateviewmodel.invoiced to'} }}</span>
<span *ngIf="(editForm.controls['InvoicedToDate']?.dirty || editForm.submitted) && editForm.controls['InvoicedToDate']?.hasError('daterange')" class="field-validation-error">{{ 'validator.{0} is out of its valid range.' | translate:{'0': 'translate|hollen.operation.accommodationterminsertupdateviewmodel.invoiced to'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-6 col-lg-12">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="FromDate">{{ 'hollen.operation.accommodationterminsertupdateviewmodel.accommodated from' | translate }}</label>
                            <div class="col-md-4">
                                <div class="">
                                    <af-datepicker [(ngModel)]="model.fromDate" name="FromDate" required></af-datepicker>
                                </div>
                                <span><span *ngIf="(editForm.controls['FromDate']?.dirty || editForm.submitted) && editForm.controls['FromDate']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.accommodationterminsertupdateviewmodel.accommodated from'} }}</span>
<span *ngIf="(editForm.controls['FromDate']?.dirty || editForm.submitted) && editForm.controls['FromDate']?.hasError('date')" class="field-validation-error">{{ 'validator.{0} is not valid date format.' | translate:{'0': 'translate|hollen.operation.accommodationterminsertupdateviewmodel.accommodated from'} }}</span>
<span *ngIf="(editForm.controls['FromDate']?.dirty || editForm.submitted) && editForm.controls['FromDate']?.hasError('daterange')" class="field-validation-error">{{ 'validator.{0} is out of its valid range.' | translate:{'0': 'translate|hollen.operation.accommodationterminsertupdateviewmodel.accommodated from'} }}</span>
</span>
                            </div>
                            <label class="control-label col-md-2" for="ToDate">{{ 'hollen.operation.accommodationterminsertupdateviewmodel.accommodated to' | translate }}</label>
                            <div class="col-md-4">
                                <div class="">
                                    <af-datepicker [(ngModel)]="model.toDate" name="toDate"></af-datepicker>
                                </div>
                                <span><span *ngIf="(editForm.controls['ToDate']?.dirty || editForm.submitted) && editForm.controls['ToDate']?.hasError('date')" class="field-validation-error">{{ 'validator.{0} is not valid date format.' | translate:{'0': 'translate|hollen.operation.accommodationterminsertupdateviewmodel.accommodated to'} }}</span>
<span *ngIf="(editForm.controls['ToDate']?.dirty || editForm.submitted) && editForm.controls['ToDate']?.hasError('daterange')" class="field-validation-error">{{ 'validator.{0} is out of its valid range.' | translate:{'0': 'translate|hollen.operation.accommodationterminsertupdateviewmodel.accommodated to'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-actions">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="offset-md-1 col-md-11">
                                    <button *afHasPerm="{obj:objectKey, perm: 'Update'}" class="btn btn-update btn-primary" type="submit">{{ 'btn.save' | translate }}</button>
                                    <button class="btn btn-update btn-warning" (click)="cancel()">{{ 'btn.cancel' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
