<div class="page-header navbar fixed-top {{ environment }}">
    <div class="page-logo">
        <a routerLink="/">
            <img src="/images/logo-intranet.png" alt="logo" class="logo-default" />
        </a>
    </div>
    <div class="page-header-bar hidden-xs">
        <af-breadcrumb home="/"></af-breadcrumb>
    </div>
    <af-top-menu></af-top-menu>
</div>
