
<div class="form" *ngIf="project">
    <div class="form-horizontal">
        <h3 class="form-section project-task">
            {{ 'project.panel.project' | translate }} - {{ project.projectNumber }}
            <a [routerLink]="['/operation/project', project.projectId]"><i class="fa fa-external-link"></i></a>
        </h3>
        <div class="form-body project-task">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-2" for="Title">{{ 'hollen.operation.projectinsertupdateviewmodel.title' | translate }}</label>
                        <div class="col-md-10">
                            <p class="form-control-plaintext">{{ project.title }}</p>
                            <af-help helpid="Hollen.Operation.ProjectInsertUpdateViewModel.Title"></af-help>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-2" for="CompanyId">{{ 'hollen.operation.projectinsertupdateviewmodel.company' | translate }}</label>

                        <div class="col-md-10">
                            <div class="input-group">
                                <p class="form-control-plaintext" style="flex-grow: 1">{{ project.companyTitleWithAddressCity }}</p>
                                <ng-container *ngIf="project">
                                    <a class="btn btn-info" *afHasPerm="{obj:'crm.Company', perm:'Read'}"
                                       [routerLink]="['/crm/company', project.companyId]">
                                        <i class="fa fa-external-link"></i>
                                    </a>
                                </ng-container>
                            </div>
                            <af-help helpid="Hollen.Operation.ProjectInsertUpdateViewModel.CompanyId"></af-help>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label col-md-4" for="StartDate">{{ 'hollen.operation.projectinsertupdateviewmodel.start date' | translate }}</label>
                                <div class="col-md-8 col-fixed-md">
                                    <p class="form-control-plaintext">{{ project.startDate | afDate: 'L' }}</p>
                                    <af-help helpid="Hollen.Operation.ProjectInsertUpdateViewModel.StartDate"></af-help>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6" *ngIf="project.endDate">
                            <div class="form-group">
                                <label class="control-label col-md-4" for="EndDate">{{ 'hollen.operation.projectinsertupdateviewmodel.end date' | translate }}</label>
                                <div class="col-md-8 col-fixed-md">
                                    <p class="form-control-plaintext">{{ project.endDate | afDate: 'L' }}</p>
                                    <af-help helpid="Hollen.Operation.ProjectInsertUpdateViewModel.EndDate"></af-help>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-2" for="IsOverHead">{{ 'hollen.operation.projectinsertupdateviewmodel.is overhead' | translate }}</label>
                        <div class="col-md-10 col-fixed-check">
                            <af-checkbox [disabled]="true" [(ngModel)]="project.isOverHead"></af-checkbox>
                            <af-help helpid="Hollen.Operation.ProjectInsertUpdateViewModel.IsOverHead"></af-help>
                        </div>
                    </div>
                </div>
            </div>
            <op-project-company-info [selectedCompany]="selectedCompany"></op-project-company-info>
        </div>
    </div>
</div>
