import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";

import { LoadingBarService, LoadingBarEvent, LoadingBarEventType } from "./loading-bar.service";
import { isPresent } from "../../../core/utils";

@Component({
    selector: "af-loading-bar",
    styleUrls: ["./loading-bar.component.scss"],
    template: `
        <div class="loading-bar">
            <div class="loading-bar-progress"
                [style.width]="progress"
                [style.backgroundColor]="color"
                [style.color]="color"
                [style.height]="height"
                [style.opacity]="show ? '1' : '0'"></div>
        </div>
        <div class="loading-bar-spinner" [style.opacity]="show ? '1' : '0'">
            <div class="spinner-icon"></div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingBarComponent implements OnInit {
    @Input() set progress(value: string) {
        if (isPresent(value)) {
            this._progress = value + "%";
        }
    }
    get progress(): string {
        return this._progress;
    }

    @Input() color = "#29d";
    @Input() height = "2px";
    @Input() show = true;

    private _progress = "0%";

    constructor(private service: LoadingBarService, private changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit(): any {
        this.service.observable.subscribe((event: LoadingBarEvent) => {
            if (event.type === LoadingBarEventType.PROGRESS) {
                this.progress = event.value;
            } else if (event.type === LoadingBarEventType.COLOR) {
                this.color = event.value;
            } else if (event.type === LoadingBarEventType.HEIGHT) {
                this.height = event.value;
            } else if (event.type === LoadingBarEventType.VISIBLE) {
                this.show = event.value;
            }
            this.changeDetectorRef.markForCheck();
        });
    }
}
