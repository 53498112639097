import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { take, switchMap, tap } from "rxjs/operators";

export enum ReportType {
    None = 0,
    Leistungnachweis = -2,
    Warenfilter = -3,
    WarenfilterSkoda = -4,
    OneToOne = -5,
    XToOne = -6,
    OneToX = -7,
    XToX = -8,
    ProjectTaskOverview = -9,
    MonthReport = -10,
    Unknown = -11, //todo
    Unknown2 = -12 //todo
}

export class ReportProjectTaskRequest {
    format: string;
    from: Date;
    languageId: string;
    projectTaskId: number;
    to: Date;
    branchId: number;
}

@Injectable()
export class ReportProjectTaskService
    extends HttpService<any, any, any>
{
    objectKey = "op.project";
    format = "xlsx";

    constructor(http: HttpClient) {
        super(http, "/api/operation/reportprojecttask/");
    }

    getReports(request: ReportProjectTaskRequest) {
        return this.http.get<app.sys.ReportViewModel[]>(this.url, { params: this.getParameters(request) });
    }

    getReportParameters(reportId: number) {
        return this.http.get(this.url + reportId + "/parameters").pipe(tap((data: any) => {
            if (data) {
                for (let item of data) {
                    if (item.typeName === "Boolean") {
                        item.value = item.value === "true";
                    }
                }
            }
        }));
    }

    getReportUrl(report: app.sys.ReportDetailViewModel, request: ReportProjectTaskRequest, parameters?: any[]) {
        let requestParams = this.getParameters(request);

        let params = [];

        if (parameters) {
            for (let item of parameters) {
                params[item.field] = item.value;
            }

            let reportParams = this.getParameters(params);

            for (let key of reportParams.keys()) {
                requestParams = requestParams.append(key, reportParams.get(key));
            }
        }        

        let format = report.format ? report.format : this.format;
        let reportLink = this.url + report.reportId + "/export?format=" + format + "&" + requestParams.toString();
        return reportLink;
    }

    getFilter(projectTaskId: number) {
        let params = new HttpParams();
        if (projectTaskId) {
            params = params.append("projectTaskId", projectTaskId.toString());
        }
        return this.http.get<app.operation.ReportProjectTaskFilterViewModel>(this.url + "filter", { params });
    }

    getLeistungsnachweis(request: app.operation.LeistungsnachweisReportRequest) {
        request.format = this.format;
        return this.url + "leistungsnachweis?" + this.getParameters(request).toString();
    }

    getMonth(request: app.operation.MonthReportRequest) {
        request.format = this.format;
        return this.url + "month?" + this.getParameters(request).toString();
    }

    getOneToX(request: app.operation.XReportRequest) {
        request.format = this.format;
        return this.url + "onetox?" + this.getParameters(request).toString();
    }

    getOneToOne(request: app.operation.XReportRequest) {
        request.format = this.format;
        return this.url + "onetoone?" + this.getParameters(request).toString();
    }

    getXToOne(request: app.operation.XReportRequest) {
        request.format = this.format;
        return this.url + "xtoone?" + this.getParameters(request).toString();
    }

    getXToX(request: app.operation.XReportRequest) {
        request.format = this.format;
        return this.url + "xtox?" + this.getParameters(request).toString();
    }

    getProjectTaskOverview(request: app.operation.ProjectTaskOverviewRequest) {
        request.format = this.format;
        return this.url + "project-task-overview?" + this.getParameters(request).toString();
    }

    getWarenfilter(request: app.operation.WarenFilterRequest) {
        request.format = this.format;
        return this.url + "warenfilter?" + this.getParameters(request).toString();
    }

    getWarenfilterSkoda(request: ReportProjectTaskRequest) {
        request.format = this.format;
        return this.url + "warenfilter-skoda?" + this.getParameters(request).toString();
    }

    isReportAvailable(reportType: ReportType, accountCenters: string[]) {
        let available = [];

        switch (reportType) {
            case ReportType.Leistungnachweis:
                available = ["SP", "SV", "CS", "CT", "CH", "SG", "SK"];
                break;
            case ReportType.MonthReport:
                available = ["CH", "SG", "SK"];
                break;
            case ReportType.OneToOne:
                available = [];
                break;
            case ReportType.OneToX:
                available = [];
                break;
            case ReportType.ProjectTaskOverview:
                available = ["HA"];
                break;
            case ReportType.Warenfilter:
                available = ["SV"];
                break;
            case ReportType.WarenfilterSkoda:
                available = ["CS", "CT"];
                break;
            case ReportType.XToOne:
                available = [];
                break;
            case ReportType.XToX:
                available = [];
                break;
            default:
                available = [];
                break;
        }

        return this.isAvailable(available, accountCenters);
    }

    private isAvailable(available: string[], accountCenters: string[]) {
        if (available.length === 0) {
            return true;
        }

        for (let accountCenterCode of accountCenters) {
            if (available.indexOf(accountCenterCode) > -1) {
                return true;
            }
        }

        return false;
    }
}
