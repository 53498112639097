import { Routes } from "@angular/router";

import { ReportProjectTaskComponent } from "./report-project-task.component";
import { ReportProjectTaskDetailComponent } from "./report-project-task-detail.component";
import { LeistungsnachweisReportComponent } from "./leistungsnachweis-report.component";
import { MonthReportComponent } from "./month-report.component";
import { OneToOneReportComponent } from "./one-to-one-report.component";
import { OneToXReportComponent } from "./one-to-x-report.component";
import { ProjectTaskOverviewReportComponent } from "./project-task-overview-report.component";
import { WarenfilterReportComponent } from "./warenfilter-report.component";
import { WarenfilterSkodaReportComponent } from "./warenfilter-skoda-report.component";
import { XToOneReportComponent } from "./x-to-one-report.component";
import { XToXReportComponent } from "./x-to-x-report.component";
import { ReportEmployeeComponent } from "./report-employee.component";
import { ReportEmployeeDetailComponent } from "./report-employee-detail.component";

export const reportRoutes: Routes = [
    {
        path: "report",
        component: ReportProjectTaskComponent
    }
];

export const reportRoutedComponents = [
    ReportProjectTaskComponent,
    ReportProjectTaskDetailComponent,
    ReportEmployeeDetailComponent,
    LeistungsnachweisReportComponent,
    MonthReportComponent,
    OneToOneReportComponent,
    OneToXReportComponent,
    ProjectTaskOverviewReportComponent,
    WarenfilterReportComponent,
    WarenfilterSkodaReportComponent,
    XToOneReportComponent,
    XToXReportComponent,
    ReportEmployeeComponent
];
