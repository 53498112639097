import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class EmployeeRelationshipService
    extends HttpService<app.operation.EmployeeRelationshipListViewModel, app.operation.EmployeeRelationshipFormViewModel, app.operation.EmployeeRelationshipInsertUpdateViewModel>
{
    objectKey = "op.EmployeeRelationship";

    constructor(http: HttpClient) {
        super(http, "/api/operation/employeerelationship/");
    }
}
