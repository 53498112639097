import { ErrorHandler, Injectable } from "@angular/core";

import { LogService } from "./log.service";
//import { ApplicationInsightsService } from './application-insights.service';

@Injectable()
export class LoggingErrorHandler extends ErrorHandler {
    triggeredErrors = [];
    maxErrors = 2;

    constructor(private logService: LogService) //, private applicationInsightsService: ApplicationInsightsService) {
    {
        super();
    }

    public handleError(error: any): void {
        if (!error || (error.status && error.status >= 400 && error.status < 500)) {
            return;
        }
        if (this.triggeredErrors.length >= this.maxErrors) {
            return;
        }
        this.triggeredErrors.push(error);

        try {
            let originalError = this.findOriginalError(error);
            //this.logService.logError(originalError);
            //this.applicationInsightsService.trackException(originalError);
        }
        catch (loggingError)
        {
            console.group("AlejFrame - ErrorHandler");
            console.warn("Error when trying to log error to", this.logService);
            console.error(loggingError);
            console.groupEnd();
        }

        super.handleError(error);

        if (this.triggeredErrors.length === this.maxErrors) {
            console.error("!!!!!!!!!!!!! MAX ERRORS REACHED !!!!!!!!!!!!!");
        }
    }

    findOriginalError(error: any): any {
        while (error && error.originalError) {
            error = error.originalError;
        }
        return (error);
    }
}
