import { finalize, filter } from "rxjs/operators";
import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef, Inject, LOCALE_ID } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

import { PermissionService } from "../../core/services/permission.service";
import { AppService } from "../../app.service";
import { State } from "../../store/reducers";
import * as appAction from "../../store/actions/app";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "af-help",
    template: `
        <a *ngIf="canInsert || exists"
            [ngbPopover]="helpContent"
            triggers="manual"
            #popover="ngbPopover"
            (click)="onClick()"
            container="body"
            popoverClass="help-popover"
            [popoverTitle]="title"
            [placement]="position"
            [ngClass]="{'text-danger': !exists, 'text-info': exists}"
        >
            <i class="fa fa-question-circle"></i>
        </a>
        <ng-template #helpContent>
            <button type="button" (click)="popover.close()" class="popover-close">&times;</button>
            <af-spinner [loading]="isLoading"></af-spinner>
            <div [innerHtml]="content"></div>
            <div class="popover-link" *ngIf="canInsert">
                <a *ngIf="exists" routerLink="/sys/help/edit" [queryParams]="{helpId: helpid}">Link</a>
                <a *ngIf="!exists" routerLink="/sys/help/create" [queryParams]="{helpId: helpid}">Link</a>
            </div>
        </ng-template>
    `
})

export class HelpComponent implements OnInit, OnDestroy {
    @ViewChild("popover") popover: NgbPopover;
    @Input() helpid: string;
    exists: boolean;
    title: string;
    position = "top";
    width: number;
    canInsert: boolean;
    isLoading: boolean;
    content: SafeHtml;
    sub: Subscription;

    constructor(
        private http: HttpClient,
        private store: Store<State>,
        private permissionService: PermissionService,
        private changeDetectorRef: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        @Inject(LOCALE_ID) private locale: string
    ) { }

    ngOnInit() {
        this.canInsert = this.permissionService.hasPermission("sy.help", "Insert");

        this.sub = this.store
            .select(x => x.app.homeViewModel).pipe(
                filter(loaded => !!loaded))
            .subscribe(res => {
                let help = res.help.filter(x => x.helpId === this.helpid);
                if (help.length > 0) {
                    this.exists = true;
                    this.title = help[0].title;
                    this.position = help[0].position.toLowerCase();
                    this.width = help[0].width;
                }
                this.changeDetectorRef.markForCheck();
            });
    }

    onClick() {
        this.popover.toggle();

        if (this.width && document.getElementById(this.popover["_ngbPopoverWindowId"])) {
            document.getElementById(this.popover["_ngbPopoverWindowId"]).style.width = this.width.toString() + "px";
        }

        if (!this.content && this.exists) {
            this.isLoading = true;
            let params = new HttpParams();
            params = params.set("helpid", this.helpid);
            params = params.set("languageid", this.locale);
            this.http
                .get("/api/help", { params, responseType: "text" }).pipe(
                    finalize(() => (this.isLoading = false)))
                .subscribe(res => {
                    this.content = this.sanitizer.bypassSecurityTrustHtml(res);
                });
        }
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
}
