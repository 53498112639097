import { OnInit, OnDestroy, ViewChildren, QueryList, HostListener, Directive } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";

import { BaseComponent } from "./base.component";
import { isEmptyObject, isPresent, isBlank, convertDateStringsToDates } from "../utils";

@Directive()
export abstract class FormComponent extends BaseComponent implements OnInit, OnDestroy {
    stateKey: string;
    isLoading = false;
    _hasPreviousState = false;
    @ViewChildren(NgForm) protected forms: QueryList<NgForm>;

    get hasPreviousState() {
        return this._hasPreviousState;
    }

    constructor(protected router: Router, protected modelName?: string) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.stateKey = "state_" + this.router.url;
        if (this.stateKey.includes('?')) {
            this.stateKey = this.stateKey.substring(0, this.stateKey.indexOf('?'));
        }
        this.loadState(this.stateKey, this.modelName);
    }

    canDeactivate() {
        return true;
    }

    loadState(key: string, modelName: string) {
        if (isBlank(modelName)) {
            return;
        }

        if (isPresent(localStorage)) {
            let model = localStorage.getItem(key);
            if (isPresent(model)) {
                let parsedModel = JSON.parse(model);
                if (isPresent(parsedModel) && !isEmptyObject(parsedModel)) {
                    convertDateStringsToDates(parsedModel);
                    this[modelName] = parsedModel;
                    this._hasPreviousState = true;
                }
            }
        }
    }

    @HostListener("window:keydown", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if (event.ctrlKey && charCode === "s") {
            this.forms.forEach(x => x.onSubmit(event));
            event.preventDefault();
        }
    }

    ngOnDestroy() {
        if (isBlank(this.modelName)) {
            return;
        }

        let model = this[this.modelName];
        if (isPresent(model) && !isEmptyObject(model)) {
            this.onSave();
            localStorage.setItem(this.stateKey, JSON.stringify(model));
        }
    }

    onSave() {

    }

    clearState(value = null) {
        if (isBlank(this.modelName)) {
            return;
        }

        if (isPresent(localStorage)) {
            this[this.modelName] = value;
            localStorage.removeItem(this.stateKey);
        }
    }
}
