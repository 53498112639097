import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { Observable, of } from "rxjs";
import { Router } from "@angular/router";

import { EmployeeTripService } from "./employee-trip.service";
import { EmployeeService } from "../employee/employee.service";
import { ListQueryParams } from "../../models/list-query-params.model";
import { PermissionService, TableBaseComponent } from "../../core";
import { filterAndDebounce } from "../../core/utils";
import { TableComponent } from "../../shared/components/table/table.component";
import { AppService } from "../../app.service";
import { finalize, switchMap } from "rxjs/operators";
import { Immutable } from '../../core/immutable';

class QueryParams extends ListQueryParams {

    countryCode: string = null;
    employeeId: number = null;
    employeeName: string = null;
    destinationName: string = null;
    tripDestination: string = null;
    tripDestinationId: number;
    tripDestinationTitle: string = null;
    departureDate: Date = null;
    returnDate: Date = null;
    plannedReturnDate: Date = null;
    note: string = null;
    year: number = null;
    month: number = null;
}

@Component({
    selector: "op-employee-trip-list",
    templateUrl: "./employee-trip-list.component.html"
})
export class EmployeeTripListComponent extends TableBaseComponent {
    @Input() employeeId: number;
    @Input() hideTitle = false;
    filter: app.operation.EmployeeTripFilterViewModel;
    filterEmployee: app.operation.EmployeeTripFilterViewModel;
    queryParams = new QueryParams();
    //currentUser: app.UserViewModel;
    objectKey: string;
    model: PagedList<app.operation.EmployeeTripListViewModel>;
    selectedEmployee: app.operation.EmployeeSelectListViewModel;
    selectedCountryCode: app.operation.EmployeeTripListViewModel;
    tripDestinations: app.operation.TripDestinationListViewModel[];

    constructor(router: Router,
        private employeeTripService: EmployeeTripService,
        private employeeService: EmployeeService,
        private appService: AppService
    ) {
        super(router);
        this.queryParams.sortBy = this.sortBy = "employeeTripId desc";
        this.objectKey = employeeTripService.objectKey;
        //this.currentUser = appService.homeViewModel.user;
        this.onReset();
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.employeeId) {
            this.queryParams.employeeId = this.employeeId;
        }

        if (this.hasPreviousState) {
            if (this.queryParams.countryCode) {
                this.selectedCountryCode = <app.operation.EmployeeTripListViewModel>{
                    countryCode: this.queryParams.countryCode
                };
            }
            if (this.queryParams.employeeId) {
                this.selectedEmployee = <app.operation.EmployeeSelectListViewModel>{
                    employeeId: this.queryParams.employeeId,
                    employeeName: this.queryParams.employeeName
                };
            }
        }

        this.employeeTripService.getFilter()
            .pipe(finalize(() => this.loadData()))
            .subscribe(res => {
                if (!this.employeeId) {
                    this.filter = res;
                }
            });
    }

    loadData() {
        if (this.selectedEmployee) {
            this.queryParams.employeeId = this.selectedEmployee.employeeId;
            this.queryParams.employeeName = this.selectedEmployee.employeeName;
        }
        else {
            this.queryParams.employeeId = null;
            this.queryParams.employeeName = null;
        }

        this.isLoading = true;
        if (this.employeeId) {
            this.queryParams.year = null;
            this.queryParams.month = null;
            this.queryParams.employeeId = this.employeeId;
        }
        this.employeeTripService.query(this.queryParams)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                let items = [];
                if (this.filter != null && this.filter.countries != null) {
                    res.data.forEach(c => items.push({ ...c, countryTitle: this.filter.countries.find(b => b.key == c.countryCode).value }));
                    this.model = { ...res, data: items };
                } else {
                    this.employeeTripService.getFilter().subscribe(result => {
                        this.filterEmployee = result;
                        res.data.forEach(c => items.push({ ...c, countryTitle: this.filterEmployee.countries.find(b => b.key == c.countryCode).value }));
                        this.model = { ...res, data: items };
                    });
                }
                this.saveState(this.queryParams);
            });
    }

    onReset() {
        this.onClear();
        this.queryParams.year = new Date().getFullYear();
        //this.queryParams.month = new Date().getMonth() + 1;
    }

    onClear() {
        this.selectedEmployee = null;
        this.selectedCountryCode = null;
        this.queryParams.year = null;
        this.queryParams.month = null;
        this.queryParams.page = 1;
        this.queryParams.pageSize = 20;
    }

    employeeAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(searchTerm => searchTerm.length < 2
                    ? of([])
                    : this.employeeService.getListByRequest(<app.operation.EmployeeSelectListRequest>{
                        page: 1,
                        pageSize: 20,
                        searchTerm,
                        onlyB2b: true,
                        isActive: true
                    })));
    }

    employeeFormatter = (employee: app.operation.EmployeeSelectListViewModel) => employee.employeeName;

}
