import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

import { RequestLogService } from "../../../core";

@Component({
    selector: "af-request-log",
    templateUrl: "./request-log.component.html"
})
export class RequestLogComponent implements OnChanges {
    request: any;
    showHeaders = false;
    @Input() id: any;

    constructor(private requestLogService: RequestLogService) {

    }

    getRequest(id: number) {
        this.requestLogService.getById(id).subscribe(res => {
            this.request = res;
        });
    }

    getJson(actionArgs: string) {
        let value = JSON.parse(actionArgs);
        return value;
    }

    ngOnChanges(changes: SimpleChanges) {
        let change = changes["id"];
        if (change.currentValue) {
            this.showHeaders = false;
            this.getRequest(change.currentValue);
        }
    }
}
