import { Component, Input, ChangeDetectionStrategy } from "@angular/core";

import { ReportProjectTaskService, ReportProjectTaskRequest } from "./report-project-task.service";

@Component({
    selector: "op-x-to-x-report",
    templateUrl: "./x-to-x-report.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class XToXReportComponent {
    @Input() params: ReportProjectTaskRequest;

    request = <app.operation.XReportRequest>{
        groupByDeliveryNote: true
    };

    constructor(private reportService: ReportProjectTaskService) { }

    getUrl() {
        let requestParams = Object.assign({}, this.params, this.request);
        return this.reportService.getXToX(requestParams);
    }
}
