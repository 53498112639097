import { Component, Input, ChangeDetectionStrategy } from "@angular/core";

import { ReportProjectTaskService, ReportProjectTaskRequest } from "./report-project-task.service";

@Component({
    selector: "op-warenfilter-skoda-report",
    templateUrl: "./warenfilter-skoda-report.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarenfilterSkodaReportComponent {
    @Input() params: ReportProjectTaskRequest;

    constructor(private reportService: ReportProjectTaskService) { }

    getUrl() {
        return this.reportService.getWarenfilterSkoda(this.params);
    }
}
