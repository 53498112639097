import { Attribute, Directive } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

@Directive({
    selector: '[maxDecimals][formControlName],[maxDecimals][formControl],[maxDecimals][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MaxDecimalsDirective, multi: true }]
})
export class MaxDecimalsDirective implements Validator {

    validator: ValidatorFn;
    static decimals: number;

    constructor(@Attribute("maxDecimals") public decimals: number) {
        this.validator = maxDecimalsValidator(decimals);
        MaxDecimalsDirective.decimals = decimals;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.validator(control);
    }
}

export function maxDecimalsValidator(decimals: number): ValidatorFn {
    return (control: AbstractControl) => {

        if (!control.value) return;

        let d = +decimals;
        let p = Math.pow(10, d)

        if (control.value - Math.round(control.value * p) / p != 0) {
            return {
                maxDecimals: true
            };
        }

        return null;
    };
}
