import { Injectable } from "@angular/core";
import { HttpService } from "../../../core/services/http.service";
import { FileUploadOptions } from "../file-upload/file-upload.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { of } from "rxjs";

@Injectable()
export class SharePointFileService
    extends HttpService<app.sharepoint.SharePointFileViewModel, app.sharepoint.SharePointFileFormViewModel, app.sharepoint.SharePointFileViewModel>
{
    objectKey = "org.islandFile";

    constructor(http: HttpClient) {
        super(http, "/api/sharepoint/");
    }

    getUploadOptions(request: app.sharepoint.SharePointFolderInfoRequest): FileUploadOptions {
        let options = new FileUploadOptions();

        if (request.employeeId) {
            options.url = this.url + "employee/" + request.employeeId.toString();
            options.data.invoiceNumber = request.invoiceNumber;
        } else if (request.workPlaceId && !request.islandCode) {
            options.url = this.url + "workPlace/" + request.workPlaceId.toString();
        } else if (request.islandCode) {
            options.url = this.url + "island/" + request.islandCode;
        } else if (request.amadeusId) {
            options.url = this.url + "amadeus/" + request.amadeusId;
        } else if (request.courseTermId) {
            options.url = this.url + "course/" + request.courseTermId;
        } else {
            return null;
        }

        if (request.path) {
            options.data.path = request.path;
        }

        return options;
    }

    delete(item: app.sharepoint.SharePointFileViewModel) {
        let params = new HttpParams();
        params = params.set("listCode", item.listCode);
        let url = this.url + item.uniqueId.toString();

        return this.http.delete(url, {
            params
        });
    }

    getSharePointFolder(request: app.sharepoint.SharePointFolderInfoRequest) {
        let params = new HttpParams();
        params = params.set("showToast", "false");

        if (request.path) {
            params = params.set("path", request.path);
        }

        if (request.employeeId) {
            return this.http.get<app.sharepoint.SharePointResponse>(this.url + "employee/" + request.employeeId.toString(), { params });
        }

        if (request.workPlaceId && !request.islandCode) {
            return this.http.get<app.sharepoint.SharePointResponse>(this.url + "workPlace/" + request.workPlaceId.toString(), { params });
        }

        if (request.islandCode) {
            return this.http.get<app.sharepoint.SharePointResponse>(this.url + "island/" + request.islandCode, { params });
        }

        if (request.amadeusId) {
            return this.http.get<app.sharepoint.SharePointResponse>(this.url + "amadeus/" + request.amadeusId, { params });
        }

        return of(null);
    }
}
