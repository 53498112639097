import { Action } from "@ngrx/store";
import { type } from "../util";

export const ActionTypes = {
    OPEN_SIDENAV: type("[Layout] Open Sidenav"),
    CLOSE_SIDENAV: type("[Layout] Close Sidenav"),
    OPEN_MOBILE_MENU: type("[Layout] Open Mobile Menu"),
    CLOSE_MOBILE_MENU: type("[Layout] Close Mobile Menu")
};

export class OpenSidenavAction implements Action {
    type = ActionTypes.OPEN_SIDENAV;
}

export class CloseSidenavAction implements Action {
    type = ActionTypes.CLOSE_SIDENAV;
}

export class OpenMobileMenuAction implements Action {
    type = ActionTypes.OPEN_MOBILE_MENU;
}

export class CloseMobileMenuAction implements Action {
    type = ActionTypes.CLOSE_MOBILE_MENU;
}

export type Actions
    = OpenSidenavAction
    | CloseSidenavAction
    | OpenMobileMenuAction
    | CloseMobileMenuAction;