import { Component, Directive, Input, ContentChild, TemplateRef, AfterContentInit, ContentChildren, QueryList } from "@angular/core";

@Directive({
    selector: "[afTemplate]"
})
export class TableExpandedRowTemplateDirective {

    @Input() type: string;

    constructor(public template: TemplateRef<any>) { }
}

@Component({
    selector: "af-table-expanded-row",
    template: ``
})
export class TableExpandedRowComponent implements AfterContentInit {
    @ContentChildren(TableExpandedRowTemplateDirective, { descendants: false }) templates: QueryList<TableExpandedRowTemplateDirective>;
    public template: TemplateRef<any>;

    ngAfterContentInit(): void {
        this.template = this.templates.first ? this.templates.first.template : null;
    }
}