import { map } from "rxjs/operators";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

import { deepFreeze } from "../utils";

export class HttpService<TList, TForm, TInsertUpdate> {
    constructor(protected http: HttpClient, protected url: string) { }

    get(id: any, appendLists: boolean, useQueryString?: boolean): Observable<TForm> {
        let params = new HttpParams();
        if (appendLists) {
            params = params.set("appendLists", appendLists.toString());
        }

        let url = this.url;
        if (useQueryString) {
            params = params.set("id", id);
        } else {
            url += id;
        }

        return this.http.get<TForm>(url, {
            params
        });
    }

    getNew(parameters?: any) {
        return this.http.get<TForm>(this.url + "new", {
            params: this.getParameters(parameters)
        });
    }

    query(parameters?: any): Observable<PagedList<TList>> {
        return this.http
            .get<PagedList<TList>>(this.url, {
                params: this.getParameters(parameters)
            }).pipe(
                map<any, PagedList<TList>>(res => {
                    return deepFreeze(res);
                }));
    }

    insert(item: TInsertUpdate) {
        return this.http.post<TInsertUpdate>(this.url, item);
    }

    update(item: TInsertUpdate, id: any, useQueryString?: boolean, urlSuffix?: string, showToast?: boolean) {
        let params = new HttpParams();

        let url = this.url;
        if (useQueryString) {
            params = params.set("id", id);
        } else {
            url += id;
        }

        if (urlSuffix) {
            url += urlSuffix;
        }

        if (showToast === false) {
            params = params.set("showToast", "false");
        }

        return this.http.put(url, item, {
            params
        });
    }

    delete(item: TInsertUpdate, id: any, useQueryString?: boolean, parameters?: any) {
        let params = this.getParameters(parameters);

        let url = this.url;
        if (useQueryString) {
            params = params.set("id", id);
        } else {
            url += id;
        }

        return this.http.delete(url, { params });
    }

    setStatus(id: number, status: number) {
        return this.http.put<TInsertUpdate>(this.url + id + "/status/" + status, null);
    }

    protected getParameters(parameters?: any): HttpParams {
        let params = new HttpParams();   

        for (let property in parameters) {     
            if (parameters.hasOwnProperty(property) && (parameters[property] !== null || typeof parameters[property] !== "undefined")) {
                if (parameters[property] instanceof Date) {
                    let date = parameters[property];
                    let unix = +moment.utc([date.getFullYear(), date.getMonth(), date.getDate(),date.getHours(),date.getMinutes()]);
                    params = params.set(property, unix.toString());
                } else if (parameters[property] instanceof Array) {
                    parameters[property].forEach((item) => {
                        params = params.append(property, item);
                    });
                } else {
                    if (parameters[property] !== null && typeof parameters[property] !== "undefined") {
                        params = params.set(property, parameters[property]);
                    }
                }
            }
        }

        return params;
    }

    getUnixDate(date: Date) {
        return +moment.utc([date.getFullYear(), date.getMonth(), date.getDate()]);
    }
}
