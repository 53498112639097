import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { deepFreeze, getUnixDate } from "../../core/utils";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class EmployeeWageRedistributionService
    extends HttpService<app.operation.EmployeeWageRedistributionListViewModel, dotnet.KeyValuePair[], dotnet.KeyValuePair[]>
{
    objectKey = "op.EmployeeWageRedistribution";

    constructor(http: HttpClient) {
        super(http, "/api/operation/employeewageredistribution/");
    }

    getFilter(): Observable<app.operation.EmployeeWageRedistributionFilterViewModel> {
        return this.http.get<app.operation.EmployeeWageRedistributionFilterViewModel>(this.url + "filter");
    }

    //getDetailedPagedList(parameters?: any): Observable<PagedList<app.operation.NormDailyOverviewDetailListViewModel>> {
    //    return this.http.get<PagedList<app.operation.NormDailyOverviewDetailListViewModel>>(this.url + "detailed", {
    //        params: this.getParameters(parameters)
    //    });
    //}
}
