import { CanDeactivate } from "@angular/router";
import { Injectable } from "@angular/core";
import { FormComponent } from "../components/form.component";
import { DialogService } from "./dialog.service";
import { isBlank } from "../utils";

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<FormComponent> {
    constructor(private dialogService: DialogService) {
    }

    canDeactivate(component: FormComponent): Promise<boolean> | boolean {
        if (isBlank(component) || isBlank(component.canDeactivate)) {
            return true;
        }

        let canDeactivate = component.canDeactivate();

        if (!canDeactivate) {
            return this.dialogService.confirm();
        }

        return true;
    }
}
