import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { EmployeeRelationshipService } from "./employee-relationship.service";
import { EmployeeService } from "../../operation/employee/employee.service";
import { ProjectTaskService } from "../../operation/project-task/project-task.service";
import { FormComponent } from "../../core";
import { finalize, switchMap } from "rxjs/operators";

@Component({
    selector: "op-employee-relationship-edit",
    templateUrl: "./employee-relationship-edit.component.html"
})
export class EmployeeRelationshipEditComponent extends FormComponent implements OnChanges {
    @Input() employeeRelationshipId: number;
    @Output() updated = new EventEmitter;
    isLoading: boolean;

    @ViewChild(NgForm, { static: true }) editEmployeeRelationshipForm: NgForm;
    employeeRelationship = <app.operation.EmployeeRelationshipInsertUpdateViewModel>{};
    objectKey: string;
    currentUser: app.UserViewModel;

    agencies: app.codebook.CodebookSelectViewModel[];
    majorProviders: app.codebook.CodebookSelectViewModel[];
    internalMajorProviders: app.codebook.CodebookSelectViewModel[];
    employeePositionTypes: app.codebook.CodebookSelectViewModel[];
    employeeContractTypes: app.codebook.CodebookSelectViewModel[];
    hollenCompanies: app.organization.HollenCompanySelectListViewModel[];
    users: af.usersystem.UserSelectListViewModel[];

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private employeeRelationshipService: EmployeeRelationshipService,
        private employeeService: EmployeeService,
        private projectTaskService: ProjectTaskService
    )
    {
        super(router, "employeeRelationship");

        this.objectKey = employeeRelationshipService.objectKey;
    }

    contractTypes = {
        'contractor': 1,
        'student': 2,
        'retired': 3,
        'parttime': 4,
        'dovp': 5,
        'distancestudent': 6,
        'retiredcontractor': 7,
        'tpp': 8,
        'agency': 9,
        'assignedemployee': 10,
        'majorprovider': 11,
        'hqs': 12
    };

    ngOnInit() {
        super.ngOnInit();
        this.loadData();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.loadData();
    }

    loadData() {
        let employeeRelationshipId = 0;

        if (this.employeeRelationshipId) {
            employeeRelationshipId = this.employeeRelationshipId;
        }
        else {
            employeeRelationshipId = +(this.route.snapshot.params["employeeRelationshipId"] || this.route.parent.snapshot.params["employeeRelationshipId"]);
        }

        this.isLoading = true;

        this.employeeRelationshipService.get(employeeRelationshipId, true).subscribe((result) => {
            this.employeeRelationship = result.model;
            this.agencies = result.agencies;
            this.majorProviders = result.majorProviders;
            this.internalMajorProviders = result.internalMajorProviders;
            this.employeePositionTypes = result.employeePositionTypes;
            this.employeeContractTypes = result.employeeContractTypes;
            this.hollenCompanies = result.hollenCompanies;
            this.users = result.users;

            this.isLoading = false;
        });
    }

    update() {
        if (this.editEmployeeRelationshipForm.invalid) {
            return;
        }

        this.isLoading = true;
        this.employeeRelationshipService.update(this.employeeRelationship, this.employeeRelationship.employeeRelationshipId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(() => {
                this.editEmployeeRelationshipForm.control.markAsPristine();
                this.updated.emit();
            });

    }

    delete() {
        this.isLoading = true;
        this.employeeRelationshipService.delete(this.employeeRelationship, this.employeeRelationship.employeeRelationshipId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((result) => {
                this.editEmployeeRelationshipForm.reset();

                if (!(this.employeeRelationshipId)) {
                    this.router.navigate(["/operation/employeeRelationship"]);
                }

                else
                {
                    this.router.navigate(["/operation/employee/" + this.employeeRelationship.employeeId + "/employeeRelationship/create"]);
                }

            });
    }

    setEmployeePositionItem($event, $index) {
        this.employeeRelationship.employeePositionTypeIds[$index] = $event;
    }

    addEmployeePositionItem() {
        this.employeeRelationship.employeePositionTypeIds.push(0);
    }


    deleteEmployeePositionItem($index) {
        this.employeeRelationship.employeePositionTypeIds.splice($index, 1);
    }

    onContractTyperChange($event) {
        this.employeeRelationship.employeeContractTypeId = $event;
        if (this.employeeRelationship.employeeContractTypeId == this.contractTypes.agency || this.employeeRelationship.employeeContractTypeId == this.contractTypes.assignedemployee) {
            this.employeeRelationship.majorProviderId = null;
        } else if
            (this.employeeRelationship.employeeContractTypeId == this.contractTypes.majorprovider) {
            this.employeeRelationship.agencyId = null;
        }
        else {
            this.employeeRelationship.majorProviderId = null;
            this.employeeRelationship.agencyId = null;
        }

        this.setInternalMajorProvider(this.employeeRelationship.employeeContractTypeId, this.employeeRelationship.branchHollenCompanyCode);
    }

    onHollenCompanyCodeChange(hollenCompanyCode) {
        this.employeeRelationship.branchHollenCompanyCode = hollenCompanyCode;       
        this.setInternalMajorProvider(this.employeeRelationship.employeeContractTypeId, this.employeeRelationship.branchHollenCompanyCode);
    }

    setInternalMajorProvider(employeeContractTypeId, hollenCompanyCode) {
        if (employeeContractTypeId != this.contractTypes.hqs)
            return;
        this.employeeRelationship.majorProviderId = this.internalMajorProviders.find(x => x.code == hollenCompanyCode)?.id;
    }
}
