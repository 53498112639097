import {
    Component,
    Input,
    Output,
    ViewChild,
    EventEmitter,
    forwardRef,
    AfterViewInit,
    OnDestroy,
    OnInit
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: "af-codemirror",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CodemirrorComponent),
            multi: true
        }
    ],
    styles: [`
        :host ::ng-deep .CodeMirror {
            height: 500px;
            border: 1px solid #ccc;
        }
    `],
    template: `<textarea #host></textarea>`
})
export class CodemirrorComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() config;

    @Output() change = new EventEmitter();
    editor;
    @ViewChild("host", { static: true }) host;

    _value = "";
    @Output() instance = null;

    /**
     * Constructor
     */
    constructor() { }

    get value(): any { return this._value; };

    // tslint:disable-next-line:adjacent-overload-signatures
    @Input() set value(v) {
        if (v !== this._value) {
            this._value = v;
            this.onChange(v);
        }
    }

    ngOnInit() {
     
    }

    /**
     * On component destroy
     */
    ngOnDestroy() {

    }

    /**
     * On component view init
     */
    ngAfterViewInit() {
        this.config = this.config || {};
        this.codemirrorInit(this.config);
    }

    /**
     * Initialize codemirror
     */
    codemirrorInit(config) {
        this.instance = window["CodeMirror"].fromTextArea(this.host.nativeElement, config);
        this.instance.on("change", (args) => {
            let value = this.instance.getValue();
            if (this._value !== value) {
                this.updateValue(value);
            }
        });
    }

    /**
     * Value update process
     */
    updateValue(value) {
        this.value = value;
        this.onChange(value);
        this.onTouched();
        this.change.emit(value);
    }

    /**
     * Implements ControlValueAccessor
     */
    writeValue(value) {
        this._value = value || "";
        if (this.instance) {
            this.instance.setValue(this._value);
        }
    }

    onChange(_) { }
    onTouched() { }
    registerOnChange(fn) { this.onChange = fn; }
    registerOnTouched(fn) { this.onTouched = fn; }
}
