import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpService } from "../../../core/services/http.service";

@Injectable()
export class B2bAdvanceService
    extends HttpService<app.b2b.AdvanceRequestListViewModel, app.b2b.AdvanceRequestFormViewModel, app.b2b.AdvanceRequestInsertUpdateViewModel>
{
    objectKey = "crm.B2bAdvance";

    constructor(http: HttpClient) {
        super(http, "/api/crm/b2badvance/");
    }

    create(item: app.b2b.AdvanceRequestInsertUpdateViewModel, isCopy: any) {
        let parameters = { isCopy: isCopy };
        return this.http.post<app.b2b.AdvanceRequestInsertUpdateViewModel>(this.url, item, { params: this.getParameters(parameters) });
    }

    approve(requestId: number) {
        return this.http.put(this.url + requestId + "/approve", null);
    }

    unapprove(requestId: number) {
        return this.http.put(this.url + requestId + "/unapprove", null);
    }
}
