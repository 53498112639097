import { B2bAdvanceCreateComponent } from "./b2b-advance-create.component";
import { B2bAdvanceEditComponent } from "./b2b-advance-edit.component";
import { B2bAdvanceListComponent } from "./b2b-advance-list.component";
import { B2bAdvanceComponent } from "./b2b-advance.component";

export const components = [
    B2bAdvanceComponent,
    B2bAdvanceCreateComponent,
    B2bAdvanceEditComponent,
    B2bAdvanceListComponent,
]
