import { Observable } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { HttpParams, HttpUrlEncodingCodec } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { Decimal } from "decimal.js";

export function isPresent(obj: any): boolean {
    return obj !== undefined && obj !== null;
}

export function isBlank(obj: any): boolean {
    return obj === undefined || obj === null;
}

export function isBoolean(obj: any): boolean {
    return typeof obj === "boolean";
}

export function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}

export function isNumber(value: any): value is number {
    return !isNaN(toInteger(value));
}

export function isInteger(value: any): value is number {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

export function isString(obj: any): obj is string {
    return typeof obj === "string";
}

export function isObject(o: any): boolean {
    return o !== null && typeof o === "object";
}

export function isArray(obj: any): boolean {
    return Array.isArray(obj);
}

export function isEmptyObject(obj) {
    return JSON.stringify(obj) === "{}";
}

export function equalArrays(array1, array2) {
    let isSame = (array1.length === array2.length) && array1.every((element, index) => {
        return element === array2[index];
    });
    return isSame;
}

export function deepCopy(oldObj: any) {
    let clone = Object.assign({}, oldObj);
    return clone;
}

export function deepCopyJson(oldObj: any) {
    let clone = JSON.parse(JSON.stringify(oldObj));
    return clone;
}

export function deepFreeze(o) {
    if (environment.production) {
        return o;
    }

    if (isBlank(o)) return;

    Object.freeze(o);

    Object.getOwnPropertyNames(o).forEach((prop) => {
        if (o.hasOwnProperty(prop)
            && o[prop] !== null
            && (typeof o[prop] === "object" || typeof o[prop] === "function")
            && !Object.isFrozen(o[prop])) {
            deepFreeze(o[prop]);
        }
    });

    return o;
}

export function filterAndDebounce(input: Observable<string>) {
    return input.pipe(debounceTime(400));
}
 
export function convertDateStringsToDates(object) {
    // ensure that we're processing an object
    if (!object || typeof object !== "object") {
        return;
    }

    // Regular expressions for different date formats
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,4})?Z?$/;
    const iso8601ExtendedRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,4})?(\+\d{2}:\d{2})?$/;

    for (let key in object) {
        if (!object.hasOwnProperty(key)) {
            continue;
        }
        let value = object[key];

        // check for string properties with a date format
        if (typeof value === "string") {
            if (iso8601Regex.test(value) || iso8601ExtendedRegex.test(value)) {
                let date = new Date(value);
                if (!isNaN(date.getTime())) {
                    object[key] = date;
                }
            }
        } else if (typeof value === "object") {
            convertDateStringsToDates(value); // recurse into object
        }
    }

    //for (let key in object) {
    //    if (!object.hasOwnProperty(key)) {
    //        continue;
    //    }
    //    let value = object[key];

    //    // check for string properties with a date format
    //    if (typeof value === "string"
    //        && (moment(value, "YYYY-MM-DDTHH:mm:ss", true).isValid()
    //            || moment(value, "YYYY-MM-DDTHH:mm:ss.SSSS", true).isValid()
    //        )) {
    //        let date = moment(value, moment.ISO_8601).toDate();
    //        object[key] = date;
    //    } else if (typeof value === "string"
    //        && (moment(value, "YYYY-MM-DDTHH:mm:ssZ", true).isValid()
    //            || moment(value, "YYYY-MM-DDTHH:mm:ss.SSSSZ", true).isValid())) {
    //        let date = moment(value, moment.ISO_8601).toDate();
    //        object[key] = date;
    //    } else if (typeof value === "object") {
    //        convertDateStringsToDates(value); // recurse into object
    //    }
    //}
}

export class CustomQueryEncoderHelper extends HttpUrlEncodingCodec {
     encodeKey(k: string): string {
         k = super.encodeKey(k);
         return k.replace(/\+/gi, "%2B");
     }
     encodeValue(v: string): string {
         v = super.encodeValue(v);
         return v.replace(/\+/gi, "%2B");
     }
 }

export function getParameters(parameters?: any, dateAsUnix = true, quoteString = false): HttpParams {
    let params = new HttpParams();

    for (let property in parameters) {
        if (parameters.hasOwnProperty(property) && (parameters[property] !== null || typeof parameters[property] !== "undefined")) {
            if (parameters[property] instanceof Date) {
                let date = parameters[property];
                if (dateAsUnix) {
                    let unix = +moment.utc([date.getFullYear(), date.getMonth(), date.getDate(),date.getHours(),date.getMinutes()]);
                    params = params.set(property, unix.toString());
                }
                else {
                    params = params.set(property, moment(date).format("YYYY-MM-DDTHH:mm:ss"));
                }
            } else if (parameters[property] instanceof Array) {
                parameters[property].forEach((item) => {
                    if (quoteString && isString(item)) {
                        params = params.append(property, "'" + item + "'");
                    }
                    else {
                        params = params.append(property, item);
                    }
                });
            } else {
                if (parameters[property] !== null && typeof parameters[property] !== "undefined") {
                    if (quoteString && isString(parameters[property])) {
                        params = params.set(property, "'" + parameters[property] + "'");
                    }
                    else {
                        params = params.set(property, parameters[property]);
                    }
                }
            }
        }
    }

    return params;
}

export function getUnixDate(date: Date) {
    return +moment.utc([date.getFullYear(), date.getMonth(), date.getDate()]);
}

export function toFixed(num: number, fractionSize) {
    return +new Decimal(num).toDecimalPlaces(fractionSize);
}

export function groupBy(arr, key) {
    return (arr || []).reduce((acc, x = {}) => ({
        ...acc,
        [x[key]]: [...acc[x[key]] || [], x]
    }), {});
}

export function toCamelCase(key, value) {
    if (value && typeof value === "object") {
        for (let k in value) {
            if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
                value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
                delete value[k];
            }
        }
    }
    return value;
}

export function urlJoin(...args: any[]) {
    function normalize(str) {
        // make sure protocol is followed by two slashes
        str = str.replace(/:\//g, "://");

        // remove consecutive slashes
        str = str.replace(/([^:\s\%\x03\A])\/+/g, "$1/");

        // remove trailing slash before parameters or hash
        str = str.replace(/\/(\?|&|#[^!])/g, "$1");

        // replace ? in parameters with &
        str = str.replace(/(\?.+)\?/g, "$1&");

        return str;
    }

    let input = args;

    if (typeof args[0] === "object") {
        // new syntax with array and options
        input = args[0];
    }

    let joined = [].slice.call(input, 0).join("/");
    return normalize(joined);
}

export function makeRandomId(): string {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 7; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}
