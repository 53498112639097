import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";

import { State } from "../../store/reducers";
import * as appActions from "../../store/actions/app";

@Injectable()
export class VersionService {
  _isNewVersion = false;
  _currentVersion;
  _currentVersionDate;

  constructor(private store: Store<State>) {}

  public get versionDate() {
    return this._currentVersionDate;
  }

  public get isNewVersion() {
    return this._isNewVersion;
  }

  public set isNewVersion(value) {
    this._isNewVersion = value;
    this.store.dispatch(new appActions.NewVersionAvailableAction());
  }

  public setVersion(version) {
    if (!version) {
      return;
    }

    if (this._currentVersion) {
      if (this._currentVersion !== version) {
        this.isNewVersion = true;
      }
    } else {
      this._currentVersion = version;
      this._currentVersionDate = new Date(+version);
    }
  }
}
