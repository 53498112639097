import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "afDate"
})
export class DatePipe implements PipeTransform {

    transform(value: any, pattern = "L", isUtc = false, optionalTime = false): string | null {
        if (!value) {
            return null;
        }

        if (!(value instanceof Date)) {
            let momentValue = moment(value);
            if (momentValue.isValid()) {
                value = momentValue.toDate();
            }
            else {
                return null;
            }
        }

        let date = moment(value);

        if (isUtc) {
            let dateUtc = moment.utc([value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes(), value.getSeconds(), value.getMilliseconds()]);
            date = moment(dateUtc.toDate())
        }

        let dateString = date.format(pattern);

        if (optionalTime) {
            if (date.seconds() !== 0) {
                dateString += date.format(" HH:mm");
            } else if (date.minutes() !== 0 || date.hours() !== 0) {
                dateString += date.format(" HH:mm:ss");
            }
        }
        return dateString;
    }
}
