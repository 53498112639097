import { ElementRef, HostListener, Component, Input, OnChanges, SimpleChange } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "af-title",
    template: "{{ title }}"
})
export class TitleComponent implements OnChanges {
    @Input() title: string;

    constructor(private titleService: Title) {

    }


    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        //console.log(this.title);
        if (this.title) {
            this.titleService.setTitle(this.title);
        }
    }
}