import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpParams, HttpClient } from "@angular/common/http";

@Injectable()
export class AccountCenterService
    extends HttpService<app.organization.AccountCenterListViewModel, app.organization.AccountCenterFormViewModel, app.organization.AccountCenterInsertUpdateViewModel>
{
    objectKey = "org.AccountCenter";

    constructor(http: HttpClient) {
        super(http, "/api/organization/accountcenter/");
    }

    getSelectList(): Observable<app.organization.AccountCenterSelectListViewModel[]> {
        let params = new HttpParams();

        return this.http.get<app.organization.AccountCenterSelectListViewModel[]>(this.url + "list", { params });
    }
}
