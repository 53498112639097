import { Injectable, OnInit } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ActivatedRouteSnapshot, ResolveEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";

@Injectable()
export class ApplicationInsightsService {
    private routerSubscription: Subscription;

    private appInsights: ApplicationInsights;

    constructor(private router: Router) {
        this.routerSubscription = this.router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
            const activatedComponent = this.getActivatedComponent(event.state.root);
            if (activatedComponent) {
                this.logPageView(activatedComponent.name, event.urlAfterRedirects);
            }
        });
    }

    setup(instrumentationKey: string) {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey
            }
        });
        console.log("insights: loadAppInsights");
        this.appInsights.loadAppInsights();
    }

    setUser(userName: string) {
        if (!this.appInsights) return;
        console.log("insights: setUser");
        this.appInsights.setAuthenticatedUserContext(userName.replace(/[,;=| ]+/g, "_"));
    }

    clearUser() {
        if (!this.appInsights) return;
        console.log("insights: clearUser");
        this.appInsights.clearAuthenticatedUserContext();
    }

    logPageView(name?: string, uri?: string) {
        if (!this.appInsights) return;
        console.log("insights: logPageView", { name, uri });
        //this.appInsights.trackPageView({ name, uri });
    }

    trackException(exception) {
        if (!this.appInsights) return;
        this.appInsights.trackException(exception);
    }

    private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }

        return snapshot.component;
    }
}
