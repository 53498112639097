import { Component, ViewChild, ViewChildren, ElementRef, QueryList, OnInit, Input, Output, SimpleChange, EventEmitter, OnChanges, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { NgbTypeaheadSelectItemEvent, NgbModal, NgbActiveModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

import { ShiftPlanService } from "./shift-plan.service";
import { ShiftTypeService } from "../shift-type/shift-type.service";
import { EmployeeService } from "../employee/employee.service";
import { AccountCenterService } from "../../organization/account-center/account-center.service";
import { WorkPlaceService } from "../../organization/work-place/work-place.service";
import { PermissionService } from "../../core";
import { AppService } from "../../app.service";
import { TimeObject } from "../../models/time-object.model";
import { filterAndDebounce } from "../../core/utils";
import { ShiftPlanCreateModalComponent } from "./shift-plan-create-modal.component";
import { ShiftPlanEditModalComponent } from "./shift-plan-edit-modal.component";
import { ShiftPlanSmsModalComponent } from "./shift-plan-sms-modal.component";
import { SmsService } from "../sms/sms.service";

class SelectedCell {
    employeeId: number;
    date: Date;
    errorMessage: string;
}
class SelectedCellSms {
    employeeId: number;
    employeeFirstName: string;
    employeeLastName: string;
    date: Date;
    errorMessage: string;
    shiftTypeId: number;
    shiftPlanId: number;
    shiftPlanTypeAbbrevation: string;
    note: null;
    workPlaceId: number;
    projectTaskId: number;
    projectTaskNumber: string;
    projectTaskTitle: string;
    workPlaceTitle: string;
    firstProjectTaskId: number;
    fromMinutes: number;
    toMinutes: number;
}


@Component({
    selector: "op-shift-plan-table",
    templateUrl: "./shift-plan-table.component.html",
    styleUrls: ["./shift-plan-table.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShiftPlanTableComponent implements OnInit, OnChanges, OnDestroy {
    @Input() from: Date;
    @Input() reloadOnChange = true;
    @Input() projectTask: app.operation.ProjectTaskSelectListViewModel;
    @Input() workPlace: app.organization.WorkPlaceSelectListViewModel;
    @Input() agency: app.crm.CompanySelectListViewModel;
    @Input() majorProvider: app.crm.CompanySelectListViewModel;
    @Input() isEditable: boolean;
    @Input() showOnlyPlanned: boolean;
    @Input() showLink = false;
    @Input() addEmployee: EventEmitter<any>;
    @Output() loading = new EventEmitter<boolean>();
    @Output() reload = new EventEmitter();
    @ViewChildren("cellElement") componentList: QueryList<ElementRef>;

    objectKey: string;
    intranetUrl: string;
    calendar: app.operation.ShiftPlanCalendarViewModel;
    shiftTypes: app.operation.ShiftTypeSelectListViewModel[];
    selectedCells = new Array<SelectedCell>();
    selectedCellsSms = new Array<SelectedCellSms>();
    subscription: Subscription;
    markModeForSms: boolean = false;
    markSelectorSize: number = 5;

    private _isLoading = false;
    get isLoading(): boolean {
        return this._isLoading;
    }
    set isLoading(value: boolean) {
        this._isLoading = value;
        this.loading.emit(value);
    }

    constructor(private shiftPlanService: ShiftPlanService,
        private shiftTypeService: ShiftTypeService,
        private appService: AppService,
        private modalService: NgbModal,
        private smsService: SmsService,
        private changeDetectorRef: ChangeDetectorRef) {
        this.objectKey = this.shiftPlanService.objectKey;
        this.intranetUrl = this.appService.homeViewModel.intranetUrl;
    }

    ngOnInit() {
        this.shiftTypeService.getSelectList().subscribe((result) => {
            this.shiftTypes = result;
        });

        this.loadCalendar(false, false);

        if (this.addEmployee) {
            this.addEmployee.subscribe((x) => {
                this.addCalendarRow(x);
                this.changeDetectorRef.detectChanges();
            });
        }
    }

    getTitle() {
        if (this.projectTask) {
            return this.projectTask.projectTaskNumber + " - " + this.projectTask.title + " / " + this.projectTask.workPlaceTitle;
        }

        if (this.workPlace) {
            return this.workPlace.title;
        }
    }

    ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        if (this.reloadOnChange) {
            this.loadCalendar(false, false);
        }
    }

    isSameMonth(index) {
        if (index === 0) { return true; }

        return this.calendar.columns[index].month === this.calendar.columns[index - 1].month;
    }

    isToday(index) {
        let date = new Date();
        let calendarDate = this.calendar.columns[index];

        return date.getMonth() === (calendarDate.month - 1) && date.getDate() === calendarDate.day;
    }

    isSame(cell: app.operation.ShiftPlanCalendarCellViewModel) {
        if (cell.items && cell.items.length !== 0) {
            let item = cell.items[0];

            if (this.projectTask) {
                return this.projectTask.firstProjectTaskId === item.firstProjectTaskId;
            } else if (this.workPlace) {
                return this.workPlace.workPlaceId === item.workPlaceId;
            }
        }
        return false;
    }

    getMonthName(month) {
        let data = moment.localeData()["_months"][month - 1];
        return data;
    }

    formatTime(item: app.operation.ShiftPlanCalendarItemViewModel) {
        let from = moment.duration(item.fromMinutes, "minutes");
        let to = moment.duration(item.toMinutes, "minutes");
        return moment.utc(from.asMilliseconds()).format("HH:mm") + " - " + moment.utc(to.asMilliseconds()).format("HH:mm");
    }

    longPress(cell, cellElement) {
        cell.isShowingDetail = !cell.isShowingDetail;
    }

    onScroll(event) { }

    handleClick(evt, cell, employeeId, index, handleOnlyRightClick) {
        switch (evt.which) {
            case 1:
                if (!handleOnlyRightClick) {
                    this.toggleCell(cell, employeeId, index); // this is left click
                }
                break;
            case 2:
                // in case you need some middle click things
                break;
            case 3:
                cell.isShowingDetail = !cell.isShowingDetail;
                break;
            default:
                break;
        }
    }

    toggleCell(cell, employeeId, index) {
        if (!this.isEditable) {
            return;
        }

        if (!this.markModeForSms) {
            if (cell.selected) {
                let idx = this.selectedCells.indexOf(cell.selected);
                if (idx !== -1) {
                    this.selectedCells.splice(idx, 1);
                }
                cell.selected = null;
            }
            else {
                let selectedCell = new SelectedCell();
                selectedCell.employeeId = employeeId;
                selectedCell.date = this.calendar.columns[index].date;
                cell.selected = selectedCell;
                this.selectedCells.push(selectedCell);
            }
        }
        if (this.markModeForSms) {
            if (cell.selectedSms) {
                this.selectedCellsSms = this.selectedCellsSms.filter(x => !(x.date == this.calendar.columns[index].date && x.employeeId == employeeId));
                cell.selectedSms = null; // attribut for css class 
            }
            else {
                let row = this.calendar.rows.find(x => x.employeeId === employeeId);
                if (row.hasPhone) { // must have phone
                    for (let i = 0; i < cell.items.length; i++) { //add every shift in that day to selected for sending sms
                        if (cell.items[i].shiftTypeId != 4 // Not taking phone
                            && cell.items[i].shiftTypeId != 5 // Refused
                            && cell.items[i].shiftTypeId != 1 // Illness
                            && cell.items[i].shiftTypeId != 16) // Not taking tasks
                        {
                            let selectedCellSms = new SelectedCellSms();
                            selectedCellSms.employeeId = employeeId;
                            selectedCellSms.date = this.calendar.columns[index].date;
                            selectedCellSms.firstProjectTaskId = cell.items[i].firstProjectTaskId;
                            selectedCellSms.note = cell.items[i].note;
                            selectedCellSms.projectTaskId = cell.items[i].projectTaskId;
                            selectedCellSms.projectTaskNumber = cell.items[i].projectTaskNumber;
                            selectedCellSms.projectTaskTitle = cell.items[i].projectTaskTitle;
                            selectedCellSms.shiftPlanId = cell.items[i].shiftPlanId;
                            selectedCellSms.shiftPlanTypeAbbrevation = cell.items[i].shiftPlanTypeAbbrevation;
                            selectedCellSms.shiftTypeId = cell.items[i].shiftTypeId;
                            selectedCellSms.workPlaceId = cell.items[i].workPlaceId;
                            selectedCellSms.workPlaceTitle = cell.items[i].workPlaceTitle;
                            selectedCellSms.fromMinutes = cell.items[i].fromMinutes;
                            selectedCellSms.toMinutes = cell.items[i].toMinutes;
                            cell.selectedSms = selectedCellSms;
                            this.selectedCellsSms.push(selectedCellSms);
                        }
                    }
                }
            }
        }
    }

    markInRowSms(employeeId: number) {
        let row = this.calendar.rows.find(x => x.employeeId === employeeId);
        for (let i = 0; i < row.cells.length; i++) {
            if (i < this.markSelectorSize) {
                this.toggleCell(row.cells[i], employeeId, i);
            }
        }
    }

    markInColSms(cellNumber: number) {
        this.calendar.rows.forEach((row) => {
            for (let i = 0; i < row.cells.length; i++) {
                if (i == cellNumber) {
                    this.toggleCell(row.cells[i], row.employeeId, i);
                }
            }
        });

    }

    loadCalendar(onInsert: boolean, preserveEmployees: boolean) {
        if (!this.from) {
            return;
        }

        let observable: Observable<app.operation.ShiftPlanCalendarViewModel> = null;

        if (this.projectTask) {
            observable = this.shiftPlanService.getCalendar(this.from, this.projectTask.firstProjectTaskId, null, this.majorProvider ? this.majorProvider.companyId : null);
        }
        else if (this.workPlace) {
            observable = this.shiftPlanService.getCalendar(this.from, null, this.workPlace.workPlaceId, this.majorProvider ? this.majorProvider.companyId : null);
        } else {
            return;
        }

        this.isLoading = true;

        this.subscription = observable.subscribe((res) => {
            let employeesIds = [];
            if (preserveEmployees) {
                employeesIds = this.getDiffEmployees(this.calendar, res);
            }

            this.calendar = res;
            if (onInsert) {
                for (let i = 0; i < this.selectedCells.length; i++) {
                    let cell = this.selectedCells[i];
                    let row = this.calendar.rows.find(x => x.employeeId === cell.employeeId);
                    if (row) {
                        let index = this.calendar.columns.findIndex(x => x.date === cell.date);
                        if (index >= 0) {
                            let calendarCell = row.cells[index];
                            calendarCell["selected"] = cell;
                        }
                    }
                    else {
                        this.addCalendarRow(cell.employeeId, cell);
                    }
                }
            }
            else {
                this.selectedCells.splice(0, this.selectedCells.length);
            }

            for (let item of employeesIds) {
                this.addCalendarRow(item);
            }
            this.selectedCells = [];
            this.selectedCellsSms = [];
            this.isLoading = false;

            this.changeDetectorRef.detectChanges();
        });
    }

    getDiffEmployees(oldCalendar: app.operation.ShiftPlanCalendarViewModel, newCalendar: app.operation.ShiftPlanCalendarViewModel) {
        let employeeIds = [];

        if (!oldCalendar || !oldCalendar.rows) {
            return employeeIds;
        }

        for (let i = 0; i < oldCalendar.rows.length; i++) {
            let hasFound = false;
            let oldEmployeeId = oldCalendar.rows[i].employeeId;

            for (let j = 0; j < newCalendar.rows.length; j++) {
                let newEmployeeId = newCalendar.rows[j].employeeId;

                if (oldEmployeeId === newEmployeeId) {
                    hasFound = true;
                    break;
                }
            }

            if (!hasFound) {
                employeeIds.push(oldEmployeeId);
            }
        }
        return employeeIds;
    }

    addCalendarRow(employeeId, cell?) {
        if (!this.calendar) {
            return;
        }

        //check if is not already in calendar
        let row = this.calendar.rows.find((item) => item.employeeId === employeeId);
        if (row) {
            return;
        }

        row = {
            employeeId,
            fullName: null,
            hasPhone: null,
            isFromAgency: null,
            isFromMajorProvider: null,
            cells: new Array<app.operation.ShiftPlanCalendarCellViewModel>()
        };

        row["isLoading"] = true;

        for (let i = 0; i < this.calendar.columns.length; i++) {
            row.cells[i] = {
                hasNote: false,
                items: []
            };
        }

        let index = this.calendar.rows.push(row) - 1;

        this.shiftPlanService.getCalendarRow(
            this.from,
            employeeId,
            this.projectTask ? this.projectTask.firstProjectTaskId : null,
            this.workPlace ? this.workPlace.workPlaceId : null
        ).subscribe((res) => {


            if (cell) {
                let cellIndex = this.calendar.columns.findIndex(x => Date.parse(x.date.toString()) == Date.parse(cell.date));
                let calendarCell = res.cells[cellIndex];
                calendarCell["selected"] = cell;
            }
            this.calendar.rows[index] = res;
            this.changeDetectorRef.detectChanges();
        });
    }

    openModal() {
        if (!this.isEditable) {
            return;
        }

        let model = {
            items: this.selectedCells,
            fromMinutes: 480,
            toMinutes: 960,
            shiftTypeId: null,
            firstProjectTaskId: this.projectTask ? this.projectTask.firstProjectTaskId : null,
            workPlaceId: this.workPlace ? this.workPlace.workPlaceId : null
        };

        const modalRef = this.modalService.open(ShiftPlanCreateModalComponent, this.getModalOptions());
        const shiftPlanInstance = modalRef.componentInstance;
        shiftPlanInstance.model = model;
        shiftPlanInstance.title = this.getTitle();
        shiftPlanInstance.shiftTypes = this.shiftTypes;
        let customShiftType = this.shiftTypes.filter(x => x.shiftTypeAbbreviation === "CUSTOM");
        if (customShiftType.length > 0) {
            shiftPlanInstance.customShiftTypeId = customShiftType[0].shiftTypeId;
        }
        shiftPlanInstance.updateSlider();
        shiftPlanInstance.countHours();

        modalRef.result.then((res: app.operation.ShiftPlanCalendarInsertViewModel) => {
            this.selectedCells.splice(0, this.selectedCells.length);
            for (let i = 0; i < res.items.length; i++) {
                this.selectedCells.push(res.items[i]);
            }
            this.reload.emit(null);
            this.loadCalendar(true, true);
        }, () => { });
    }

    getModalOptions() {
        let options = <NgbModalOptions>{};
        options.backdrop = "static";
        options.windowClass = "modal-shiftplan";
        return options;
    }

    openEditModal(item: app.operation.ShiftPlanCalendarItemViewModel) {
        if (!this.isEditable) {
            return;
        }

        const modalRef = this.modalService.open(ShiftPlanEditModalComponent, this.getModalOptions());
        const shiftPlanInstance = modalRef.componentInstance;
        shiftPlanInstance.model = item;
        shiftPlanInstance.shiftTypes = this.shiftTypes;
        let customShiftType = this.shiftTypes.filter(x => x.shiftTypeAbbreviation === "CUSTOM");
        if (customShiftType.length > 0) {
            shiftPlanInstance.customShiftTypeId = customShiftType[0].shiftTypeId;
        }

        if (item.workPlaceId) {
            shiftPlanInstance.selectedWorkPlace = <app.organization.WorkPlaceSelectListViewModel>{
                workPlaceId: item.workPlaceId,
                title: item.workPlaceTitle
            };
        }

        if (item.projectTaskId) {
            shiftPlanInstance.selectedProjectTask = <app.operation.ProjectTaskSelectListViewModel>{
                firstProjectTaskId: item.firstProjectTaskId,
                title: item.projectTaskTitle,
                projectTaskNumber: item.projectTaskNumber
            };
        }

        shiftPlanInstance.updateSlider();
        shiftPlanInstance.countHours();

        modalRef.result.then((res: app.operation.ShiftPlanCalendarInsertViewModel) => {
            this.loadCalendar(true, true);
        }, () => { });
    }

    deleteCalendar() {
        if (this.selectedCells.length === 0) {
            return;
        }

        let shiftPlanIds = [];

        for (let i = 0; i < this.selectedCells.length; i++) {
            let cell = this.selectedCells[i];
            let row = this.calendar.rows.find(x => x.employeeId === cell.employeeId);
            let index = this.calendar.columns.findIndex(x => x.date === cell.date);
            let calendarCell = row.cells[index];

            for (let j = 0; j < calendarCell.items.length; j++) {
                shiftPlanIds.push(calendarCell.items[j].shiftPlanId);
            }
        }

        if (shiftPlanIds.length > 0) {
            this.isLoading = true;
            this.shiftPlanService.deleteCalendar(shiftPlanIds).subscribe((res) => {
                this.reload.emit(null);
                this.loadCalendar(false, true);
            }, () => {
                this.isLoading = false;
            });
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    changeToMarkMode() {
        this.markModeForSms = !this.markModeForSms;
    }

    openSmsModal() {
        let options = this.getModalOptions()

        const modalRef = this.modalService.open(ShiftPlanSmsModalComponent,options );

        const instance = modalRef.componentInstance;

        this.shiftPlanService.prepareSms(this.selectedCellsSms)
            .subscribe((res) => {
                instance.model=res;          
                this.selectedCellsSms = [];
                this.loadCalendar(true, true);
            });

        modalRef.result.then(() => {        
            this.reload.emit(null);
            this.loadCalendar(true, true);
        }, () => { });
    }

    sendToMajorProvider() {
        if (this.projectTask) {
            let request = <app.operation.ShiftPlanToAgencyRequestViewModel>{
                from: this.from,
                firstProjectTaskId: this.projectTask.firstProjectTaskId,
                companyId: this.majorProvider ? this.majorProvider.companyId : null
            };

            this.shiftPlanService.sendPlansToMajorProvider(request)
                .subscribe((res) => {
                    toastr.info(res);
                });
        }
    }

    checkSmsStatus(sms: any) {   
        this.smsService.checkStatus(sms.smsId).subscribe((result) => {
            sms.smsStatusText = result;
            this.loadCalendar(false, false);
        });

    }
}
