
<af-spinner [loading]="isLoadingSharePoint" [timeout]="0"></af-spinner>

<af-note *ngIf="!isLoadingSharePoint && !content?.folder" type="info">
    <span>{{ 'table.folder does not exist' | translate }}</span>
</af-note>

<div class="row" *ngIf="!isLoadingSharePoint">
    <div class="col-md-8">
        <h3 *ngIf="queryParams.invoiceNumber">{{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.invoice files' | translate }}</h3>
        <h3 *ngIf="queryParams.documentType === 'GT'">{{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.general terms files for country' | translate }}</h3>
        <div *ngIf="!queryParams.documentType && !queryParams.invoiceNumber && content?.folder?.path">
            @if(isMetriqFileMode && isTopFolder)
            {
                <button class="btn btn-default"
                        style="margin-right: 6px;"
                        type="button"                
                        (click)="goToFolder()">
                    <span class="fa fa-long-arrow-up"></span>
                </button>
     
            }
            @else
            {
                <button class="btn btn-default"
                        style="margin-right: 6px;"
                        type="button"
                        [disabled]="isTopFolder"
                        (click)="goToUpperFolder()">
                    <span class="fa fa-long-arrow-up"></span>
                </button>
            }

            @if(isMetriqFileMode){
            <span class="label label-primary rounded" style="margin-right:10px">MetriQ</span>
            }
            <span *ngFor="let folderName of content.folder.path; let $index = index">
                <a (click)="goToPath($index)">{{ folderName }}</a>
                <i class="fa fa-angle-right" style="margin-left: 6px; margin-right: 6px;"></i>
            </span>
        </div>
    </div>
    <div class="col-md-4" *ngIf="!isLoadingSharePoint && content?.folder">
        <af-page-bar>
            <a *ngIf="isSharepointEnabled" class="btn btn-fit-height btn-info" [class.disabled-all]="!content?.folder?.url" target="_blank" [href]="content?.folder?.url">
                SharePoint
            </a>
            @if(isMetriqFileMode)
            {
            <a *afHasPerm="{obj:objectKey, perm: 'Insert', if: isUpload}" class="btn btn-fit-height btn-success" [class.disabled-all]="!content?.folder?.url" target="_blank" (click)="createFolderModal()">
                {{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.create folder' | translate }} <i class="fa fa-plus"></i>
            </a>
            }
            <af-file-upload *afHasPerm="{obj:objectKey, perm: 'Insert', if: isUpload}"
                            name="FileName"
                            [simple]="true"
                            [multiple]="true"
                            styleClass="btn btn-success"
                            (success)="onSuccessUpload($event)" [options]="uploadOptions">
                {{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.upload file' | translate }} <i class="fa fa-plus"></i>
            </af-file-upload>
            <af-help *ngIf="isUpload" class="help-static" style="margin-left: 3px;" helpid="sharePoint.fileUpload"></af-help>
        </af-page-bar>
    </div>
</div>

<form class="form-inline" *ngIf="!isLoadingSharePoint && !queryParams.documentType && !queryParams.invoiceNumber && content?.folder?.subFolders">
    <div class="form-group">


        @if(!isMetriqFileMode)
        {
        <button class="btn btn-default" *ngFor="let folderName of content.folder.subFolders; let $index = index"
                type="button"
                (click)="goToFolder(folderName)">
            {{ folderName }}
        </button>


        <button class="btn btn-default btn-primary"
                type="button"
                *ngIf="isTopFolder"
                (click)="goToMetriqFolder()">
            MetriQ Files
        </button>
        }

    </div>
</form>

<hr />

<af-page-filter [loading]="isLoading" [params]="queryParams" (filter)="loadFiles()" (reset)="onReset()" (clear)="onClear()">
    <div class="form-group">
        <input type="text" class="form-control" [(ngModel)]="queryParams.searchTerm" placeholder="{{ 'filter.keyword' | translate }}" />
    </div>
</af-page-filter>

@if(isMetriqFileMode)
{

<div class="table-wrapper" *ngIf="model &&  model.data.length > 0 || metriqFolders.length > 0">
        <af-paginator [pageSize]="model.pageSize" [page]="model.page" [totalCount]="model.totalCount"
                      styleClass="table-paginator-bottom" (pageChange)="paginate($event)"></af-paginator>
    <table  class="af-table table table-bordered table-striped table-hover mt-3 mb-3">
            <thead>
                <tr>
                    <th class="sortable"
                        (click)="sort('fileName')"
                        [ngClass]="{
                    'sort-asc': isSorted('fileName', null),
                    'sort-desc': isSorted('fileName', 'desc')}">
                        <div class="sort-indicator">
                            {{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.file' | translate }}
                        </div>
                    </th>
                    <th class="sortable"
                        (click)="sort('createdUtc')"
                        [ngClass]="{
                    'sort-asc': isSorted('createdUtc', null),
                    'sort-desc': isSorted('createdUtc', 'desc')}">
                        <div class="sort-indicator">{{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.created at' | translate }} </div>
                    </th>
                    <th class="sortable"
                        (click)="sort('createdBy')"
                        [ngClass]="{
                    'sort-asc': isSorted('createdBy', null),
                    'sort-desc': isSorted('createdBy', 'desc')}">
                        <div class="sort-indicator">
                            {{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.author' | translate }}
                        </div>
                    </th>
                    <th class="sortable"
                        (click)="sort('lastUpdatedUtc')"
                        [ngClass]="{
                    'sort-asc': isSorted('lastUpdatedUtc', null),
                    'sort-desc': isSorted('lastUpdatedUtc', 'desc')}">
                        <div class="sort-indicator">
                            {{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.edited at' | translate }}
                        </div>
                    </th>
                    <th class="sortable"
                        (click)="sort('lastUpdatedBy')"
                        [ngClass]="{
                    'sort-asc': isSorted('lastUpdatedBy', null),
                    'sort-desc': isSorted('lastUpdatedBy', 'desc')}">
                        <div class="sort-indicator">
                            {{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.edited by' | translate }}
                        </div>
                    </th>
                    <th class="sortable"
                        (click)="sort('documentType')"
                        [ngClass]="{
                    'sort-asc': isSorted('documentType', null),
                    'sort-desc': isSorted('documentType', 'desc')}">
                        <div class="sort-indicator">
                            {{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.type of doc.' | translate }}
                        </div>
                    </th>
                    <th class="sortable"
                        (click)="sort('length')"
                        [ngClass]="{
                    'sort-asc': isSorted('length', null),
                    'sort-desc': isSorted('length', 'desc')}">
                        <div class="sort-indicator">
                            {{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.size' | translate }}
                        </div>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="!isTopFolder">
                <td colspan="8" (click)="goToUpperFolder()">
                    <a> <i class="fa fa-arrow-up"></i> {{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.go up' | translate }}</a>
                    </td>
                </tr>
            <tr *ngFor="let folder of metriqFolders; let $indexFolder = index">
                    <td colspan="7">
                        @if(folder.isRenaming)
                        {
                            <input type="text" name="'newFolderName' + $indexFolder"
                                [(ngModel)]="folder.newFolderName"
                                >
                        }
                        @else
                        {
                            <a (click)="goToMetriqFolder(folder.originalFolderName)"><i class="fa fa-folder" aria-hidden="true"></i> {{ folder.originalFolderName }}</a>
                        }
                  
                    </td>
                    <td>
                    <button *afHasPerm="{obj:objectKey, perm: 'Update', if: !folder.isRenaming}" class="btn btn-sm btn-update btn-primary" (click)="onRenameFolder(folder)" type="button">{{ 'btn.rename' | translate }}</button>
                    <button *afHasPerm="{obj:objectKey, perm: 'Update', if:folder.isRenaming}" class="btn btn-sm btn-update btn-primary" (click)="onSaveRename(folder)" type="button">{{ 'btn.save' | translate }}</button>
                    <button *afHasPerm="{obj:objectKey, perm: 'Update', if: folder.isRenaming}" class="btn btn-sm btn-update btn-warning" (click)="onCancelRename(folder)" type="button">{{ 'btn.cancel' | translate }}</button>
                    <button afConfirmClick (confirmed)="deleteFolder(folder);" type="button" class="btn btn-sm btn-delete btn-danger"
                            placement="left"
                            *afHasPerm="{obj:objectKey, perm: 'Delete', if: content?.folder?.isDeletePermission && (!archiveLocation && !folder.isRenaming)}"
                            message="{{ 'confirm.are you sure you want to delete this file?' | translate }}"
                            yes="{{ 'confirm.delete.delete' | translate }}"
                            no="{{ 'confirm.delete.cancel' | translate }}">
                        {{ 'btn.delete' | translate }}
                    </button>
                    </td>
                </tr>
                <tr *ngFor="let item of model.data; let $index=index;">
                    <td>
                        <i class="fa fa-cog" *ngIf="item.isGenerated"></i>
                        <a [href]="sanitize(item.fileUrl)" [target]="target(item)">{{ item.fileName }}</a>
                    </td>
                    <td>
                        {{ item.createdUtc | afDate : 'L LTS' : true }}
                    </td>
                    <td>
                        {{ item.createdBy }}
                    </td>
                    <td>
                        {{ item.lastUpdatedUtc | afDate : 'L LTS' : true }}
                    </td>
                    <td>
                        {{ item.lastUpdatedBy }}
                    </td>
                    <td>
                        {{ item.documentType }}
                    </td>
                    <td>
                        {{ item.length | afFileSize }}
                    </td>
                    <td>    
                        <button *afHasPerm="{obj:objectKey, perm: 'Update', if: item['isEditing']}" class="btn btn-sm btn-update btn-primary" (click)="onSave(item)" type="button">{{ 'btn.save' | translate }}</button>
                        <button *afHasPerm="{obj:objectKey, perm: 'Update', if: item['isEditing']}" class="btn btn-sm btn-update btn-warning" (click)="onCancel(item)" type="button">{{ 'btn.cancel' | translate }}</button>
                        <button afConfirmClick (confirmed)="delete(item);" type="button" class="btn btn-sm btn-delete btn-danger"
                                placement="left"
                                *afHasPerm="{obj:objectKey, perm: 'Delete', if: content?.folder?.isDeletePermission && (!archiveLocation && !item['isEditing'])}"
                                message="{{ 'confirm.are you sure you want to delete this file?' | translate }}"
                                yes="{{ 'confirm.delete.delete' | translate }}"
                                no="{{ 'confirm.delete.cancel' | translate }}">
                            {{ 'btn.delete' | translate }}
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <af-paginator [pageSize]="model.pageSize" [page]="model.page" [totalCount]="model.totalCount"
                      styleClass="table-paginator-bottom" (pageChange)="paginate($event)"></af-paginator>
    </div>
<af-note *ngIf="model &&  model.data.length === 0 && metriqFolders.length == 0" type="info">
    <span>{{ 'table.no files was found' | translate }}</span>
</af-note>


}

@if(!isMetriqFileMode)
{
    <af-table #afTable [value]="model" [loading]="isLoading" [timeout]="0" [params]="queryParams" [sortBy]="sortBy" (load)="loadFiles()" noRecordsFoundText="{{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.no files found' | translate }}">
        <af-column field="fileName" sortable="fileName" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.file' | translate }}">
            <ng-template let-item="rowData" afTemplate>
                <i class="fa fa-cog" *ngIf="item.isGenerated"></i>
                <a [href]="sanitize(item.fileUrl)" [target]="target(item)">{{ item.fileName }}</a>
            </ng-template>
        </af-column>
        <af-column *ngIf="isEconomyFolder()" field="invoiceNumber" sortable="invoiceNumber" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.invoice number' | translate }}"></af-column>
        <af-column field="createdUtc" sortable="createdUtc" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.created' | translate }}">
            <ng-template let-item="rowData" afTemplate>
                {{ item.createdUtc | afDate : 'L LTS' : true }}
            </ng-template>
        </af-column>
        <af-column field="createdBy" sortable="createdBy" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.author' | translate }}">
            <ng-template let-item="rowData" afTemplate>
                {{ item.createdBy }}
            </ng-template>
        </af-column>
        <af-column field="lastUpdatedUtc" sortable="lastUpdatedUtc" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.edited' | translate }}">
            <ng-template let-item="rowData" afTemplate>
                {{ item.lastUpdatedUtc | afDate : 'L LTS' : true }}
            </ng-template>
        </af-column>
        <af-column field="lastUpdatedBy" sortable="lastUpdatedBy" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.editor' | translate }}">
            <ng-template let-item="rowData" afTemplate>
                {{ item.lastUpdatedBy }}
            </ng-template>
        </af-column>
        <af-column field="documentType" sortable="documentType" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.document type' | translate }}">
            <ng-template let-item="rowData" afTemplate>
                {{ item.documentType }}
            </ng-template>
        </af-column>
        <af-column field="length" sortable="length" styleClass="text-center" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.size' | translate }}">
            <ng-template let-item="rowData" afTemplate>
                {{ item.length | afFileSize }}
            </ng-template>
        </af-column>
        <af-column *ngIf="!queryParams.invoiceNumber" field="validFrom" sortable="validFrom" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.valid from' | translate }}">
            <ng-template let-item="rowData" afTemplate>
                <span *ngIf="!item.isEditing">{{ item.validFrom | afDate : 'L' }}</span>
                <af-datepicker *ngIf="item['isEditing']" [ngModel]="item.validFrom" (ngModelChange)="editItem(item, 'validFrom', item.validFrom, $event)" [disabled]="item['isLoading']"></af-datepicker>

            </ng-template>
        </af-column>
        <af-column *ngIf="!queryParams.invoiceNumber" field="validUntil" sortable="validUntil" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.valid until' | translate }}">
            <ng-template let-item="rowData" afTemplate>
                <span *ngIf="!item.isEditing">{{ item.validUntil | afDate : 'L' }}</span>
                <af-datepicker *ngIf="item['isEditing']" [ngModel]="item.validUntil" (ngModelChange)="editItem(item, 'validUntil', item.validUntil, $event)" [disabled]="item['isLoading']"></af-datepicker>
            </ng-template>
        </af-column>
        <af-column field="length" sortable="length" styleClass="text-center" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.notification of validity at ac' | translate }}">
            <ng-template let-item="rowData" afTemplate>
                <span *ngIf="!item.isEditing">
                    <ng-container *ngFor="let ac of item.accountCenterIds">
                        {{ac}},
                    </ng-container>
                </span>
                <div *ngIf="item['isEditing'] && accountCenterList">
                    <div class="form-check form-check-inline" *ngFor="let ac of accountCenterList">
                        <input type="checkbox" name="accountCenter{{ac.code}}"
                               [value]="ac.code"
                               [checked]="selectedAccountCenters.indexOf(ac.code) > -1"
                               (click)="accountCenterChange(ac.code)"
                               class="form-check-input">
                        <label class="form-check-label">{{ac.title}}</label>
                    </div>
                </div>
            </ng-template>
        </af-column>
        <af-column field="length" sortable="length" styleClass="text-center" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.notification interval' | translate }}">
            <ng-template let-item="rowData" afTemplate>
                <span *ngIf="!item.isEditing">
                    <ng-container *ngIf="item.notificationInterval == 1">
                        {{item.notificationInterval}} {{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.month' | translate }}
                    </ng-container>
                    <ng-container *ngIf="item.notificationInterval > 1">
                        {{item.notificationInterval}} {{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.months' | translate }}
                    </ng-container>
                </span>
                <select class="form-control form-select" *ngIf="item.isEditing" [(ngModel)]="selectedNotificationInterval" id="NotificationInterval" name="NotificationInterval">
                    <option [ngValue]="1"> 1 {{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.month' | translate }} </option>
                    <option [ngValue]="2"> 2 {{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.months' | translate }} </option>
                    <option [ngValue]="3"> 3 {{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.months' | translate }} </option>
                </select>
            </ng-template>
        </af-column>
        <af-column>
            <ng-template let-item="rowData" afTemplate>
                <div [class.disabled-all]="item['isLoading']">
                    <button *afHasPerm="{obj:objectKey, perm: 'Update', if: !item['isEditing'] && !queryParams.invoiceNumber}" class="btn btn-sm btn-update btn-primary" (click)="onEdit(item)" type="button">{{ 'btn.edit' | translate }}</button>
                    <button *afHasPerm="{obj:objectKey, perm: 'Update', if: item['isEditing']}" class="btn btn-sm btn-update btn-primary" (click)="onSave(item)" type="button">{{ 'btn.save' | translate }}</button>
                    <button *afHasPerm="{obj:objectKey, perm: 'Update', if: item['isEditing']}" class="btn btn-sm btn-update btn-warning" (click)="onCancel(item)" type="button">{{ 'btn.cancel' | translate }}</button>
                    <button afConfirmClick (confirmed)="delete(item);" type="button" class="btn btn-sm btn-delete btn-danger"
                            placement="left"
                            *afHasPerm="{obj:objectKey, perm: 'Delete', if: content?.folder?.isDeletePermission && (!archiveLocation && !item['isEditing'])}"
                            message="{{ 'confirm.are you sure you want to delete this file?' | translate }}"
                            yes="{{ 'confirm.delete.delete' | translate }}"
                            no="{{ 'confirm.delete.cancel' | translate }}">
                        {{ 'btn.delete' | translate }}
                    </button>
                </div>
            </ng-template>
        </af-column>
    </af-table>
}



<ng-template #newFolder let-close="close" let-dismiss="dismiss">
    <div class="modal-header">
        <h3 class="modal-title">{{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.new folder' | translate }}</h3>
    </div>
    <div class="modal-body">
        <div class="form">
            <div class="form-horizontal">
                <div class="form-body">
                    <div class="form-group">
                        <label class="control-label col-md-4"> {{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.folder name' | translate }} </label>
                        <div class="col-md-8">
                            <input type="text" name="newFolderName"
                                   [(ngModel)]="newFolderName"                             
                                  >
                        </div>             
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-update btn-primary" [disabled]="!newFolderName" (click)="goToMetriqFolder(newFolderName,true)">{{ 'btn.create' | translate }}</button>
        <button type="button" class="btn-back btn-warning btn" (click)="close()">{{ 'hollen.clientapp.src.app.crm.company.company-file-list.component.cancel' | translate }}</button>
    </div>
</ng-template>
