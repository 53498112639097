import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable, of, Subject, Subscription } from "rxjs";
import { finalize, switchMap, tap } from "rxjs/operators";
import { FormComponent } from "../../../core";
import { B2bAdvanceService } from "./b2b-advance.service";

@Component({
    selector: "crm-company-b2b-advance-edit",
    templateUrl: "./b2b-advance-edit.component.html"
})
export class B2bAdvanceEditComponent extends FormComponent {

    @Input() input = new Observable<{ advanceId: number }>();
    @Output() updated = new EventEmitter<any>();
    @Output() canceled = new EventEmitter<any>();
    @ViewChild("editForm", { static: true }) editForm: NgForm;
    subscription: Subscription;
    model = <app.b2b.AdvanceRequestInsertUpdateViewModel>{};
    objectKey: string;
    advanceTypes: dotnet.KeyValuePair[];
    maxAmount: number;

    constructor(protected router: Router,
        private b2bAdvanceService: B2bAdvanceService) {
        super(router);
        this.objectKey = b2bAdvanceService.objectKey;
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.subscription = this.input
            .pipe(tap(() => this.isLoading = true),
                switchMap(x => this.b2bAdvanceService.get(x.advanceId, false)
                    .pipe(finalize(() => this.isLoading = false))))
            .subscribe(res => {
                this.model = res.model;
                this.advanceTypes = res.advanceTypes;
                this.maxAmount = res.maxAmount;
            });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    update() {
        if (this.editForm.invalid) {
            return;
        }

        this.isLoading = true;

        this.b2bAdvanceService.update(this.model, this.model.requestId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => this.updated.emit());
    }

    cancel() {
        this.canceled.emit();
    }

    delete() {
        this.b2bAdvanceService.delete(this.model, this.model.requestId)
            .subscribe(res => this.updated.emit());
    }
}
