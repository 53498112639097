import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "afAbs"
})
export class AbsPipe implements PipeTransform {

    transform(value: number): number | null {
        if (!value) {
            return null;
        }
        return Math.abs(value);
    }
}
