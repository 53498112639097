import { Pipe, PipeTransform } from '@angular/core';
import { Decimal } from "decimal.js";

@Pipe({
    name: 'afSum',
    pure: false
})
export class SumPipe implements PipeTransform {
    transform(items: any[], attr: string): any {
        if (!items) {
            return 0;
        }
        return items.reduce((a, b) => +Decimal.add(a,+b[attr]), 0);
    }
}