import { Component, Input, ViewContainerRef, OnInit, OnDestroy, ChangeDetectionStrategy, EmbeddedViewRef } from "@angular/core";

@Component({
    selector: "af-column-header-template-loader",
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: ``
})
export class ColumnHeaderTemplateLoaderComponent implements OnInit, OnDestroy {

    @Input() column: any;

    @Input() rowData: any;

    view: EmbeddedViewRef<any>;

    constructor(private viewContainer: ViewContainerRef) { }

    ngOnInit() {
        this.view = this.viewContainer.createEmbeddedView(this.column.headerTemplate, {
            $implicit: this.column,
            rowData: this.rowData
        });
    }

    ngOnDestroy() {
        if (this.view) {
            this.view.destroy();
        }
    }
}
