
<af-page-bar title="{{ 'hollen.clientapp.src.app.operation.employee-trip.employee-trip-list.component.employee trip' | translate }}" *ngIf="!hideTitle">
    <ng-container *ngIf="!employeeId">
        <button type="button" class="btn btn-fit-height btn-success" *afHasPerm="{obj:objectKey,perm:'Insert'}" [routerLink]="['create']">
            {{ 'btn.list.add.add item' | translate }} <i class="fa fa-plus"></i>
        </button>
    </ng-container>
</af-page-bar>

<af-page-filter *ngIf="filter" [control]="afTable" [loading]="isLoading" [params]="queryParams" (filter)="loadData()" (reset)="onReset()" (clear)="onClear()">
    <div class="form-group" *ngIf="!employeeId">
        <label>{{ 'hollen.clientapp.src.app.operation.employee-trip.employee-trip-list.component.employee' | translate }}</label>
        <div class="input-group">
                <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
            <input type="text" name="EmployeeId" class="form-control"
                   [(ngModel)]="selectedEmployee"
                   [editable]="false"
                   [ngbTypeahead]="employeeAutocomplete"
                   [resultFormatter]="employeeFormatter"
                   [inputFormatter]="employeeFormatter" />
        </div>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.employee-trip.employee-trip-list.component.country' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryParams.countryCode">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee-trip.employee-trip-list.component.all' | translate }}</option>
            <option *ngFor="let country of filter.countries" [ngValue]="country.key">{{country.value}}</option>
        </select>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.employee-trip.employee-trip-list.component.destination' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryParams.tripDestinationId">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee-trip.employee-trip-list.component.all' | translate }}</option>
            <option *ngFor="let destination of filter.destinations" [ngValue]="destination.tripDestinationId">{{destination.title}}</option>
        </select>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.employee-trip.employee-trip-list.component.year' | translate }}</label>
        <select class="form-control form-select"
                [(ngModel)]="queryParams.year">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee-trip.employee-trip-list.component.all' | translate }}</option>
            <option *ngFor="let year of filter.years" [ngValue]="year.key">{{year.value}}</option>
        </select>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.employee-trip.employee-trip-list.component.month' | translate }}</label>
        <select class="form-control form-select"
                [(ngModel)]="queryParams.month">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee-trip.employee-trip-list.component.all' | translate }}</option>
            <option *ngFor="let month of filter.months" [ngValue]="month.key">{{month.value}}</option>
        </select>
    </div>

</af-page-filter>


<af-table #afTable [value]="model" [loading]="isLoading" [params]="queryParams" [sortBy]="sortBy" (load)="loadData()">

    <af-column field="employeeLastName" sortable="employeeLastName" header="{{ 'hollen.operation.employeetriplistviewmodel.employee' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a [routerLink]="['/operation/employee', item.employeeId, 'employeeTrip', item.employeeTripId]">{{item.employeeName}}</a>
        </ng-template>
    </af-column>
    <af-column field="projectTaskNumber" sortable="projectTaskNumber" header="{{ 'hollen.operation.employeetriplistviewmodel.project task' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a *ngIf="item.projectTaskId" [routerLink]="['/operation/projectTask', item.projectTaskId]">{{item.projectTaskNumber}}</a>
        </ng-template>
    </af-column>
    <af-column field="countryCode" sortable="countryCode" header="{{ 'hollen.operation.employeetriplistviewmodel.country code' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.countryCode}} - {{item.countryTitle}}
        </ng-template>  
    </af-column>
    <af-column field="tripDestinationTitle" sortable="tripDestinationTitle" header="{{ 'hollen.operation.employeetriplistviewmodel.destination' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a [routerLink]="['/operation/tripDestination', item.tripDestinationId]">{{ item.tripDestinationTitle }}</a>
        </ng-template>
    </af-column>
    <af-column field="departureDateTime" sortable="departureDateTime" header="{{ 'hollen.operation.employeetriplistviewmodel.date and time of departure' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.departureDateTime | afDate:'DD.MM.YYYY HH:mm'}}
        </ng-template>
    </af-column>
    <af-column field="returnDateTime" sortable="returnDateTime" header="{{ 'hollen.operation.employeetriplistviewmodel.return date and time' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.returnDateTime | afDate:'DD.MM.YYYY HH:mm'}}
        </ng-template>
    </af-column>
    <af-column field="plannedReturnDateTime" sortable="plannedReturnDateTime" header="{{ 'hollen.operation.employeetriplistviewmodel.planned return date' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.plannedReturnDateTime | afDate:'L'}}
        </ng-template>
    </af-column>

    <af-column field="note" header="{{ 'hollen.operation.employeetriplistviewmodel.note' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.note}}
        </ng-template>
    </af-column>

    <af-column field="sourceCountry" header="{{ 'hollen.operation.employeetriplistviewmodel.source country' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.sourceCountry}}
        </ng-template>
    </af-column>
</af-table>
