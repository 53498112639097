import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from "@angular/core";

import { ReportProjectTaskService, ReportProjectTaskRequest } from "./report-project-task.service";

@Component({
    selector: "op-leistungsnachweis-report",
    templateUrl: "./leistungsnachweis-report.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeistungsnachweisReportComponent implements OnChanges {
    @Input() params: ReportProjectTaskRequest;
    previousBranchId: number;
    request = <app.operation.LeistungsnachweisReportRequest>{};

    constructor(private reportService: ReportProjectTaskService) { }

    getUrl() {
        let requestParams = Object.assign({}, this.params, this.request);
        return this.reportService.getLeistungsnachweis(requestParams);
    }

    ngOnChanges(changes: SimpleChanges) {
        let newParams = changes["params"];
        if (newParams) {
            if (newParams.currentValue.branchId !== this.previousBranchId) {
                this.request = <app.operation.LeistungsnachweisReportRequest>{};
            }
            this.previousBranchId = newParams.currentValue.branchId;
        }
    }
}
