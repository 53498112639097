// Import the core angular services.
import { Directive, Input, Output, HostListener } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { ClipboardService } from "../../core/services/clipboard.service";


// This directive acts as a simple glue layer between the given [clipboard] property
// and the underlying ClipboardService. Upon the (click) event, the [clipboard] value
// will be copied to the ClipboardService and a (clipboardCopy) event will be emitted.
@Directive({
    selector: "[clipboard]",
    host: {
        '[style.cursor]': '"pointer"',
    }
})
export class ClipboardDirective {

    @Input() clipboard = "";
    @Output() clipboardCopy = new EventEmitter<string>();
    @Output() clipboardError = new EventEmitter<Error>();

    // I initialize the clipboard directive.
    constructor(private clipboardService: ClipboardService) {
    }

    @HostListener("click")
    copyToClipboard(): void {
        this.clipboardService
            .copy(this.clipboard)
            .then(
            (value: string): void => {
                this.clipboardCopy.emit(value);
            }
            )
            .catch(
            (error: Error): void => {
                this.clipboardError.emit(error);
            });
    }
}
