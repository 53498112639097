import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class RoleService
    extends HttpService<af.usersystem.RoleListViewModel, af.usersystem.RoleFormViewModel, af.usersystem.RoleInsertUpdateViewModel>
{
    objectKey = "us.role";

    constructor(http: HttpClient) {
        super(http, "/api/usersystem/role/");
    }

    getLanguage(roleId: number, languageId: string): Observable<af.usersystem.RoleLocalizationSelectViewModel> {
        let params = new HttpParams();
        params = params.set("languageId", languageId);
        return this.http.get<af.usersystem.RoleLocalizationSelectViewModel>(this.url + roleId + "/localization", { params });
    }

    getPermissionsByRole(id: number, appendLists?: boolean) {
        let params = new HttpParams();
        if (appendLists) {
            params = params.set("appendLists", appendLists.toString());
        }
        return this.http.get<af.usersystem.PermissionFormViewModel>(this.url + id + "/permission", { params });
    }

    updatePermissions(id: number, permissions: af.usersystem.PermissionInsertUpdateViewModel[]) {
        return this.http.put(this.url + id + "/permission", permissions);
    }

    getSelectedRoles(roles: af.usersystem.RoleSelectViewModel[]): number[] {
        if (roles.length === 0) {
            return null;
        }

        let result = new Array<number>();

        for (let i = 0; i < roles.length; i++) {
            let role = roles[i];
            if (role.isInRole) {
                result.push(role.roleId);
            }
        }

        return result;
    }

    getUsersByRole(parameters?: any) {
        return this.http.get<PagedList<app.usersystem.RoleUserViewModel>>(this.url + parameters.roleId + "/user", {
            params: this.getParameters(parameters)
        });
    }

    // project specific
    getUsersByRoleExtended(parameters?: any) {
        return this.http.get<PagedList<app.usersystem.RoleUserViewModel>>(this.url + parameters.roleId + "/user-extended", {
            params: this.getParameters(parameters)
        });
    }
}
