import { ProjectTaskFileComponent } from "./project-task/project-task-file.component";
import { ProjectTaskProductRateSummaryComponent } from "./project-task-product-rate/project-task-product-rate-summary.component";
import { ProjectTaskProductRateInvoiceComponent } from "./project-task-product-rate/project-task-product-rate-invoice.component";
import { EmployeeWorkClothesComponent } from "./employee/employee-work-clothes.component";
import { ProjectTaskListComponent } from "./project-task/project-task-list.component";
import { EmployeeInventoryComponent } from "./employee/employee-inventory.component";
import { ShiftPlanTableComponent } from './shift-plan/shift-plan-table.component';
import { ShiftPlanTableDayComponent } from './shift-plan/shift-plan-table-day.component';
import { EmployeeTripCreateComponent } from './employee-trip/employee-trip-create.component';
import { EmployeeTripEditComponent } from './employee-trip/employee-trip-edit.component';
import { EmployeeTripListComponent } from './employee-trip/employee-trip-list.component';
import { ProjectTaskDocumentsComponent } from './project-task/project-task-documents.component';
import { ProjectCompanyInfoComponent } from "./project/project-company-info.component";
import { ProjectReadComponent } from './project/project-read.component';
import { reportRoutedComponents } from "./report/report.routes";
import { presentationItemRoutedComponents } from './presentation-item/presentation-item.routes';
import { EmployeeRelationshipCreateComponent } from "./employee-relationship/employee-relationship-create.component";
import { EmployeeRelationshipEditComponent } from "./employee-relationship/employee-relationship-edit.component";
import { EmployeeRelationshipListComponent } from "./employee-relationship/employee-relationship-list.component";
import { components as accommodationTermComponents } from "./accommodation-term/shared.components";
import { EmployeeAdvanceCreateComponent } from "./employee-advance/employee-advance-create.component";
import { EmployeeAdvanceEditComponent } from "./employee-advance/employee-advance-edit.component";
import { EmployeeAdvanceListComponent } from "./employee-advance/employee-advance-list.component";

export const components = [
    ProjectTaskListComponent,
    ProjectTaskFileComponent,
    ProjectTaskProductRateSummaryComponent,
    ProjectTaskProductRateInvoiceComponent,
    EmployeeWorkClothesComponent,
    EmployeeInventoryComponent,
    ShiftPlanTableComponent,
    ShiftPlanTableDayComponent,
    EmployeeTripCreateComponent,
    EmployeeTripEditComponent,
    EmployeeTripListComponent,
    EmployeeAdvanceCreateComponent,
    EmployeeAdvanceEditComponent,
    EmployeeAdvanceListComponent,
    EmployeeRelationshipCreateComponent,
    EmployeeRelationshipEditComponent,
    EmployeeRelationshipListComponent,
    ProjectTaskDocumentsComponent,
    ProjectCompanyInfoComponent,
    ProjectReadComponent,
    ...reportRoutedComponents,
    ...presentationItemRoutedComponents,
    ...accommodationTermComponents,    
];
