import { finalize, switchMap, tap } from "rxjs/operators";
import { Component, ViewChild, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectionStrategy } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { forkJoin, Observable, of } from "rxjs";
import { PresentationItemService } from './presentation-item.service';
import { AppService } from '../../app.service';
import { ProjectTaskFileService } from '../project-task/project-task-file.service';
import { ListQueryParams } from '../../models';
import { filterAndDebounce } from "../../core/utils";
import { ProjectTaskService } from "../project-task/project-task.service";
export class QueryParams extends ListQueryParams {
    presentationItemTypeId: number = null;
    firstProjectTaskId: number = null;
    projectTaskId: number;
    onlyPresentation: boolean = false;
}

@Component({
    selector: "op-presentation-item-edit",
    templateUrl: "./presentation-item-edit.component.html"
})
export class PresentationItemEditComponent implements OnInit {
    @Input() presentationItemId: number;
    @Input() isPresentationConfirmationRequired: boolean;
    @Output() updated = new EventEmitter();
    @Output() deleted = new EventEmitter();
    queryParams = new QueryParams();

    @ViewChild(NgForm, { static: false }) editForm: NgForm;
    form = <app.operation.ProjectTaskPresentationItemInsertUpdateViewModel>{};
    presentationItem = <app.operation.ProjectTaskPresentationItemInsertUpdateViewModel>{};
    objectKey: string;
    isLoading = false;
    projectTaskId: number;
    
    constructor(protected router: Router,
        protected route: ActivatedRoute,
        private appService: AppService,
        private presentationItemService: PresentationItemService,
        private projectTaskService: ProjectTaskService,
        private projectTaskFileService: ProjectTaskFileService) {
        this.objectKey = presentationItemService.objectKey;
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges) {
        this.loadData();
    }

    loadData() {
        this.projectTaskId = +this.appService.getParam("projectTaskId");

        let presentationItemId = 0;

        if (this.presentationItemId) {
            presentationItemId = this.presentationItemId;
        }

        if (!this.projectTaskId)
        {
            return;
        }

        this.queryParams.projectTaskId = this.projectTaskId;
        this.queryParams.onlyPresentation = true;

        this.isLoading = true;
        forkJoin([
            this.presentationItemService.get(this.projectTaskId, presentationItemId, true),
            this.projectTaskFileService.query(this.queryParams)
        ])
        .pipe(finalize(() => this.isLoading = false))
        .subscribe(result => {
            this.form = result[0];
            this.presentationItem = result[0];
            if (this.presentationItem != null) {
                this.presentationItem.fileUrl = result[1].data.find(x => x.uniqueId == this.presentationItem.fileUniqueId).fileUrl;
            }
            this.checkRows();
        });
    }

    update() {      
        if (this.editForm && this.editForm.invalid || this.isLoading) {
            return;
        }

        this.isLoading = true;

        this.presentationItemService.saveChanges(this.projectTaskId, this.presentationItem.projectTaskPresentationItemId ,this.presentationItem).pipe(
        finalize(() => this.isLoading = false))
            .subscribe(res => {               
                if (this.editForm) {
                    this.editForm.control.markAsPristine();
                }
            this.updated.emit();
        });
    }

    delete() {
        this.isLoading = true;

        this.presentationItemService.deleteById(this.presentationItem.projectTaskPresentationItemId,this.presentationItem.firstProjectTaskId).pipe(
            finalize(() => this.isLoading = false))
            .subscribe((result) => {
                if (this.editForm)
                {
                    this.editForm.reset();
                }
                if (this.presentationItemId) {
                    this.deleted.emit();
                }
                else {
                    this.router.navigate(["/operation/projectTask/" + this.presentationItem.firstProjectTaskId + "/presentation-item"]);
                }
            });
    }

    deleteComponent(id: number, index: number) {
        this.presentationItem.components.splice(index, 1);
        this.checkRows();
    }

    checkValue(items: app.operation.ProjectTaskPartNumberSelectListViewModel[], index: number) {
        if (items[index] !== null && typeof items[index] === "object") {
            return;
        }
        items[index] = <app.operation.ProjectTaskPartNumberSelectListViewModel>{};
    }

    addComponent() {
        if (!this.presentationItem.components) {
            this.presentationItem.components = [];
        }

        this.presentationItem.components.push(<app.operation.ProjectTaskPartNumberSelectListViewModel>{});
    }

    componentFormatter = (component: app.operation.ProjectTaskPartNumberSelectListViewModel) => component.partNumber;

    componentAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(term => term.length < 2 ? of([]) : this.projectTaskService.getComponentSelectList(this.projectTaskId,term)));
    }

    checkRows() {
        let defaultCount = 1;
        if (this.presentationItem && this.presentationItem.components && this.presentationItem.components.length < defaultCount) {
            this.addComponent();
        }
    }

    onPresentationItemChange($event: app.operation.ProjectTaskPartNumberSelectListViewModel,index) {
        if ($event && $event.partNumber) {
            this.presentationItem.components[index] = $event;
        }
    }
    
}
