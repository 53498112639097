import { Injectable } from "@angular/core";

import { AuthConfig } from "./auth.config";
import { LoginResponse } from "./auth.service";

@Injectable()
export class StorageService {
    accessToken: string | null;
    expiresDate: number | null;

    constructor(private authConfig: AuthConfig) { }

    getToken(): string | null {
        if (window.localStorage) {
            return window.localStorage.getItem(this.authConfig.tokenName);
        }
        return this.accessToken;
    };

    getExpireDate(): number | null {
        if (window.localStorage) {
            return +window.localStorage.getItem(this.authConfig.tokenExpName);
        }
        return this.expiresDate;
    };

    isTokenExpired() {
        if (!this.getToken()) {
            return true;
        }

        if (!this.expiresDate) {
            this.expiresDate = this.getExpireDate();
        }

        return this.expiresDate < +moment();
    }

    set(response: LoginResponse): void {
        this.accessToken = response.access_token;
        this.expiresDate = +moment().add(response.expires_in, "seconds").subtract(10, "minutes");

        if (window.localStorage) {
            window.localStorage.setItem(this.authConfig.tokenName, this.accessToken);
            window.localStorage.setItem(this.authConfig.tokenExpName, this.expiresDate.toString());
        }
    };

    clear(): void {
        if (window.localStorage) {
            window.localStorage.removeItem(this.authConfig.tokenName);
            window.localStorage.removeItem(this.authConfig.tokenExpName);
            window.localStorage.clear();
        }
        this.accessToken = null;
        this.expiresDate = null;
    };
}
