

<af-page-filter *ngIf="filter" [control]="afTable" [loading]="isLoading" [params]="queryParams" (filter)="loadData()" (reset)="onReset()" (clear)="onClear()">
    <div class="form-group" *ngIf="!employeeId">
        <label>{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.employee' | translate }}</label>
        <div class="input-group">
            <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
            <input type="text" name="EmployeeId" class="form-control"
                   [(ngModel)]="selectedEmployee"
                   [editable]="false"
                   [ngbTypeahead]="employeeAutocomplete"
                   [resultFormatter]="employeeFormatter"
                   [inputFormatter]="employeeFormatter" />
        </div>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.hollen company' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryParams.hollenCompanyCode">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.all' | translate }}</option>
            <option *ngFor="let company of filter.hollenCompanies" [ngValue]="company.code">{{company.title}}</option>
        </select>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.ac' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryParams.accountCenterCode">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.all' | translate }}</option>
            <option *ngFor="let ac of filter.accountCenters" [ngValue]="ac.code">{{ac.title}}</option>
        </select>
    </div>

    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.payment type' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryParams.paymentType">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.all' | translate }}</option>
            <option *ngFor="let pt of filter.paymentTypes" [ngValue]="pt.key">{{pt.value}}</option>
        </select>
    </div>

    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.approved' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryParams.isApproved">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.all' | translate }}</option>
            <option [ngValue]="true">{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.yes' | translate }}</option>
            <option [ngValue]="false">{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.no' | translate }}</option>
        </select>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.month of validity' | translate }} {{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.year' | translate }}</label>
        <select class="form-control form-select"
                [(ngModel)]="queryParams.year">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.all' | translate }}</option>
            <option *ngFor="let year of filter.years" [ngValue]="year.key">{{year.value}}</option>
        </select>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.month' | translate }}</label>
        <select class="form-control form-select"
                [(ngModel)]="queryParams.month">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.all' | translate }}</option>
            <option *ngFor="let month of filter.months" [ngValue]="month.key">{{month.value}}</option>
        </select>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.generated' | translate }}</label>
        <select class="form-control form-select" [(ngModel)]="queryParams.wasGenerated">
            <option [ngValue]="null">{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.all' | translate }}</option>
            <option [ngValue]="true">{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.yes' | translate }}</option>
            <option [ngValue]="false">{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.no' | translate }}</option>
        </select>
    </div>
</af-page-filter>


<div class="row mt-3 mb-1">
    <div class="col-md-3">
        <ng-container *ngIf="filter" [class.disabled-all]="afTable.isLoading">
            <button *afHasPerm="{obj: 'op.EmployeeAdvance.RFB', perm: 'Read'}" class="btn btn-default-alt btn-large-icon" [class.disabled-all]="isLoading"
                    afConfirmClick
                    (confirmed)="generateRFB()"
                    message="{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.are you sure you want to execute rfb payment order?' | translate }}"
                    yes="{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.payment order' | translate }}"
                    no="{{ 'confirm.cancel.cancel' | translate }}">
                <i class="fa fa-file-code"></i><span>{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.payment order' | translate }}</span>
            </button>
            <a *ngIf="rfbfile" class="btn btn-primary" [href]="rfbUrl + rfbfile.fileName"><i class="fa fa-download"></i></a>
        </ng-container>
    </div>
    <div class="col-md-9">
        <div class="filter-group float-end">
            <button *ngIf="isApprovingRole"
                    class="btn btn-danger btn-secondary btn-sm " type="button" (click)="unapproveFiltered()">
                {{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.unapprove all' | translate }} <i class="fa fa-square" style="padding-left:7px"></i>
            </button>
            <button *ngIf="isApprovingRole" class="btn btn-update btn-secondary btn-sm " type="button" (click)="approveFiltered()">
                {{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.approve all' | translate }} <i class="fa fa-check" style="padding-left:7px"></i>
            </button>
        </div>
    </div>
</div>




<af-table #afTable [value]="model" [loading]="isLoading" [params]="queryParams" [sortBy]="sortBy" (load)="loadData()">

    <af-column field="employeeAdvanceId" sortable="employeeAdvanceId" header="{{ 'hollen.operation.employeeadvancelistviewmodel.id of employee advance' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a [routerLink]="['/operation/employeeAdvance', item.employeeAdvanceId]">{{item.employeeAdvanceId}}</a>
        </ng-template>
    </af-column>
    <af-column field="employeeFullName" sortable="employeeFullName" header="{{ 'hollen.operation.employeeadvancelistviewmodel.employee' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a [routerLink]="['/operation/employee', item.employeeId]">{{item.employeeFullName}}</a>
        </ng-template>
    </af-column>

    <af-column field="description" header="{{ 'hollen.operation.employeeadvancelistviewmodel.description' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.description}}
        </ng-template>
    </af-column>

    <af-column field="amount" header="{{ 'hollen.operation.employeeadvancelistviewmodel.amount' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.amount| afCurrency: 'CZK' : '.2-9'}}
        </ng-template>
    </af-column>

    <af-column field="projectTaskNumber" sortable="projectTaskNumber" header="{{ 'hollen.operation.employeeadvancelistviewmodel.project task number' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a *ngIf="item.projectTaskId" [routerLink]="['/operation/projectTask', item.projectTaskId]">{{item.projectTaskNumber}}</a>
        </ng-template>
    </af-column>

    <af-column field="validityMonth" sortable="validityMonth" header="{{ 'hollen.operation.employeeadvancelistviewmodel.validity month' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.validityMonth | afDate:'MM/YYYY' }}
        </ng-template>
    </af-column>

    <af-column field="note" header="{{ 'hollen.operation.employeeadvancelistviewmodel.note' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.note}}
        </ng-template>
    </af-column>

    <af-column field="approvedAt" sortable="approvedAt" header="{{ 'hollen.operation.employeeadvancelistviewmodel.approved' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <ng-container *ngIf="item.approvedAt">
                <i class="fa fa-check" style="padding-left:7px;color:green;"></i>
            </ng-container>
            <ng-container *ngIf="!item.approvedAt">
                <i class="fa fa-close" style="padding-left:7px;color:red;"></i>
            </ng-container>
            {{item.approvedAt | afDate:'DD/MM/YYYY'}}
        </ng-template>
    </af-column>

    <af-column field="paymentDay" sortable="paymentDay" header="{{ 'hollen.operation.employeeadvancelistviewmodel.day of payment' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <ng-container *ngIf="item.paymentDay">              
                <i class="fa fa-check" style="padding-left:7px;color:green;"></i>          
                {{item.paymentDay | afDate:'DD/MM/YYYY'}}
            </ng-container>
            <ng-container *ngIf="!item.paymentDay">
                <i class="fa fa-close" style="padding-left:7px;color:red;"></i>
            </ng-container>
        </ng-template>
    </af-column>

    <af-column field="paymentType" sortable="paymentType" header="{{ 'hollen.operation.employeeadvancelistviewmodel.payment type' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <ng-container *ngIf="filter && filter.paymentTypes">
              {{filter.paymentTypes[item.paymentType-1].value}}
            </ng-container>
        </ng-template>
    </af-column>

    <af-column field="action" header="{{ 'hollen.clientapp.src.app.operation.employee-advance.employee-advance-list.component.action' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <button *ngIf="!item.approvedAt" class="btn btn-sm btn-default"
                    [routerLink]="['/operation/employeeAdvance', item.employeeAdvanceId]"
                    type="button">
                {{ 'btn.edit' | translate }}
            </button>
            <button *ngIf="!item.approvedAt" class="btn btn-sm btn btn-default" (click)="onApprove(item)" type="button" [disabled]="!canApprove">{{ 'btn.approve' | translate }}</button>
            <button *ngIf="item.approvedAt && !item.paymentDay" class="btn btn-sm btn btn-default" (click)="onUnapprove(item)" type="button">{{ 'btn.unapprove' | translate }}</button>
            <button *ngIf="filter && item.approvedAt && !item.paymentDay" class="btn btn-sm btn-default" (click)="onPay(item,item.paymentType)" type="button">{{filter.paymentTypes[item.paymentType-1].value}}</button>

        </ng-template>
    </af-column>

    <af-column field="wasRFBGenerated" header="{{ 'hollen.operation.employeeadvancelistviewmodel.generated' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <ng-container *ngIf="item.wasRFBGenerated">
                <i class="fa fa-check" style="padding-left:7px;color:green;"></i>
            </ng-container>
            <ng-container *ngIf="!item.wasRFBGenerated">
                <i class="fa fa-close" style="padding-left:7px;color:red;"></i>
            </ng-container>
        </ng-template>
    </af-column>

</af-table>
