import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class SmsService
    extends HttpService<app.operation.SmsListViewModel, app.operation.SmsFormViewModel, app.operation.SmsInsertUpdateViewModel>
{
    objectKey = "op.Sms";

    constructor(http: HttpClient) {
        super(http, "/api/operation/sms/");
    }

    getEmployeesForAccountCenters(accountCenters: string[], employeeContractTypes: number[]): Observable<app.operation.EmployeeSmsSelectListViewModel[]> {
        let params = new HttpParams();
        if (accountCenters && accountCenters.length > 0) {
            params = params.set("accountCenters", accountCenters.join(";"));
        }
        if (employeeContractTypes && employeeContractTypes.length > 0) {
            params = params.set("employeeContractTypes", employeeContractTypes.join(";"));
        }
        return this.http.get<app.operation.EmployeeSmsSelectListViewModel[]>(this.url + "employees", {
            params
        });
    }

    send(item: app.operation.SmsInsertUpdateViewModel): Observable<string> {
        return this.http.post(this.url, item, { responseType: "text" });
    }

    insertTemplate(text: string) {
        return this.http.post<app.operation.SmsTemplateListViewModel>(this.url + "template", { text });
    }

    deleteTemplate(id: number) {
        return this.http.delete(this.url + "template/" + id);
    }

    getFilter(): Observable<app.operation.SmsListFilterViewModel> {
        return this.http.get<app.operation.SmsListFilterViewModel>(this.url + "filter");
    }

    checkStatus(id: number): Observable<string> {
        return this.http.get(this.url + id + "/checkstatus", { responseType: "text" });
    }

    getListByRequest(request?: app.operation.EmployeeSelectListRequest): Observable<app.operation.EmployeeSelectListViewModel[]> {
        
        return this.http.get<app.operation.EmployeeSelectListViewModel[]>("/api/operation/employee/list", {
            params: this.getParameters(request) 
        });
    }

    getWorkerList(page?: number, pageSize?: number, searchTerm?: string): Observable<app.operation.EmployeeSelectListViewModel[]> {
        return this.getListByRequest(<app.operation.EmployeeSelectListRequest>{
            page,
            pageSize,
            searchTerm
        });
    }
}
