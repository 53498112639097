import { Component, ViewChild, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import { ProjectTaskService } from "./project-task.service";
import { AppService } from "../../app.service";
import { FileUploadOptions } from "../../shared/components/file-upload/file-upload.service";
import { ReportType } from "../report/report-project-task.service";

@Component({
    selector: "[opProjectTaskDocuments]",
    templateUrl: "./project-task-documents.component.html"
})
export class ProjectTaskDocumentsComponent implements OnChanges {
    @Input() projectTaskId: number;
    @Input() languageId: string;
    @Input() selected = ReportType.None;

    objectKey: string;
    bozpUrl: string;
    reportData: app.operation.ProjectTaskReportViewModel;
    orderLanguage: string;
    workingProcedureTemplate: string;
    workingProcedureSupplementTemplate: string;
    workingProcedureSecondPage = false;
    currentUser: app.UserViewModel;
    bozpOptions: FileUploadOptions;
    workingProcedureOptions: FileUploadOptions;

    constructor(protected router: Router, protected route: ActivatedRoute,
        private projectTaskService: ProjectTaskService,
        private appService: AppService) {
        this.currentUser = appService.homeViewModel.user;
        this.objectKey = projectTaskService.objectKey;
        this.orderLanguage = this.currentUser.language;
    }

    ngOnInit() {
        this.bozpUrl = this.projectTaskService.getBozpUrl(this.projectTaskId);
        this.bozpOptions = this.projectTaskService.getBozpUploadOptions(this.projectTaskId);
        this.workingProcedureOptions = this.projectTaskService.getWorkingProcedureUploadOptions(this.projectTaskId);

        this.loadData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["languageId"] && !changes["languageId"].firstChange) {
            this.loadData();
        }
    }

    loadData() {
        this.projectTaskService.getReportData(this.projectTaskId, this.languageId).subscribe(res => {
            this.reportData = res;
            this.workingProcedureSecondPage = res.isSecondPageVisible;
            this.workingProcedureTemplate = res.workingProcedureTemplates.length > 0 ? res.workingProcedureTemplates[0] : null;
            this.workingProcedureSupplementTemplate = res.workingProcedureSupplementTemplates.length > 0 ? res.workingProcedureSupplementTemplates[0] : null;
        });
    }

    onSuccessUpload(data: any): void {
        this.loadData();
    }

    getOrderUrl() {
        return this.projectTaskService.getOrderUrl(this.projectTaskId, this.orderLanguage);
    }

    getWorkingProcedureTemplateUrl() {
        return this.projectTaskService.getWorkingProcedureTemplateUrl(this.workingProcedureTemplate);
    }

    getWorkingProcedureUrl() {
        return this.projectTaskService.getWorkingProcedureUrl(this.projectTaskId, this.languageId, this.workingProcedureTemplate, this.workingProcedureSecondPage);
    }

    getWorkingProcedureSupplementUrl() {
        return this.projectTaskService.getWorkingProcedureSupplementUrl(this.projectTaskId, this.workingProcedureSupplementTemplate);
    }

    isVisible(type: ReportType) {
        return (this.selected === ReportType.None || this.selected === type);
    }
}
