import { Directive, forwardRef, Attribute } from "@angular/core";
import { Validator, AbstractControl, NG_VALIDATORS } from "@angular/forms";

@Directive({
    selector: "[validateEqual][formControlName],[validateEqual][formControl],[validateEqual][ngModel]",
    providers: [
        { provide: NG_VALIDATORS, useExisting: EqualValidatorDirective, multi: true }
    ]
})
export class EqualValidatorDirective implements Validator {
    // tslint:disable-next-line:no-attribute-parameter-decorator
    constructor( @Attribute("validateEqual") public validateEqual: string) {
    }

    validate(c: AbstractControl): { [key: string]: any } {
        // self value
        let v = c.value;

        // control vlaue
        let e = c.root.get(this.validateEqual);

        // value not equal
        if (e && v !== e.value) {
            return {
                validateEqual: true
            };
        }

        return null;
    }
}
