import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { getUnixDate } from "../../core/utils";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class ProductRateService
    extends HttpService<app.finance.ProductRateListViewModel, app.finance.ProductRateFormViewModel, app.finance.ProductRateInsertUpdateViewModel>
{
    objectKey = "fin.ProductRate";

    constructor(http: HttpClient) {
        super(http, "/api/finance/productrate/");
    }

    getList(projectId?: number, projectTaskId?: number, workPlaceId?: number,
        startDate?: Date, companyId?: number, accountCenterCode?: string, languageId?: string,employeeId?:number): Observable<app.finance.ProductRateViewModel[]> {
        let params = new HttpParams();

        if (projectId) {
            params = params.set("projectId", projectId.toString());
        }
        if (projectTaskId) {
            params = params.set("projectTaskId", projectTaskId.toString());
        }
        if (workPlaceId) {
            params = params.set("workPlaceId", workPlaceId.toString());
        }
        if (startDate) {
            params = params.set("startDate", getUnixDate(startDate).toString());
        }
        if (companyId) {
            params = params.set("companyId", companyId.toString());
        }
        if (employeeId) {
            params = params.set("employeeId", employeeId.toString());
        }
        if (accountCenterCode) {
            params = params.set("accountCenterCode", accountCenterCode);
        }
        if (languageId) {
            params = params.set("languageId", languageId);
        }

        return this.http.get<app.finance.ProductRateViewModel[]>(this.url + "list", { params });
    }

    getInvoiceTextSelectList(searchTerm: string, languageId: string): Observable<string[]> {
        let params = new HttpParams();
        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        if (languageId) {
            params = params.set("languageId", languageId);
        }

        return this.http.get<string[]>(this.url + "invoicetext", { params });
    }

    setIsArchived(id: number, isArchived: boolean) {
        return this.http.put<app.finance.ProductRateInsertUpdateViewModel>(this.url + id + "/isarchived/" + isArchived, null);
    }

    setIsApproved(id: number, isApproved: boolean) {
        return this.http.put<app.finance.ProductRateInsertUpdateViewModel>(this.url + id + "/isapproved/" + isApproved, null);
    }

    getCurrencies() {
        return this.http.get<string[]>(this.url + "currencies");
    }

    fileUrls(parameters?: any) {
        return this.http.get<app.finance.ProductRateUpdateFileUrlViewModel[]>(this.url + "fileurls", {
            params: this.getParameters(parameters)
        });
    }

    getHistory(page: number, pageSize: number, id: number): Observable<af.sys.EntityHistoryViewModel[]> {
        let params = new HttpParams();
        params = params.set("page", page.toString());
        params = params.set("pageSize", pageSize.toString());
        return this.http.get<af.sys.EntityHistoryViewModel[]>(this.url + id + "/history", { params });
    }
}
