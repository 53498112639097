import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "../../../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class SettingsRentService
    extends HttpService<app.operation.EmployeeB2bSettingsRentListViewModel, app.operation.EmployeeB2bSettingsRentFormViewModel, app.operation.EmployeeB2bSettingsRentInsertUpdateViewModel>
{
    objectKey = "op.EmployeeB2bSettingsRent";

    constructor(http: HttpClient) {
        super(http, "/api/operation/employeeb2bsettingsrent/");
    }   

    receiveFromProvider(item: app.operation.EmployeeB2bSettingsRentInsertUpdateViewModel, id: number) {      
        return this.http.put(this.url + "receive/" + id, item);
    }
}
