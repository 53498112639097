<div class="card" *ngIf="request">
    <div class="card-header">
        Request detail<a class="float-end" (click)="request = null"><i class="fa fa-close"></i></a>
    </div>
    <div class="card-block">
        <div class="form-horizontal">
            <div class="form-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2">Date</label>
                            <div class="col-md-10">
                                <span class="form-control-plaintext">{{ request.createdUtc | afDate: 'DD.MM.YYYY HH:mm:ss.SSSS':true }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2">Controller</label>
                            <div class="col-md-10">
                                <span class="form-control-plaintext">{{ request.controller }}</span>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2">Url</label>
                            <div class="col-md-10">
                                <span class="form-control-plaintext">{{ request.url }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2">Username</label>
                            <div class="col-md-10">
                                <span class="form-control-plaintext"><b>{{ request.username }}</b></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2">Request Method</label>
                            <div class="col-md-10">
                                <span class="form-control-plaintext"><b>{{ request.requestMethod }}</b></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2">Request Header</label>
                            <div class="col-md-10">
                                <a class="fa fa-plus-square" *ngIf="!showHeaders" (click)="showHeaders = true"></a>
                                <span class="form-control-plaintext" *ngIf="showHeaders">{{ request.requestHeader }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="col-md-11 offset-md-1">
                                <pre *ngIf="request.actionArguments" style="word-break: break-word; white-space: pre-wrap;">{{ getJson(request.actionArguments) | json }}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
