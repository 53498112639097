import { Injectable, EventEmitter } from "@angular/core";

import { AppService } from "../../../app.service";
import { ToastService } from "../../../core/services";
import { StorageService } from "../../../auth/storage.service";

export class ProgressInfo {
    loaded = 0;
    total = 0;
    percent = 0;
    speed = 0;
    speedHumanized: string;
}

export class UploadedFile {
    id: string;
    status: number;
    statusText: string;
    progress: ProgressInfo;
    originalName: string;
    size: number;
    response: string;
    done: boolean;
    success: boolean;
    error: boolean;
    abort: boolean;
    startTime: number;
    endTime: number;
    speedAverage: number;
    speedAverageHumanized: string;

    constructor(id: string, originalName: string, size: number) {
        this.id = id;
        this.originalName = originalName;
        this.size = size;
        this.progress = new ProgressInfo();
        this.done = false;
        this.success = false;
        this.error = false;
        this.abort = false;
        this.startTime = new Date().getTime();
        this.endTime = 0;
        this.speedAverage = 0;
        this.speedAverageHumanized = null;
    }

    setProgres(progress: ProgressInfo): void {
        this.progress = progress;
    }

    setError(): void {
        this.error = true;
        this.done = true;
    }

    setAbort(): void {
        this.abort = true;
        this.done = true;
    }

    onFinished(status: number, statusText: string, response: string): void {
        this.endTime = new Date().getTime();
        this.speedAverage = this.size / (this.endTime - this.startTime) * 1000;
        this.speedAverage = parseInt(this.speedAverage as any, 10);
        this.speedAverageHumanized = humanizeBytes(this.speedAverage);
        this.status = status;
        this.statusText = statusText;
        this.response = response;
        this.done = true;
        this.success = this.status < 300;
    }
}

export class FileUploadOptions {
    url: string;
    cors = false;
    withCredentials = false;
    multiple = false;
    maxUploads = 3;
    data: { [index: string]: any } = {};
    autoUpload = true;
    multipart = true;
    method = "POST";
    debug = false;
    customHeaders: any = {};
    encodeHeaders = true;
    authTokenPrefix = "Bearer";
    authToken: string = undefined;
    fieldName = "file";
    fieldReset = true;
    previewUrl = false;
    calculateSpeed = false;
    showToast = true;

    constructor(url: string = null, data: { [index: string]: any } = {}) {
        this.url = url;
        this.data = data || {};
    }
}

@Injectable()
export class FileUploadService {
    options = new FileUploadOptions();
    _queue: any[] = [];
    _emitter: EventEmitter<any> = new EventEmitter();
    _previewEmitter: EventEmitter<any> = new EventEmitter();

    constructor(private appService: AppService, private storageService: StorageService, private toastService: ToastService) {
    }

    setOptions(options: FileUploadOptions): void {
        this.options.url = options.url != null ? options.url : this.options.url;
        this.options.cors = options.cors != null ? options.cors : this.options.cors;
        this.options.withCredentials = options.withCredentials != null ? options.withCredentials : this.options.withCredentials;
        this.options.multiple = options.multiple != null ? options.multiple : this.options.multiple;
        this.options.maxUploads = options.maxUploads != null ? options.maxUploads : this.options.maxUploads;
        this.options.data = options.data != null ? options.data : this.options.data;
        this.options.autoUpload = options.autoUpload != null ? options.autoUpload : this.options.autoUpload;
        this.options.multipart = options.multipart != null ? options.multipart : this.options.multipart;
        this.options.method = options.method != null ? options.method : this.options.method;
        this.options.customHeaders = options.customHeaders != null ? options.customHeaders : this.options.customHeaders;
        this.options.encodeHeaders = options.encodeHeaders != null ? options.encodeHeaders : this.options.encodeHeaders;
        this.options.authTokenPrefix = options.authTokenPrefix != null ? options.authTokenPrefix : this.options.authTokenPrefix;
        this.options.authToken = options.authToken != null ? options.authToken : this.options.authToken;
        this.options.fieldName = options.fieldName != null ? options.fieldName : this.options.fieldName;
        this.options.fieldReset = options.fieldReset != null ? options.fieldReset : this.options.fieldReset;
        this.options.previewUrl = options.previewUrl != null ? options.previewUrl : this.options.previewUrl;
        this.options.calculateSpeed = options.calculateSpeed != null ? options.calculateSpeed : this.options.calculateSpeed;
        this.options.showToast = options.showToast != null ? options.showToast : this.options.showToast;
        if (!this.options.multiple) {
            this.options.maxUploads = 1;
        }
    }

    uploadFilesInQueue(): void {
        let newFiles = this._queue.filter((f) => !f.uploading);
        newFiles.forEach((f) => {
            this.uploadFile(f).then(() => { }).catch(() => {
                console.debug("File upload rejected");
            });
        });
    }

    uploadFile(file: any, extraOptions?: any): Promise<UploadedFile> {
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            let form = new FormData();
            let options = Object.assign({}, this.options, extraOptions);

            form.append(options.fieldName, file, file.name);

            Object.keys(options.data).forEach(k => {
                form.append(k, options.data[k]);
            });

            let uploadingFile = new UploadedFile(
                this.generateRandomIndex(),
                file.name,
                file.size
            );

            let queueIndex = this._queue.indexOf(file);

            let time: number = new Date().getTime();
            let load = 0;
            let speed = 0;
            let speedHumanized: string = null;

            xhr.upload.onprogress = (e: ProgressEvent) => {
                if (e.lengthComputable) {
                    if (options.calculateSpeed) {
                        time = new Date().getTime() - time;
                        load = e.loaded - load;
                        speed = load / time * 1000;
                        speed = parseInt(speed as any, 10);
                        speedHumanized = humanizeBytes(speed);
                    }

                    let percent = Math.round(e.loaded / e.total * 100);
                    let progress = new ProgressInfo();
                    progress.total = e.total;
                    progress.loaded = e.loaded;
                    progress.percent = percent;

                    if (speed !== 0) {
                        progress.speed = speed;
                        progress.speedHumanized = speedHumanized;
                    }
                    uploadingFile.setProgres(progress);
                    this._emitter.emit(uploadingFile);
                }
            };

            xhr.upload.onabort = (e: Event) => {
                uploadingFile.setAbort();
                this._emitter.emit(uploadingFile);
                reject(uploadingFile);
            };

            xhr.upload.onerror = (e: Event) => {
                uploadingFile.setError();
                this._emitter.emit(uploadingFile);
                reject(uploadingFile);
            };

            xhr.onreadystatechange = () => {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    uploadingFile.onFinished(
                        xhr.status,
                        xhr.statusText,
                        xhr.response
                    );

                    let wasRejected = false;

                    if (xhr.status >= 300) {
                        if (xhr.status === 500) {
                            if (xhr.responseText && this.appService.isDebugEnabled()) {
                                console.log(xhr.responseText);

                                let win = window.open();
                                if (win && win.document) {
                                    win.document.open();
                                    win.document.write(xhr.responseText);
                                    win.document.close();
                                }
                            } else {
                                this.toastService.error("Server Error");
                            }
                        } else if (xhr.status === 400 && xhr.responseText) {
                            try {
                                let result = JSON.parse(xhr.responseText);
                                if (result["modelErrors"] && result["modelErrors"].length > 0) {
                                    for (let i = 0; i < result["modelErrors"].length; i++) {
                                        let modelError = result["modelErrors"][i];
                                        this.toastService.error(modelError.errorMessage);
                                    }
                                }
                                else if (result["message"]) {
                                    this.toastService.error(result["message"]);
                                }
                            } catch (e) {
                                this.toastService.error(xhr.responseText || "Server Error");
                            }
                        } else {
                            this.toastService.error(xhr.responseText || "Server Error");
                        }

                        reject(uploadingFile);
                        wasRejected = true;
                    } else if (xhr.status === 201) {                  
                        if (this.options.showToast) {
                            this.toastService.success("OK!");
                        }
                        if (xhr.responseText) {
                            try {
                                let result = JSON.parse(xhr.responseText);
                                if (result["openUrl"]) {
                                    window.open(result["openUrl"]);
                                }
                            } catch (e) { }
                        }
                    }

                    this.removeFileFromQueue(file);
                    this._emitter.emit(uploadingFile);
                    if (!wasRejected) {
                        resolve(uploadingFile);
                    }
                }
            };

            xhr.open(options.method, options.url, true);
            xhr.withCredentials = options.withCredentials;

            if (options.customHeaders) {
                Object.keys(options.customHeaders).forEach((key) => {
                    xhr.setRequestHeader(key, options.customHeaders[key]);
                });
            }

            if (!options.authToken) {
                options.authToken = this.storageService.getToken();
            }

            if (options.authToken) {
                xhr.setRequestHeader("Authorization", `${options.authTokenPrefix} ${options.authToken}`);
            }

            xhr.send(form);
        });
    }

    addFilesToQueue(files: File[]): void {
        files.forEach((file: File, i: number) => {
            if (this.isFile(file) && !this.inQueue(file)) {
                this._queue.push(file);
            }
        });

        if (this.options.previewUrl) {
            files.forEach(file => this.createFileUrl(file));
        }

        if (this.options.autoUpload) {
            this.uploadFilesInQueue();
        }
    }

    createFileUrl(file: File) {
        let reader: FileReader = new FileReader();
        reader.addEventListener("load", () => {
            this._previewEmitter.emit(reader.result);
        });
        reader.readAsDataURL(file);
    }

    removeFileFromQueue(file: File): void {
        let queueIndex = this._queue.indexOf(file);
        this._queue.splice(queueIndex, 1);
    }

    clearQueue(): void {
        this._queue = [];
    }

    getQueueSize(): number {
        return this._queue.length;
    }

    inQueue(file: any): boolean {
        let fileInQueue = this._queue.filter((f) => f === file);
        return fileInQueue.length ? true : false;
    }

    isFile(file: any): boolean {
        return file !== null && (file instanceof Blob || (file.name && file.size));
    }

    generateRandomIndex(): string {
        return Math.random().toString(36).substring(7);
    }
}

export class UploadRejected {
    public static get EXTENSION_NOT_ALLOWED(): string { return "ExtensionNotAllowed"; }
    public static get MAX_SIZE_EXCEEDED(): string { return "MaxSizeExceeded"; }

    file: any;
    reason: string;
}

function humanizeBytes(bytes: number): string {
    if (bytes === 0) {
        return "0 Byte";
    }
    let k = 1024;
    const sizes: string[] = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
    let i: number = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i] + "/s";
}
