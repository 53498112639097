
import { filter } from "rxjs/operators";
import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { Store } from "@ngrx/store";

import { State } from "../../../store/reducers";
import { MenuService } from "../../../core/services/menu.service";
import { MenuItem } from "../../../models/menu-item";

@Component({
  selector: "af-breadcrumb",
  styleUrls: ["./breadcrumb.component.scss"],
  template: `<ul class="page-breadcrumb" *ngIf="breadcrumbs.length > 0">
            <li style="margin-right: 3px;">
                <a [routerLink]="home"><i class="fa fa-home"></i></a>
            </li>
            <li *ngFor="let crumb of breadcrumbs, let i = index" [class.active]="isLast(i)">
                <span *ngIf="!isLast(i) && !crumb.url" (click)="open(crumb)">{{ crumb.title }}</span>
                <a [routerLink]="crumb.url" *ngIf="!isLast(i) && crumb.url"> {{ crumb.title }} </a>
                <i class="fa fa-angle-right breadcrumb-separator" *ngIf="!isLast(i)"></i>
                <span *ngIf="isLast(i)" (click)="open(crumb)">{{ crumb.title }}</span>
            </li>
        </ul>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  @Input() home: string;

  breadcrumbs = [];
  sub: Subscription;

  constructor(private menuService: MenuService, private store: Store<State>, private changeDetectorRef: ChangeDetectorRef) { }

  isLast(index: number): boolean {
    return this.breadcrumbs.length - 1 === index;
  }

  ngOnInit() {
    this.sub = this.store
      .select(x => x.menu.menu).pipe(
        filter(loaded => !!loaded))
      .subscribe(() => {
        this.updateBreadcrumbs();
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  open(crumb: any) {
    let item = this.menuService.getItem(crumb.id);
    if (!item.isOpen) {
      this.menuService.toggleOpen(item);
    }
  }

  updateBreadcrumbs() {
    let crumbs = [];
    let item = this.menuService.getActiveItem();
    let params = this.menuService.getCurrentParams();

    while (item && item.parentId) {
      let crumb = {
        id: item.id,
        title: item.title,
        url: null
      };

      crumb.url = this.menuService.getUrl(item, params);

      if (item.type !== "None" || item.children.length === 0) {
        crumbs.push(crumb);
      }

      item = this.menuService.getItem(item.parentId);
    }

    if (crumbs.length > 1) {
      crumbs.reverse();
      this.breadcrumbs = crumbs;
    } else {
      this.breadcrumbs = crumbs;
    }
  }
}
