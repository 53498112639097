import { Injectable } from "@angular/core";
import { MissingTranslationHandler, TranslateLoader, MissingTranslationHandlerParams, TranslateParser, TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";

@Injectable()
export class TextParser implements TranslateParser {
    stringFormatTemplateMatcher: RegExp = /{(\d+)}/g;

    constructor(private translateService: TranslateService) {
    }

    public interpolate(expr: string, params?: any): string {
        if (typeof expr !== "string" || !params) {
            return expr;
        }

        return expr.replace(this.stringFormatTemplateMatcher, (substring: string, b: string) => {
            let r = this.getValue(params, b);
            return typeof r !== "undefined" ? r : substring;
        });
    }

    public getValue(target: any, key: string): string {
        let keys = key.split(".");
        key = "";
        do {
            key += keys.shift();
            if (target !== undefined && target[key] !== undefined && (typeof target[key] === "object" || !keys.length)) {
                target = target[key];
                key = "";
            } else if (!keys.length) {
                target = undefined;
            } else {
                key += ".";
            }
        } while (keys.length);

        if (target && target.indexOf("translate|") === 0) {
            this.translateService.get(target.substring("translate|".length)).subscribe(value => target = value);
        }

        return target;
    }

}
