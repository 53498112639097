<div *ngIf="!employeeId">
    <af-page-bar title="{{ 'hollen.clientapp.src.app.operation.employee-relationship.employee-relationship-create.component.new relationship' | translate }} "></af-page-bar>
</div>
<div *ngIf="employeeId">
    <span style="font-size: 28px;font-weight: 300;color: #666;font-family: 'Open Sans',sans-serif;">{{ 'hollen.clientapp.src.app.operation.employee-relationship.employee-relationship-create.component.new relationship' | translate }} </span>
</div>
<div class="form">
    <form (ngSubmit)="insert()" name="createEmployeeRelationshipForm" novalidate="" #createEmployeeRelationshipForm="ngForm" [class.disabled-all]="isLoading">
        <div class="form-horizontal">
            <div class="form-body">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="EmployeeContractTypeId">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.contract type' | translate }}</label>
                            <div class="col-md-10">
                                <select class="form-control form-select" [ngModel]="employeeRelationship.employeeContractTypeId" (ngModelChange)="onContractTyperChange($event)" [required] id="EmployeeContractTypeId" name="EmployeeContractTypeId" required="">
                                    <option *ngFor="let contractType of employeeContractTypes" [ngValue]="contractType.id">{{contractType.title}}</option>
                                </select>
                                <af-help helpid="Hollen.Operation.EmployeeRelationshipInsertUpdateViewModel.EmployeeContractTypeId"></af-help>
                                <span><span *ngIf="(createEmployeeRelationshipForm.controls['EmployeeContractTypeId']?.dirty || createEmployeeRelationshipForm.submitted) && createEmployeeRelationshipForm.controls['EmployeeContractTypeId']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.contract type'} }}</span>
</span>
                            </div>
                            <label class="control-label col-md-2" for="RelationshipOrder">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.relationship n.' | translate }}</label>
                        </div>

                    </div>
                    <ng-container *ngIf="employeeRelationship.employeeContractTypeId == contractTypes.tpp && employeeRelationship.isIslandCodeRequired">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label col-md-2" for="IslandCode">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.island code' | translate }}</label>
                                <div class="col-md-10 col-fixed-md">
                                    <select class="form-control form-select" [required]="employeeRelationship.employeeContractTypeId == contractTypes.tpp && employeeRelationship.isIslandCodeRequired" [(ngModel)]="employeeRelationship.islandCode" id="IslandCode" maxlength="6" name="IslandCode">
                                        <option [ngValue]="null"></option>
                                        <option *ngFor="let island of islands" [ngValue]="island.code">{{island.title}}</option>
                                    </select>
                                    <af-help helpid="Hollen.Operation.EmployeeRelationshipInsertUpdateViewModel.IslandCode"></af-help>
                                    <span><span *ngIf="(createEmployeeRelationshipForm.controls['IslandCode']?.dirty || createEmployeeRelationshipForm.submitted) && createEmployeeRelationshipForm.controls['IslandCode']?.hasError('maxlength')" class="field-validation-error">{{ 'validator.the field {0} must be a string or array type with a maximum  length of \'{1}\'.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.island code', '1': '6'} }}</span>
</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="StartDate">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.cooperation start date' | translate }}</label>
                            <div class="col-md-4">
                                <div class="fixed-datepicker">
                                    <af-datepicker [ngModel]="employeeRelationship.startDate" (ngModelChange)="onStartDateChange($event)" name="StartDate" required></af-datepicker>
                                    <af-help helpid="Hollen.Operation.EmployeeRelationshipInsertUpdateViewModel.StartDate"></af-help>
                                </div>
                                <span><span *ngIf="(createEmployeeRelationshipForm.controls['StartDate']?.dirty || createEmployeeRelationshipForm.submitted) && createEmployeeRelationshipForm.controls['StartDate']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.cooperation start date'} }}</span>
<span *ngIf="(createEmployeeRelationshipForm.controls['StartDate']?.dirty || createEmployeeRelationshipForm.submitted) && createEmployeeRelationshipForm.controls['StartDate']?.hasError('date')" class="field-validation-error">{{ 'validator.{0} is not valid date format.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.cooperation start date'} }}</span>
<span *ngIf="(createEmployeeRelationshipForm.controls['StartDate']?.dirty || createEmployeeRelationshipForm.submitted) && createEmployeeRelationshipForm.controls['StartDate']?.hasError('daterange')" class="field-validation-error">{{ 'validator.{0} is out of its valid range.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.cooperation start date'} }}</span>
</span>
                            </div>
                            <label class="control-label col-md-2" for="EndDate">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.cooperation end date' | translate }}</label>
                            <div class="col-md-4">
                                <div class="fixed-datepicker">
                                    <af-datepicker [(ngModel)]="employeeRelationship.endDate" name="EndDate"></af-datepicker>
                                    <af-help helpid="Hollen.Operation.EmployeeRelationshipInsertUpdateViewModel.EndDate"></af-help>
                                </div>
                                <span><span *ngIf="(createEmployeeRelationshipForm.controls['EndDate']?.dirty || createEmployeeRelationshipForm.submitted) && createEmployeeRelationshipForm.controls['EndDate']?.hasError('date')" class="field-validation-error">{{ 'validator.{0} is not valid date format.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.cooperation end date'} }}</span>
<span *ngIf="(createEmployeeRelationshipForm.controls['EndDate']?.dirty || createEmployeeRelationshipForm.submitted) && createEmployeeRelationshipForm.controls['EndDate']?.hasError('daterange')" class="field-validation-error">{{ 'validator.{0} is out of its valid range.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.cooperation end date'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="AgencyId">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.agency' | translate }}</label>
                            <div class="col-md-8">
                                <select class="form-control form-select" [(ngModel)]="employeeRelationship.agencyId" [disabled]="contractTypes && !(employeeRelationship.employeeContractTypeId == contractTypes.agency || employeeRelationship.employeeContractTypeId == contractTypes.assignedemployee)" id="AgencyId" name="AgencyId">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let agency of agencies" [ngValue]="agency.id">{{agency.title}}</option>
                                </select>
                                <af-help helpid="Hollen.Operation.EmployeeInsertUpdateViewModel.AgencyId"></af-help>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="MajorProviderId">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.major provider' | translate }}</label>
                            <div class="col-md-8">
                                <select class="form-control form-select" [ngModel]="employeeRelationship.majorProviderId" (ngModelChange)="onMajorProviderChange($event)" [required]="employeeRelationship.employeeContractTypeId == contractTypes.majorprovider" [disabled]="contractTypes && employeeRelationship.employeeContractTypeId != contractTypes.majorprovider" id="MajorProviderId" name="MajorProviderId">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let provider of majorProviders" [ngValue]="provider.id">{{provider.title}}</option>
                                </select>
                                <af-help helpid="Hollen.Operation.EmployeeInsertUpdateViewModel.EmployeeMajorProviderId"></af-help>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="BranchHollenCompanyCode">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.hollen company' | translate }}</label>
                            <div class="col-md-8">
                                <select class="form-control form-select" [ngModel]="employeeRelationship.branchHollenCompanyCode" (ngModelChange)="onHollenCompanyCodeChange($event)" id="BranchHollenCompanyCode" maxlength="3" name="BranchHollenCompanyCode" required="">
                                    <option *ngFor="let hollenCompany of hollenCompanies" [ngValue]="hollenCompany.code">{{hollenCompany.title}}</option>
                                </select>
                                <af-help helpid="Hollen.Operation.EmployeeRelationshipInsertUpdateViewModel.BranchHollenCompanyCode"></af-help>
                                <span><span *ngIf="(createEmployeeRelationshipForm.controls['BranchHollenCompanyCode']?.dirty || createEmployeeRelationshipForm.submitted) && createEmployeeRelationshipForm.controls['BranchHollenCompanyCode']?.hasError('maxlength')" class="field-validation-error">{{ 'validator.the field {0} must be a string or array type with a maximum  length of \'{1}\'.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.hollen company', '1': '3'} }}</span>
<span *ngIf="(createEmployeeRelationshipForm.controls['BranchHollenCompanyCode']?.dirty || createEmployeeRelationshipForm.submitted) && createEmployeeRelationshipForm.controls['BranchHollenCompanyCode']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.hollen company'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="EmploymentContractExpiryDate">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.employment contract expiration date' | translate }}</label>
                            <div class="col-md-8">
                                <div class="fixed-datepicker">
                                    <af-datepicker [(ngModel)]="employeeRelationship.employmentContractExpiryDate" name="EmploymentContractExpiryDate"></af-datepicker>
                                    <af-help helpid="Hollen.Operation.EmployeeRelationshipInsertUpdateViewModel.EmploymentContractExpiryDate"></af-help>
                                </div>
                                <span><span *ngIf="(createEmployeeRelationshipForm.controls['EmploymentContractExpiryDate']?.dirty || createEmployeeRelationshipForm.submitted) && createEmployeeRelationshipForm.controls['EmploymentContractExpiryDate']?.hasError('date')" class="field-validation-error">{{ 'validator.{0} is not valid date format.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.employment contract expiration date'} }}</span>
<span *ngIf="(createEmployeeRelationshipForm.controls['EmploymentContractExpiryDate']?.dirty || createEmployeeRelationshipForm.submitted) && createEmployeeRelationshipForm.controls['EmploymentContractExpiryDate']?.hasError('daterange')" class="field-validation-error">{{ 'validator.{0} is out of its valid range.' | translate:{'0': 'translate|hollen.operation.employeerelationshipinsertupdateviewmodel.employment contract expiration date'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="EmployeePositionTypeId">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.position' | translate }}</label>
                            <div class="col-md-8">
                                <select class="form-control form-select" [(ngModel)]="employeeRelationship.employeePositionTypeId" id="EmployeePositionTypeId" name="EmployeePositionTypeId">
                                    <option [ngValue]="0"></option>
                                    <option *ngFor="let position of employeePositionTypes" [ngValue]="position.id">{{position.title}}</option>
                                </select>
                                <af-help helpid="Hollen.Operation.EmployeeRelationshipInsertUpdateViewModel.EmployeePositionTypeId"></af-help>
                                <span></span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-md-12">
                                <button type="button"
                                        class="btn btn-default btn-sm float-end"
                                        (click)="addEmployeePositionItem()">
                                    {{ 'hollen.clientapp.src.app.operation.employee-relationship.employee-relationship-create.component.add other position' | translate }}
                                </button>
                            </div>
                        </div>
                        <div class="form-group" *ngFor="let item of employeeRelationship.employeePositionTypeIds; let $index = index; trackBy: trackByIndexFn">
                            <label [for]="'EmployeePositionTypeId' + $index" class="control-label col-md-4">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.other position' | translate }}</label>
                            <div class="col-md-8">
                                <div class="input-group">
                                    <select [name]="'EmployeePositionTypeId' + $index" class="form-control form-select" [ngModel]="item" (ngModelChange)="setEmployeePositionItem($event, $index)">
                                        <option [ngValue]="0"></option>
                                        <option *ngFor="let position of employeePositionTypes" [ngValue]="position.id">{{position.title}}</option>
                                    </select>
                                    <button type="button" class="btn btn-danger btn-sm" (click)="deleteEmployeePositionItem($index)">{{ 'hollen.clientapp.src.app.operation.employee-relationship.employee-relationship-create.component.remove' | translate }}</button>
                                </div>
                                <af-help helpid="Hollen.Operation.EmployeeRelationshipInsertUpdateViewModel.EmployeePositionTypeIds"></af-help>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label col-md-4" for="DirectSupervisorUserId">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.direct supervisor' | translate }}</label>
                            <div class="col-md-8">
                                <select class="form-control form-select" [(ngModel)]="employeeRelationship.directSupervisorUserId" id="DirectSupervisorUserId" name="DirectSupervisorUserId">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let user of users" [ngValue]="user.userId">{{user.fullName}}</option>
                                </select>
                                <af-help helpid="Hollen.Operation.EmployeeRelationshipInsertUpdateViewModel.DirectSupervisorUserId"></af-help>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <ng-container *ngIf="employeeRelationship.employeeContractTypeId == contractTypes.hqs">
                            <div class="form-group">
                                <label class="control-label col-md-4">{{ 'hollen.clientapp.src.app.operation.employee-relationship.employee-relationship-create.component.rate type' | translate }}</label>
                                <div class="col-md-4">
                                    <af-radio [value]="'rateType'" name="RateCategory" [ngModel]="employeeRelationship.rateCategory" (ngModelChange)="onRateCategoryChange($event)">
                                        {{ 'hollen.clientapp.src.app.operation.employee-relationship.employee-relationship-create.component.rate group' | translate }}
                                    </af-radio>
                                </div>
                                <div class="col-md-4">
                                    <af-radio [value]="'b2b'" name="RateCategory" [ngModel]="employeeRelationship.rateCategory" (ngModelChange)="onRateCategoryChange($event)">
                                        {{ 'hollen.clientapp.src.app.operation.employee-relationship.employee-relationship-create.component.flat rate' | translate }}
                                    </af-radio>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="employeeRelationship.employeeContractTypeId == contractTypes.majorprovider">
                            <div class="form-group">
                                <label class="control-label col-md-4" for="CooperantRateId">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.cooperant rate' | translate }}</label>
                                <div class="col-md-8">
                                    <select class="form-control form-select" [(ngModel)]="employeeRelationship.cooperantRateId" [required]="employeeRelationship.employeeContractTypeId == contractTypes.majorprovider" [disabled]="contractTypes && employeeRelationship.employeeContractTypeId != contractTypes.majorprovider" id="CooperantRateId" name="CooperantRateId">
                                        <option [ngValue]="null"></option>
                                        <option *ngFor="let rate of cooperantRates" [ngValue]="rate.id">{{rate.title}}</option>
                                    </select>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-3">
                        <ng-container *ngIf="employeeRelationship.employeeContractTypeId == contractTypes.hqs">
                            <ng-container *ngIf="employeeRelationship.rateCategory === 'rateType'">
                                <div class="form-group">
                                    <label class="control-label col-md-4" for="RateTypeId">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.rate type' | translate }}</label>
                                    <div class="col-md-8">
                                        <select class="form-control form-select" [(ngModel)]="employeeRelationship.rateTypeId" [required]="employeeRelationship.employeeContractTypeId == contractTypes.hqs" [disabled]="contractTypes && employeeRelationship.employeeContractTypeId != contractTypes.hqs" id="RateTypeId" name="RateTypeId">
                                            <option [ngValue]="null"></option>
                                            <option *ngFor="let rate of rateTypes" [ngValue]="rate.id">{{rate.title}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-md-4" for="RateYears">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.rate years' | translate }}</label>
                                    <div class="col-md-8">
                                        <input class="form-control" [(ngModel)]="employeeRelationship.rateYears" [disabled]="contractTypes && employeeRelationship.employeeContractTypeId != contractTypes.hqs" type="number" id="RateYears" name="RateYears" required="" value="" /><input name="__Invariant" type="hidden" value="RateYears" />
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="employeeRelationship.rateCategory === 'b2b'">
                                <div class="form-group">
                                    <label class="control-label col-md-4" for="EmployeeB2bRateId">{{ 'hollen.operation.employeerelationshipinsertupdateviewmodel.flat rate' | translate }}</label>
                                    <div class="col-md-8">
                                        <select class="form-control form-select" [(ngModel)]="employeeRelationship.employeeB2bRateId" [required]="employeeRelationship.employeeContractTypeId == contractTypes.hqs" [disabled]="contractTypes && employeeRelationship.employeeContractTypeId != contractTypes.hqs" id="EmployeeB2bRateId" name="EmployeeB2bRateId">
                                            <option [ngValue]="null"></option>
                                            <option *ngFor="let employeeB2bRate of employeeB2bRates" [ngValue]="employeeB2bRate.employeeB2bRateId">{{employeeB2bRate.title}}</option>
                                        </select>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>

                <div class="form-actions">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="offset-md-1 col-md-11">
                                    <button *afHasPerm="{obj:objectKey, perm: 'Insert'}" class="btn btn-insert btn-primary" type="submit">{{ 'btn.insert' | translate }}</button>
                                    <a class="btn-back btn-warning btn" onclick="history.back()">{{ 'btn.back' | translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
