import { filter } from "rxjs/operators";
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2, Inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";

import { AuthService } from "../../../auth/auth.service";
import { State } from "../../../store/reducers";
import * as layout from "../../../store/actions/layout";
import * as appActions from "../../../store/actions/app";
import { DOCUMENT } from "@angular/common";

@Component({
    selector: "af-top-menu",
    styleUrls: ["./top-menu.component.scss"],
    templateUrl: "./top-menu.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopMenuComponent implements OnInit {
    fullName: string;
    intranetUrl: string;
    isLoading = false;
    isCollapsed$: Observable<boolean>;
    isCollapsed: boolean;
    subscriptions: Subscription[] = [];
    isDarkModeEnabled = false;

    constructor(private store: Store<State>,
        private authService: AuthService,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2) { }

    ngOnInit() {
        this.isLoading = true;
        this.isCollapsed$ = this.store.select(x => x.layout.mobileMenuIsCollapsed);

        this.subscriptions.push(this.store
            .select(x => x.app.theme)
            .subscribe(res => {
                this.isDarkModeEnabled = res === "dark";

                if (this.isDarkModeEnabled) {
                    this.renderer.addClass(this.document.documentElement, "dark-theme");
                    this.renderer.setAttribute(this.document.documentElement, "data-bs-theme", "dark");
                }
                else {
                    this.renderer.removeClass(this.document.documentElement, "dark-theme");
                    this.renderer.setAttribute(this.document.documentElement, "data-bs-theme", "light");
                }
            }));

        this.subscriptions.push(this.isCollapsed$.subscribe(isCollapsed => {
            this.isCollapsed = isCollapsed;
            this.changeDetectorRef.markForCheck();
        }));

        this.subscriptions.push(
            this.store
                .select(x => x.app.homeViewModel).pipe(
                    filter(loaded => !!loaded))
                .subscribe(res => {
                    this.fullName = res.user.fullName;
                    this.intranetUrl = res.intranetUrl;
                    this.isLoading = false;
                    this.changeDetectorRef.markForCheck();
                    this.isLoading = false;
                    this.changeDetectorRef.markForCheck();
                })
        );
    }

    logout() {
        this.authService.logout(true);
    }

    refresh() {
        window.location.reload();
    }

    back() {
        window.history.back();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    toggleMenu() {
        if (this.isCollapsed) {
            this.store.dispatch(new layout.OpenMobileMenuAction());
        }
        else {
            this.store.dispatch(new layout.CloseMobileMenuAction());
        }
    }

    changeTheme() {
        this.store.dispatch(
            new appActions.ThemeChangedAction(this.isDarkModeEnabled ? "light" : "dark")
        );
    }
}
