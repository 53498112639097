import { CodebookService } from "./codebook/codebook.service";
import { ProductService } from "./product/product.service";
import { CommonAnalyticsService } from './common-analytics/common-analytics.service';
import { AccountConverterService } from "./account-converter/account-converter.service";
import { TechnicianChecklistService } from "./technician-checklist/technician-checklist.service";
import { CustomPropertyService } from "./custom-property/custom-property.service";
import { EmployeePositionTypeService } from "./employee-position-type/employee-position-type.service";
import { ReportMetricTemplateService } from "./report-metric-template/report-metric-template.service";

export const providers = [
    CodebookService,
    ProductService,
    CommonAnalyticsService,
    CustomPropertyService,
    AccountConverterService,
    TechnicianChecklistService,
    EmployeePositionTypeService,
    ReportMetricTemplateService
];
