import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class MetriqGroupService
    extends HttpService<app.crm.MetriqGroupListViewModel, app.crm.MetriqGroupFormViewModel, app.crm.MetriqGroupInsertUpdateViewModel>
{
    objectKey = "crm.MetriqGroup";

    constructor(http: HttpClient) {
        super(http, "/api/crm/metriqgroup/");
    }

    getSelectList(request: app.crm.GetMetriqGroupSelectListRequest): Observable<app.crm.GetMetriqGroupSelectListRequest[]> {
        let params = new HttpParams();

        if (request.searchTerm) {
            params = params.set("searchTerm", request.searchTerm);
        }
        if (request.page) {
            params = params.set("page", request.page.toString());
        }
        if (request.pageSize) {
            params = params.set("pageSize", request.pageSize.toString());
        }

        return this.http.get<app.crm.GetMetriqGroupSelectListRequest[]>(this.url + "list", { params });
    }
}
