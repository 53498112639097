import { Observable, of } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { ShiftPlanService } from "./shift-plan.service";
import { TimeObject } from "./../../models/time-object.model";
import { TimeService } from "./../../core/services/time.service";
import { WorkReportSliderFormatter } from "./../work-report/work-report-slider-formatter";
import { ProjectTaskService } from "./../project-task/project-task.service";
import { WorkPlaceService } from "./../../organization/work-place/work-place.service";
import { filterAndDebounce } from "../../core/utils";
import { finalize, switchMap } from "rxjs/operators";

@Component({
    selector: "op-shift-plan-edit-modal",
    templateUrl: "./shift-plan-modal.component.html"
})
export class ShiftPlanEditModalComponent {
    model: any;
    title: string;
    shiftTypes: app.operation.ShiftTypeSelectListViewModel[];
    isEditable = true;
    isSliderVisible = true;
    times: TimeObject[];
    customShiftTypeId = null;
    totalHours: number;
    timeRange = [480, 960];
    formatter: WorkReportSliderFormatter;
    sliderOptions: any;
    selectedWorkPlace: app.organization.WorkPlaceSelectListViewModel;
    selectedProjectTask: app.operation.ProjectTaskSelectListViewModel;
    isSubmittingCalendar: boolean;

    constructor(public activeModal: NgbActiveModal,
        private shiftPlanService: ShiftPlanService,
        private workPlaceService: WorkPlaceService,
        private projectTaskService: ProjectTaskService,
        private timeService: TimeService) {
        this.times = timeService.getTimes(0, 30);

        this.formatter = new WorkReportSliderFormatter(this.times);

        this.sliderOptions = {
            connect: true,
            tooltips: [this.formatter, this.formatter],
            keyboard: true,
            step: 15,
            range: {
                min: [this.times[0].value],
                max: [this.times[this.times.length - 1].value]
            },
            format: new WorkReportSliderFormatter(this.times, false),
            pips: {
                mode: "positions",
                values: [0, 100],
                format: this.formatter
            }
        };
    }

    ok() {
        this.isSubmittingCalendar = true;

        if (this.selectedWorkPlace) {
            this.model.workPlaceId = this.selectedWorkPlace.workPlaceId;
        } else {
            this.model.workPlaceId = null;
        }

        if (this.selectedProjectTask) {
            this.model.firstProjectTaskId = this.selectedProjectTask.firstProjectTaskId;
        } else {
            this.model.firstProjectTaskId = null;
            this.model.projectTaskId = null;
        }

        this.shiftPlanService.updateCalendar(this.model)
            .pipe(finalize(() => this.isSubmittingCalendar = false))
            .subscribe((res) => {
                this.activeModal.close(res);
            });
    }

    delete() {
        this.shiftPlanService.delete(null, this.model.shiftPlanId).subscribe((res) => {
            this.activeModal.close(res);
        });
    }

    projectTaskAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(term => term.length < 2 ? of([]) : this.projectTaskService.getList(1, 20, term)));
    }

    projectTaskFormatter = (projectTask: app.operation.ProjectTaskSelectListViewModel) => (projectTask.projectTaskNumber + " - " + projectTask.title);

    workPlaceAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(term => term.length < 2 ? of([]) : this.workPlaceService.getSelectList(1, 20, term)));
    }

    workPlaceFormatter = (workPlace: app.organization.WorkPlaceSelectListViewModel) => workPlace.title;

    cancel() {
        this.activeModal.dismiss("cancel");
    }

    countHours() {
        this.totalHours = (this.model.toMinutes - this.model.fromMinutes) / 60;
    }

    sliderChanged() {
        this.model.shiftTypeId = this.customShiftTypeId;
        this.model.fromMinutes = this.timeRange[0];
        this.model.toMinutes = this.timeRange[1];
        this.countHours();
    }

    updateSlider() {
        this.timeRange = [this.model.fromMinutes, this.model.toMinutes];
    }

    shiftTypeChanged(value: number) {
        this.model.shiftTypeId = value;
        let shiftType = this.shiftTypes.filter(x => x.shiftTypeId === this.model.shiftTypeId);
        if (shiftType.length > 0 && (shiftType[0].toMinutes !== shiftType[0].fromMinutes || shiftType[0].shiftTypeId === this.customShiftTypeId)) {
            this.isSliderVisible = true;
            if (shiftType[0].shiftTypeId === this.customShiftTypeId) {
                this.model.fromMinutes = 480;
                this.model.toMinutes = 960;
                this.updateSlider();
            }
            else {
                this.model.fromMinutes = shiftType[0].fromMinutes;
                this.model.toMinutes = shiftType[0].toMinutes;
                this.updateSlider();
            }
            this.countHours();
        }
        else {
            this.isSliderVisible = false;
        }
    }
}
