import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class SyncQueueService
    extends HttpService<app.metriqsync.SyncQueueListViewModel, app.metriqsync.SyncQueueListViewModel, app.metriqsync.SyncQueueListViewModel>
{
    objectKey = "mq.MetriqSync";

    constructor(http: HttpClient) {
        super(http, "/api/metriqsync/metriqsync/");
    }

    getFilter(): Observable<app.metriqsync.SyncQueueFilterViewModel> {
        return this.http.get<app.metriqsync.SyncQueueFilterViewModel>(this.url + "filter");
    }

    syncById(syncQueueId: number) {
        let params = new HttpParams();
        params = params.set("showToast", "true");
        return this.http.put(this.url + syncQueueId + "/syncById", "", { params });
    }

    getRow(syncQueueId: number): Observable<app.metriqsync.SyncQueueListViewModel> {
        return this.http.get<app.metriqsync.SyncQueueListViewModel>(this.url + syncQueueId + "/row");
    }

    runSyncJob() {
        let job = "SyncQueueJob";
        let params = new HttpParams();
        params = params.append("job", job);
        return this.http.post("/api/sys/job", null, { params });
    }
}
