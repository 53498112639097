import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: "af-note",
    styleUrls: ["./note.component.scss"],
    template: `
        <div [class]="classes">
            <ng-content></ng-content>
        </div>
    `
})
export class NoteComponent implements OnInit {
    @Input() type = "default";
    @Input() margin = true;
    @Input() size: string;

    constructor() { }

    ngOnInit() { }

    get classes() {
        return "note note-" + this.type + (this.size ? ` note-${this.size}` : "") + (this.margin ? "" : " note-nomargin");
    }
}
